import { Agjs2, ST } from '../agjs/types'
import { randomId, Utils } from '../agjs'
import { Runtime } from './runtime'
import { Scope, ScopeMember } from './scope'
import { F } from './factory'
import { toJS } from 'mobx'
import { Ancestry, Cursor } from './cursor'

interface IIndexedNode {
  node: Agjs2.Node
  location?: Agjs2.NodeLocation
  ca?: Agjs2.CustomNodeAttrs
  scope?: Scope
}

interface WfCallerInfo {
  elementId: string
  propKey: string
}

export interface DocLink {
  text: string
  getTarget: () => DocSnippet
}

export interface DocSnippet {
  subject: Agjs2.Node
  itemName: string | null
  originSummary: string
  summary: string
  description: string
  examples: string[]
  parameters: string[]
  methods: DocLink[]
  parent: DocLink | null
}

type CustomAttrFunc = (node: Agjs2.Node) => unknown

type NodeChangeCallback<T extends Agjs2.Node> = (node: T) => void

interface INodeIndexOpts {
  customAttrs?: CustomAttrFunc
  runtime: Runtime
}

const defaultResolver: Agjs2.LibResolverFn = (libId: Agjs2.nodeId, version: Agjs2.nodeId): Agjs2.Library => {
  throw new Error(`Cannot resolve library ${libId} (version ${version}). No lib resolver set in NodeIndex`)
}

/** IndexableNode contains way more nodes, not all of them are actually beeing mounted.
 * This list of node types are all nodes types that are actually processed by NodeIndex.mount()
 */
const MOUNTABLE_NODE_TYPES: Array<Agjs2.IndexableNode['t']> = [
  'List',
  'DefsList',
  'RenderPageElement',
  'DbTableDef',
  'PageElementClass',
  'Workflow',
  'VariableStmt',
  'Param'
]

/** returns true if the node is of a mountable type, meaning it has been mounted
 * when the project was loaded */
export function isMountable (node: Agjs2.Node): boolean {
  return MOUNTABLE_NODE_TYPES.includes((node as Agjs2.IndexableNode).t)
}

export class NodeIndex {
  _dbg: boolean = false
  project: Agjs2.Project
  runtime: Runtime
  globalScope: Scope
  globalClassScope: Scope

  // ObjMethods are in this scope. The only reasons ObjMethods are processed
  // is to be able to to a lookup on them. Maybe that is not necessary-
  // (currently only happens in the arguments editor).
  globalObjMethodScope: Scope

  index: Map<Agjs2.nodeId, IIndexedNode>
  libResolver: Agjs2.LibResolverFn = defaultResolver
  customAttrs: CustomAttrFunc = () => ({ ide: false })
  beforeRemoveNode: (node: Agjs2.Node) => void

  constructor (opts: INodeIndexOpts) {
    this.index = new Map()
    this.beforeRemoveNode = () => null
    this.runtime = opts.runtime
    if (opts.customAttrs != null) {
      this.customAttrs = opts.customAttrs
    }

    this.globalScope = new Scope(null, '$global', this.runtime)
    this.globalClassScope = new Scope(null, '$classes', this.runtime)
    this.globalObjMethodScope = new Scope(null, '$objMethods', this.runtime)
  }

  ensureInit (): void {
    if (this.project == null) throw new Error('NodeIndex wasn\'t initialized with a project.')
  }

  // begin v2 implementation

  buildFromProject (project: Agjs2.Project, libResolver: Agjs2.LibResolverFn = defaultResolver): void {
    this.project = project

    this.libResolver = libResolver

    this.index.clear()
    this.globalScope.reset()
    this.index.set(project.id, { node: project })

    this.reloadProjectLibs()

    const projectArrayAttr = (attrKey: string): void => {
      const attrArray = project[attrKey] as Agjs2.IndexableNode[]

      attrArray.forEach((item, index) =>
        this.mount(item, { parentId: project.id, parentAttr: attrKey, index }, this.globalScope))
    }

    // TODO: create sub scope/ namespace (and figure out how they relate- maybe allow an non-autodeletable namespace-scope
    projectArrayAttr('data')
    this.mount(project.db, { parentId: project.id, parentAttr: 'db' }, this.globalScope)

    project.pages.forEach(page => this.mountPage(page))

    // buildFromAttr('library')
    // buildFromAttr('imports')
  }

  // TODO: when do we ever take care of proper UNmounting a lib?!
  reloadProjectLibs (): void {
    this.globalClassScope.reset()
    this.globalObjMethodScope.reset()
    this.runtime.libs.reset()

    this.processLib(this.runtime.libs.builtIn(), false)

    this.project.imports.forEach(libImp => {
      const resolvedLib = this.libResolver(libImp.libraryId, libImp.libraryVersionId)
      this.runtime.libs.add(resolvedLib)
      this.processLib(resolvedLib, false)
    })

    this.runtime.libs.add(this.project.library)
    this.processLib(this.project.library, false)
  }

  /** When RenderPageElement was defined via AGL (JSX like syntax) not all props need to be specified.
   * After all the parsing and loading into nodeIndex has been done (because the library also needs to be available at this point),
   * populateMissingProps() will look at all page elements and create propValues for all props that have not been sent (with default values)
   * (NOTE: currently not used anywhere. Only had it in one test, but it doesn't make a difference there.
   * I also remember that after implementing this, I found the actual problem that caused my reasoning for needing this (and it had nothing to do with this).
   * So the whole function can probably removed.)
   */
  populateMissingProps (): void {
    (this.getByElementType<ST.Exp.Render.PageElement>('RenderPageElement')).forEach(element => {
      const cd = this.runtime.getElementClassDef(element)
      Object.keys(cd.props).forEach(propKey => {
        if (element.propValues[propKey] == null) {
          element.propValues[propKey] = Utils.deepClone(cd.props[propKey].defaultValue)
        }
      })
    })
  }

  removeEmptyScopes (scope: Scope): void {
    scope.children.forEach(childScope => this.removeEmptyScopes(childScope))
    if (scope.children.length === 0) scope.detachFromTree()
  }

  /**
   * Indexes the element by its id and creates a scope (if necessary)
   * with element related symbols.
   */
  mount (node: Agjs2.IndexableNode | ST.Exp.PageElementList | ST.Exp.ElementStyling | ST.Exp.WorkflowList | Agjs2.DbDescription | Agjs2.DbTableDef, location: Agjs2.NodeLocation, scope: Scope, unmount: boolean = false): void {
    if (this._dbg) console.log(`mount()${unmount ? ' [as unmount]' : ''}:`, toJS(node), 'Location:', toJS(location))
    switch (node.t) {
      // case 'Library':
      //   this.processWithAttrs(node, ['classDefs', 'objMethodDefs'], location, scope)
      //   break
      case 'DbTableDef':
        this.processDbTableDef(node, location, scope, unmount)
        break
      case 'DbDescription':
        node.tables.forEach((tableDef: Agjs2.DbTableDef, index: number) => this.mount(
          tableDef,
          {
            ...location,
            parentAttr: `${location.parentAttr}.tables`,
            index
          },
          scope,
          unmount
        )
        )
        if (unmount) this.removeEmptyScopes(scope)
        break
      case 'List':
      case 'DefsList':
        node.items.forEach((item: Agjs2.IndexableNode, index: number) => this.mount(
          item,
          {
            ...location,
            parentAttr: `${location.parentAttr}.items`,
            index
          },
          scope,
          unmount
        )
        )
        if (unmount) this.removeEmptyScopes(scope)
        break
      case 'RenderPageElement':
        if (node.cid === 'PageRootClass') {
          // Why doesn't TS picks up the type guard via node.cid?
          this.mountPage(node as ST.Exp.Render.PageRootElement, unmount)
        } else {
          this.processRenderPageElement(node, location, scope, unmount)
        }

        break
      case 'Workflow':
        this.processWorkflow(node, location, scope, unmount)
        break
      case 'PageElementClass':
        // this shouldn't be called here, because processClassDef gets triggered from processLib and takes
        // care of proper parentId (the lib) etc.
        this.processClassDef(node, location, this.globalClassScope, unmount)
        break
      case 'Param': {
        if (unmount) {
          this.index.delete(node.id)
          scope.remove(node)
          this.removeEmptyScopes(scope)
        } else {
          if (location.parentAttr === 'inputs') {
            const inputsScope = scope.findChildScope('$inputs$') ?? scope.sub('$inputs$')
            this.index.set(node.id, { node, location, scope: inputsScope })
            inputsScope.add(node)
          } else {
            this.index.set(node.id, { node, location, scope })
            scope.add(node)
          }
        }
        break
      }
      case 'VariableStmt': {
        if (unmount) {
          this.index.delete(node.id)
          scope.remove(node)
          this.removeEmptyScopes(scope)
        } else {
          // FIXME: is this correct? originally we didn't had a scope here.
          // VariableStmt doesn't have its own scope. Make scope mandatory and set the parent scope? or will this
          // destroy the parent scope upon unmounting etc.?
          // comment above outdated (or I don't know what it is about). code below was written well after that comment:

          // This variable belongs to an element (or at least is stored in a states attr, so add scope for this if necessary)
          if (location.parentAttr === 'states') {
            const statesScope = scope.findChildScope('$states$') ?? scope.sub('$states$')
            this.index.set(node.id, { node, location, scope: statesScope })
            statesScope.add(node)
          } else {
            this.index.set(node.id, { node, location, scope })
            scope.add(node)
          }
        }
        break
      }
      // case 'ConstData':
      //   this.processWithAttrs(tNode, [], location, scope)
      //   // Wenn coming from an element custom state, it will not have a scope
      //   if (scope != null) scope.add(tNode)
      //   break
    }
  }

  processDbTableDef (tableDef: Agjs2.DbTableDef, location: Agjs2.NodeLocation, parentScope: Scope, unmount: boolean): void {
    this.ensureInit()

    const mode = unmount ? 'remove' : 'add'

    // const tdScope = parentScope.sub(('$workflow$') + tableDef.id.toString())
    // tdScope.addOrRemove(mode, tableDef, 'thisWorkflow', '$thisWorkflow')

    const tdScope = parentScope.sub(('$dbTable$') + tableDef.id.toString())
    parentScope.addOrRemove(mode, tableDef)

    if (unmount) {
      this.index.delete(tableDef.id)
    } else {
      this.index.set(tableDef.id, {
        scope: tdScope,
        location,
        node: tableDef
      })
    }

    if (unmount) this.removeEmptyScopes(parentScope)
  }

  processWorkflow (wf: Agjs2.Workflow, location: Agjs2.NodeLocation, parentScope: Scope, unmount: boolean): void {
    this.ensureInit()

    const mode = unmount ? 'remove' : 'add'

    const wfScope = parentScope.sub(('$workflow$') + wf.id.toString())

    // We don't need the workflow to be a symbol of parent? Wfs will be fetched from reading propValues.workflows
    // parentScope.addOrRemove(mode, wf)

    wfScope.addOrRemove(mode, wf, 'thisWorkflow', '$thisWorkflow')

    // TODO: Add Workflow's Input/Output params to scope! (This is the way)
    ;(wf.inputs.concat(wf.outputs)).forEach((param: ST.Param) => wfScope.addOrRemove(mode, param))
    wf.inputs.forEach((inputParam, index) => {
      this.mount(inputParam,
        {
          parentId: wf.id,
          parentAttr: 'inputs',
          index
        },
        wfScope,
        unmount
      )
    })

    // const inputParams = {}
    // wf.inputs.forEach((param: ST.Param) => inputParams[param.name] = param)

    // const inputs = F.makeT('Record', { fields: inputParams }) as ST.Exp.RecordGen<ST.Param>
    // wfScope.addOrRemove(mode, inputs)

    if (unmount) {
      this.index.delete(wf.id)
    } else {
      this.index.set(wf.id, {
        scope: wfScope,
        location,
        node: wf
      })
    }

    if (unmount) this.removeEmptyScopes(parentScope)
  }

  processRenderPageElement (pe: ST.Exp.Render.PageElement, location: Agjs2.NodeLocation, parentScope: Scope, unmount: boolean): void {
    this.ensureInit()

    const isPage = pe.cid === 'PageRootClass'
    const isElementIterator = pe.cid === 'ElementIteratorClass'

    const mode = unmount ? 'remove' : 'add'

    const elScope = parentScope.sub(
      (isPage ? '$page$' : '$element$') + pe.id.toString()
    )

    Object.keys(pe.propValues).forEach((propKey, index) => {
      this.mount(pe.propValues[propKey],
        {
          parentId: pe.id,
          parentAttr: `propValues.${propKey}`,
          index
        },
        elScope,
        unmount
      )
    })

    if (isPage) {
      elScope.addOrRemove(mode, pe, 'thisPage', '$thisPage')

      // Pages are added to the global scope. Access from there for example when used as
      // parameter for navigation
      this.globalScope.addOrRemove(mode, pe)
    } else {
      parentScope.addOrRemove(mode, pe)
      elScope.addOrRemove(mode, pe, 'thisElement', '$thisElement')

      // first we have to process at least pe.propValues.dataSource BEFORE we can infer
      // its type and add the currentItem with this type to the scope.
      if (isElementIterator) {
        const exampleData = pe.propValues.exampleData as ST.Exp.IterableList

        const exp = pe.propValues.dataSource as ST.Exp.Expression

        let firstExample: ST.Exp.Variable

        let t: ST.DT.TypeDef | null = null

        const types = this.runtime.tc.inferType(exp)
        t = types[0]

        if (t != null) {
          if (t.type === 'List') {
            if (this._dbg) console.log('(V) using a very cool value:', JSON.stringify(t.itemType))
            if (t.itemType.type === '$never') {
              // TODO: as long as no dataSource is set, the type will return $never.
              // In this case we shouldn't really have a 'firstExample', instead the pageEditor should render a hint
              // that the dataSource needs to be configured!
              firstExample = F.makeVariable('currentItem', F.makePrimitiveDT('Null'), F.makeNull())
            } else {
              firstExample = F.makeVariable('currentItem', t.itemType, this.runtime.defaultValueForDataType(t.itemType))
            }
          } else {
            throw new Error(`dataSource of iterator must be a list. But it resolved to be a: ${JSON.stringify(t)}`)
          }
        } else {
          if (this._dbg) console.log('initializing currentItem from example data type')
          firstExample = exampleData.items.length === 0 ? F.makeVariable('currentItem', F.makeAnyDT(), F.makeString('')) : F.makeVariable('currentItem', F.makeAnyDT(), exampleData.items[0])
        }

        firstExample.id = `$currentItem-${pe.id}`

        if (unmount) {
          this.index.delete(firstExample.id)
        } else {
          this.index.set(firstExample.id, {
            scope: elScope,
            location,
            node: firstExample,
            ca: undefined
          })
        }

        elScope.addOrRemove(
          mode,
          // should be unknown instead of any
          firstExample,
          // F.makeVariable('__bogusItem', F.makeAnyDT(), exampleData.items[0]),
          'currentItem',
          '$currentItem'
        )
      }
    }

    if (unmount) {
      this.index.delete(pe.id)
    } else {
      if (this._dbg) console.log('processRenderPageElement: this is setting custom attributes for', pe.id)
      this.index.set(pe.id, {
        scope: elScope,
        location,
        node: pe,
        ca: this.customAttrs(pe) as Agjs2.CustomNodeAttrs
      })
    }

    // const stateScope = elScope.sub('$states$')
    // In this case, statesScope will be automatically created inside mount(),
    // because parentAttr is set to states.
    // So we pass along elScope here, but mount will create or reuse a subscope called '$states$'.
    pe.states.forEach((state, index) => {
      this.mount(state,
        {
          parentId: pe.id,
          parentAttr: 'states',
          index
        },
        // stateScope,
        elScope,
        unmount
      )
    })

    // if (unmount) this.removeEmptyScopes(parentScope)
    if (unmount) this.removeEmptyScopes(this.globalScope)
  }

  /** Unmounts a node, then applies changes by calling changeFunc,
   * then mounts the node again in the same location */
  remount <T extends Agjs2.NamedNode>(node: T, changeFunc: NodeChangeCallback<T>): void {
    const location = this.location(node)

    const scope = this.sc(node)
    const scopeName = scope.name
    const parentScope = scope.parent

    if (parentScope == null) throw new Error(`Cannot remount an element in scope ${scopeName} (has no parent scope)`)

    this.unmount(node)

    changeFunc(node)

    this.mount(node, location, parentScope.sub(scopeName))
  }

  unmount (node: Agjs2.NamedNode): void {
    if (this._dbg) console.log('UNmount():', toJS(node))
    this.ensureInit()
    this.mount(node, this.location(node), this.sc(node), true)
  }

  mountPage (page: ST.Exp.Render.PageRootElement, unmount: boolean = false): void {
    this.ensureInit()

    this.processRenderPageElement(page, {
      parentId: this.project.id,
      parentAttr: 'pages',
      index: this.project.pages.indexOf(page)
    },
    this.globalScope,
    unmount)
  }

  // processWithAttrs (el: Agjs2.IndexableNode | ST.Exp.StatementInExp, attrNames: string[], location: Agjs2.NodeLocation, scope: Scope, unmount: boolean): void {
  //   // TODO: customAttrs only needed for pages so far!
  //   // this.index.set(el.id, { scope, location, node: el, ca: this.customAttrs(el) as Agjs2.CustomNodeAttrs })
  //   attrNames.forEach(attrKey => {
  //     const attr = el[attrKey]
  //     if (Array.isArray(attr)) {
  //       attr.forEach((attrItem, index) => this.mount(attrItem, { parentId: el.id, parentAttr: attrKey, index }, scope, unmount))
  //     } else {
  //       this.mount(el[attrKey], { parentId: el.id, parentAttr: attrKey }, scope, unmount)
  //     }
  //   })
  // }

  processLib (lib: Agjs2.Library, unmount: boolean): void {
    const scope = this.globalClassScope
    lib.classDefs.forEach((cd, index) => {
      this.processClassDef(cd, {
        parentId: lib.id,
        parentAttr: 'classDefs',
        index
      },
      scope,
      unmount)
    })

    lib.globalMethodDefs.forEach((method, index) => {
      if (unmount) {
        this.index.delete(method.id)
      } else {
        const location = { parentId: lib.id, parentAttr: 'globalMethodDefs', index }
        this.index.set(method.id, { scope: this.globalScope, location, node: method })
      }
      this.globalScope.addOrRemove(unmount ? 'remove' : 'add', method)
    })

    lib.objMethodDefs.forEach((method, index) => {
      if (unmount) {
        this.index.delete(method.id)
      } else {
        const location = { parentId: lib.id, parentAttr: 'objMethodDefs', index }
        this.index.set(method.id, { scope: this.globalObjMethodScope, location, node: method })
      }
    })
  }

  /**
   * Main reason for this function is to create a new sub scope for the classdef and add the thisElement reference to it
   * (and of course, add classDef to index- only useful for getting the scope then).
   */
  processClassDef (cd: Agjs2.ClassDef, location: Agjs2.NodeLocation, scope: Scope, unmount: boolean): void {
    const mode = unmount ? 'remove' : 'add'

    const classScope = scope.sub(`$classDef${cd.id}`)

    scope.addOrRemove(mode, cd, cd.cid)
    classScope.addOrRemove(mode, cd, 'thisElement', '$thisElement')

    switch (cd.t) {
      case 'PageElementClass':
        // The following code is not used. Reason: This is the class def, so all props will only contain 'default' values
        // and for children nothing can be set. (so no need to load symbols).
        // If somewhere inside the renderContext of this class is used thisElement.<propName> for example,
        // the propName doesn't need to be in Scope because it is automatically inferred by nodeIndex.
        //
        // Object.keys(cd.props).forEach((propKey, index) => {
        //   this.mount(cd.props[propKey], { parentId: cd.id, parentAttr: `props.${propKey}`, index }, classScope, unmount)
        // })
        //
        //
        // FIXME: ist das hier nicht doppelt? da oben schon zum scope hinzugefügt wird???
        // 22.11.2023 habe ich folgende zeile mal testweise deaktiviert.
        // scope.addOrRemove(mode, cd)
        break
      case 'StaticClass':
        // TODO: was ist mit dem classScope oben (war ja ursprünglich für PageElementClass), brauche ich das für
        // diese singleton/static classes?
        this.globalScope.addOrRemove(mode, cd)

        // scope.addOrRemove(mode, cd)

        // TODO: is it a good idea to add all methods to the index? It is used when lookup() is used to quickly retrieve
        // the methods' documentation etc. It is probably OK. I don't like that this introduces redundancy (because a CallMethod node is
        // always an operand of a DotOp where the left side references the StaticClass anyway.
        cd.methods.forEach((method, index) => {
          if (unmount) {
            this.index.delete(method.id)
          } else {
            this.index.set(method.id, { scope: classScope, location: { parentAttr: 'methods', parentId: cd.id, index }, node: method })
          }
        })

        break
      default:
        throw new Error(`NodeIndex cannot process ${(cd as Agjs2.NamedNode).t} yet.`)
    }

    if (unmount) {
      this.index.delete(cd.id)
    } else {
      this.index.set(cd.id, { scope: classScope, location, node: cd })
    }
  }

  remove (tree: Agjs2.Node): void {
    if (Array.isArray(tree)) {
      tree.forEach(subtree => this.remove(subtree))
    } else {
      // VERY inefficient but carefree, maybe built a second index for ancestry? (indexed by parentId)
      this.index.forEach(indexedNode => {
        if ((indexedNode.location != null) && indexedNode.location.parentId === tree.id) {
          this.remove(indexedNode.node)
        }
      })
      this.index.delete(tree.id)
    }
  }

  lookup (id: Agjs2.nodeId): Agjs2.Node {
    const result = this.index.get(id)
    if (result != null) return result.node

    console.warn(`[lookup] Node "${id}" not found in nodeIndex. ${this.index.size} nodes known.`)
    throw new Error(`[lookup] Node "${id}" not found in nodeIndex`)
  }

  lookupAllowMissing (id: Agjs2.nodeId): Agjs2.Node | undefined {
    const result = this.index.get(id)
    return result?.node
  }

  debugIndexContent (label: string = ''): void {
    const output = (content: string): void => { typeof process !== 'undefined' ? process.stdout.write(content + '\n') : console.log(content) }
    output(`[debugIndexContent] ${label} ${this.index.size} nodes in index`)
    for (const [key, node] of this.index.entries()) {
      // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/restrict-template-expressions
      output(`[debugIndexContent] ${key}: ${node.node['t'] ?? ''}`)
    }
  }

  location (element: Agjs2.Node): Agjs2.NodeLocation {
    const result = this.index.get(element.id)
    if (result?.location != null) return result.location
    throw new Error(`[location] Node "${element.id}" not found in nodeIndex (or it has no location)`)
  }

  ca (element: Agjs2.InstanceNode): unknown {
    const result = this.index.get(element.id)
    if (result != null) return result.ca
    throw new Error(`[ca] Node "${element.id}" not found in nodeIndex`)
  }

  sc (element: Agjs2.Node): Scope {
    if ((element as Agjs2.Project).t === 'Project') {
      return this.globalScope
    }
    const result = this.index.get(element.id)
    if (result?.scope != null) return result.scope
    throw new Error(`Scope not found for node ${element.id}`)
  }

  docs (element: Agjs2.Node): DocSnippet {
    const exp = element as Agjs2.NodesWithT

    const doc: DocSnippet = {
      subject: exp,
      itemName: null,
      originSummary: '',
      description: '',
      summary: '<em>(No documentation available)</em>',
      examples: [],
      parameters: [],
      methods: [],
      parent: null
    }

    switch (exp.t) {
      case 'StaticClass': {
        const l = this.location(exp)
        doc.itemName = exp.name
        doc.originSummary = `Static class from ${l.parentId}`
        if (exp.meta.description != null) doc.description = exp.meta.description
        if (exp.meta.summary != null || doc.description !== '') doc.summary = exp.meta.summary ?? doc.description

        // doc.parentId = l.parentId
        doc.methods = exp.methods.map(m => ({
          text: m.name,
          getTarget: () => this.docs(m)
        }))
        break
      }
      case 'Method': {
        const l = this.location(exp)
        doc.itemName = exp.name
        doc.originSummary = `Method from ${l.parentId}`
        if (exp.meta.description != null) doc.description = exp.meta.description
        if (exp.meta.summary != null || doc.description !== '') doc.summary = exp.meta.summary ?? doc.description

        // doc.parentId = l.parentId
        // doc.methods = exp.methods.map(m => ({
        //   text: m.name,
        //   getTarget: () => this.docs(m)
        // }))
        break
      }
      case 'RenderPageElement': {
        // const l = this.location(exp)
        const cd = this.runtime.libs.getClassDef(exp.cid)
        doc.itemName = exp.name
        doc.originSummary = `Instance of ${cd.name}`
        const stDocs = 'Custom states:\n' + exp.states.map(st => `${st.name}`).join('\n')
        if (exp.states.length > 0) doc.summary = stDocs
        // if (cd.meta.description != null) doc.description = cd.meta.description
        // if (cd.meta.summary != null || doc.description !== '') doc.summary = cd.meta.summary ?? doc.description
        break
      }
      default: {
        // const l = this.location(exp)
        // doc.itemName = `Bogus docs for ${exp.t}`
        break
      }
    }
    return doc
  }

  getByElementType <T extends Agjs2.IndexableNode>(type: T['t']): T[] {
    const result: T[] = []
    for (const el of this.index.values()) {
      const node = el.node as T
      if (node.t === type) result.push(node)
    }
    return result
  }

  getParentPage (element: ST.Exp.Render.PageElement | ST.Exp.Render.PageRootElement): ST.Exp.Render.PageRootElement {
    if ((element as ST.Exp.Render.PageRootElement).cid === 'PageRootClass') {
      return element as ST.Exp.Render.PageRootElement
    } else {
      const loc = this.location(element)
      const p = this.lookup(loc.parentId)
      return this.getParentPage(p as ST.Exp.Render.PageElement)
    }
  }

  getEventsCallingWorkflow (workflowId: string): WfCallerInfo[] {
    // TODO: ideally, have a structure that stores these references.
    // second best, check if this is a page workflow and if so, only check elements on the associated page.

    const callers: WfCallerInfo[] = []

    const addEventsFromElement = (element: ST.Exp.Render.PageElement): void => {
      const cd = this.runtime.getElementClassDef(element)
      Object.keys(cd.props).forEach(propKey => {
        if (cd.props[propKey].dataTypes[0].type === 'EventCallback') {
          if ((element.propValues[propKey] as ST.Exp.WorkflowRef)?.refId === workflowId) {
            callers.push({ elementId: element.id, propKey })
          }
        }
      })
    }

    const scanEventsOfChild = (element: ST.Exp.Render.PageElement): void => {
      addEventsFromElement(element)
      if (element.propValues?.children != null) {
        (element.propValues.children as ST.Exp.PageElementList).items.forEach(child => {
          scanEventsOfChild(child)
        })
      }
    }

    this.project.pages.forEach(page => {
      page.propValues.children.items.forEach(el => scanEventsOfChild(el))
    })
    return callers
  }

  getStates (): ST.Exp.Variable[] {
    let result: ST.Exp.Variable[] = [];
    (this.getByElementType<ST.Exp.Render.PageElement>('RenderPageElement')).forEach(pe => {
      result = result.concat(pe.states)
    })
    return result
  }

  /**
   * Retrieves a list of nodes that are suitable for autocompleting a DotOp expression.
   * Note that while in a normally parsed project structure, there only exist DotOp nodes and GetNode or CallMethod nodes,
   * but when used for autocompleting the editor input, the notes directly are used (and returned).
   * For example, instead of CallMethod, the Method node itself is returned.
   * @param leftOperand The node that the dotOp is applied to. For example, if leftOperand is a StaticClass, the function will return a list of all the classes' methods and props.
   * @returns the node itself that the right side should reference, not a DotOpRightSide resolve.
   *
   */
  suggestDotOpRightSide (leftOperand: Agjs2.Node | ST.Exp.Expression | ST.Exp.StaticClass): Array<ST.Exp.GetAttr | ST.Exp.ObjMethod | ST.Exp.Method> {
    let results: Array<ST.Exp.GetAttr | ST.Exp.ObjMethod | ST.Exp.Method> = []

    // FIXME:
    // const filterByName = (exp: Agjs2.Node): boolean => exp.name.toLowerCase().startsWith(name.toLowerCase())
    const filterByName = (exp: ST.Exp.GetAttr | ST.Exp.ObjMethod): boolean => true

    // TODO: typecast shouldnt be necessary, consider widening acceptanced types in inferType()
    const leftTypes = this.runtime.tc.inferType(leftOperand as Agjs2.IndexableNode)
    if (leftTypes.length > 1) throw new Error('More than one return type when lookings at DotOpLeftSide')
    const leftType = leftTypes[0]

    const pushClassPropsToResults = (cid: string): void => {
      const classDef = this.runtime.libs.getClassDef(cid)

      Object.keys(classDef.props).forEach(propId => {
        const p = classDef.props[propId]
        results.push({
          t: 'GetAttr',
          id: randomId(),
          attrPath: 'propValues.' + propId,
          _name: p.name
        })
      })
    }

    results = this.runtime.libs.findObjMethods(leftType)

    switch (leftType.type) {
      case 'Record':
        results = results.concat(leftType.fields.map(field => { return F.makeGetAttr(field.name) }))
        return results.filter(filterByName)
      case 'StaticClass': {
        const staticClass = this.lookup(leftType.refId) as ST.Exp.StaticClass
        pushClassPropsToResults(leftType.refId)
        results = results.concat(staticClass.methods)
        return results.filter(filterByName)
      }
      case 'PageElementClass': {
        pushClassPropsToResults(leftType.cid)
        return results.filter(filterByName)
      }
      case 'PageElementInstance': {
        results = results.concat([
          {
            t: 'GetAttr',
            id: randomId(),
            attrPath: 'isVisible',
            _name: 'isVisible'
          },
          {
            t: 'GetAttr',
            id: randomId(),
            attrPath: 'states',
            _name: 'states'
          }
        ])

        pushClassPropsToResults((this.lookup(leftType.refId) as ST.Exp.Render.PageElement).cid)

        return results.filter(filterByName)
      }
      default:
        return results.filter(filterByName)
    }
  }

  /**
   * Searches through all nodes in the project (not only those in node index) and returns matching nodes.
   * WARNING: currently only searches through pages and workflows!
   */
  selectNodes (t: Agjs2.NodesWithT['t'], attrs: Record<string, string | number | boolean | null>): Array<{ parent: Agjs2.IndexableNode, node: Agjs2.Node }> {
    interface NodeWithParent { parent: Agjs2.IndexableNode, node: Agjs2.Node }
    const matches: Record<string, NodeWithParent> = {}

    const addMatches = (startNode: Agjs2.Node): void => {
      const cursor = new Cursor(startNode)
      cursor.iterate2(
        (node: Agjs2.Node, ancestry: Ancestry): null => {
          if ((node as Agjs2.NodesWithT).t === t) {
            if (Object.keys(attrs).every(key => node[key] === attrs[key])) {
              // TODO: recursively go back in the ancestry tree to find the closest node that is IN the node index,
              // then use this as orientation.
              const findIndexedParent = (currentNode: Agjs2.Node, anc: Ancestry): Agjs2.Node => {
                if (this.index.has(currentNode.id)) return currentNode
                if (anc.parentNode == null || anc.ancestors == null) throw new Error('Node doesn\'t have any indexed parents.')
                return findIndexedParent(anc.parentNode, anc.ancestors)
              }
              const parent = findIndexedParent(node, ancestry) as Agjs2.IndexableNode
              matches[node.id] = { parent, node }
            }
          }
          return null
        }
      )
    }

    this.project.pages.forEach(page => addMatches(page))
    this.project.workflows.forEach(workflow => addMatches(workflow))

    return Object.keys(matches).map(key => matches[key])
  }

  getReferenceFor (sm: ScopeMember): ST.Exp.ResolvedSymbol {
    const ref: ST.Exp.ResolvedSymbol = { t: 'GetNode', refId: sm.node.id, id: randomId(), _name: sm.name }
    return ref
  }

  /** Returns a list of ResolvedSymbol expressions on the given search string.
   * @See resolveDotOp
   */
  resolveName (name: string, scope: Scope): ST.Exp.ResolvedSymbol[] {
    return scope.findByName(name).map(this.getReferenceFor)
  }

  newName (prefix: string, opts: { startWithoutNumber?: boolean, minNumber?: number, scope?: Scope, namesList?: string[] } = {}): string {
    let max = 1

    // FIXME: allow setting namesList AND scope at the same time?
    if (opts.namesList == null) {
      // Special option for pageElements: search also symbols of child scopes?
      const symbols = opts?.scope == null ? this.globalScope.allSymbols() : opts.scope.allSymbols()

      symbols.forEach(symbol => {
        const remainingName = symbol.name.toUpperCase().replace(prefix.toUpperCase(), '')
        if (remainingName === '') {
          max = Math.max(max, 2)
        } else {
          const suffix = Number.parseInt(remainingName) * 1
          if (Number.isInteger(suffix)) max = Math.max(max, suffix + 1)
        }
      })
    } else {
      opts.namesList.forEach(name => {
        const remainingName = name.toUpperCase().replace(prefix.toUpperCase(), '')
        if (remainingName === '') {
          max = Math.max(max, 2)
        } else {
          const suffix = Number.parseInt(remainingName) * 1
          if (Number.isInteger(suffix)) max = Math.max(max, suffix + 1)
        }
      })
    }

    if (opts?.minNumber != null) max = Math.max(max, opts.minNumber)
    if (opts.startWithoutNumber != null && max === 1) {
      return prefix
    } else {
      return `${prefix}${max}`
    }
  }
}
