import {
  EditorView,
  Decoration,
  WidgetType,
  DecorationSet,
  ViewPlugin,
  ViewUpdate
} from '@codemirror/view'

import { ChangeDesc, EditorState, Extension, Facet, StateEffect, StateField, Transaction, Range } from '@codemirror/state'

import { Tree, TreeCursor, SyntaxNode } from '@lezer/common'

import { syntaxTree } from '@codemirror/language'

const foldEffect = StateEffect.define<DocRange>({ map: mapRange })
const unfoldEffect = StateEffect.define<DocRange>({ map: mapRange })

function mkBtn (symbol: string, title: string, onClick?: (ev: Event) => void): HTMLElement {
  const btn = document.createElement('div')
  btn.textContent = symbol
  btn.title = title
  btn.className = 'material-symbols-outlined select-none cursor-pointer cm-unfold-args-btn'
  if (onClick != null) btn.onclick = onClick
  return btn
}

function mkLaunchArgEditorCallback (view: EditorView, invocNode: SyntaxNode, refElement: HTMLElement): (event: Event) => void {
  return (event: Event): void => {
    const config = view.state.facet(foldConfig)
    // const pos = view.posAtDOM(event.target as HTMLElement)
    // const invocNode = syntaxTree(view.state).resolveInner(pos)
    // const node = invocNode.node

    if (config.length === 0) throw new Error('No config supplied for foldConfig')

    const editArgumentsCallback = config[0].editArguments

    editArgumentsCallback(
      syntaxTree(view.state),
      view.state.sliceDoc(),
      invocNode.from, invocNode.to,
      refElement,
      (replacedCode: string) => view.dispatch({ changes: { from: invocNode.from, to: invocNode.to, insert: replacedCode } })
    )

    event.preventDefault()
  }
}

interface DocRange { from: number, to: number }

function mapRange (range: DocRange, mapping: ChangeDesc): DocRange | undefined {
  const from = mapping.mapPos(range.from, 1); const to = mapping.mapPos(range.to, -1)
  return from >= to ? undefined : { from, to }
}

function findFold (state: EditorState, from: number, to?: number): DocRange | null {
  let found: DocRange | null = null

  if (to != null) {
    state.field<typeof foldState>(foldState, false)?.between(from, to, (from: number, to: number): void => {
      if ((found == null) || found.from > from) found = { from, to }
    })
  } else {
    state.field<typeof foldState>(foldState, false)?.between(from, state.doc.length, (from: number, to: number): void => {
      if ((found == null) || found.from > from) found = { from, to }
    })
  }
  return found
}

/**
 * This widget renders the buttons to fold or edit the argument list
 * (not the widget that shows a folded symbol invocation
 */
class UnfoldedInvocationWidget extends WidgetType {
  constructor (readonly node: SyntaxNode) {
    super()
  }

  eq (other: UnfoldedInvocationWidget): boolean { return other.node === this.node }

  toDOM (view: EditorView): HTMLElement {
    const wrap = document.createElement('span')

    wrap.className = 'cm-arguments-widget'

    const groupDiv = wrap.appendChild(document.createElement('div'))
    groupDiv.className = 'd-inline-block'

    groupDiv.appendChild(document.createElement('span'))

    const triggerFolding = (event: Event): void => {
      // const pos = view.posAtDOM(event.target as HTMLElement)
      // const invocNode = syntaxTree(view.state).resolveInner(pos)
      // const node = invocNode.node

      const invocNode = this.node
      const symbolNode = invocNode.getChild('Symbol')
      if (symbolNode == null) throw new Error('SymbolInvoc but no Symbol node as child')

      const { from, to } = invocNode

      const folded = findFold(view.state, from, to)
      if (folded != null) {
        view.dispatch({ effects: unfoldEffect.of(folded) })
      } else {
        view.dispatch({ effects: foldEffect.of({ from, to }) })
      }
      event.preventDefault()
    }

    const toggleBtn = mkBtn('collapse_content', 'Shrink', triggerFolding)
    const editBtn = mkBtn('tune', 'Edit arguments')
    editBtn.onclick = mkLaunchArgEditorCallback(view, this.node, editBtn)

    groupDiv.appendChild(toggleBtn)
    groupDiv.appendChild(editBtn)

    return wrap
  }

  ignoreEvent (): boolean {
    return false
  }
}

// FIXME: This whole file needs to be refactored potentially, because of a shared global state?
// is the foldState instance (in this module) shared between all CodeMirro instances or is this just the
// functional component and does not 'store' the 'state' in this module?
function foldStateManager (): Extension {
  return foldState
}

const markersPlugin = ViewPlugin.fromClass(class {
  // markers: RangeSet<FoldMarker>
  // These decorations only store the fold/unfold buttons.
  // Need another place to store actual range (of replaced content) to define atomic ranges.
  decorations: DecorationSet

  constructor (view: EditorView) {
    // this.markers = this.buildMarkers(view)
    this.decorations = this.buildFoldBtns(view)
  }

  update (update: ViewUpdate): void {
    if (update.docChanged || update.viewportChanged ||
        update.startState.field(foldState, false) !== update.state.field(foldState, false) ||
        syntaxTree(update.startState) !== syntaxTree(update.state)) {
      this.decorations = this.buildFoldBtns(update.view)
    }
  }

  /**
   * This will walk the whole syntax tree (of visible editor range)
   * and insert a widget (showing toggle/edit arguments editor buttons)
   * If the widget should encompass different node types (symbol invoc or the arg list),
   * it needs to be changed here.
   */
  buildFoldBtns (view: EditorView): DecorationSet {
    const widgets: Array<Range<Decoration>> = []
    for (const { from, to } of view.visibleRanges) {
      syntaxTree(view.state).iterate({
        from,
        to,
        enter: (nodeCursor: TreeCursor) => {
          if (nodeCursor.name === 'SymbolInvoc') {
            // Decide what to show intitially:

            // Show unfolded args:
            const widgetUI = Decoration.widget({
              widget: new UnfoldedInvocationWidget(nodeCursor.node),
              side: 1
            })
            // insert at pos -1 and use side=1 (above) so that the buttons are inserted inbetween
            // the parenthesis. not happy with this but OK for now (that way, this widget gets hidden when
            // args are folded.)
            widgets.push(widgetUI.range(nodeCursor.to - 1))

            // Show folded args:
            // TODO: showing folded state right away doesn't work because this just creates the widget,
            // but not the markers (works, but doesn't let it unfold- or would need a different mechanism?
            // but we need to know with parts are folded and which aren't, so foldedState is crucial)
            // const widgetUI = Decoration.replace({
            //   widget: new FoldedInvocationWidget(nodeCursor.node)
            // })
            // widgets.push(widgetUI.range(nodeCursor.from, nodeCursor.to))
          }
        }
      })
    }
    return Decoration.set(widgets, true)
  }

  /*
  buildMarkers(view: EditorView) {
    let builder = new RangeSetBuilder<FoldMarker>()
    for (let line of view.viewportLineBlocks) {
      const mark = findFold(view.state, line.from, line.to) ? canUnfold
        : foldable(view.state, line.from, line.to) ? canFold : null
      if (mark) builder.add(line.from, line.from, mark)
    }
    return builder.finish()
  } */
}, {
  decorations: instance => instance.decorations
})

type EditArgumentsCallback = (tree: Tree, source: string, from: number, to: number, ref: HTMLElement, replacedCode: (text: string) => void) => void

interface ArgFoldConfig {
  editArguments: EditArgumentsCallback
}

const foldConfig = Facet.define<ArgFoldConfig>({})

export function foldableArgs (config: ArgFoldConfig): Extension {
  return [
    markersPlugin,
    foldStateManager(),
    foldConfig.of(config)
  ]
}

function foldExists (folded: DecorationSet, from: number, to: number): boolean {
  let found = false
  // folded.between(from, from, (a, b) => { if (a == from && b == to) found = true })
  folded.between(from, to, (a, b) => { if (a === from && b === to) found = true })
  return found
}

/**
 * This widget renders the method name of the folded method and a button to unfold.
 * @see UnfoldedInvocationWidget which renders only the buttons to shrink (and edit)
 */
class FoldedInvocationWidget extends WidgetType {
  eq (other: UnfoldedInvocationWidget): boolean { return other.node === this.node }

  constructor (readonly node: SyntaxNode) {
    super()
  }

  toDOM (view: EditorView): HTMLElement {
    const { state } = view

    const triggerUnfolding = (event: Event): void => {
      const pos = view.posAtDOM(event.target as HTMLElement)
      const folded = findFold(view.state, pos)
      if (folded != null) view.dispatch({ effects: unfoldEffect.of(folded) })
      event.preventDefault()
    }

    const unfoldBtn = mkBtn('more_horiz', 'Edit as code', triggerUnfolding)
    const invocNode = this.node
    const symbolNode = invocNode.getChild('Symbol')
    if (symbolNode == null) throw new Error('SymbolInvoc without Symbol node')

    const methodName = state.sliceDoc(symbolNode.from, symbolNode.to)

    const element = document.createElement('span')
    element.textContent = methodName
    element.setAttribute('aria-label', state.phrase('folded code'))
    element.title = `Configure call to "${methodName}"`
    element.className = 'cm-folded-symbol-invoc select-none cursor-pointer'
    element.onclick = mkLaunchArgEditorCallback(view, invocNode, element)

    element.append(unfoldBtn)

    return element
  }
}

const foldState = StateField.define<DecorationSet>({
  create () {
    return Decoration.none
  },
  update (folded: DecorationSet, tr: Transaction): DecorationSet {
    folded = folded.map(tr.changes)

    for (const e of tr.effects) {
      if (e.is(foldEffect) && !foldExists(folded, e.value.from, e.value.to)) {
        const tree = syntaxTree(tr.state)
        const node = tree.resolve(e.value.to, -1)
        // node = SymbolInvoc(Symbol,ListArgs(Number,Number,Number))
        const invocNode = node
        if (invocNode == null) throw new Error('SymbolInvoc but no Symbol node as child')

        const foldWidget = Decoration.replace({
          widget: new FoldedInvocationWidget(invocNode)
        })

        const range = foldWidget.range(e.value.from, e.value.to)
        folded = folded.update({
          add: [range]
        })
      } else if (e.is(unfoldEffect)) {
        folded = folded.update({
          filter: (from, to) => e.value.from !== from || e.value.to !== to,
          filterFrom: e.value.from,
          filterTo: e.value.to
        })
      }
    }
    return folded
  },
  provide: f => [
    EditorView.decorations.from(f),
    EditorView.atomicRanges.of(view => {
      const field = view.state.field(foldState, false)
      if (field == null) return Decoration.none
      return field
    })
  ]
})
