import { Agjs2, ST } from '../types'
import { makeCloneOfClassDef, toCustomElement } from '../../custom_element_converter'
import { MGClassDefClone, MGClassDefCreateFromElement, MGClassDefPropDefCreate, MGClassDefPropDefDelete, MGClassDefSetRenderContext } from '../mutation_commands'
import { OpCollection } from './op_collection'

export class ClassDefOp extends OpCollection {
  createFromElement (elementNode: ST.Exp.Render.PageElement): void {
    // TODO: how is the name generated?
    const clonedDef = toCustomElement(elementNode, this.config.runtime)

    this.config.history.createInsertGroup<MGClassDefCreateFromElement>(
      'classdef.element.create_from',
      {
        name: clonedDef.name,
        id: clonedDef.id,
        elementName: elementNode.name,
        elementId: elementNode.id
      }, {
        parentId: this.nodeIndex.project.id,
        parentAttr: 'library.classDefs',
        index: this.nodeIndex.project.library.classDefs.length
      }, clonedDef
    )
  }

  /** @returns cid of new class def */
  cloneClassDef (cid: string): string {
    const classDef = this.config.runtime.libs.getClassDef(cid)
    const clonedDef = makeCloneOfClassDef(classDef, this.config.runtime)

    // TODO: have a clear understanding of difference between cid and id,
    // those are used interchangibly, but I feel there should only be one.
    clonedDef.id = clonedDef.cid

    this.config.history.createInsertGroup<MGClassDefClone>(
      'classdef.clone',
      {
        name: clonedDef.name,
        id: clonedDef.id,
        sourceName: classDef.name,
        sourceId: classDef.id
      }, {
        parentId: this.nodeIndex.project.id,
        parentAttr: 'library.classDefs',
        index: this.nodeIndex.project.library.classDefs.length
      }, clonedDef
    )

    return clonedDef.cid
  }

  createPropDef (classDef: Agjs2.ClassDef, propDef: ST.Param): void {
    this.config.history.createInsertGroup<MGClassDefPropDefCreate>(
      'classdef.propdef.create',
      {
        name: classDef.name,
        id: classDef.id,
        propName: propDef.name
      }, {
        parentId: classDef.id,
        parentAttr: 'props'
      }, propDef,
      propDef.id // FIXME: name or id? here needs to be the id I think, but probably its used interchangibly (wrongly)?
    )
  }

  deletePropDef (classDef: Agjs2.ClassDef, propDef: ST.Param): void {
    this.config.history.createDeleteRecordGroup<MGClassDefPropDefDelete>(
      'classdef.propdef.delete',
      {
        name: classDef.name,
        id: classDef.id,
        propName: propDef.name
      }, {
        parentId: classDef.id,
        parentAttr: 'props'
      }, propDef.id
    )
  }

  setRenderContext (cid: string, newExp: ST.Exp.RenderContext): void {
    const classDef = this.config.runtime.libs.getClassDef(cid)

    if (classDef == null) {
      console.log(`WARNING: classdef ${cid} not found.`)
      return
    }

    this.config.history.createChangeGroup<MGClassDefSetRenderContext>('classdef.element.set_render_context', {
      name: classDef.name,
      id: classDef.id
    },
    classDef,
    'render',
    newExp
    )
  }
}
