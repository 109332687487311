// r, g, b: 0-255
// a: 0-1
export interface RGBAColor {
  r: number
  g: number
  b: number
  a: number
}

// h: 0-360
// w, b: 0-1
export interface HWBAColor {
  h: number
  w: number
  b: number
  a: number
}

// h: 0-360
// s, v: 0-1
export interface HSVAColor {
  h: number
  s: number
  v: number
  a: number
}

export function getContrastingTextColor (color: RGBAColor): 'black' | 'white' {
  const r = color.r / 255
  const g = color.g / 255
  const b = color.b / 255

  const L = 0.2126 * r + 0.7152 * g + 0.0722 * b

  // return L > 0.179 ? 'black' : 'white'
  return L > 0.5 ? 'black' : 'white'
}

/*
 * conversion functions directly taken from CSS specification
 * https://drafts.csswg.org/css-color/#hwb-to-rgb
 */

/**
 * @param {number} red - Red component 0..1
 * @param {number} green - Green component 0..1
 * @param {number} blue - Blue component 0..1
 * @return {number[]} Array of HSL values: Hue as degrees 0..360, Saturation and Lightness in reference range [0,100]
 */
function rgbToHsl (red: number, green: number, blue: number): [number, number, number] {
  const max = Math.max(red, green, blue)
  const min = Math.min(red, green, blue)

  // default hue was NaN in original reference implementation:
  // let [hue, sat, light] = [NaN, 0, (min + max) / 2]
  // this would kick for any grey values (at least black).
  // Instead set hue to zero.

  let [hue, sat, light] = [0, 0, (min + max) / 2]

  const d = max - min

  if (d !== 0) {
    sat = (light === 0 || light === 1)
      ? 0
      : (max - light) / Math.min(light, 1 - light)

    switch (max) {
      case red: hue = (green - blue) / d + (green < blue ? 6 : 0); break
      case green: hue = (blue - red) / d + 2; break
      case blue: hue = (red - green) / d + 4
    }

    hue = hue * 60
  }

  // Very out of gamut colors can produce negative saturation
  // If so, just rotate the hue by 180 and use a positive saturation
  // see https://github.com/w3c/csswg-drafts/issues/9222
  if (sat < 0) {
    hue += 180
    sat = Math.abs(sat)
  }

  if (hue >= 360) {
    hue -= 360
  }

  return [hue, sat * 100, light * 100]
}

/**
 * @param {number} red - Red component 0..1
 * @param {number} green - Green component 0..1
 * @param {number} blue - Blue component 0..1
 * @return {number[]} Array of HWB values: Hue as degrees 0..360, Whiteness and Blackness in reference range [0,100]
 */
function rgbToHwb (red: number, green: number, blue: number): [number, number, number] {
  const hsl = rgbToHsl(red, green, blue)
  const white = Math.min(red, green, blue)
  const black = 1 - Math.max(red, green, blue)
  return ([hsl[0], white, black])
}

/**
 * @param {number} hue - Hue as degrees 0..360
 * @param {number} sat - Saturation in reference range [0,100]
 * @param {number} light - Lightness in reference range [0,100]
 * @return {number[]} Array of sRGB components; in-gamut colors in range [0..1]
 */
function hslToRgb (hue: number, sat: number, light: number): [number, number, number] {
  sat /= 100
  light /= 100

  function f (n: number): number {
    const k = (n + hue / 30) % 12
    const a = sat * Math.min(light, 1 - light)
    return light - a * Math.max(-1, Math.min(k - 3, 9 - k, 1))
  }

  return [f(0), f(8), f(4)]
}

/**
 * @param {number} hue -  Hue as degrees 0..360
 * @param {number} white -  Whiteness in reference range [0,100]
 * @param {number} black -  Blackness in reference range [0,100]
 * @return {number[]} Array of RGB components 0..1
 */
function hwbToRgb (hue: number, white: number, black: number): [number, number, number] {
  white /= 100
  black /= 100
  if (white + black >= 1) {
    const gray = white / (white + black)
    return [gray, gray, gray]
  }
  const rgb = hslToRgb(hue, 100, 50)
  for (let i = 0; i < 3; i++) {
    rgb[i] *= (1 - white - black)
    rgb[i] += white
  }
  return rgb
}

export const HexToRgb = (hex: string): RGBAColor => {
  if (hex[0] === '#') {
    return HexToRgb(hex.substring(1))
  }

  if (/^([0-9A-Fa-f]{3})$/.test(hex)) {
    return (
      {
        r: parseInt(hex[0] + hex[0], 16),
        g: parseInt(hex[1] + hex[1], 16),
        b: parseInt(hex[2] + hex[2], 16),
        a: 1
      }
    )
  } else if (/^([0-9A-Fa-f]{6})$/.test(hex)) {
    return (
      {
        r: parseInt(hex.slice(0, 2), 16),
        g: parseInt(hex.slice(2, 4), 16),
        b: parseInt(hex.slice(4, 6), 16),
        a: 1
      }
    )
  } else if (/^([0-9A-Fa-f]{8})$/.test(hex)) {
    return (
      {
        r: parseInt(hex.slice(0, 2), 16),
        g: parseInt(hex.slice(2, 4), 16),
        b: parseInt(hex.slice(4, 6), 16),
        a: parseInt(hex.slice(6, 8), 16) / 255
      }
    )
  }
  throw new Error(`Don't know how to convert hex color code "${hex}" to RGBAColor.`)
}

export const RgbToHex = (color: RGBAColor): string => {
  const redHex = color.r.toString(16).padStart(2, '0')
  const greenHex = color.g.toString(16).padStart(2, '0')
  const blueHex = color.b.toString(16).padStart(2, '0')
  const alphaHex = color.a < 1 ? Math.round(color.a * 255).toString(16).padStart(2, '0') : ''

  const hexValue = `#${redHex}${greenHex}${blueHex}${alphaHex}`

  return hexValue.toUpperCase()
}

export const HwbToCss = (color: HWBAColor): string => {
  const hue = Math.round(color.h).toString()
  const white = `${Math.round(color.w * 100)}%`
  const black = `${Math.round(color.b * 100)}%`
  const alpha = color.a < 1 ? ` / ${color.a}` : ''

  return `hwb(${hue} ${white} ${black}${alpha})`
}

/**
 * The RGB color space features values from 0-255,
 * the HSV color space has hue from 0-360, but saturation and value from 0-1 (decimals).
 * Even though the spaces should be convertible lossless, the algorithm below (by ChatGPT)
 * only works accurately using decimal numbers.
 * The HSV space is only used internally by the color picker widget so that is OK.
 */
export function RgbToHwb (color: RGBAColor): HWBAColor {
  const { r, g, b } = color

  const [h, w, bl] = rgbToHwb(r / 255, g / 255, b / 255)
  return { h, w, b: bl, a: color.a }
}

export function HwbToRgb (color: HWBAColor): RGBAColor {
  const [red, green, blue] = hwbToRgb(color.h, color.w * 100, color.b * 100)
  return {
    r: Math.round(red * 255),
    g: Math.round(green * 255),
    b: Math.round(blue * 255),
    a: color.a
  }
}

export function HwbToHsv (color: HWBAColor): HSVAColor {
  const { h, w, b, a } = color
  const v = 1 - b
  return {
    h,
    v,
    s: (v - w) / v,
    a
  }
}

export function HsvToHwb (color: HSVAColor): HWBAColor {
  const { h, s, v, a } = color
  return {
    a,
    h,
    w: (1 - s) * v,
    b: 1 - v
  }
}
