import { VNode } from 'preact'
import { useLayoutEffect, useRef, useState } from 'preact/hooks'
import { toCamelCase } from '../agjs/string_utils'
import { CSSPropValuePicker } from './CSSPropValuePicker'
import { IStyleWidgetProps } from './expression_editors/ElementStylingInput'

interface SpacingPolyProps {
  x1: number
  y1: number
  x2: number
  y2: number
  x3: number
  y3: number
  x4: number
  y4: number
  contrast: number
  name: string
  activeProp: string
}

const SpacingBackground = ({ x1, y1, x2, y2, x3, y3, x4, y4, contrast, name, activeProp }: SpacingPolyProps): VNode => {
  const fills = [
    '#f5f5f5',
    '#e5e5e5',
    '#d4d4d4',
    '#a3a3a3',
    '#737373',
    '#525252',
    '#404040',
    '#262626',
    '#171717'
  ]
  // was used to set fill={fills[constrast]} in SVG, but now replaced with activeProp
  const [spacingType] = name.split('-')
  const [activeSpacingType] = activeProp.split('-')

  let fill = fills[2]

  if (spacingType === activeSpacingType) {
    // const fillId = spacingType === 'margin' ? 3 : 4
    const fillId = 3
    fill = name === activeProp ? fills[fillId + 1] : fills[fillId]
  }
  return (
    <svg baseProfile='tiny' xmlns='http://www.w3.org/2000/svg' style='position: absolute; left: 0; top:0; width: 100%; height: 100%;'>
      <defs />
      <polygon fill={fill} points={`${x1},${y1} ${x2},${y2} ${x3},${y3} ${x4},${y4}`} stroke={fill} />
    </svg>
  )
}

const SpacingInput = ({ style, propName, onChange, onFocus, theme, onBlur }: IStyleWidgetProps & { propName: string, onFocus: (propName: string) => void, onBlur: (propName: string) => void }): VNode => {
  const camelCasePropName = toCamelCase(propName)

  return (
    <div className={`item c-${propName}`}>
      <CSSPropValuePicker unitType='length' propName={camelCasePropName} style={style} options={theme.spacing} onChange={onChange} onFocus={() => onFocus(propName)} onBlur={() => onBlur(propName)} />
    </div>
  )
}

interface OffsetRect {
  left: number
  top: number
  right: number
  bottom: number
}

interface NestedRects {
  r1: OffsetRect
  r2: OffsetRect
  r3: OffsetRect
}

export const SpacingWidget = (props: IStyleWidgetProps): VNode => {
  const [rects, setRects] = useState<NestedRects | null>(null)

  const [focused, setFocused] = useState('')

  const outerRect = useRef<HTMLDivElement>(null)
  const middleRect = useRef<HTMLDivElement>(null)
  const innerRect = useRef<HTMLDivElement>(null)

  const spProps = {
    ...props,
    onFocus: (name: string) => setFocused(name),
    onBlur: (name: string) => { if (focused === name) setFocused('') }
  }

  useLayoutEffect(() => {
    const extractCoords = (ref: typeof outerRect): OffsetRect => {
      if (ref.current == null) throw new Error('ref undefined in spacing widget.')
      const res = {
        left: ref.current.offsetLeft,
        top: ref.current.offsetTop,
        right: ref.current.offsetLeft + ref.current.offsetWidth,
        bottom: ref.current.offsetTop + ref.current.offsetHeight
      }
      return res
    }

    setRects({
      r1: extractCoords(outerRect),
      r2: extractCoords(middleRect),
      r3: extractCoords(innerRect)
    })
    console.log('triggereing uselayout')
  }, [])

  const Trapezoids = ({ c }: { c: NestedRects }): VNode => {
    return (
      <>
        <SpacingBackground
          x1={c.r1.left} y1={c.r1.top}
          x2={c.r2.left} y2={c.r2.top}
          x3={c.r2.left} y3={c.r2.bottom}
          x4={c.r1.left} y4={c.r1.bottom}
          contrast={1}
          name='margin-left'
          activeProp={focused}
        />
        <SpacingBackground
          x1={c.r2.left} y1={c.r2.top}
          x2={c.r3.left} y2={c.r3.top}
          x3={c.r3.left} y3={c.r3.bottom}
          x4={c.r2.left} y4={c.r2.bottom}
          contrast={3}
          name='padding-left'
          activeProp={focused}
        />

        <SpacingBackground
          x1={c.r1.right} y1={c.r1.top}
          x2={c.r2.right} y2={c.r2.top}
          x3={c.r2.right} y3={c.r2.bottom}
          x4={c.r1.right} y4={c.r1.bottom}
          contrast={3}
          name='margin-right'
          activeProp={focused}
        />
        <SpacingBackground
          x1={c.r2.right} y1={c.r2.top}
          x2={c.r3.right} y2={c.r3.top}
          x3={c.r3.right} y3={c.r3.bottom}
          x4={c.r2.right} y4={c.r2.bottom}
          contrast={0}
          name='padding-right'
          activeProp={focused}
        />

        <SpacingBackground
          x1={c.r1.left} y1={c.r1.top}
          x2={c.r1.right} y2={c.r1.top}
          x3={c.r2.right} y3={c.r2.top}
          x4={c.r2.left} y4={c.r2.top}
          contrast={0}
          name='margin-top'
          activeProp={focused}
        />
        <SpacingBackground
          x1={c.r2.left} y1={c.r2.top}
          x2={c.r2.right} y2={c.r2.top}
          x3={c.r3.right} y3={c.r3.top}
          x4={c.r3.left} y4={c.r3.top}
          contrast={2}
          name='padding-top'
          activeProp={focused}
        />

        <SpacingBackground
          x1={c.r2.left} y1={c.r2.bottom}
          x2={c.r2.right} y2={c.r2.bottom}
          x3={c.r1.right} y3={c.r1.bottom}
          x4={c.r1.left} y4={c.r1.bottom}
          contrast={2}
          name='margin-bottom'
          activeProp={focused}
        />
        <SpacingBackground
          x1={c.r3.left} y1={c.r3.bottom}
          x2={c.r3.right} y2={c.r3.bottom}
          x3={c.r2.right} y3={c.r2.bottom}
          x4={c.r2.left} y4={c.r2.bottom}
          contrast={1}
          name='padding-bottom'
          activeProp={focused}
        />
      </>
    )
  }

  return (
    <div className='spacing-widget-container'>
      <div style='grid-area: m / m / me / me; position: relative;'>
        {rects != null ? <Trapezoids c={rects} /> : null}
      </div>
      <div className='c-marker c-margin p-1'>margin</div>
      <div className='c-marker c-padding p-1'>padding</div>
      <div className='rect-1' ref={outerRect} />
      <div className='rect-2' ref={middleRect} />
      <div className='rect-3' ref={innerRect} />
      <SpacingInput {...spProps} propName='margin-top' />
      <SpacingInput {...spProps} propName='margin-right' />
      <SpacingInput {...spProps} propName='margin-bottom' />
      <SpacingInput {...spProps} propName='margin-left' />
      <SpacingInput {...spProps} propName='padding-top' />
      <SpacingInput {...spProps} propName='padding-right' />
      <SpacingInput {...spProps} propName='padding-bottom' />
      <SpacingInput {...spProps} propName='padding-left' />
    </div>
  )
}
