// This will only work in the browser. To get it to work backend side, consider using
// something like this: https://github.com/jsdom/cssstyle
// (which depends on the unmaintained CSSOM node module)

const dummySheet = new CSSStyleSheet()

export function parseCssText (cssText: string): CSSStyleDeclaration {
  if (dummySheet.cssRules.length > 0) dummySheet.deleteRule(0)
  // dummySheet.insertRule('.fun { }', 0)
  // const rule = dummySheet.cssRules[0] as CSSStyleRule
  // rule.cssText = cssText
  // return rule.style

  dummySheet.insertRule(`.fun { ${cssText} }`, 0)
  const rule = dummySheet.cssRules[0] as CSSStyleRule
  return rule.style
}
