import { Component, VNode } from 'preact'
import { basicSetup } from '../code_mirror/basic_setup'
import { EditorState, EditorStateConfig, Extension, StateEffect } from '@codemirror/state'

import { EditorView, keymap } from '@codemirror/view'
import { openArgumentsEditor } from './arguments_editor'

import { startCompletion, CompletionContext, CompletionResult } from '@codemirror/autocomplete'
import { Runtime } from '../agjs/runtime'
import { Scope } from '../agjs/scope'

import { parser } from '../agjs/lr_parser'
import { Tree } from '@lezer/common'

import { LRLanguage, LanguageSupport, indentNodeProp, foldNodeProp, foldInside, delimitedIndent, syntaxTree } from '@codemirror/language'
import { styleTags, tags as t } from '@lezer/highlight'

import { linter, Diagnostic } from '@codemirror/lint'
import { foldableArgs } from '../code_mirror/foldable_args'
import { Parser } from '../agjs/parser'
import { Agjs2, ST } from '../agjs/types'
import { Ancestry, Cursor } from '../agjs/cursor'

export type ComplCallback = (tree: Tree, context: CompletionContext) => CompletionResult
export type LintCallback = (tree: Tree, source: string, diagnostics: Diagnostic[]) => void

function AglLinter (onLint: LintCallback): Extension {
  return linter(view => {
    const diagnostics: Diagnostic[] = []
    onLint(syntaxTree(view.state), view.state.doc.sliceString(0), diagnostics)
    return diagnostics
  })
}

function AglWithScope (onAutocomplete: ComplCallback): Extension {
  return new LanguageSupport(
    LRLanguage.define({
      parser: parser.configure({
        props: [
          indentNodeProp.add({
            Application: delimitedIndent({ closing: ')', align: false })
          }),
          foldNodeProp.add({
            Application: foldInside
          }),

          // Documentation:
          // https://lezer.codemirror.net/docs/ref/#highlight.Tag
          styleTags({
            Symbol: t.name,
            SymbolInvoc: t.function(t.name),
            List: t.list,
            Boolean: t.bool,
            Number: t.number,
            AttrName: t.propertyName,
            CompareOp: t.compareOperator,
            BoolOp: t.logicOperator,
            MultOp: t.arithmeticOperator,
            AddOp: t.arithmeticOperator,
            Null: t.null,
            String: t.string,
            Comment: t.comment,
            Group: t.paren,
            RenderContext: t.keyword,
            DefPageElement: t.keyword,
            RenderEval: t.brace,
            RenderHTMLTag: t.className,
            RenderPageElement: t.className,
            HTMLTagName: t.tagName,
            HTMLAttrName: t.attributeName // t.attributeValue
          })
        ]
      }),
      languageData: {
        name: 'AGL',
        commentTokens: { line: '#' },
        autocomplete: (context: CompletionContext) => {
          // const tree: Tree = context.state.tree as Tree
          const tree = syntaxTree(context.state)
          const completions = onAutocomplete(tree, context)
          return completions
        }
      }
    })
  )
}

type InputCallback = (tree: Tree, source: string) => void

export interface ICmInputProps {
  runtime: Runtime
  scope: Scope
  onInput: InputCallback
  onPressEnter?: InputCallback
  onAutocomplete: ComplCallback
  onLint: LintCallback
  source: string
}

/**
 * CmInput encapsulates a CodeMirror instance as a Preact class component
 */
export class CmInput extends Component<ICmInputProps> {
  view: EditorView

  shouldComponentUpdate (): boolean {
    // do not re-render via diff:
    return false
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps (nextProps: ICmInputProps): void {
    let updateNeeded = false
    const currentSource = this.view.state.doc
    if (nextProps.source !== currentSource.sliceString(0)) {
      this.view.dispatch({
        changes: [
          { from: 0, to: currentSource.length },
          { from: 0, insert: nextProps.source }
        ]
      })
    }

    if (!Object.is(this.props.scope, nextProps.scope)) {
      updateNeeded = true
      // TODO: update editor extensions (aglWithScope)
      console.log('WARNING: scope has changed! component would need to re-init')
    }
  }

  componentDidMount (): void {
    const smokeEffect = StateEffect.define()

    const focusTriggersAutocomplete = EditorView.focusChangeEffect.of((state, focusing) => {
      // FIXME: This works but is not recommended, but not sure what a better strategy is
      // (create a transaction which changes some state which results in showing the auto complete?)
      if (focusing) {
        setTimeout(() => startCompletion(this.view), 250)
      }
      return smokeEffect.of(null)
      // return this.autoCompleteCompartment.reconfigure(EditorView.editable.of(true))
    })

    // const emptyDocTriggersAutocomplete = EditorView.updateListener.of(update => {
    //   if (update.docChanged && update.state.sliceDoc() === '') {
    //     startCompletion(this.view)
    //   }
    // })

    const editArguments = (tree: Tree, source: string, from: number, to: number, ref: HTMLElement, replaceCode: (newCode: string) => void): void => {
      const aglParser = new Parser(this.props.runtime)
      const exp = aglParser.astToExp(tree.topNode, source, this.props.scope)

      const cursor = new Cursor(exp)
      let callExp: ST.Exp.CallMethod | ST.Exp.CallObjMethod | null = null

      cursor.iterate2((node: Agjs2.Node & Agjs2.ParserMetaInfo) => {
        if (node.parser.from === from && node.parser.to === to) {
          const tnode = node as Agjs2.NodesWithT

          if (tnode.t !== 'CallMethod' && tnode.t !== 'CallObjMethod') {
            throw new Error(`editArguments: cannot find CallMethod or CallObjMethod node at asked position (${from}..${to}). Found node type was ${tnode.t}`)
          }
          callExp = tnode
        }
        return null
      })

      if (callExp == null) throw new Error(`editArguments: call expression not found at position ${from}..${to}.`)

      // Here, TypeScript thinks callExp is of type 'never' (probably because it doesn't understand that the callback in iterate2 will be called).
      // Couldn't fix it with chatgpt so doing a type coercion here. :(
      // Not even 100% correct since it can also be CallObjMethod
      const callExp2 = callExp as ST.Exp.CallMethod

      console.log(callExp2)

      // TODO: Use formatter to generate a string for callExp.
      openArgumentsEditor(callExp2, this.props.scope, ref, () => { replaceCode('db: frogs') })
    }

    // The references to these have to be wrapped in this arrow function.
    // Because CmInput will be mounted inside functional components that interact with the input, the input callbacks
    // can change anytime (this.props.onInput will change). So if we only pass down the reference directly, CM will have
    // an outdated reference and the callback will not work properly (actual problem from the past)
    // TODO: onInput is not used inside these callbacks anyway, since I am using the update listener now (below).
    const currentOnInput: InputCallback = (tree, source) => this.props.onInput(tree, source)
    const currentOnLint: LintCallback = (tree: Tree, source: string, diagnostics: Diagnostic[]) => this.props.onLint(tree, source, diagnostics)
    const currentOnAutocomplete: ComplCallback = (tree: Tree, context: CompletionContext) => this.props.onAutocomplete(tree, context)

    const updateListener = EditorView.updateListener.of(update => {
      if (update.docChanged) {
        const tree = syntaxTree(update.state)
        const doc = update.state.sliceDoc()
        currentOnInput(tree, doc)
        if (doc === '') startCompletion(this.view)
      }
    })

    const interceptEnter =
     keymap.of([{
       key: 'Enter',
       run: (editorView) => {
         /// / // Prevent the default behavior of creating a new line
         /// / editorView.dispatch({
         /// /   selection: EditorSelection.cursor(editorView.state.doc.length),
         /// / });
         /// / // Trigger your callback function here
         /// / yourCallbackFunction();
         if (this.props.onPressEnter != null) {
           this.props.onPressEnter(syntaxTree(editorView.state), editorView.state.sliceDoc())
           return true
         } else {
           return false
         }
       }
     }])

    const editorState = EditorState.create({
      doc: this.props.source,
      extensions: [
        interceptEnter,
        basicSetup,
        AglWithScope(currentOnAutocomplete),
        AglLinter(currentOnLint),
        // emptyDocTriggersAutocomplete, // passiert schon im updateListener
        // focusTriggersAutocomplete,
        foldableArgs({ editArguments }),
        updateListener
      ]
    })

    this.view = new EditorView({
      state: editorState,
      parent: this.base as Element
    })
  }

  componentWillUnmount (): void {
    this.view?.destroy()
  }

  render (): VNode {
    return (
      <span class='w-100 d-inline-block needs-kbd' />
    )
  }
}
