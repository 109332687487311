import { VNode } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { observer } from 'mobx-react'
import { generatePageElementClassContextMenuFunc } from '../helpers'
import { AppContext } from '../app_context'
import { Agjs2, ST } from '../agjs/types'
import { CollapsibleSection, FormSection, Panel, Section } from '../pcomponents'

interface LibraryElementProps {
  elementClass: ST.Exp.PageElementClass
}

const LibraryElement = ({ elementClass }: LibraryElementProps): VNode<any> => {
  const { store } = useContext(AppContext)
  const drags = (event: DragEvent): void => { store.ui.dndBuffer.startDragging(event, elementClass) }
  const drage = (): void => { store.ui.dndBuffer.endDragging() }

  const contextMenuHandler = (ev: MouseEvent): void => store.ui.contextMenu(
    ev,
    generatePageElementClassContextMenuFunc(elementClass, store)
  )

  return (
    <div
      className='p-2 border-bottom cursor-grab'
      onDragStart={drags}
      onDragEnd={drage}
      draggable
      onContextMenu={contextMenuHandler}
    >
      {elementClass.name}
    </div>
  )
}

const PanelSearchbox = ({ placeholder, onSearch }: { placeholder: string, onSearch: (needle: string) => void }): VNode => {
  return (
    <div className='input-group flex-nowrap'>
      <span className='input-group-text material-symbols-outlined'>search</span>
      <input type='text' className='form-control' placeholder={placeholder} onInput={(ev: InputEvent) => onSearch((ev.target as HTMLInputElement)?.value ?? '')} />
    </div>
  )
}

const PageElementLibrary = observer((): VNode<any> | null => {
  const { store } = useContext(AppContext)

  const [needle, setNeedle] = useState('')

  const cats = store.project.componentsByCategories

  const search = (needle: string): void => {
    setNeedle(needle)
  }

  const matchesNeedle = (item: Agjs2.ClassDef): boolean => {
    return item.name.toLowerCase().startsWith(needle.toLowerCase())
  }

  const AllCats = (): VNode => (<>
    {cats.filter(category => category.name !== 'hidden').map(category =>
      <CollapsibleSection title={category.name} key={category.name}>
        {category.components.filter(c => c.t === 'PageElementClass').map(c => <LibraryElement elementClass={c as ST.Exp.PageElementClass} key={c.id} />)}
      </CollapsibleSection>)}
  </>)

  const FilteredElements = (): VNode => {
    const hits = cats
      .filter(category => category.name !== 'hidden')
      .map(category => category.components.filter(c => c.t === 'PageElementClass' && matchesNeedle(c))).flat()
    return <Section>{(hits as ST.Exp.PageElementClass[]).map(c => <LibraryElement elementClass={c} key={c.id} />)}</Section>
  }

  if (cats.length === 0) {
    return null
  } else {
    return (
      <Panel title='Elements'>
        <FormSection>
          <PanelSearchbox placeholder='Search element library...' onSearch={search} />
        </FormSection>
        {needle === '' ? <AllCats /> : <FilteredElements />}
      </Panel>
    )
  }
})

export { PageElementLibrary }
