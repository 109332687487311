import { useContext } from 'preact/hooks'
import { VNode } from 'preact'
import { observer } from 'mobx-react'

import { AppContext } from '../app_context'
import { Agjs2, ST } from '../agjs/types'
import { F } from '../agjs/factory'
import { Ide } from '../types'
import { DataTypeTag, CollapsibleSection, Panel, IconButton } from '../pcomponents'
import { Dialog, NamedDataTypeStruct } from '../pcomponents/dialogs'

import { NameEditor } from '../pcomponents/prop_editor'
import { CloseFn, openModal } from '../overlay_manager'
import { SimpleWrapper } from '../jsx_helpers'

const InspectorRow: SimpleWrapper = ({ children }) => (
  <div className='p-2 border-ridge border-b'>{children}</div>
)

/*
const CustomState = observer(({ state } : { state : Agjs2.ConstData }) => {
  const { store } = useContext(AppContext) as Ide.App

  return <InspectorRow>
    <div class='flex'>
      {state.name}
      <IconButton icon={'edit_note'} title={'Edit default values'} onClick={() => store.ui.showCustomStateEditor(state)}/>
      <IconButton icon={'delete'} title={'Remove custom state'} onClick={() => store.project.deleteNodeElement({ elementId: state.id })}/>
    </div>
    </InspectorRow>
}
)
*/

const ParameterRow = ({ param }: { param: ST.Param }): VNode => {
  return (
    <div className='p-2 border-ridge border-b'>
      <label>{param.name}</label>
      {param.dataTypes.map(dt => <DataTypeTag dataType={dt} key={dt.id} />)}
    </div>
  )
}

const openParamDialog = (app: Ide.App, workflow: Agjs2.Workflow, attr: 'input' | 'output', param: ST.Param | null = null): void => {
  const { store } = app

  const makeNewParam = (): ST.Param => {
    const generatedName = store.project.nodeIndex.newName(attr, { namesList: workflow.inputs.map(p => p.name) })
    return F.makeParam(generatedName, F.makeNoneType(), F.makeIncomplete())
  }

  const val = param ?? makeNewParam()

  const create = (newVal: NamedDataTypeStruct): void => {
    const newP = F.makeParam(newVal.name, newVal.dataType, store.project.nodeIndex.runtime.defaultValueForDataType(newVal.dataType))
    newP.id = val.id
    console.log(newP)
    // app.store.project.setAttrListItem(workflow,
    //                               attr + 's',
    //                               'after',
    //                               { ...newVal, defaultValue: app.runtime.defaultValueForDataType(newVal.dataTypes[0])})

    app.store.project.op.generic.attrInsertListItem(workflow, `${attr}s`, null, newP)
    closeFn()
  }

  const closeFn = openModal((closeFn: CloseFn) => <Dialog.NamedDataType
    closeFn={closeFn}
    node={{ name: val.name, dataType: val.dataTypes[0] }}
    onSubmit={create}
    title={`Create ${attr} parameter for ${workflow.name}`}
    submitLabel={`Create ${attr} parameter`}
                                                  />
  )
}

const WorkflowInspector = observer(({ workflow }: { workflow: Agjs2.Workflow | null }): VNode | null => {
  const app = useContext(AppContext)

  if (workflow == null) return null

  const ParameterSection = observer(({ attr, title }: { attr: 'input' | 'output', title: string }) => (
    <CollapsibleSection title={title}>
      {workflow[attr + 's'].map((p: ST.Param) => <ParameterRow param={p} key={p.id} />)}
      <InspectorRow>
        <IconButton icon='add' label={`Add ${attr} parameter`} onClick={() => openParamDialog(app, workflow, attr)} />
      </InspectorRow>
    </CollapsibleSection>
  ))

  if (workflow != null) {
    const changeName = (newName: string): void => {
      app.store.project.op.generic.renameElement(workflow as any, newName)
    }
    const title = <NameEditor element={workflow as any} onChange={changeName} />
    return (
      <Panel title={title}>
        <ParameterSection title='Inputs' attr='input' />
        <ParameterSection title='Outputs' attr='output' />
      </Panel>
    )
  } else {
    return (
      <Panel>
        <div class='m-2 text-center'>
          (No element selected)
        </div>
      </Panel>
    )
  }
})

export { WorkflowInspector }
