import { Agjs2 } from './types'

import StdLib from './stdlib'

export class LibResolver {
  resolve: Agjs2.LibResolverFn

  constructor () {
    this.resolve = function (libId: string, version: string): Agjs2.Library {
      if (libId === 'StdLib') {
        if (version === 'v1.0.0') {
          return StdLib
        }
      }
      throw new Error(`Cannot resolve library ${libId} (version ${version}). Library not found by resolver.`)
    }
  }
}
