import consumer from './consumer'
import { globalApp } from './../app_context'

consumer.subscriptions.create({ channel: 'StudioSessionChannel', studio_session_id: globalApp.studioSessionId }, {
  connected () {
    // Called when the subscription is ready for use on the server
    console.log('studio sess connected')
  },

  disconnected () {
    // Called when the subscription has been terminated by the server
    console.log('studio sess disconnected')
  },

  received (data) {
    globalApp.store.project.processStudioSessionEvent(data)
  }
})
