import { VNode } from 'preact'
import { Agjs2, ST } from '../../agjs/types'
import { useContext, useEffect, useRef, useState } from 'preact/hooks'
import { AppContext } from '../../app_context'
import { HorizontalField, InputHeader, InputSection, UnbufferedExpressionInput, UnbufferedInputProps } from '.'
import { colorFromCssProperty, colorMode, colorToCssProperty, getRgbFromColor } from '../../agjs/style_compiler'
import { ColorPickerWidget, ColorSwatch } from '../ColorPickerWidget'
import { CloseFn, openPopover, PopoverContent } from '../../overlay_manager'
import { RGBAColor } from '../../utils/color_tools'
import { StandardDialog } from '../dialogs'
import { Icon } from '..'
import { parseCssText } from '../../utils/css_tools'
import { SpacingWidget } from '../SpacingWidget'
import { CSSPropValuePicker } from '../CSSPropValuePicker'
import { type ThemeService } from '../../agjs/theme_service'
import { capitalizeFirstLetter } from '../../agjs/string_utils'

export type OptionsInArray = Array<[string, string]>

interface ButtonGroupItem {
  title: string
  value: string
  icon: string
}

export const ButtonGroup = ({ items, value, onChange }: { items: ButtonGroupItem[], value: string, onChange: (newValue: string) => void }): VNode => {
  const ButtonWithIcon = (props: { title: string, icon: string, value: string, currentValue: string, onClick: (newVal: string) => void }): VNode => {
    return (
      <button type='button' class={'btn btn-secondary' + (props.value === props.currentValue ? ' active' : '')} onClick={() => props.onClick(props.value)}>
        <Icon icon={props.icon} title={props.title} autosize />
      </button>
    )
  }

  const p = {
    onClick: (newVal: string) => onChange(newVal),
    currentValue: value
  }

  return (
    <div class='btn-group' role='group' aria-label='Select alignment'>
      {items.map(item => <ButtonWithIcon key={item.value} title={item.title} value={item.value} icon={item.icon} {...p} />)}
    </div>
  )
}

const AlignmentButtons = ({ value, onChange }: { value: string, onChange: (newValue: string) => void }): VNode => {
  const buttons: ButtonGroupItem[] = [
    { title: '(not set)', value: '', icon: 'close' },
    { title: 'Left', value: 'left', icon: 'format_align_left' },
    { title: 'Center', value: 'center', icon: 'format_align_center' },
    { title: 'Right', value: 'right', icon: 'format_align_right' },
    { title: 'Justify', value: 'justify', icon: 'format_align_justify' }
  ]

  return <ButtonGroup items={buttons} value={value} onChange={onChange} />
}

const ItalicizeButtons = ({ value, onChange }: { value: string, onChange: (newValue: string) => void }): VNode => {
  const buttons: ButtonGroupItem[] = [
    { title: '(not set)', value: '', icon: 'close' },
    { title: 'Normal', value: 'normal', icon: 'title' },
    { title: 'Italic', value: 'italic', icon: 'format_italic' }
  ]

  return <ButtonGroup items={buttons} value={value} onChange={onChange} />
}

const DecorationButtons = ({ value, onChange }: { value: string, onChange: (newValue: string) => void }): VNode => {
  const buttons: ButtonGroupItem[] = [
    { title: '(not set)', value: '', icon: 'close' },
    { title: 'Strikethrough', value: 'line-through', icon: 'format_strikethrough' },
    { title: 'Underline', value: 'underline', icon: 'format_underlined' },
    { title: 'Overline', value: 'line-through', icon: 'format_overline' }
  ]

  return <ButtonGroup items={buttons} value={value} onChange={onChange} />
}

export interface IStyleWidgetProps {
  style: CSSStyleDeclaration
  onChange: (rule: CSSStyleDeclaration) => void
  theme: ThemeService
}

const FontPicker = ({ style, onChange, theme }: IStyleWidgetProps): VNode => {
  return (
    <>
      <HorizontalField title='Font'>
        <CSSPropValuePicker propName='fontFamily' style={style} options={theme.fontFamily} onChange={onChange} />
      </HorizontalField>
      <HorizontalField title='Size'>
        <CSSPropValuePicker propName='fontSize' unitType='length' style={style} options={theme.fontSize} onChange={onChange} />
      </HorizontalField>
      <HorizontalField title='Align'>
        <AlignmentButtons value={style.textAlign} onChange={newVal => { style.textAlign = newVal; onChange(style) }} />
      </HorizontalField>
      <HorizontalField title='Style'>
        <div className='d-flex'>
          <div className='me-2'>
            <ItalicizeButtons value={style.fontStyle} onChange={newVal => { style.fontStyle = newVal; onChange(style) }} />
            <p>Italicize</p>
          </div>
          <div>
            <DecorationButtons value={style.textDecoration} onChange={newVal => { style.textDecoration = newVal; onChange(style) }} />
            <p>Decoration</p>
          </div>
        </div>
      </HorizontalField>
    </>
  )
}

function openColorPickerWidget (anchor: HTMLElement, color: Agjs2.ColorSettings, theme: ThemeService, onSubmit: (newColor: Agjs2.ColorSettings) => void): CloseFn {
  return openPopover((closeFn: () => void) => {
    const [currentColor, setCurrentColor] = useState(color)
    // return (
    //   <PopoverContent
    //     title={`Edit color`}
    //   >
    //     <ColorPicker color={color} />
    //   </PopoverContent>
    // )
    return (
      <StandardDialog
        title='Edit color'
        onSubmit={() => { onSubmit(currentColor); closeFn() }}
        onCancel={() => closeFn()}
      >
        <ColorPickerWidget
          color={color}
          onChange={(newColor, close) => { if (close != null) { onSubmit(newColor); closeFn() } else { setCurrentColor(newColor) } }}
          theme={theme}
        />
      </StandardDialog>
    )
  }, anchor, 'bottom-start')
}

interface IColorInput {
  color: Agjs2.ColorSettings
  onChange: (settings: Agjs2.ColorSettings) => void
}

const ColorInput = ({ color, onChange }: IColorInput): VNode => {
  const { store } = useContext(AppContext)
  const ref = useRef<HTMLDivElement>(null)

  const customColor: RGBAColor = getRgbFromColor(color, store.project.themeService)

  const cm = colorMode(color)
  const colorAsText: string = cm === 'none' ? '' : color.value

  const parseColor = (newValue: string): void => {
    if (newValue === '') {
      onChange({ value: 'none' })
    } else {
      const rgbResult = getRgbFromColor({ value: newValue }, store.project.themeService)
      if (rgbResult.r !== -2) {
        onChange({ value: newValue })
      }
    }
  }

  return (
    <div className='input-group' ref={ref}>
      <ColorSwatch color={customColor} onClick={() => ref.current != null && openColorPickerWidget(ref.current, color, store.project.themeService, newColorSettings => onChange(newColorSettings))} />
      <input type='text' className='form-control' value={colorAsText} spellcheck={false} placeholder='(color not set)' onInput={ev => parseColor(ev.currentTarget.value)} />
    </div>
  )
}

type BorderMode = 'left' | 'right' | 'top' | 'bottom' | 'all'

interface IBorderSelectButton {
  style: CSSStyleDeclaration
  side: BorderMode
  borderMode: BorderMode
  onClick: (side: BorderMode) => void
}

const BorderSelectButton = ({ style, side, borderMode, onClick }: IBorderSelectButton): VNode => {
  const ic = getIndividuallyDefinedBorders(style)
  const active = side === borderMode

  let propertyDefined = false

  if ((side === 'all' && ic.length === 0) && (
    style.borderStyle.length > 0 ||
    style.borderColor.length > 0 ||
    style.borderWidth.length > 0
  )) propertyDefined = true
  if (ic.includes(side)) propertyDefined = true

  let hint = 'Border was customized'

  if (!active) {
    if (side === 'all' && ic.length > 0) hint = `Use stylings from ${borderMode} border for all borders.`
    if (side !== 'all' && propertyDefined) hint = 'Border has custom stylings defined.'
    if (side !== 'all' && borderMode === 'all' && !propertyDefined) hint = 'Define custom stylings for each corner.'
  }

  return (
    <button
      type='button'
      onClick={() => onClick(side)}
      class={`btn btn-secondary ${active ? 'active' : ''}`}
      title={!active ? hint : ''}
    >
      <span class={propertyDefined ? 'text-property-set' : ''}>{side}</span>
    </button>
  )
}

// It is a bit fiddly to find out which corners have individual styles.
// When reading i.e. border-top-style, the property will always have a value,
// even if not individually set.
// Therefore we have to see if all individual properties have the same value as everything
// else.
function getIndividuallyDefinedBorders (style: CSSStyleDeclaration): string[] {
  const individalCorners: string[] = [];
  ['top', 'left', 'right', 'bottom'].forEach(corner => {
    ['style', 'color', 'width'].forEach(prop => {
      const individualCornerValue = style.getPropertyValue(`border-${corner}-${prop}`)
      if ((style.getPropertyValue(`border-${prop}`) !== individualCornerValue && individualCornerValue.length > 0)) {
        if (!individalCorners.includes(corner)) individalCorners.push(corner)
      }
    })
  })
  return individalCorners
}

function getBorderMode (style: CSSStyleDeclaration): BorderMode {
  const ic = getIndividuallyDefinedBorders(style)

  if (ic.length === 0) return 'all'
  return ic[0] as BorderMode
}

const BorderStyleWidget = (props: IStyleWidgetProps): VNode => {
  const [borderMode, setBorderMode] = useState<BorderMode>(() => getBorderMode(props.style))

  useEffect(() => {
    // TODO: what I want is to re-decide border mode ONLY if the underlying element has changed
    // (so when the inspector is rendered for a different element)
    // const newBorderMode = getBorderMode(props.style)
    // console.log('re-deciding which border to show')
    // setBorderMode(getBorderMode(props.style))
  }, [props.style])

  const handleBorderSelect = (newBorderMode: BorderMode): void => {
    if (newBorderMode === borderMode) return
    if (borderMode === 'all') {
      const currentStyle = props.style.borderStyle
      const currentWidth = props.style.borderWidth
      const currentColor = props.style.borderColor
      props.style.removeProperty('border-style')
      props.style.removeProperty('border-width')
      props.style.removeProperty('border-color')

      // if another border is selected, but we previously edited 'all' borders,
      // copy value from all into each individual corner.
      ;['border-left-', 'border-top-', 'border-right-', 'border-bottom-'].forEach(individualCorner => {
        props.style.setProperty(individualCorner + 'style', currentStyle)
        props.style.setProperty(individualCorner + 'color', currentColor)
        props.style.setProperty(individualCorner + 'width', currentWidth)
      })
    } else if (newBorderMode === 'all') {
      // if the 'all' button is selected, copy values from the current border into the main border prop
      ['style', 'color', 'width'].forEach(borderProp => {
        const currentCorner = `border-${borderMode}-${borderProp}`
        props.style.setProperty(`border-${borderProp}`, props.style.getPropertyValue(currentCorner))
      }
      )
    } else {
      // if we end up here, we just toggled between editing different corners, no need to call onChange (below)
      setBorderMode(newBorderMode)
      return
    }
    setBorderMode(newBorderMode)
    props.onChange(props.style)
  }

  const removeCurrentBorder = (): void => {
    const borderPrefix = borderMode === 'all' ? 'border-' : `border-${borderMode}-`;
    ['style', 'color', 'width'].forEach(borderProp => {
      props.style.removeProperty(borderPrefix + borderProp)
    })
    props.onChange(props.style)
  }

  const bsProps = { style: props.style, borderMode, onClick: handleBorderSelect }

  const border = borderMode === 'all' ? 'border' : `border${capitalizeFirstLetter(borderMode)}`

  const currentBorderHasSettings = `${props.style[border + 'Style'] as string}${props.style[border + 'Width'] as string}${props.style[border + 'Color'] as string}`.length > 0

  return (
    <div className='hstack gap-2 align-items-start'>
      <div className='border-selection-container'>
        <div />
        <BorderSelectButton side='top' {...bsProps} />
        <div />
        <BorderSelectButton side='left' {...bsProps} />
        <BorderSelectButton side='all' {...bsProps} />
        <BorderSelectButton side='right' {...bsProps} />
        <div />
        <BorderSelectButton side='bottom' {...bsProps} />
        <div />
      </div>
      <div className='d-grid gap-2' style='grid-template-columns: 1fr 3fr'>
        <div>Style</div>
        <CSSPropValuePicker {...props} propName={border + 'Style'} options={props.theme.borderStyle} />
        <div>Width</div>
        <CSSPropValuePicker {...props} propName={border + 'Width'} options={props.theme.borderWidth} unitType='length' />
        <div>Color</div>
        <ColorPickerForProp {...props} propName={border + 'Color'} />
        <div style='grid-column: span 2;'>
          <button
            type='button'
            onClick={removeCurrentBorder}
            class={`btn btn-secondary w-100 ${currentBorderHasSettings ? '' : 'disabled'}`}
          >
            Clear border settings
          </button>
        </div>
      </div>
    </div>
  )
}

const BorderRadius = (props: IStyleWidgetProps): VNode => {
  const { style, theme, onChange } = props

  const [allCorners, setAllCorners] = useState<boolean>(() => props.style.borderRadius.toString().length > 0)
  const buttons: ButtonGroupItem[] = [
    { title: 'All corners', value: 'all', icon: 'crop_square' },
    { title: 'Individual corners', value: 'individual', icon: 'pageless' }
  ]

  const toggleAllCorners = (all: boolean): void => {
    /* eslint-disable @typescript-eslint/strict-boolean-expressions */
    if (all) {
      setAllCorners(true)
      const existingRadius = '' + (style.borderTopLeftRadius || style.borderTopRightRadius || style.borderBottomLeftRadius || style.borderBottomRightRadius)
      style.borderTopLeftRadius = style.borderTopRightRadius = style.borderBottomLeftRadius = style.borderBottomRightRadius = ''
      style.borderRadius = existingRadius
      onChange(style)
    } else {
      setAllCorners(false)
      style.borderTopLeftRadius = style.borderTopRightRadius = style.borderBottomLeftRadius = style.borderBottomRightRadius = style.borderRadius
      onChange(style)
    }
    /* eslint-enable @typescript-eslint/strict-boolean-expressions */
  }

  const cornerValueProps = { style, options: theme.borderRadius, onChange }

  return (
    <div className='d-flex align-items-start'>
      <ButtonGroup items={buttons} value={allCorners ? 'all' : 'individual'} onChange={cornerMode => toggleAllCorners(cornerMode === 'all')} />
      <div className='ms-1 flex-grow-1'>
        {allCorners
          ? (
            <CSSPropValuePicker propName='borderRadius' {...cornerValueProps} unitType='length' />
            )
          : (
            <>
              <div className='d-flex justify-content-between'>
                <CSSPropValuePicker propName='borderTopLeftRadius' {...cornerValueProps} unitType='length' />
                <CSSPropValuePicker propName='borderTopRightRadius' {...cornerValueProps} unitType='length' />
              </div>
              <div className='d-flex justify-content-between'>
                <CSSPropValuePicker propName='borderBottomLeftRadius' {...cornerValueProps} unitType='length' />
                <CSSPropValuePicker propName='borderBottomRightRadius' {...cornerValueProps} unitType='length' />
              </div>
            </>
            )}
      </div>
    </div>
  )
}

const SizingWidget = (props: IStyleWidgetProps): VNode => {
  return (
    <div className='d-grid gap-2' style='grid-template-columns: 1fr 2fr 1fr 2fr'>
      <div>Width</div><CSSPropValuePicker {...props} propName='width' options={props.theme.width} />
      <div>Height</div><CSSPropValuePicker {...props} propName='height' options={props.theme.height} />
      <div>Min W</div><CSSPropValuePicker {...props} propName='minWidth' options={props.theme.minWidth} />
      <div>Min H</div><CSSPropValuePicker {...props} propName='minHeight' options={props.theme.minHeight} />
      <div>Max W</div><CSSPropValuePicker {...props} propName='maxWidth' options={props.theme.maxWidth} />
      <div>Max H</div><CSSPropValuePicker {...props} propName='maxHeight' options={props.theme.maxHeight} />
      <div>Overflow</div>
      <div style='grid-column: span 3;'>
        <CSSPropValuePicker {...props} propName='overflow' options={props.theme.overflow} />
      </div>
    </div>
  )
}

const ColorPickerForProp = ({ style, propName, onChange }: Pick<IStyleWidgetProps, 'style' | 'onChange'> & { propName: string }): VNode => {
  return (
    <ColorInput
      color={colorFromCssProperty(style[propName])}
      onChange={nc => { style[propName] = colorToCssProperty(nc); onChange(style) }}
    />
  )
}

export const ElementStylingInput = (props: UnbufferedInputProps): VNode => {
  const { store } = useContext(AppContext)

  const editorName = 'Style editor'

  const currentExp = props.value as ST.Exp.ElementStyling

  const [mode, setMode] = useState<string>('ui')

  const updateExp = (value: ST.Exp.Expression): void => {
    if (value != null) {
      props.onChange(Object.assign(props.value, value))
    }
  }

  const updateStyleRule = (styleRule: CSSStyleDeclaration): void => {
    const newProp = { ...currentExp, cssText: styleRule.cssText }
    props.onChange(newProp)
  }

  const style = parseCssText(currentExp.cssText)

  const theme = store.project.themeService

  if (mode === 'ui') {
    return (
      <InputSection>
        <InputHeader {...props} />
        <h6>Spacing</h6>
        <SpacingWidget style={style} onChange={updateStyleRule} theme={theme} />
        <h6>Sizing</h6>
        <SizingWidget style={style} onChange={updateStyleRule} theme={theme} />
        <h6>Typography</h6>
        <FontPicker
          style={style}
          onChange={updateStyleRule}
          theme={theme}
        />

        <HorizontalField title='Color'>
          <ColorPickerForProp propName='color' style={style} onChange={updateStyleRule} />
        </HorizontalField>

        <h6>Backgrounds</h6>
        <HorizontalField title='Color'>
          <ColorPickerForProp propName='backgroundColor' style={style} onChange={updateStyleRule} />
        </HorizontalField>

        <h6>Borders</h6>
        <HorizontalField title='Radius' alignItems='start'>
          <BorderRadius style={style} onChange={updateStyleRule} theme={theme} />
        </HorizontalField>

        <BorderStyleWidget style={style} onChange={updateStyleRule} theme={theme} />
      </InputSection>
    )
  }

  return (<UnbufferedExpressionInput {...props} onUiMode={(mode: string | ((prevState: string) => string)) => setMode(mode)} uiName={editorName} value={currentExp} onChange={exp => updateExp(exp)} />)
}
