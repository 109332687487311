import { VNode } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { Agjs2, ST } from '../../agjs/types'
import { CloseFn } from '../../overlay_manager'
import { deepClone } from '../../agjs/utils'
import { toSnakeCase } from '../../agjs/string_utils'
import { RowWithLabel, StandardDialog } from '.'
import { IconButton, UITextInput } from '..'
import { DataTypeEditor } from '../data_type_editor'
import { F } from '../../agjs'
import { AppContext } from '../../app_context'

const ColumnContainer = ({ children }): VNode => <div className='border border-ridge rounded-sm p-1 mb-1'>{children}</div>

interface ColumnEditorProps {
  coldef: Agjs2.DbColDef
  onChange: (updatedColDef: Agjs2.DbColDef) => void
  onDelete: () => void
}

const ColumnEditor = ({ coldef, onChange, onDelete }: ColumnEditorProps): preact.VNode => {
  const onChangeColName = (newName: string): void => {
    onChange({ ...coldef, name: newName, pgName: toSnakeCase(newName) })
  }

  const onChangeColType = (newType: ST.DT.DbCompatible): void => {
    onChange({ ...coldef, dataType: newType })
  }

  return (
    <ColumnContainer>
      <RowWithLabel label='Column name'>
        <UITextInput value={coldef.name} onInput={ev => onChangeColName((ev.target as HTMLInputElement).value)} />
      </RowWithLabel>
      <RowWithLabel label='Data type'>
        <DataTypeEditor dt={coldef.dataType} dbCompatible onPick={value => onChangeColType(value as ST.DT.DbCompatible)} />
      </RowWithLabel>
      <IconButton
        icon='delete'
        title='Remove column'
        onClick={() => onDelete()}
      />
    </ColumnContainer>
  )
}

interface EditDbTableSchemaWithCallbacksProps {
  title: string
  table: Agjs2.DbTableDef
  submitLabel: string
  closeFn: CloseFn
  onChangeName: Agjs2.SimpleCallback<string>
  onSubmit: Agjs2.SimpleCallback<Agjs2.DbTableDef>
}

export const EditDbTableSchemaWithCallbacks = ({ table, title, closeFn, onChangeName, onSubmit, submitLabel }: EditDbTableSchemaWithCallbacksProps): VNode => {
  const { runtime } = useContext(AppContext)
  const [columns, setColumns] = useState<Agjs2.DbColDef[]>(deepClone(table.columns))
  const [name, setName] = useState(table.name)

  const addColumn = (): void => {
    const newName = runtime.nodeIndex.newName('NewColumn', { namesList: columns.map(cd => cd.name) })
    const newCol = F.makeDbColDef(newName, F.makePrimitiveDT('String'))
    setColumns(columns.concat(newCol))
  }

  const removeColumn = (index: number): void => {
    const newCols = columns.slice()
    newCols.splice(index, 1)
    setColumns(newCols)
  }

  const updateColumn = (newColDef: Agjs2.DbColDef): void => {
    const index = columns.findIndex(cd => cd.id === newColDef.id)
    if (index === -1) throw new Error('Column id of updated col def not found in existing columns.')
    columns[index] = newColDef
    setColumns(columns.slice())
  }

  const visibleColumns = columns.filter(coldef => !['id', 'created_at', 'updated_at'].includes(coldef.pgName))

  const invalid = visibleColumns.length === 0

  const columnEditor = (
    <RowWithLabel label='Columns'>
      {visibleColumns.map((coldef, index) => <ColumnEditor
        key={coldef.id}
        coldef={coldef}
        onChange={updateColumn}
        onDelete={() => removeColumn(index)}
                                             />)}
      <IconButton
        icon='add'
        title='Add column'
        onClick={addColumn}
      />
    </RowWithLabel>
  )

  return (
    <StandardDialog
      title={title}
      onCancel={closeFn}
      onSubmit={() => onSubmit({ ...table, columns, name, pgName: toSnakeCase(name) })}
      submitDisabled={invalid}
      submitLabel={submitLabel}
    >
      <RowWithLabel label='Table name'>
        <UITextInput value={name} onInput={ev => { onChangeName((ev.target as HTMLInputElement).value); setName((ev.target as HTMLInputElement).value) }} />
      </RowWithLabel>
      {visibleColumns.length > 0
        ? columnEditor
        : (
          <div className='alert alert-light'>
            You need to add at least one column to this table.
            <button className='btn btn-sm btn-outline-secondary ms-1' onClick={addColumn}>Add columns</button>
          </div>
          )}
    </StandardDialog>
  )
}
