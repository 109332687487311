import { Agjs2, ST } from './types'
import { F } from './factory'
import { Compiler } from './compiler'
import { CompilationContext } from './compiler_utils'
import { Runtime } from './runtime'

type JSOpMethodImplementation = (left: ST.Exp.LiteralExpression, right: ST.Exp.LiteralExpression) => ST.Exp.LiteralExpression

function opStr (op: string): [string, string] {
  switch (op) {
    case '<':
      return ['Lt', 'Check if X is less than Y.']
    case '<=':
      return ['Lte', 'Check if X is less than or equal to Y.']
    case '>=':
      return ['Gte', 'Check if X is greater than or equal to Y.']
    case '>':
      return ['Gt', 'Check if X greater than Y.']
    case '==':
      return ['Eq', 'Check if X equal to Y.']
    case '!=':
      return ['Neq', 'Check if X is different from Y.']
    case 'and':
      return ['And', 'Check if X and Y are both true.']
    case 'or':
      return ['Or', 'Check if X or Y (or both) are true.']
    case 'xor':
      return ['Xor', 'Check if X is true and Y is false or the other way around (exclusive or).']
    case '+': return ['Plus', '']
    case '-': return ['Minus', '']
    case '*': return ['Star', '']
    case '/': return ['Slash', '']
    case '%': return ['Percent', '']
    case '**': return ['DblStar', '']
    default:
      return [op, '']
  }
}

function mkOp<TObj extends ST.DT.TypeDef, TReturn extends ST.DT.TypeDef> (
  objType: TObj,
  op: string,
  operandType: ST.DT.TypeDef[],
  returnType: TReturn,
  implementation: JSOpMethodImplementation,
  description?: string): ST.Exp.ObjMethod {
  const [opVerb, opDescription] = opStr(op)
  const name = `${objType.type}${opVerb}${operandType.map(t => t.type).join('Or')}`
  return {
    t: 'ObjMethod',
    id: `$${name}`,
    name: op,
    isBinOp: true,
    meta: {
      description: description ?? opDescription,
      category: 'Built-in',
      order: 1
    },
    parameters: [F.makeParamWithMultipleDTs('operand', operandType)],
    kwParameters: {},
    objType,
    returnType
  }
}

interface mkSimpleOpts {
  js?: Agjs2.SourceGeneratorFn
  inlineJs?: Agjs2.InlineSourceGeneratorFn
}

type ReturnTypeOrReturnTypeFn = ST.DT.TypeDef | ST.Exp.ReturnTypeFn

function mkSimple<TObj extends ST.DT.TypeDef, TReturn extends ReturnTypeOrReturnTypeFn> (
  objType: TObj,
  methodName: string,
  returnTypeOrFn: TReturn,
  sourceGen: mkSimpleOpts,
  description: string,
  opts: { parameters?: ST.Param[], kwParameters?: Record<string, ST.Param> } = {}): ST.Exp.ObjMethod {
  const name = `${objType.type}${methodName}`
  const methodDef: ST.Exp.ObjMethod = {
    t: 'ObjMethod',
    id: `$${name}`,
    name: methodName,
    meta: {
      description,
      category: 'Built-in',
      order: 1
    },
    parameters: opts.parameters ?? [],
    kwParameters: opts.kwParameters ?? {},
    objType,
    returnType: F.makeNoneType()
  }

  if (typeof returnTypeOrFn === 'function') {
    methodDef.returnTypeJs = returnTypeOrFn
  } else {
    methodDef.returnType = returnTypeOrFn
  }

  const js = sourceGen.js
  if (js != null) {
    methodDef.js = (compiler: Compiler,
      cc: CompilationContext
    ): string => {
      return js(compiler, cc)
    }
  }

  const inlineJs = sourceGen.inlineJs
  if (inlineJs != null) {
    methodDef.inlineJs = (compiler: Compiler,
      cc: CompilationContext,
      left: ST.Exp.LiteralExpression,
      params: ST.Exp.LiteralExpression[]): string => {
      return inlineJs(compiler, cc, left, params)
    }
  }

  return methodDef
}

const p = (typeName: ST.DT.PrimitiveTypeDef['type']): ST.DT.TypeDef => F.makePrimitiveDT(typeName)

const tString = p('String')
const tNumber = p('Number')
const tBoolean = p('Boolean')
const tGenericList = F.makeListType(F.makeAnyDT())

type ConstString = ST.Exp.Lit.String
type ConstNumber = ST.Exp.Lit.Number
type ConstBoolean = ST.Exp.Lit.Boolean

const arith = (desc: string): string => `Calculate ${desc}.`

const binOpMethods = [
  // Operations on strings
  mkOp(tString, '+', [tString, tNumber, tBoolean], tString, (left: ConstString, right: ConstString) => F.makeString(left.value + ('' + right.value)), 'Append Y to the end of X (concatenate).'),

  // Boolean logic
  mkOp(tBoolean, 'and', [tBoolean], tBoolean, (left: ConstBoolean, right: ConstBoolean) => F.makeBoolean(left.value && right.value)),
  mkOp(tBoolean, 'or', [tBoolean], tBoolean, (left: ConstBoolean, right: ConstBoolean) => F.makeBoolean(left.value || right.value)),
  mkOp(tBoolean, 'xor', [tBoolean], tBoolean, (left: ConstBoolean, right: ConstBoolean) => F.makeBoolean((left.value && !right.value) || (!left.value && right.value))),

  // Arithmetic operations on numbers
  mkOp(tNumber, '+', [tNumber], tNumber, (left: ConstNumber, right: ConstNumber) => F.makeNumber(left.value + right.value), arith('X + Y')),
  mkOp(tNumber, '-', [tNumber], tNumber, (left: ConstNumber, right: ConstNumber) => F.makeNumber(left.value - right.value), arith('X - Y')),
  mkOp(tNumber, '/', [tNumber], tNumber, (left: ConstNumber, right: ConstNumber) => F.makeNumber(left.value / right.value), arith('X / Y')),
  mkOp(tNumber, '*', [tNumber], tNumber, (left: ConstNumber, right: ConstNumber) => F.makeNumber(left.value * right.value), arith('X * Y')),
  mkOp(tNumber, '%', [tNumber], tNumber, (left: ConstNumber, right: ConstNumber) => F.makeNumber(left.value % right.value), arith('modulo (the remainder of X / Y)')),
  mkOp(tNumber, '**', [tNumber], tNumber, (left: ConstNumber, right: ConstNumber) => F.makeNumber(left.value ** right.value), arith('X to the power of Y (multiply X by itself Y times)')),

  // Compare numbers
  mkOp(tNumber, '<', [tNumber], tBoolean, (left: ConstNumber, right: ConstNumber) => F.makeBoolean(left.value < right.value)),
  mkOp(tNumber, '<=', [tNumber], tBoolean, (left: ConstNumber, right: ConstNumber) => F.makeBoolean(left.value <= right.value)),
  mkOp(tNumber, '>=', [tNumber], tBoolean, (left: ConstNumber, right: ConstNumber) => F.makeBoolean(left.value >= right.value)),
  mkOp(tNumber, '>', [tNumber], tBoolean, (left: ConstNumber, right: ConstNumber) => F.makeBoolean(left.value > right.value)),
  mkOp(tNumber, '==', [tNumber], tBoolean, (left: ConstNumber, right: ConstNumber) => F.makeBoolean(left.value === right.value)),
  mkOp(tNumber, '!=', [tNumber], tBoolean, (left: ConstNumber, right: ConstNumber) => F.makeBoolean(left.value !== right.value)),

  // Compare strings
  mkOp(tString, '<', [tString], tBoolean, (left: ConstString, right: ConstString) => F.makeBoolean(left.value < right.value)),
  mkOp(tString, '<=', [tString], tBoolean, (left: ConstString, right: ConstString) => F.makeBoolean(left.value <= right.value)),
  mkOp(tString, '>=', [tString], tBoolean, (left: ConstString, right: ConstString) => F.makeBoolean(left.value >= right.value)),
  mkOp(tString, '>', [tString], tBoolean, (left: ConstString, right: ConstString) => F.makeBoolean(left.value > right.value)),
  mkOp(tString, '==', [tString], tBoolean, (left: ConstString, right: ConstString) => F.makeBoolean(left.value === right.value)),
  mkOp(tString, '!=', [tString], tBoolean, (left: ConstString, right: ConstString) => F.makeBoolean(left.value !== right.value))
]

/** Generates an inline source generator which simply compiles the left side expression and adds the given string with a dot.
* mostly useful for implementing all the built in javascript functions for each object. */
function mkCallWith (jsFuncOrPropertyName: string): Agjs2.InlineSourceGeneratorFn {
  return (compiler: Compiler, cc: CompilationContext, left: ST.Exp.LiteralExpression, params: ST.Exp.LiteralExpression[]): string => {
    const leftExp = compiler.compileExp(left, cc)
    if (!jsFuncOrPropertyName.startsWith('[')) {
      return `${leftExp}.${jsFuncOrPropertyName}`
    } else {
      return `${leftExp}${jsFuncOrPropertyName}`
    }
  }
}

// A list of a lot (all?) of JavaScript's built in object's methods is here:
// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/f7ec78508c6797e42f87a4390735bc2c650a1bfd/types/es-abstract/GetIntrinsic.d.ts
const stringMethods = [
  mkSimple(tString, 'trim', tString, { inlineJs: mkCallWith('trim()') }, 'Returns a new string with any white space in front or end of the string removed.'),
  mkSimple(tString, 'trimStart', tString, { inlineJs: mkCallWith('trimStart()') }, 'Returns a new string where any white space characters in the beginning have been removed.'),
  mkSimple(tString, 'trimEnd', tString, { inlineJs: mkCallWith('trimEnd()') }, 'Returns a new string where any white space characters in the end have been removed.'),
  mkSimple(tString, 'toUpperCase', tString, { inlineJs: mkCallWith('toUpperCase()') }, 'Returns a new string with upper case letters.'),
  mkSimple(tString, 'toLowerCase', tString, { inlineJs: mkCallWith('toLowerCase()') }, 'Returns a new string with lower case letters.'),
  mkSimple(tString, 'length', tNumber, { inlineJs: mkCallWith('length') }, 'Returns length of string.'),

  mkSimple(tString, 'repeat', tNumber, {
    inlineJs: (compiler: Compiler, cc: CompilationContext, left: ST.Exp.LiteralExpression, params: ST.Exp.LiteralExpression[]): string => {
      const compiledLeft = compiler.compileExp(left, cc)
      const compiledParam = compiler.compileExp(params[0], cc)
      return `${compiledLeft}.repeat(${compiledParam})`
    }
  }, 'Returns a new string, repeated +count+ times.',
  {
    parameters: [
      F.makeParam('times', F.makePrimitiveDT('Number'), F.makeNumber(1))
    ]
  }),

  mkSimple(tString, 'reverse', tString, {
    js: (_compiler: Compiler, _cc: CompilationContext): string => {
      return 'return object.split("").reverse().join("");'
    }
  }, 'Returns a reversed version of the string.'),

  mkSimple(tString, 'toNumber', tNumber, {
    js: (_compiler: Compiler, _cc: CompilationContext): string => {
      return 'const num = Number.parseFloat(object); return (typeof num === "number" && isFinite(num) ? num : 0;'
    }
  }, 'Returns a number. If the string does not represent a valid number, 0 is returned.')
]

const numberMethods = [

  mkSimple(tNumber, 'toString', tString, {
    js: (_compiler: Compiler, _cc: CompilationContext): string => {
      return 'return object.toString()'
    }
  }, 'Returns a number. If the string does not represent a valid number, 0 is returned.')
]

const tListItemType: ST.Exp.ReturnTypeFn = (
  _runtime: Runtime,
  _paramTypes: ST.DT.TypeList[],
  _kwParamTypes: Record<string, ST.DT.TypeList>,
  objType: ST.DT.TypeDef): ST.DT.TypeList => {
  const listType = objType as ST.DT.ListTypeDef
  return [listType.itemType]
}

const listMethods = [
  mkSimple(tGenericList, 'first', tListItemType, { inlineJs: mkCallWith('[0]') }, 'Returns the first element of the list.'),
  mkSimple(tGenericList, 'last', tListItemType, {
    js: (_compiler: Compiler, _cc: CompilationContext): string => {
      return 'return object[object.length - 1];'
    }
  }, 'Returns the first element of the list.'),
  mkSimple(tGenericList, 'length', tNumber, { inlineJs: mkCallWith('length') }, 'Returns length of a list.')
]

const ElementIteratorClass: ST.Exp.PageElementClass = {
  t: 'PageElementClass',
  id: 'ElementIteratorClass',
  cid: 'ElementIteratorClass',
  name: 'Iterator',
  meta: {
    fixed: true,
    category: 'Special'
  },
  props: {
    children: F.makeChildrenProp(),
    dataSource: F.makeIterableProp('dataSource'),
    exampleData: F.makeIterableProp('exampleData'),
    elementStyling: F.makeStylingProp()
  },
  render: F.makeRenderContext([
    F.makeRenderHTMLTag('div', {}, [
      F.makeRenderEval(
        F.makeDotOp(
          F.makeGetNode('$thisElement'),
          F.makeGetAttr('children')
        ))]
    )])
}

const PageRootClass: ST.Exp.PageElementClass = {
  t: 'PageElementClass',
  id: 'PageRootClass',
  cid: 'PageRootClass',
  name: 'PageRoot',
  meta: {
    fixed: true,
    category: 'hidden'
  },
  props: {
    // TODO: use new props, test if adding new pages works
    url: F.makeParam('url', F.makePrimitiveDT('String'), F.makeString('/')),
    title: F.makeParam('title', F.makePrimitiveDT('String'), F.makeString('My Page')),
    children: F.makeChildrenProp(),
    workflows: F.makeParam('workflows', F.makeDefsListType(F.makePrimitiveDT('WorkflowList')), F.makeDefsList([]))
    // TODO after compiler: onLoad: F.makeParam('onLoad', F.makePrimitiveDT('EventCallback'), F.makeNodeRef('$undefined'))
  },
  // fc: ({ title, children, elementNode, onLoad, customAttrs, _ide }) => {
  //   window['pageLoaded'] ||= {}
  //   if (!_ide && !window['pageLoaded'][elementNode.id]) {
  //     onLoad.trigger()
  //     window['pageLoaded'][elementNode.id] = true
  //   }
  //   return (_ide ?
  //     outerTag('div', { 'class': 'page-editor-page-root' }, customAttrs, html`${children}`) :
  //     // html`<html><head><title>${title}</title></head><body>${children}</body></html>`)
  //     html`${children}`)
  // }
  render: F.makeRenderContext([
    F.makeRenderHTMLTag('div', {}, [
      F.makeRenderEval(
        F.makeDotOp(
          F.makeGetNode('$thisElement'),
          F.makeGetAttr('children')
        ))]
    )])
}

/**
 * Global methods from all libraries will be injected into the global scope of the project.
 * This has been implemented and works (25.11.2023) from syntax support in the grammar up
 * until auto completion and type checks.
 * However, as of now, no global methods are used and it is probably a better language design to
 * not do this.
 *
 * Uncomment the code below and add it to the globalMethodDefs array.
 */

/*
const globalTest: ST.Exp.Method = {
  t: 'Method',
  id: 'BuiltIn.globalTest',
  name: 'globalTest',
  meta: {
    description: 'A method to test global method lookup.',
    category: 'Test',
    order: 1
  },
  js: (arg: ST.Exp.Expression) => {
    console.info(arg)
    return F.makeString(JSON.stringify(arg))
  },
  parameters: [F.makeParam('message', [F.makePrimitiveDT('String')])],
  kwParameters: {},
  returnType: F.makePrimitiveDT('String')
}
*/

const BuiltInLib = {
  classDefs: [PageRootClass, ElementIteratorClass],
  objMethodDefs: binOpMethods
    .concat(stringMethods)
    .concat(numberMethods)
    .concat(listMethods),
  globalMethodDefs: []
}

export default BuiltInLib
