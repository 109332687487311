import { Fragment, VNode } from 'preact'
import { observer } from 'mobx-react'
import { useContext, useState, useRef, useEffect } from 'preact/hooks'
import { F } from '../agjs'
import { Scope } from '../agjs/scope'
import { Agjs2, ST } from '../agjs/types'
import { ExpressionEditor } from './ExpressionEditor'
import { UI, IconButton, UITextInput } from './index'

import { AppContext } from '../app_context'
import { formatAsElementName } from '../agjs/string_utils'
import {toJS} from 'mobx'

interface IExpressionPropEditor {
  element: ST.Exp.Render.PageElement
  prop: ST.Param
  propValue: ST.Exp.PropValue
  scope: Scope
}

const ExpressionPropEditor = ({ element, prop, propValue, scope }: IExpressionPropEditor) => {
  const { store } = useContext(AppContext)

  // const openDialog = () => store.ui.openExpressionEditor(element, prop, propValue)

  const saveChanges = (result: ST.Exp.Expression) => {
    store.project.op.generic.setProp(element, prop.id, result)
  }

  if (prop.dataTypes.length > 1) throw new Error('multiple data types not supported')
  return <ExpressionEditor fieldName={prop.name} exp={propValue as ST.Exp.Expression} scope={scope} requiredType={prop.dataTypes[0]} onSubmit={saveChanges} />
}

const NameEditor = ({ element, onChange, light }: { element: Agjs2.NamedNode, onChange: (newName: string) => void, light?: boolean }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [editing, setEditing] = useState(false)
  const [newValue, setNewValue] = useState(element.name)
  const startEditing = () => setEditing(true)
  const stopEditing = () => {
    setEditing(false)
  }
  const commitChanges = () => {
    stopEditing()
    onChange(newValue)
  }
  const changeHandler = (e: Event) => {
    const target = e.target as HTMLInputElement
    const validName = formatAsElementName(target.value)
    if (validName != target.value) target.value = validName
    setNewValue(validName)
  }

  const cancelEditing = () => {
    setNewValue(element.name)
    stopEditing()
  }

  useEffect(() => {
    setEditing(false)
    setNewValue(element.name)
  }, [element.name, element.id])

  useEffect(() => {
    if (inputRef.current == null) return;
    const el = inputRef.current as unknown as ({ base: HTMLInputElement } | undefined)
    if (editing && el?.base != null) {
      el.base.focus()
    }
  }, [editing])

  if (editing) {
    return (
      <UI.HGroup>
        <UI.Grow>
          <UITextInput
            value={newValue}
            ref={inputRef}
            onInput={changeHandler}
            onEsc={() => cancelEditing()}
            onEnter={() => commitChanges()}
          />
        </UI.Grow>
        <IconButton icon='close' onClick={cancelEditing} light={light} />
        <IconButton icon='done' onClick={commitChanges} light={light} />
      </UI.HGroup>
    )
  } else {
    return (
      <UI.HGroup>
        <UI.Grow>
          <span onClick={startEditing} class='select-none cursor-pointer'>
            {element.name}
          </span>
        </UI.Grow>
        <IconButton icon='edit' onClick={startEditing} light={light} />
      </UI.HGroup>
    )
  }
}

/*
const TextPropEditor = observer(({ element, prop, value }) => {
  const { store } = useContext(AppContext) as Ide.App
  // prop.value is an expression whose value is the string.
  const [stringConst, setStringConst] = useState(value.value)
  const onChange = (e: Event) => {
    setStringConst(e.target.value)
    // TODO: normalize this somewhere else...
    // element.propValues ||= {}
    // element.propValues[prop.id] ||= {...prop.defaultValue}
    // element.propValues[prop.id].value = e.target.value
    store.project.setProp(element, prop.id, {_exp: 'Text', value: e.target.value})
  }
  useEffect(() => setStringConst(value.value), [element.id, prop.id])
  return (
    <UITextInput value={stringConst} onInput={onChange} />
  )
})
 */

const StringPropEditor = observer(({ element, prop, value }) => {
  // prop.value is an expression whose value is the string.
  const [stringConst, setStringConst] = useState(value.value)
  const { store } = useContext(AppContext)
  const onChange = (e: Event) => {
    const str: string = (e.target as HTMLInputElement).value
    setStringConst(str)
    store.project.op.generic.setProp(element, prop.id, { ...F.makeString(str), id: value.id })
  }
  useEffect(() => setStringConst(value.value), [element.id, prop.id])
  return (
    <UITextInput value={stringConst} onInput={onChange} />
  )
})

const DataSourcePropEditor = observer(({ element, prop, propValue, scope }: IExpressionPropEditor) => {
  const { store } = useContext(AppContext)

  const saveChanges = (result: ST.Exp.Expression) => {
    store.project.op.generic.setProp(element, prop.id, result)
  }

  const dt = F.makeListType(F.makeAnyDT())

  return <ExpressionEditor fieldName={prop.name} exp={propValue as ST.Exp.Expression} scope={scope} requiredType={dt} onSubmit={saveChanges} />
})

const BooleanPropEditor = observer(({ element, prop, value }) => {
  const checked = !!value.value
  const { store } = useContext(AppContext)
  const toggle = () => {
    store.project.op.generic.setProp(element, prop.id, F.makeBoolean(!checked))
  }
  return (
    <div className='p-2 border-ridge border-b'>
      <label>{`${prop.name} of ${element.name}`}
        <input type='checkbox' checked={checked} onClick={toggle} />
      </label>
    </div>
  )
})

/*
const EnumPropEditor = observer(({ element, prop, propValue }) => {
  const { store } = useContext(AppContext)
  const select = (ev) => {
    const newValue = ev.target.value
    store.project.op.generic.setProp(element, prop.id, { t: 'EnumValue', value: newValue })
  }

  return (
    <select className="form-control" onInput={select}>
      {prop.allValues.map(ev => <option value={ev.value} selected={propValue.value === ev.value}>{ev.name}</option>)}
    </select>
  )
})
*/

type OnChangeArgumentCb = (name: string, exp: ST.Exp.Expression) => void

interface IWorkflowArgumentsEditor {
  workflowId: string
  args: Record<string, ST.Exp.Expression>
  scope: Scope
  onChange: OnChangeArgumentCb
}

const WorkflowArgumentsEditor = observer(
  ({ workflowId, args, scope, onChange }: IWorkflowArgumentsEditor): VNode | null => {
  const { store } = useContext(AppContext)

  const wf: Agjs2.Workflow = toJS(store.project.nodeIndex.lookupAllowMissing(workflowId)) as Agjs2.Workflow

  if (wf == null || wf.inputs.length === 0) return null

  return (
    <div className='p-2 border-ridge border-b'><strong>Workflow inputs</strong><br />
      <UI.HGroup>
        {wf.inputs.map(param => (
        <div>
          {param.name}
          <ExpressionEditor
            fieldName={param.name}
            exp={args[param.name] ?? store.project.nodeIndex.runtime.defaultValueForDataType(param.dataTypes[0])}
            scope={scope}
            requiredType={param.dataTypes[0]}
            onSubmit={(result: ST.Exp.Expression) => onChange(param.name, result)} />
        </div>))}
      </UI.HGroup>
    </div>
   )
})

const EventPropEditor = observer(({ element, prop, propValue }) => {
  const { store } = useContext(AppContext)
  const scope = store.project.nodeIndex.sc(element)
  const select = (ev: Event) => {
    const newId = (ev.target as HTMLSelectElement).value
    if (newId === '$new') {
      const page = store.project.nodeIndex.getParentPage(element)

      const wf = store.project.op.workflow.createOnPage(`${prop.name}${element.name}`, page.id)
      // store.project.op.generic.setProp(element, prop.id, F.makeNodeRef(wf.id, wf.name))
      store.project.op.generic.setProp(element, prop.id, F.makeWorkflowRef(wf.id, wf.name))
      store.project.openWorkflow(wf.id)
    } else if (newId === '$null') {
      store.project.op.generic.setProp(element, prop.id, F.makeNull())
    } else {
      store.project.op.generic.setProp(element, prop.id, F.makeWorkflowRef(newId))
    }
  }

  const updateArg: OnChangeArgumentCb = (name:string, exp: ST.Exp.Expression): void => {
    const kwArgs = toJS(propValue.kwArgs)
    kwArgs[name] = toJS(exp)
    store.project.op.generic.setProp(element, prop.id, { ...propValue, kwArgs })
  }

  if (store.project.currentPage == null) return null

  interface OptionRow { id: string, label: string, disabled?: boolean }

  const options: OptionRow[] = []
  options.push({ id: '$null', label: '(do nothing)' })
  store.project.currentPage.propValues.workflows.items.forEach(wf => options.push({ id: wf.id, label: wf.name }))
  options.push({ id: '--', label: '--', disabled: true })
  options.push({ id: '$new', label: 'Create Workflow...' })

  return (
    <Fragment>
      <select className="form-select" onInput={select}>
        {options.map(opt => <option value={opt.id} disabled={opt.disabled} selected={propValue.refId === opt.id}>{opt.label}</option>)}
      </select>
      <WorkflowArgumentsEditor workflowId={propValue.refId} args={propValue.kwArgs} scope={scope} onChange={updateArg} />
    </Fragment>
  )
})

/** A widget for editing prop values for an element (instance) */
const PropEditor = observer(({ element, propDefValue }: { element: Agjs2.InstanceNode, propDefValue: Agjs2.PropDefValue }) => {
  const { store } = useContext(AppContext)
  let editor: VNode | null = null
  const prop = propDefValue.prop
  const propValue = propDefValue.propValue

  const scope = store.project.nodeIndex.sc(element)
  let hideSectionTitle = false

  if (prop.dataTypes.length > 1) throw new Error('multiple data types not supported')
  switch (prop.dataTypes[0].type) {
    case 'String':
      editor = <StringPropEditor element={element} prop={prop} value={propValue} />
      break
    case 'Text':
      // editor = <TextPropEditor element={element} prop={prop} value={propValue}/>
      // TODO: have all this as part of expression wrapper (so the configurabel viewAs datatype)...
      // if it is text, you can switch between cminput and regular input etc.
      // should better be a third type: DataTypeAwareInput that summons ExpresiionEditor when required.
      // with LITERAL types, none of this matters (expression editor), it never needs to be shown.
      editor = <ExpressionPropEditor element={element} prop={prop} propValue={propValue} scope={scope} />
      break
    case 'Boolean':
      editor = <BooleanPropEditor element={element} prop={prop} value={propValue} />
      break
/*
    case 'Enum':
      editor = <EnumPropEditor element={element} prop={prop} propValue={propValue} />
      break
*/
    case 'EventCallback':
      return (<div className='p-2 border-ridge border-b'>{hideSectionTitle ? null : <Fragment>{prop.name}<br /></Fragment>}
        <EventPropEditor element={element} prop={prop} propValue={propValue} />
      </div>)
    case 'ElementStyling':
      hideSectionTitle = prop.name === 'styling'
      editor = <ElementStylingEditor element={element} prop={prop} propValue={propValue} />
      break
    case 'IterableList':
      editor = <DataSourcePropEditor element={element} prop={prop} propValue={propValue} scope={scope} />
      break
    default:
      return null
      // editor = <div>{`Unsupported data type '${prop.dataType.type}'`}</div>
  }
  return (
    <div className='p-2 border-ridge border-b'>{hideSectionTitle ? null : <Fragment>{prop.name}<br /></Fragment>}
      <UI.HGroup>{editor}</UI.HGroup>
    </div>
  )
})

/** A widget for editing prop defs for a class def (used in elements workbench) */
const PropDefEditor = observer(({ classDef, propDef, onDelete }: { classDef: Agjs2.ClassDef, propDef: ST.Param, onDelete: () => void }) => {
  // const { store } = useContext(AppContext) as Ide.App
  // let editor: VNode | null = null
  // const prop = propDefValue.prop
  // const propValue = propDefValue.propValue

  // const scope = store.project.nodeIndex.sc(element) as Scope

  // switch(prop.dataType.type) {
  //   case 'String':
  //     editor = <StringPropEditor element={element} prop={prop} value={propValue}/>
  //     break;
  //   case 'Text':
  //     // editor = <TextPropEditor element={element} prop={prop} value={propValue}/>
  //     editor = <ExpressionPropEditor element={element} prop={prop} propValue={propValue} scope={scope}/>
  //     break;
  //   case 'Boolean':
  //     editor = <BooleanPropEditor element={element} prop={prop} value={propValue}/>
  //     break;
  //   case 'Enum':
  //     editor = <EnumPropEditor element={element} prop={prop} propValue={propValue}/>
  //     break;
  //   case 'EventCallback':
  //     editor = <EventPropEditor element={element} prop={prop} propValue={propValue}/>
  //     break;
  //   default:
  //     return null
  //     // editor = <div>{`Unsupported data type '${prop.dataType.type}'`}</div>
  // }

  const options = ['Text', 'String', 'Boolean', 'Enum', 'EventCallback', 'KVStrings', 'PageElementList', 'WorkflowList'].map(n => ({
    id: n,
    disabled: false,
    label: n
  }))

  const select = (x) => console.log('you are:', x)

  const typeName = propDef.dataTypes[0].type

  const bogusEl = { id: 'xx' }

  return (
    <div className='p-2 border-ridge border-b'>{propDef.name}<br />
      <UI.HGroup>
        <select className="form-select" onInput={select}>
          {options.map(opt => <option value={opt.id} disabled={opt.disabled} selected={typeName === opt.id}>{opt.label}</option>)}
        </select>
      </UI.HGroup>
      <UI.HGroup>
        Default value
        <StringPropEditor element={bogusEl} prop={propDef} value={propDef.defaultValue} />
      </UI.HGroup>
      <div onClick={() => onDelete()}>remove prop</div>
    </div>
  )
})

export { NameEditor, PropEditor, PropDefEditor }
