import { Agjs2, ST } from './types'
import { F } from './factory'

import { Parser } from './parser'
import { Scope } from './scope'
import { Runtime } from './runtime'
import { Cursor, Ancestry } from './cursor'
import { toJS } from 'mobx'

// this whole code is copied from parser.ts und parser specific stuff like withRaw() was stripped.
// maybe these two functions can be reconciled if the universal version of resolveSymbol would support a callback before return-
// this callback would then wrap the to-be-returned-values with a call to withRaw- including the from/to parameters which have been stripped here?
// edit: callback included as a dummy!
const resolveSymbol = (dotOpLeft: ST.Exp.Expression | null, needle: string, runtime: Runtime, scope: Scope): ST.Exp.Expression => {
  // throw new Error('this function may need tweaks (incorporate code changes made for resolveSymbolOrSymbolInvoc() in parser, namely it needs to parse the arguments for method calls?')
  const withRaw = (exp: ST.Exp.Expression): ST.Exp.Expression => exp
  if (dotOpLeft != null) {
    // FIXME: ist hier kein exact-match helper direkt in indexIndex enthalten?
    const allResults = runtime.nodeIndex.suggestDotOpRightSide(dotOpLeft)
    const exactMatches = allResults.filter(res => ((res as ST.Exp.GetAttr)._name ?? (res as Agjs2.NamedNode).name) === needle)

    if (exactMatches.length === 1) {
      const match = exactMatches[0]

      if ((match as Agjs2.NamedNode).t === 'ObjMethod') {
        return withRaw(F.makeCallObjMethod((match as ST.Exp.ObjMethod).name, []))
      } else if ((match as ST.Exp.GetAttr).t === 'GetAttr') {
        return withRaw(match as ST.Exp.GetAttr)
      }
    }
  } else {
    const res = scope.allSymbols().filter(sm => sm.name === needle).map(runtime.nodeIndex.getReferenceFor)
    if (res.length === 1) {
      return withRaw(res[0])
    }
  }
  return withRaw(F.makeUnresolved(needle))
}

export interface ClassFromSourceOpts {
  resolve?: boolean
}

/**
 * Expects a runtime where a libraries have already been loaded (StdLib etc.)
 * On the first pass, symbols used in renderExps of these libraries will be unresolved.
 * This function iterates over all classDefs of all libries and basically calls
 * `resolveSymbolsInClassDef()`.
 * This changes the nodes of the classes and replaces unresolved nodes with proper references.
 * `resolveAllSymbolsInLib` also re-adds and reloads all processed libraries to the nodeIndex of the runtime.
 *
 * @see resolveSymbolsInClassDef
 */
export function resolveAllSymbolsInLib (runtime: Runtime): void {
  runtime.libs.list.forEach(library => {
    library.classDefs.forEach(cd => {
      resolveSymbolsInClassDef(cd, library, runtime)
    })
  })
}

/**
 * Adds the class to `library`, add it to nodeIndex (so scope and thisElement will be set for the classDef).
 * Then, iterate over classes renderExpression to replace $unresolved symbols with the actual reference (from scope).
 * After this, the newly fixed classDef object replaces the old one in library, and the library is reloaded by
 * the runtime.
 *
 * @param library - A library where to add this classDef (or where it is contained)
 */
export function resolveSymbolsInClassDef (cd: Agjs2.ClassDef, library: Agjs2.Library, runtime: Runtime): void {
  const reloadLibrary = (withClass: Agjs2.ClassDef): void => {
    const existingCdIndex = library.classDefs.map(c => c.cid).indexOf(withClass.cid)

    if (existingCdIndex > -1) {
      runtime.nodeIndex.processLib(library, true)
      library.classDefs[existingCdIndex] = withClass
    } else {
      library.classDefs.push(withClass)
    }

    // add() will take care to properly replace existing libs with same id
    runtime.libs.add(library)
    runtime.nodeIndex.processLib(library, false)
  }

  reloadLibrary(cd)

  const scope = runtime.nodeIndex.sc(cd)

  const c = new Cursor(cd)

  const classWithResolvedSymbols = c.iterate2(<T>(node: T, anc: Ancestry) => {
    const dotOp = node as ST.Exp.DotOp

    if (dotOp?.t === 'DotOp') {
      const right = dotOp.right as ST.Exp.Unresolved
      if (right.t === '$unresolved') {
        let left = dotOp.left
        if (left.t === '$unresolved') left = resolveSymbol(null, left.value, runtime, scope)
        // if (dotOp.left.t === '$unresolved') console.log(cd.cid, 'resolving left side:', left)
        const resolvedRightSide = resolveSymbol(left, right.value, runtime, scope)
        const resolvedDotOp = { ...dotOp, left, right: resolvedRightSide }
        // console.log(cd.cid, 'resolved right side: ', scope.name, resolvedDotOp)
        return resolvedDotOp as T
      }

      // const parentDotOp = anc.parentNode as ST.Exp.DotOp
      // CASE 1: parent is a dotop, so resolve left & right.
      // if (parentDotOp?.t === 'DotOp') {
      //   if (anc.attrName === 'right') {
      //     const left = parentDotOp.left
      //     const right = node as ST.Exp.DotOpRightSide

    //     // TODO: resolve left and right and then return both right node.
    //     console.log('this node is a right side of a dot op. its left side is:', parentDotOp.left)
    //   }
    } else if ((anc.parentNode as ST.Exp.DotOp)?.t !== 'DotOp') {
      // CASE 2: parent is not a dot op so just try to resolve the symbol in the scope of the class.
      const unres = node as ST.Exp.Unresolved
      const needle = unres.value
      if (unres.t === '$unresolved') {
        // console.log('attempting to solve unresolved symbol [not part of a dotop]', unres)
        const res = scope.allSymbols().filter(sm => sm.name === needle).map(runtime.nodeIndex.getReferenceFor)
        if (res.length === 1) {
          return res[0] as T
        }
        throw new Error(`Cannot resolve symbol on 2nd pass: ${needle}. Scope: ${scope.name}`)
      }
    }
    return null
  }) as ST.Exp.PageElementClass

  reloadLibrary(classWithResolvedSymbols)
}

export function classFromSource (source: string, opts: ClassFromSourceOpts = {}): ST.Exp.PageElementClass {
  const runtime = new Runtime({})
  const parser = new Parser(runtime)

  const tree = parser.parse(source)

  const cd = parser.astToExp(tree.topNode, source, runtime.nodeIndex.globalClassScope) as ST.Exp.PageElementClass
  if (cd.t !== 'PageElementClass') throw new Error(`classFromSource called, but source isn't a class def: ${(cd as ST.Exp.Expression).t}`)

  // runtime.nodeIndex.mount(exp, { parentId: '-', parentAttr: '-' }, runtime.nodeIndex.globalScope)

  return cd
}

export function ensureLiteral<T extends ST.Exp.Lit.Primitive> (
  node: ST.Lang | null,
  litType: T['t'],
  onValid: (validExp: T) => void,
  throwError: boolean = false): boolean {
  if ((node != null) && node.t === litType) {
    onValid(node as T)
    return true
  }
  if (throwError) throw new Error(`Expected a ${litType} but got ${JSON.stringify(node)}`)
  return false
}

export function ensureStringLiteralValue (node: ST.Lang | null, onValid: (value: string) => void): boolean {
  return ensureLiteral<ST.Exp.Lit.String>(node, 'String', validExp => onValid(validExp.value))
}

export function returnLiteral<T extends ST.Exp.Lit.Primitive> (node: ST.Lang | null, litType: T['t']): T {
  if ((node != null) && node.t === litType) {
    return node as T
  }
  throw new Error(`Expected a ${litType} but got ${JSON.stringify(node)}`)
}

export function returnStringLiteralValue (node: ST.Lang): string {
  return returnLiteral<ST.Exp.Lit.String>(node, 'String').value
}

export function getAttr (node: Agjs2.Node, attrPath: string): unknown {
  const path = attrPath.split('.')
  let base = node
  while (path.length > 0) { base = base[path.shift() as string] }
  return base
}

/** Return next element if current element is part of a list, otherwise continue
 * traversing the tree upwards towards this element's parent
 */
export function getNextElementOrParent<T extends Agjs2.Node> (parent: T, parentAttr: string, oldIndex: number): T {
  const siblings = getAttr(parent, parentAttr) as T[]
  if (siblings.length <= 1) return parent
  let newIndex = oldIndex + 1
  if (newIndex > siblings.length - 1) newIndex = oldIndex - 1
  return siblings[newIndex]
}

/** Return next node from siblings, if oldIndex is going to be deleted (or returns null
 * if the only element left in siblings is at oldIndex).
 */
export function getNextNode<T extends Agjs2.Node> (siblings: T[], oldIndex: number): T | null {
  if (siblings.length <= 1) return null
  let newIndex = oldIndex + 1
  if (newIndex > siblings.length - 1) newIndex = oldIndex - 1
  return siblings[newIndex]
}

export function deepClone (obj: any): any {
  // const type = {}.toString.call(obj).slice(8, -1)

  if (Array.isArray(obj)) {
    return obj.map(item => deepClone(item))
  }

  switch (typeof obj) {
    case 'object': {
      const result = {}
      for (const key in obj) {
        const val = deepClone(obj[key])
        if (val !== '__$omitted_attr') result[key] = val
      }
      return result
    }
    case 'string':
    case 'number':
    case 'bigint':
    case 'boolean':
    case 'undefined':
    case 'symbol':
      return obj
    default:
      return '__$omitted_attr'
  }
}

/** Currently ONLY supports PageElementList. A hypthetical single PageElement prop is not
 * supported (will return false) */
export function isElementDataType (propDef: ST.Param): boolean {
  // if (propDef.dataType.type === 'PageElement') throw new Error('isElementDataType: this prop type of type PageElement (not PageElementList) is not supported yet.')
  // return ['PageElementList', 'PageElement'].includes(propDef.dataType.type)
  if (propDef.dataTypes.length > 1) throw new Error('multiple datatypes not supported')
  return ['PageElementList'].includes(propDef.dataTypes[0].type)
}

/** * Returns a prop name if the given expression retrieves this property on $thisElement.
 * For example referenceToPropValue(exp) with exp beeing a DotOp($thisElement, GetAttr('children'))
 * will return "children".
 * If the expression is anything else, this function will return null
 */
export function referenceToPropValue (exp: ST.Exp.Expression): null | string {
  if ((exp).t === 'DotOp') {
    const dotOp: ST.Exp.DotOp = exp
    const gn = dotOp.left as ST.Exp.GetNode
    if (gn.t !== 'GetNode') { console.log('ERROR: PageElementListPropWrapper left side of dotop needs to be GetNode', toJS(gn)); return null }
    if (gn.refId !== '$thisElement') { console.log('ERROR: PageElementListPropWrapper left side of dotOp should reference $thisElement'); return null }
    const ga = dotOp.right as ST.Exp.GetAttr
    if (ga.t !== 'GetAttr') { console.log('ERROR: PageElementListPropWrapper right side of dotop needs to be GetAttr'); return null }

    // const attrPath = `propValues.${ga._name}`
    // return thisElement.propValues[ga._name] as Agjs2.PageElement[]
    return ga._name
  }
  return null
}
