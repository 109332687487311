import { Agjs2, ST } from './agjs/types'
import { F } from './agjs/factory'
import { toJS } from 'mobx'
import { Utils, Runtime, randomId } from './agjs'

export function toCustomElement (elementNode: ST.Exp.Render.PageElement, runtime: Runtime): ST.Exp.PageElementClass {
  const cldef = runtime.getElementClassDef(elementNode)

  const clonedDef: ST.Exp.PageElementClass = Utils.deepClone(toJS(cldef))

  if (clonedDef.render == null) throw new Error('Cannot convert page elements to component when render function is empty')

  const instancePropValues: ST.Exp.KwArgs = Utils.deepClone(toJS(elementNode.propValues))
  // Check if the renderContext from this class def references any props (for example,
  // outputs thisElement.content or so).
  // If so, the new renderContext will have such references replaced with the actual propValues
  // from the instance we are trying to convert.

  const replaceGetNodeRefWithValue = (parent: ST.Exp.Render.All, exp: ST.Exp.Expression, propValues: ST.Exp.KwArgs): ST.Exp.Render.All[] => {
    const getNodeFromThisElement = exp
    const attrName = Utils.referenceToPropValue(getNodeFromThisElement as ST.Exp.DotOp)

    if (attrName != null) {
      const propValue: ST.Exp.PropValue = propValues[attrName]

      // FIXME: statt nach namen sollte hier nach datentyp agiert werden.
      if (attrName === 'children') {
        return (propValue as ST.Exp.PageElementList).items
      } else {
        // Replace reference to a prop with actual prop value (for non-children attributes)
        return [F.makeRenderEval(propValue)]
      }
    }
    return [parent]
  }

  const processRenderExps = (renderExps: ST.Exp.Render.All[], propValues: ST.Exp.KwArgs): ST.Exp.Render.All[] => {
    let newItems: ST.Exp.Render.All[] = []

    renderExps.forEach(re => {
      switch (re.t) {
        case 'RenderEval': {
          const result = replaceGetNodeRefWithValue(re, re.value, propValues)
          newItems = newItems.concat(result)
          break
        }
        case 'RenderHTMLTag': {
          const result: ST.Exp.Render.HTMLElement = {
            ...re,
            children: processRenderExps(re.children, propValues)
          }
          newItems = newItems.concat(result)
          break
        }
        case 'RenderPageElement':
          // TODO:
          // process re.children the same way?
          newItems.push(re)
          break
        case 'RenderCond':
        case 'RenderList':
          newItems.push(re)
          throw new Error('converting RenderCond/RenderList not implemented')
        default:
          newItems.push(re)
          break
      }
    })

    return newItems
  }

  const newRenderExp: ST.Exp.RenderContext = F.makeRenderContext(
    processRenderExps(clonedDef.render.items, instancePropValues)
  )

  const name = runtime.nodeIndex.newName(`${elementNode.name}AsElement`, { startWithoutNumber: true, scope: runtime.nodeIndex.globalClassScope })

  const newDef: ST.Exp.PageElementClass = {
    t: 'PageElementClass',
    id: randomId(),
    name,
    cid: name,
    meta: {
      category: 'Project',
      description: `Custom element from ${elementNode.name}`
    },
    props: {}, // TODO: editor to select which props to redirect here.
    // TODO: muss das nicht ein arrary sein hier?!
    render: newRenderExp
  }

  return newDef
}

export function makeCloneOfClassDef (cldef: Agjs2.ClassDef, runtime: Runtime): ST.Exp.PageElementClass {
  // const cldef = runtime.libs.getClassDef(cid)

  const name = runtime.nodeIndex.newName(`CopyOf${cldef.name}`, { startWithoutNumber: true, scope: runtime.nodeIndex.globalClassScope })

  const clonedDef: ST.Exp.PageElementClass = Utils.deepClone(toJS(cldef))

  clonedDef.id = randomId()
  clonedDef.name = name
  clonedDef.cid = name
  clonedDef.meta.category = 'Project'
  clonedDef.meta.description = `Based on ${cldef.name}`

  return clonedDef
}
