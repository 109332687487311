import Preact, {ComponentChildren, VNode} from 'preact';
import { useRef, useEffect } from 'preact/hooks';
import { makeObservable, action, observable, runInAction } from "mobx"
import { observer } from "mobx-react"

import { Ide } from '../types'
import { IconButton, Icon, Panel } from '../pcomponents'

/* RE-implementation 2024 */

/*
 * Ideas:
 * add sidebar containers with an id. (each sidebar container has a list of panels (meta data) that belong to it, ordered list!)
 * add panels in desired order with target sidebar container id. (panel meta data has actual sidebar id in it)
 * separate component that takes dock manager renders the sidebar containers.
 * third component takes dock manager and renders floating windows if necessary
 */

interface IDockState {
  attachFrom: 'left' | 'right'
  x: number
  width: number
}

export type DockableViewStruct = [Ide.IDockableState, Preact.VNode<any>]

export type DockablePanelContentFn = () => VNode | null

interface PanelConfig {
  autoId: number
  containerId: string
  title: string
  contentFn: DockablePanelContentFn
  state: 'docked' | 'floating' | 'dragging'
  rect: {
    x: number, y: number, height: number, width: number
  }
}

interface SidebarConfig {
  name: string
  rect: {
    x: number, y?: number, height?: number, width: number
  }
  state: 'invisible' | 'visible' | 'dragover'
}

class DockManager {
  store: Ide.Store
  lastTimer: NodeJS.Timeout
  lastPanelId: number = 0

  panels: PanelConfig[] = []
  sidebars: SidebarConfig[] = []

  // Offset where the movable area (of the workspace view) starts.
  areaOffsX: number
  areaOffsY: number

  parents: Map<string, IDockState>

  constructor(store: Ide.Store) {
    this.store = store
    this.parents = new Map()
    makeObservable(this, {
      panels: observable,
      sidebars: observable,
      stopMoving: action,
      startMoving: action,
      dock: action,
      undock: action,
      move: action,
      registerDock: action,
      dockToLastContainer: action
    })
  }

  addPanel (containerId: string, title: string, contentFn: DockablePanelContentFn): void {
    this.lastPanelId += 1
    this.panels.push({ autoId: this.lastPanelId, containerId, title, contentFn, state: 'docked', rect: { x: 0, y: 0, height: 0, width: 0 }})
  }

  startMoving(ev: PointerEvent, dockableState: Ide.IDockableState, outerDiv: HTMLElement) {
    // parent node, since the move/mouse click was triggered inside the header
    // div only- otherwise we only get the height of the header
    const rect = outerDiv.getBoundingClientRect()

    dockableState.moving = true
    dockableState.oldX = rect.x
    dockableState.oldY = rect.y
    dockableState.width = rect.width
    dockableState.height = rect.height
    dockableState.startOffsX = ev.screenX
    dockableState.startOffsY = ev.screenY
    this.move(ev, dockableState)
  }

  stopMoving(dockableState: Ide.IDockableState) {
    if (!dockableState.moving) return;
    dockableState.moving = false
    if (dockableState.potentialParent) {

      // 100% correct would be to only look at windows docked in potentialParent
      // to determine the max order value
      // WARNING: according to docs, using spread syntax here only works with 'few'
      // elements but should be sufficient for floating windows
      dockableState.order = Math.max(...Array.from(this.store.ui.dockables.values()).
        map(ds => ds.order)) + 1

      dockableState.parentContainer = dockableState.potentialParent
      dockableState.potentialParent = null
      dockableState.floating = false
    } else {
      dockableState.floating = true
    }
  }

  dockToLastContainer(dockableState: Ide.IDockableState) {
    dockableState.floating = false
  }

  move(ev: PointerEvent, dockableState: Ide.IDockableState) {
    if (dockableState.moving) {
      ev.stopPropagation()
      dockableState.x = ev.screenX - dockableState.startOffsX + dockableState.oldX
      dockableState.y = ev.screenY - dockableState.startOffsY + dockableState.oldY

      const potentialP = Array.from(this.parents.entries()).find(([name, parent]) => {
        if ((parent.attachFrom === 'right' && dockableState.x < parent.x + parent.width + 10)||
            (parent.attachFrom === 'left' && (dockableState.x + dockableState.width > parent.x - 10))) {
            return true
          }
        }
      )

      if (potentialP) {
        dockableState.potentialParent = potentialP[0]
      } else {
        dockableState.potentialParent = null
      }

      // dockableState.x = ev.screenX - dockableState.startOffsX + this.areaOffsX + dockableState.oldX
      // dockableState.y = ev.screenY - dockableState.startOffsY + this.areaOffsY + dockableState.oldY
      clearTimeout(this.lastTimer)
      this.lastTimer = setTimeout(() => this.stopMoving(dockableState), 1000)
    }
  }

  undock (panelId: number): void {
    const index = this.panels.findIndex(panel => panel.autoId === panelId)
    if (index === -1) return
    runInAction(() => { this.panels[index].state = 'floating' })
  }

  dock(parentContainer, dockableState) {
    dockableState.floating = false
    dockableState.moving = false
    dockableState.parentContainer = parentContainer
  }

  // registerParent(name: string, { attachFrom, x, width } : IDockState) {
  //   this.parents.set(name, { attachFrom, x, width })
  // }

  registerDock(name: string, rect: { x: number, width: number }) {
    console.log('registering sidebar', name)
    this.sidebars.push({ name, state: 'visible', rect })
  }
}

const DockableWindow = observer(({ title, children, dockableState, manager }) => {
  // check dockOrFloat in DockableViewController to migrate full code

  const { floating, moving } = dockableState
  const wrapperClasses = floating ? '' : 'overflow-hidden'

  const outerDiv = useRef<HTMLDivElement>(null)

  const mousedown = ev => {
    manager.startMoving(ev, dockableState, outerDiv.current)
  }

  const mouseup = ev => {
    manager.stopMoving(dockableState)
  }

  const mousemove = (ev: MouseEvent) => {
    manager.move(ev, dockableState)
  }

  const shadow = 'drop-shadow-[0_15px_15px_rgba(0,0,0,0.75)]'
  const wrapperCl = floating || moving ? `z-20 ${shadow} absolute p-px border border-popped` : ''
  const wrapperFloatingAttrs = moving || floating ? {style: `${dockableState.attachTo}: ${dockableState.x}px; top: ${dockableState.y}px`} : {}

  return (
      <div ref={outerDiv} className={'bg-container-bg ' + wrapperCl} {...wrapperFloatingAttrs}>
        <div className={'d-flex justify-content-between bg-header-bg text-guitext text-sm select-none p-1 ' + (dockableState.moving ? 'cursor-grabbing' : 'cursor-grab')}
          onMouseMove={mousemove}
          onMouseUp={mouseup}
          onMouseDown={mousedown}>
          <div>
            <Icon icon={'drag_indicator'} />{title}
          </div>
          <div>
          {floating && <IconButton icon={'close'} onClick={() => manager.dockToLastContainer(dockableState)} />}
          </div>
        </div>
        {children}
      </div>
  )
})

interface IDock {
  name: string
  views: Preact.VNode[]
  manager: DockManager
  attachFrom: 'left' | 'right'
}

// (formerly VerticalDock)
// const SidebarDock = observer(({ name, views, manager, attachFrom } : IDock) => {
//   const { store } = useContext(AppContext) as Ide.App
// 
//   const div = useRef()
// 
//   useEffect(() => {
//     const rect = div.current.getBoundingClientRect()
//     manager.registerParent(name, { attachFrom , x: rect.x, width: rect.width })
//   })
// 
//   const DWPlaceholder = ({ dockableState }) => {
//     // const sizeAttrs = {style: `width: ${dockableState.width}px; height: ${dockableState.height}px`}
//     const sizeAttrs = {style: `height: ${dockableState.height}px`}
//     return (
//       <div class={'box-border p-2 w-64'} style='width: 100px'>
//         <div class={'m-2 border rounded border-2 border-ridge border-dashed'} {...sizeAttrs}>
//         </div>
//       </div>
//     )
//   }
//   const movingDockable = store.ui.getMovingDockable()
// 
//   const sortedViews = views.
//     filter(([dw, element]) => dw.parentContainer === name && !(dw.floating || dw.moving)).
//     sort((a, b) => a[0].order - b[0].order).
//     map(([dw, element]) => element)
// 
//   return (
//   <div ref={div} className='bg-container-bg h-0 min-h-100 overflow-auto d-flex flex-column'>
//     {sortedViews}
//     {movingDockable && movingDockable.potentialParent === name ? <DWPlaceholder dockableState={movingDockable} /> : null}
//   </div>
//   )
// })

const SidebarDock = observer(({ name, manager }: { name: string, manager: DockManager }) => {
  const div = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (div?.current == null) return
    const rect = div.current.getBoundingClientRect()
    manager.registerDock(name, { x: rect.x, width: rect.width })
    // TODO: resize observer
  }, [])

  let cl = ''
  const draggingActive = manager.panels.find(panel => panel.state === 'dragging') != null
  const visiblePanels = manager.panels.filter(panel => panel.containerId === name && panel.state === 'docked')

  if (visiblePanels.length === 0) cl += ' empty-sidebar-dock'
  // TODO: if ANY panel goes into state dragging, ALL SidebarDocks need to render the docking points.
  return (
    <div ref={div} className={`sidebar-container${cl}`}>
      {visiblePanels.map(panel =>
          <DockedPanelWrapper panel={panel} key={panel.autoId} manager={manager}>{panel.contentFn()}</DockedPanelWrapper>
      )}
    </div>
  )
  // {movingDockable && movingDockable.potentialParent === name ? <DWPlaceholder dockableState={movingDockable} /> : null}
  // deprecated: sollte durch panel array erledigt werden.

  // const DWPlaceholder = ({ dockableState }) => {
  //   // const sizeAttrs = {style: `width: ${dockableState.width}px; height: ${dockableState.height}px`}
  //   const sizeAttrs = {style: `height: ${dockableState.height}px`}
  //   return (
  //     <div class={'box-border p-2 w-64'} style='width: 100px'>
  //       <div class={'m-2 border rounded border-2 border-ridge border-dashed'} {...sizeAttrs}>
  //       </div>
  //     </div>
  //   )
  // }
  // const movingDockable = store.ui.getMovingDockable()

  // const sortedViews = views.
  //   filter(([dw, element]) => dw.parentContainer === name && !(dw.floating || dw.moving)).
  //   sort((a, b) => a[0].order - b[0].order).
  //   map(([dw, element]) => element)

  // return (
  // <div ref={div} className='bg-container-bg h-0 min-h-100 overflow-auto d-flex flex-column'>
  //   {sortedViews}
  //   {movingDockable && movingDockable.potentialParent === name ? <DWPlaceholder dockableState={movingDockable} /> : null}
  // </div>
  // )
})

const FloatingPanelWrapper = (props: { children: ComponentChildren, panel: PanelConfig }): VNode | null => {
  // TODO: das äußere div aussortieren, stattdessen sollte die Panel componente das alles unterstützen. (floating mode oder nicht)
  return (
    <div class='position-absolute bg-white p-2' style={`left: ${props.panel.rect.x}px; top: ${props.panel.rect.y}px;`}>
    <div onClick={() => runInAction(() => { props.panel.state = 'docked' })}>Dock it</div>
    {props.children}
    </div>
  )
}

const DockedPanelWrapper = (props: { children: ComponentChildren, panel: PanelConfig, manager: DockManager }): VNode | null => {
  const wrapperDiv = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (wrapperDiv?.current == null) return
    const rect = wrapperDiv.current.getBoundingClientRect()
    runInAction(() => {
      props.panel.rect.x = rect.x
      props.panel.rect.y = rect.y
      props.panel.rect.width = rect.width
      props.panel.rect.height = rect.height
    })
    // TODO: resize observer
  }, [])

  return (<Panel divRef={wrapperDiv}><div onClick={() => props.manager.undock(props.panel.autoId)}>Make float</div>{props.children}</Panel>)
}

const FloatingPanels = observer(({ manager }: { manager: DockManager }): VNode | null => {
  // const { store } = useContext(AppContext) as Ide.App
  // used to have 'relative' as styling, but I probably have a relative element further up
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const div = divRef.current
    if (div == null) return

    const { x, y } = div.getBoundingClientRect()
    manager.areaOffsX = x
    manager.areaOffsY = y

    // TODO: resize observer usen hier.
  }, [])

  const floatingPanels = manager.panels.filter(panel => panel.state === 'floating' || panel.state === 'dragging')

  return (
    <div ref={divRef}>
      {floatingPanels.map(panel => <FloatingPanelWrapper panel={panel} key={panel.autoId}>{panel.contentFn()}</FloatingPanelWrapper>)}
    </div>
  )
})

// const FloatingWindows = observer(({ manager, views }) => {
//   // const { store } = useContext(AppContext) as Ide.App
//   // used to have 'relative' as styling, but I probably have a relative element further up
//   const div = useRef()
// 
//   useEffect(() => {
//     const { x, y } = div.current.getBoundingClientRect()
//     manager.areaOffsX = x
//     manager.areaOffsY = y
//   }, [])
// 
//   return (
//     <div ref={divRef}>
//       {views.filter(([dw, element]) => dw.floating || dw.moving).map(([dw, element]) => element)}
//     </div>
//   )
// })

export { DockManager, DockableWindow, SidebarDock, FloatingPanels }
