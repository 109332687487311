import { VNode } from 'preact'
import { useContext, useState, useMemo, useEffect, useRef } from 'preact/hooks'
import { Scope } from '../agjs/scope'
import { F } from '../agjs/factory'
import { Formatter } from '../agjs/formatter'
import { Agjs2, ST } from '../agjs/types'
import { Parser } from '../agjs/parser'
import { DropdownButton, IconButton } from './index'

import { AppContext } from '../app_context'
import { CmInput } from './CmInput'
import { Tree as LeezerTree } from '@lezer/common'
import { makeAutocompleteCallback, makeLintingCallback } from '../utils/expression_editor_tools'
import { Cursor, Ancestry, StripParserMetaInfo } from '../agjs/cursor'
import { CloseFn, openModal } from '../overlay_manager'
import { StandardDialog } from './dialogs'
import { command, header, MenuItem } from './menu'

export type UIMode = 'ui' | 'exp'

interface IExpressionEditor {
  exp: ST.Exp.Expression
  requiredType?: ST.DT.TypeDef
  onSubmit: (exp: ST.Exp.Expression) => void
  onCancel?: () => void
  onUiMode?: (mode: UIMode) => void
  uiName?: string
  type?: 'inline' | 'modal'
  scope: Scope
  fieldName?: string
  light?: boolean
  unbuffered?: boolean
}

const isIncomplete = (exp: Agjs2.Node): boolean => {
  const cursor = new Cursor(exp)
  let foundIncomplete = false
  cursor.iterate2((node: Agjs2.Node, _ancestors: Ancestry) => {
    if (['$incomplete', '$unresolved'].includes((node as ST.Exp.Expression).t)) foundIncomplete = true
    return null
  })
  return foundIncomplete
}

export const ConfigureInputButton = ({ onChangeMode, uiName, mode }: { uiName: string, mode: UIMode, onChangeMode: (mode: UIMode) => void }): VNode => {
  const menuGen = (): MenuItem[] => [
    header('Input mode'),
    command(uiName, () => onChangeMode('ui'), undefined, { checked: mode === 'ui', radio: true }),
    command('Code editor', () => onChangeMode('exp'), undefined, { checked: mode === 'exp', radio: true })
  ]
  return <DropdownButton icon='more_vert' generator={menuGen} />
}

export const ExpressionEditor = ({ exp, fieldName = 'this field', requiredType, onSubmit, scope, onCancel, type = 'inline', onUiMode, uiName, unbuffered }: IExpressionEditor): VNode => {
  const { runtime } = useContext(AppContext)

  const [tempExp, setTempExp] = useState(exp)
  const [editing, setEditing] = useState(false)

  const divRef = useRef<HTMLDivElement>(null)

  // The internal state needs to be reset if the 'exp' prop changes.
  // This happens also when the editor is shown in the inspector and the user picks a different page element-
  // the inspector renders the same amount and order of components, only 'exp' (and scope) has changed.
  // But because 'exp' is stored in the internal state, the value of the previous component is retained.
  // So we need to listen for changes in 'exp' (and 'scope') and reset the component accordingly.
  useEffect(() => {
    setTempExp(exp)
    setEditing(false)
  }, [scope, exp])

  const parser = useMemo(() => new Parser(runtime), [runtime])

  const autocompCb = makeAutocompleteCallback(parser, scope, runtime)
  const lintingCb = makeLintingCallback(parser, scope, runtime, requiredType ?? F.makeAnyDT(), fieldName)

  let raw = (tempExp as unknown as Agjs2.ParserMetaInfo).parser?.raw
  if (typeof raw !== 'string') {
    const formatter = new Formatter(runtime)
    raw = formatter.toString(tempExp)
  }

  let lastTypeError: string | null = null

  const isWrongType = (exp: ST.Exp.Expression): boolean => {
    const inferredType = runtime.tc.inferType(exp)

    if (requiredType != null) {
      const tcRes = runtime.tc.isCompatible([requiredType], inferredType, { r: fieldName, i: 'your expression' })
      if (!tcRes.ok) {
        lastTypeError = tcRes.messages.join('\n')
        return true
      }
    }
    lastTypeError = null
    return false
  }

  const error = isIncomplete(tempExp) || isWrongType(tempExp)

  const save = (newExp: ST.Exp.Expression): boolean => {
    if (isIncomplete(newExp) || isWrongType(newExp)) return false
    setEditing(false)

    const expWithoutParserMetaInfo = StripParserMetaInfo(newExp) as ST.Exp.Expression
    onSubmit(expWithoutParserMetaInfo)
    return true
  }

  const cancel = (): void => {
    setEditing(false)
    setTempExp(exp)
  }

  // TODO: ein letztes problem bleibt noch. wenn 'exp' sich ändert (gleiches objekt, aber inhalt ändert sich)
  // bekommt das die instanz hier nicht mit. muss noch angepasst werden irgendwie.
  const parseTreeInput = (tree: LeezerTree, doc: string): void => {
    // TODO: have an option to specify (in astToExp) that we want expressions exclusively (throws error when parsing a statement)
    const newExp = parser.astToExp(tree.topNode, doc, scope, exp.id) as ST.Exp.Expression

    setEditing(true)
    setTempExp(newExp)
    if (unbuffered === true) save(newExp)
  }

  const handlePressEnter = (tree: LeezerTree, doc: string): void => {
    const newExp = parser.astToExp(tree.topNode, doc, scope, exp.id) as ST.Exp.Expression
    if (save(newExp)) {
      (document.activeElement as HTMLElement).blur()
    // TODO: blur
    }
  }

  const expandEditor = (): void => {
    const localSubmit = (exp: ST.Exp.Expression): void => {
      closeModalFn()
      setTempExp(exp)
      save(exp)
    }

    const cancelModal = (): void => closeModalFn()

    const closeModalFn = openModal((closeFn: CloseFn) => (
      <ExpressionEditor
        exp={tempExp}
        fieldName={fieldName}
        requiredType={requiredType}
        scope={scope}
        onSubmit={localSubmit}
        onCancel={cancelModal}
        type='modal'
      />),
    {
      animatedOutline: true,
      outlineOrigin: divRef.current ?? undefined

    })
  }

  const wrapWithDialog = (inner: VNode): VNode => {
    let reqTypeName: string = ''

    if (requiredType != null) {
      reqTypeName = `(${runtime.tc.toS(requiredType)})`
    }

    let errorInfo: VNode | null = null

    if (error) {
      if (lastTypeError != null) {
        errorInfo = <div className='text-warning'>{lastTypeError}</div>
      } else {
        errorInfo = <div className='text-danger'>Expression is incomplete or has errors.</div>
      }
    }

    return (
      <StandardDialog
        title={`Edit expression for ${fieldName} ${reqTypeName}`}
        onCancel={() => onCancel?.()}
        onSubmit={() => save(tempExp)}
        submitDisabled={error}
        submitLabel='Save changes'
      >
        {errorInfo}
        {inner}
      </StandardDialog>
    )
  }

  const wrapWithInputGroup = (inner: VNode): VNode => (
    <div className='input-group' ref={divRef}>
      <div className={`form-control expression-editor ${error ? 'is-invalid' : ''}`} style='padding: 0;'>
        {inner}
      </div>
      {onUiMode != null ? <ConfigureInputButton mode='exp' uiName={uiName ?? 'UI editor'} onChangeMode={() => { onUiMode('ui') }} /> : null}
      <IconButton icon='open_in_full' onClick={() => expandEditor()} />
      {editing && unbuffered !== true &&
        (
          <>
            <IconButton icon='close' onClick={cancel} />
            <IconButton icon='done' disabled={error} onClick={() => save(tempExp)} />
          </>
        )}
    </div>
  )

  const cmInput = (submitOnEnter: boolean): VNode => (
    <CmInput
      source={raw}
      onInput={parseTreeInput}
      onPressEnter={submitOnEnter ? handlePressEnter : undefined}
      runtime={runtime}
      scope={scope}
      onAutocomplete={autocompCb}
      onLint={lintingCb}
    />
  )

  return type === 'modal' ? wrapWithDialog(cmInput(false)) : wrapWithInputGroup(cmInput(true))
}
