import { Agjs2, ST } from '../agjs/types'
import { Ide } from '../types'
import { App } from '../app_context'
import { makeAutoObservable, action, toJS } from 'mobx'

type PositionalRef = 'top' | 'bottom' | 'left' | 'right'

interface IDragPlaceholder {
  parent: ST.Exp.Render.PageElement
  parentAttr: string
  toRef: PositionalRef
  adjacentNode?: ST.Exp.Render.PageElement
}

export class DndBuffer {
  draggedItem: ST.Exp.Render.PageElement | ST.Exp.PageElementClass | null = null

  dragPlaceholder: IDragPlaceholder | null = null

  app: App

  constructor (app: App) {
    makeAutoObservable(this, {
      store: false,
      draggedItem: false,
      startDragging: action,
      endDragging: action
      // ...
    })

    this.app = app
  }

  get store (): App['store'] {
    return this.app.store
  }

  ca (elementNode: Agjs2.InstanceNode): Ide.CustomAttrs {
    return this.store.project.nodeIndex.ca(elementNode) as Ide.CustomAttrs
  }

  startDragging (event: DragEvent, item: ST.Exp.Render.PageElement | ST.Exp.PageElementClass): void {
    this.draggedItem = item

    if (event.dataTransfer != null) {
      event.dataTransfer.dropEffect = item.t === 'RenderPageElement' ? 'move' : 'copy'
      event.dataTransfer.setData(`ag/${item.t}`, item.id)

      // create drag image:
      const div = document.querySelector('#drag-image > div') as HTMLElement

      // Ignore when running test environment
      if (div != null) {
        div.innerText = item.name
        const h = div.offsetHeight
        const w = div.offsetWidth

        event.dataTransfer.setDragImage(div, w / 2, h / 2)
      }
    }
  }

  endDragging (): void {
    this.draggedItem = null
    this.targetPageElement(null)
  }

  // targetPageElement (pageElementId: Agjs2.nodeId, propName: string, position: PositionalRef): void {
  targetPageElement (parent: ST.Exp.Render.PageElement | null, parentAttr?: string, adjacentNode?: ST.Exp.Render.PageElement, toRef?: Ide.DragMarkerRef): void {
    if (parent == null) {
      if (this.dragPlaceholder != null) {
        this.clearDragPlaceholder()
      }
      return
    }
    if (this.draggedItem == null) {
      console.log('Stopping setDragTarget because no draggedItem.')
      return
    }

    const p = this.dragPlaceholder

    if ((p != null) && p.parent === parent &&
          p.parentAttr === parentAttr &&
          p.adjacentNode === adjacentNode &&
          p.toRef === toRef) {
      return
    }

    if (this.dragPlaceholder != null) {
      if (this.dragPlaceholder.parent != null && this.dragPlaceholder.parent !== parent) this.ca(this.dragPlaceholder.parent).dragMarker = null
      if (this.dragPlaceholder.adjacentNode != null && this.dragPlaceholder.adjacentNode !== adjacentNode) this.ca(this.dragPlaceholder.adjacentNode).dragMarker = null
    }

    if (parentAttr == null) {
      this.dragPlaceholder = null
    } else {
      this.dragPlaceholder = { parent, parentAttr, adjacentNode, toRef: toRef ?? 'left' }
    }

    if (this.store.ui.pageEditor.highlightedElement != null) this.store.ui.setHover(null)

    if (parent != null) this.ca(parent).dragMarker = { attr: parentAttr }

    if (adjacentNode != null) this.ca(adjacentNode).dragMarker = { marker: toRef }
  }

  dropOnTarget (): void {
    if (this.dragPlaceholder == null || this.draggedItem == null) return
    const { parent, parentAttr } = this.dragPlaceholder
    let index = 0

    if (this.dragPlaceholder.adjacentNode != null) {
      const peList: ST.Exp.PageElementList = parent.propValues[parentAttr] as ST.Exp.PageElementList
      index = peList.items.indexOf(this.dragPlaceholder.adjacentNode)
      if (index === -1) console.log('adjacent node not found in peList:', toJS(peList.items), toJS(this.dragPlaceholder.adjacentNode))
      if (['bottom', 'right'].includes(this.dragPlaceholder.toRef)) {
        index += 1
      }
    }

    // todo: wenn gemoved wird, muss brücksichtigt werden, dass sich der index ja ändert, je nach dem
    // wann das alte element wohin gepackt wird, wenn in gleichem prop!
    // zusätzlich kann index hier noch bearbeitet worden sein (bottom/right).
    //
    // also mal eine matrix aufstellen mit kombinatinsmöglichkeiten. (altes element ist vor oder nach dem index)

    const fullPath = `propValues.${parentAttr}.items`

    switch (this.draggedItem.t) {
      case 'PageElementClass':
        this.store.project.op.page.createElement(this.draggedItem.id, {
          namePrefix: this.draggedItem.name,
          target: {
            parentId: parent.id,
            parentAttr: fullPath,
            index
          },
          success: () => { this.clearDragPlaceholder() }
        })
        break
      case 'RenderPageElement': {
        const peList = parent.propValues[parentAttr] as ST.Exp.PageElementList
        if (index > peList.items.length - 1) index = peList.items.length - 1

        this.store.project.op.page.moveElement(this.draggedItem, {
          target: {
            index,
            parentId: parent.id,
            parentAttr: fullPath
          },
          success: () => { this.clearDragPlaceholder() }
        })
        break
      }
    }
  }

  clearDragPlaceholder (): void {
    if (this.dragPlaceholder?.parent != null) this.ca(this.dragPlaceholder.parent).dragMarker = null
    if (this.dragPlaceholder?.adjacentNode != null) this.ca(this.dragPlaceholder.adjacentNode).dragMarker = null
    this.dragPlaceholder = null
  }
}
