import { ComponentChildren, VNode } from 'preact'
import { observer } from 'mobx-react'
import { Agjs2, ST } from '../../agjs/types'
import { useContext, useState } from 'preact/hooks'
import { ConfigureInputButton, ExpressionEditor, UIMode } from './../ExpressionEditor'
import { ElementStylingInput } from './ElementStylingInput'
import { Scope } from '../../agjs/scope'
import { Icon, UISelect, UISelectOption, UITextInput } from '../index'
import { AppContext } from '../../app_context'
import { NodeIndex } from '../../agjs/node_index'
import { F } from '../../agjs'

export interface FriendlyInputProps {
  argOrPropId: string
  dataTypes: ST.DT.TypeDef[]
  value: ST.Exp.Expression
  onChange: (newValue: ST.Exp.Expression) => void
  onRemove?: () => void
  allowExpression?: boolean
  name?: string
  scope: Scope
}

/**
 * A data type specific input component (unbuffered).
 * This renders an input most appropriate for the given data type (and can be switched to a bare expression editor).
 * The input is unbuffered, meaning it will directly modify the given value (expression) object and not create a new one.
 * It will call onChange with that object whenever something has changed.
 * However it will ensure the new expression always confirms to the dataTypes.
 * @param value expression to display and edit. Will modify this object directly, no buffering!
 * @param dataTypes allowed data types for the expression
 */
export const FriendlyInput = observer((props: FriendlyInputProps): VNode => {
  // Achso, diese implementierung wurde gewählt weil es ja mehrere data types geben kann...
  // in diesem fall braucht es noch einen selector:
  // TODO:
  // Allow using different data type if available?? show expression editor in this case?
  const currentDt = props.dataTypes[0]
  // const [currentDt, setCurrentDt] = useState(props.dataTypes[0])

  // // FIXME: according to chatGPT, watching the array type (dataTypes) will not work,
  // // since Object.is() is used for comparing (shallow comparison).
  // // its necessary to implement my own deep quality check and update the state accordingly.
  // useEffect(() => {
  //   setCurrentDt(props.dataTypes[0])
  // }, [props.argOrPropId, props.dataTypes])

  const inputProps = {
    ...props,
    currentDt,
    // onSwitchDataType: (dt: ST.DT.TypeDef) => setCurrentDt(dt),
    name: props.name ?? 'This value'
  }

  switch (currentDt.type) {
    case 'String':
      return <StringInput {...inputProps} />
    case 'Record':
      return <RecordInput {...inputProps} />
    case 'AnyDbTable':
      return <AnyDbTableInput {...inputProps} />
    case 'ElementStyling':
      return <ElementStylingInput {...inputProps} />
    case 'PageElementList':
    case 'WorkflowList':
    case 'EventCallback':
    case 'DefsList':
      return <div>${props.name} - Unsupported: ${currentDt.type}</div>
    default:
      return <ExpressionInput {...inputProps} buffered />
  }
})

const getTableIdFromExp = (exp: ST.Exp.Expression, nodeIndex: NodeIndex): string | null => {
  const e = exp as ST.Exp.GetNode
  if (e.t === 'GetNode') {
    if (e.refId != null) {
      const table = nodeIndex.lookup(e.refId) as Agjs2.DbTableDef
      if (table.t === 'DbTableDef') return table.id
    }
  }
  return null
}

export interface UnbufferedInputProps extends FriendlyInputProps {
  currentDt: ST.DT.TypeDef
  // onSwitchDataType: (dt: ST.DT.TypeDef) => void
  onUiMode?: (mode: UIMode) => void
  uiName?: string
}

// TODO: wrap in observer because it accesses project.dbTables... so that it gets updated when the tables are updated!
const AnyDbTableInput = (props: UnbufferedInputProps): VNode => {
  const { store } = useContext(AppContext)

  const [currentTableId, setCurrentTableId] = useState<string | null>(getTableIdFromExp(props.value, store.project.nodeIndex))
  const [mode, setMode] = useState<string>('ui')

  const updateValue = (value: string | null): void => {
    setCurrentTableId(value)
    if (value == null) {
      props.onChange(F.makeNull())
    } else {
      const table = store.project.nodeIndex.lookup(value) as Agjs2.DbTableDef
      props.onChange(F.makeGetNode(table.id, table.name))
    }
  }

  // TODO: useEffect to check if mode is still current

  const tables: UISelectOption[] = store.project.dbTables.map(tableDef => (
    {
      id: tableDef.id,
      value: tableDef.id,
      label: tableDef.name,
      disabled: false
    }
  ))

  tables.unshift({
    id: 'no_table',
    value: null,
    label: '(none)'
  })

  // FIXME: Im Moment wird das ganze ding neu gerendert wenn das pop aufgeht.
  // GRUND: weil alle fenster im overlay manager auch neu gerendert werden, und mit dem menu ein neues overlay aufgeht.
  // gleichzeitig ist diese component (AnyDbTableInput) auch in einem Popup enthalten. DAS IST AUCH DER GRUND wahrscheinlich
  // warm ich oben die referenz verliere (DropdownButton). Denn dieser button wird ja auch neu gerendert, so bald das menu aufgeht.
  // fix muss also in overlay manager sein.
  // das er die liste der overlays iteriert ist ja OK. aber warum aktualisiert er den dom? das ist wharscheinlich auch der grund
  // warum das mouseEnter crazy läuft bei den nested menus.

  if (mode === 'ui') {
    return (
      <InputSection>
        <InputHeader {...props} />
        <div className='input-group'>
          <UISelect options={tables} selected={currentTableId ?? 'no_table'} onSelect={updateValue} />
          <ConfigureInputButton uiName='Database table picker' mode={mode} onChangeMode={mode => setMode(mode)} />
        </div>
      </InputSection>
    )
  }
  // TODO: hook up mode changer, parse expression output to update currentTableId etc.
  return (<ExpressionInput {...props} buffered />)
}

export const RecordInput = (props: UnbufferedInputProps): VNode => {
  const editorName = 'Record editor'
  type RecordExp = ST.Exp.RecordOfExps

  const currentExp = props.value as RecordExp

  const { runtime } = useContext(AppContext)

  const [mode, setMode] = useState<string>('ui')

  const updateExp = (value: ST.Exp.Expression): void => {
    if (value != null) {
      props.onChange(Object.assign(props.value, value))
    }
  }

  const updateField = (name: string, newExp: ST.Exp.Expression | null): void => {
    const newRecord = props.value as RecordExp // deepClone(currentExp) as RecordExp
    if (newExp != null) {
      newRecord.fields[name] = newExp
    } else {
      delete newRecord.fields[name]
    }
    props.onChange(props.value)
  }

  const dt = props.dataTypes[0] as ST.DT.RecordTypeDef

  const rdata = props.value as RecordExp // currentExp as RecordExp

  if (mode === 'ui') {
    return (
      <InputSection>
        <InputHeader {...props} />
        <div className='form-control bg-base-2'>
          <div className='d-flex'>
            <div className='flex-fill'>
              {dt.fields.filter(rf => rdata.fields[rf.name] == null).map(rf => (
                <span key={rf.name}>
                  <a className='badge rounded-pill text-bg-secondary me-1' href='#' onClick={() => updateField(rf.name, runtime.defaultValueForDataType(rf.dataType, { allowNull: true }))}>+ {rf.name}</a>
                </span>
              ))}
            </div>
            <ConfigureInputButton uiName={editorName} mode={mode} onChangeMode={mode => setMode(mode)} />
          </div>
          {dt.fields.filter(rf => rdata.fields[rf.name] != null).map(rf => (
            <div key={rf.name} className='mb-3'>
              <FriendlyInput
                name={rf.name}
                onRemove={rf.optional === true ? () => updateField(rf.name, null) : undefined}
                argOrPropId={rf.name}
                value={rdata.fields[rf.name]}
                scope={props.scope}
                dataTypes={[rf.dataType]}
                onChange={newExp => updateField(rf.name, newExp)}
              />
            </div>
          ))}
        </div>
      </InputSection>
    )
  }

  return (<UnbufferedExpressionInput {...props} onUiMode={mode => setMode(mode)} uiName={editorName} value={currentExp} onChange={exp => updateExp(exp)} />)
}

const ExpressionInput = (props: UnbufferedInputProps & { buffered: boolean }): VNode => {
  return (
    <InputSection>
      <InputHeader {...props} />
      <ExpressionEditor exp={props.value} onUiMode={props.onUiMode} uiName={props.uiName} fieldName={props.name} requiredType={props.currentDt} onSubmit={props.onChange} scope={props.scope} />
    </InputSection>
  )
}

export const UnbufferedExpressionInput = (props: UnbufferedInputProps): VNode => {
  return (
    <InputSection>
      <InputHeader {...props} />
      <ExpressionEditor exp={props.value} unbuffered onUiMode={props.onUiMode} uiName={props.uiName} fieldName={props.name} requiredType={props.currentDt} onSubmit={props.onChange} scope={props.scope} />
    </InputSection>
  )
}

export const InputSection = ({ children }: { children: ComponentChildren }): VNode => {
  return (
    <div className='mx-2'>{children}</div>
  )
}

export const InputHeader = ({ name, onRemove }: { name?: string, onRemove?: () => void }): VNode => {
  return (
    <>
      <h6>{name ?? '(no name specified)'}
        {onRemove != null
          ? (
            <a
              className='badge rounded-pill text-bg-secondary ms-1'
              title='Delete'
              href='#'
              onClick={onRemove}
            ><Icon icon='delete' autosize />
            </a>)
          : null}
      </h6>
    </>
  )
}

export const HorizontalField = ({ title, children, alignItems }: { title: string, children: ComponentChildren, alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch' }): VNode => (
  <div className='row mb-2'>
    <div className={`col-2 d-flex align-items-${alignItems ?? 'center'}`}>
      {title}
    </div>
    <div className='col-10'>
      {children}
    </div>
  </div>
)

const StringInput = (props: UnbufferedInputProps): VNode => {
  const editorName = 'String editor'

  const currentExp = props.value
  const [mode, setMode] = useState<string>('ui')

  const updateExp = (exp: ST.Exp.Expression): void => {
    props.onChange(exp)
  }

  const handleStringInput = (ev: Event): void => {
    const inputValue = (ev.target as HTMLInputElement).value
    if (inputValue === '=') {
      setMode('exp')
      updateExp(F.makeString(''))
      // setCurrentExp(F.makeString(''))
    } else {
      if (currentExp.t === 'String') {
        currentExp.value = inputValue
        updateExp(currentExp)
      } else {
        updateExp(F.makeString(inputValue))
      }
    }
  }

  const strvalue = currentExp.t === 'String' ? currentExp.value : null

  if (mode === 'ui' && strvalue != null) {
    return (
      <InputSection>
        <InputHeader {...props} />
        <div className='input-group'>
          <UITextInput value={strvalue} onInput={handleStringInput} />
          <ConfigureInputButton uiName={editorName} mode={mode} onChangeMode={mode => setMode(mode)} />
        </div>
      </InputSection>
    )
  }

  return (<UnbufferedExpressionInput {...props} onUiMode={mode => setMode(mode)} uiName={editorName} value={currentExp} onChange={exp => updateExp(exp)} />)
}
