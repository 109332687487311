import { Agjs2, ST } from './types'
import { F } from './factory'
import { Runtime } from './runtime'

const evalBinOp = (left: ST.Exp.Expression, op: string, right: ST.Exp.Expression, runtime: Runtime): ST.Exp.LiteralExpression => {
  // type is wrong, just to satisfy TS
  const resultLeft = expToJS(evalExp(left, runtime)) as number
  const resultRight = expToJS(evalExp(right, runtime)) as number

  const autoConst = (jsval: any): ST.Exp.LiteralExpression => {
    if (typeof jsval === 'number') {
      return F.makeLit('Number', jsval)
    } else {
      // return F.makeString(`${jsval}`)
      return F.makeString(jsval.toString())
    }
  }

  switch (op) {
    case '+':
      return autoConst(resultLeft + resultRight)
    case '-':
      return autoConst(resultLeft - resultRight)
    case '*':
      return autoConst(resultLeft * resultRight)
    case '/':
      return autoConst(resultLeft / resultRight)
    default:
      throw new Error(`unsupported operation: ${op}.`)
  }
}

type Simple = null | string | number | boolean | Simple[]

const expToJS = (expression: ST.Exp.Expression): Simple => {
  if (expression == null) return null

  switch (expression.t) {
    case 'String':
    case 'Text':
    case 'Number':
    case 'Boolean':
      return expression.value
    case 'List':
      return expression.items.map(item => expToJS(item))
    default:
      console.log('WARNING: Unknown expression for expToJS:', expression)
      return null
  }
}

const evalExp = (expression: ST.Exp.Expression, runtime: Runtime): ST.Exp.LiteralExpression => {
  return F.makeString('if you see this, evalExp cannot be deprecated. Check expression.ts evalExp()')
  /*
  if (expression == null) return F.makeNull()
  switch (expression.t) {
    case 'String':
    case 'Text':
    case 'Number':
    case 'Boolean':
      return expression
    case 'List':
      if (expression.items.length === 0) {
      return expression as ST.Exp.Lit.ListOfLit
    } else {
      throw new Error(`evalExp potentially called for a non literal expression?`)
    }
    case 'BinaryOp':
      return evalBinOp(expression.left, expression.op, expression.right, runtime)
    // case 'EnumValue':
    //   return (expression.value)
    case 'Group':
      return evalExp(expression.child, runtime)
    // case 'SymbolRef':
    case 'GetNode':
    case 'GetAttr':
      throw new Error('deprecated (call it within dotOp ONLY)')
    case 'CallObjMethod': {
      const args = expression.args.map(arg => evalExp(arg, runtime))
      return runtime.runObjMethod(expression.methodId, args) as ST.Exp.LiteralExpression
    }
    default:
      console.warn('WARNING: Unknown expression', expression)
      return F.makeNull()
  }
 */
}

export { evalExp, expToJS }
