import { Agjs2, ST } from '../types'
import { NodeIndex } from '../node_index'
import { Runtime } from '../runtime'
import { MutationCommands } from '../mutation_commands'
import { PageOp } from './page_op'
import { GenericOp } from './generic_op'
import { ClassDefOp } from './classdef_op'
import { WorkflowOp } from './workflow_op'

export interface ProjectConfig {
  nodeIndex: NodeIndex
  history: MutationCommands
  runtime: Runtime
  getOp: () => Op
}

export interface ModifyProjectParams {
  success?: (updatedNode: Agjs2.Node) => void
}

export interface MoveElementParams extends ModifyProjectParams {
  target: Agjs2.NodeLocation
}

export interface DeleteElementParams extends ModifyProjectParams {
  elementId: Agjs2.nodeId
}

export interface InsertElementParams extends ModifyProjectParams {
  namePrefix: string
  propValues?: ST.Exp.KwArgs
  target: Agjs2.NodeLocation
}

export class Op {
  pageOp: PageOp
  genericOp: GenericOp
  classDefOp: ClassDefOp
  workflowOp: WorkflowOp
  config: ProjectConfig

  constructor (nodeIndex: NodeIndex, mutationCommands: MutationCommands, runtime: Runtime) {
    this.config = {
      nodeIndex,
      runtime,
      history: mutationCommands,
      getOp: function () { return this }.bind(this)
    }
    this.pageOp = new PageOp(this.config)
    this.genericOp = new GenericOp(this.config)
    this.classDefOp = new ClassDefOp(this.config)
    this.workflowOp = new WorkflowOp(this.config)
  }

  get page (): PageOp {
    return this.pageOp
  }

  get generic (): GenericOp {
    return this.genericOp
  }

  get workflow (): WorkflowOp {
    return this.workflowOp
  }

  get classDef (): ClassDefOp {
    return this.classDefOp
  }
}
