import { useContext, useEffect, useState } from 'preact/hooks'
import { observer } from 'mobx-react'

import { Agjs2, ST } from '../agjs/types'
import { IconButton, ShortUUID } from '../pcomponents'

import { AppContext } from '../app_context'
import { VNode } from 'preact'
import { StandardDialog } from './dialogs'
import { RecordInput } from './expression_editors'
import { CloseFn, openModal } from '../overlay_manager'

// TODO: extract to project wide feature (timestamp interface)
const dtf = new Intl.DateTimeFormat(undefined, {
  dateStyle: 'short',
  timeStyle: 'short'
})

interface EditRecordDialogProps {
  // tableDef: Agjs2.DbTableDef
  table?: string
  tableDef: Agjs2.DbTableDef
  currentRecord: ST.Exp.Lit.RecordOfLit
  closeFn: CloseFn
  onSubmit: (record: ST.Exp.Lit.RecordOfLit, closeFn: CloseFn) => void
}

// FIXME: extract to shared code for data workbench
const EditRecordDialog = ({ tableDef, currentRecord, closeFn, onSubmit }: EditRecordDialogProps): VNode => {
  const { runtime } = useContext(AppContext)

  const dt = runtime.tc.makeRecordFromTableType(tableDef.id, { usedFor: 'update' })
  const [currentExp, setCurrentExp] = useState<ST.Exp.Lit.RecordOfLit>(() => {
    const initialRecord: ST.Exp.Lit.RecordOfLit = { ...runtime.defaultValueForDataType(dt), ...currentRecord }
    return initialRecord
  }
  )

  const scope = runtime.nodeIndex.sc(tableDef)

  return (
    <StandardDialog
      title={`Edit record ${(currentRecord.fields.Id as ST.Exp.Lit.String).value} on ${tableDef.name}`}
      onSubmit={() => onSubmit(currentExp, closeFn)} onCancel={closeFn}
    >
      <RecordInput
        name={tableDef.name}
        argOrPropId={tableDef.name}
        currentDt={dt}
        dataTypes={[dt]}
        value={currentExp}
        scope={scope}
        onChange={exp => setCurrentExp(exp as ST.Exp.Lit.RecordOfLit)}
        buffered={false}
      />
    </StandardDialog>
  )
}

interface TableRowProps {
  table: Agjs2.DbTableDef
  rowAsRecord: ST.Exp.Lit.RecordOfLit
  onDelete: () => void
  onUpdate: (updatedRecord: ST.Exp.Lit.RecordOfLit, closeFn: CloseFn) => void
}

const LitFromCol = ({ exp }: { exp: ST.Exp.Expression }): VNode => {
  const value = (exp as ST.Exp.Lit.Primitive).value
  const formatted = value instanceof Date ? dtf.format(value) : value
  return (
    <span>{formatted}</span>
  )
}

interface TableViewProps {
  table: Agjs2.DbTableDef
  onAddRecord: () => void
}

const TableRow = ({ table, rowAsRecord, onDelete, onUpdate }: TableRowProps): VNode => {
  const customColumns = table.columns.filter(cd => !['Id', 'CreatedAt', 'UpdatedAt'].includes(cd.name))

  const id = (rowAsRecord.fields.Id as ST.Exp.Lit.String).value
  const createdAt = (rowAsRecord.fields.CreatedAt as ST.Exp.Lit.Timestamp).value
  const updatedAt = (rowAsRecord.fields.UpdatedAt as ST.Exp.Lit.Timestamp).value

  const launchEditor = (record: ST.Exp.Lit.RecordOfLit): void => {
    openModal((closeFn: CloseFn) => <EditRecordDialog
      tableDef={table}
      currentRecord={record}
      onSubmit={(row, closeFn) => onUpdate(row, closeFn)}
      closeFn={closeFn}
                                    />
    )
  }

  return (
    <tr>
      <td>
        <div><ShortUUID uuid={id} /></div>
        <div className='text-muted'>Created {dtf.format(createdAt)}</div>
        {createdAt.toString() !== updatedAt.toString() ? <div className='text-muted'>Updated {dtf.format(updatedAt)}</div> : null}
      </td>
      {customColumns.map(cd => (<td key={cd.id}><LitFromCol exp={rowAsRecord.fields[cd.name]} /></td>))}
      <td>
        <IconButton icon='edit' title='Edit record' light onClick={() => launchEditor(rowAsRecord)} />
        <IconButton icon='delete' title='Delete record' light onClick={onDelete} />
      </td>
    </tr>
  )
}

const DataTypeTag = ({ dt }: { dt: ST.DT.TypeDef }): VNode => {
  if (dt.type === 'DbForeignKey') {
    return <span className='badge rounded-pill text-bg-info'>{dt.refId}</span>
  }

  return <span className='badge rounded-pill text-bg-secondary'>{dt.type}</span>
}

/**
 * TODO: decide if all the mutating actions should be handled inside that component or outside.
 * if handled outside, this view could be reused for non tably things, like looking at AI
 * generated fake data (or pre CSV imports) before storing it in the database (would still allow to edit this)
 */
export const TableView = observer(({ table, onAddRecord }: TableViewProps): VNode => {
  const { store } = useContext(AppContext)
  const [rows, setRows] = useState<ST.Exp.Lit.RecordOfLit[]>([])

  useEffect(() => {
    store.project.fetchDbTable(table).then(res => {
      setRows(res.rows)
    })
  }, [table])

  const customColumns = table.columns.filter(cd => !['Id', 'CreatedAt', 'UpdatedAt'].includes(cd.name))

  const deleteRow = (rowId: string): void => {
    store.project.deleteDbRow(table, rowId).then(wasDeleted => {
      if (wasDeleted) {
        setRows(rows.filter(row => (row.fields.Id as ST.Exp.Lit.String).value.toString() !== rowId))
      } else {
        console.log('error deleting row', rowId)
      }
    })
  }

  const updateRow = (updatedRowRecord: ST.Exp.Lit.RecordOfLit, closeFn: CloseFn): void => {
    store.project.updateDbRow(table, updatedRowRecord).then(success => {
      setRows(rows.filter(row => (row.fields.Id as ST.Exp.Lit.String).value.toString() !== (updatedRowRecord.fields.Id as ST.Exp.Lit.String).value))
      console.log('updating the record worked', success)
      closeFn()
    })
  }

  return (
    <div>
      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th>Id</th>
            {customColumns.map(column => <th key={column.id} className='text-left p-1'>{column.name} <DataTypeTag dt={column.dataType} /></th>)}
            <th className=''>
              <IconButton disabled icon='settings' title='Edit columns' light onClick={() => {}} />
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map(rowAsRecord => {
            const rowId = (rowAsRecord.fields.Id as ST.Exp.Lit.String).value
            return (
              <TableRow
                key={rowId}
                table={table}
                rowAsRecord={rowAsRecord}
                onDelete={() => deleteRow(rowId)}
                onUpdate={updateRow}
              />
            )
          })}
          <tr>
            <th colspan={table.columns.length + 1} className='border border-neutral-200 text-sm py-1 pr-1'>
              <IconButton icon='add' title='Add this row' light onClick={onAddRecord} />
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  )
})
