import { Fragment, VNode } from 'preact'
import { useContext, useState, useMemo } from 'preact/hooks'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'

import { AppContext } from '../app_context'
import { Agjs2, ST } from '../agjs/types'
import { ListViewWrapper, Panel, IconButton, DataTypeTag } from '../pcomponents'

import { generateDbTableContextMenuFunc, generateStaticDataElementContextMenuFunc } from '../helpers'
import { NameEditor } from '../pcomponents/prop_editor'
import { DataBrowser } from '../pcomponents/data_browser'
import { TableView } from '../pcomponents/db_table_browser'
import { DockManager, SidebarDock, FloatingPanels } from '../pcomponents/dockable_layout'
import { Dialog, StandardDialog } from '../pcomponents/dialogs'
import { CloseFn, openModal } from '../overlay_manager'
import { BenchDesk, WorkbenchViewWrapper } from '../pcomponents/workbench_ui'
import { RecordInput } from '../pcomponents/expression_editors'

/**
 * This should already be wrapped in a SidebarContainer, so this is just rendering the
 * inside of it. Wrapping in SidebarContainer should be handled by DockableViews etc.
 */
const DataPicker = observer((): VNode => {
  return (
    <>
      <DatabaseSchema />
      <DataItems />
    </>
  )
})

const DataItems = observer(() => {
  const { store } = useContext(AppContext)

  const [renamingId, setRenamingId] = useState<null | string>(null)

  const triggerRename = (item: ST.Exp.Variable): void => {
    store.project.openDataItem(item.id)
    setRenamingId(item.id)
  }

  const ConstItem = observer(({ item }: { item: ST.Exp.Variable }): VNode => {
    let cl = ''
    if (item.id === store.project.currentDataItemId) {
      cl = ' focused'
    }

    const tags = [<DataTypeTag dataType={item.dataType} />]

    const contextMenuHandler = (ev): void => {
      store.project.openDataItem(item.id)
      store.ui.contextMenu(
        ev,
        generateStaticDataElementContextMenuFunc(item, store)
      )
    }

    // if (item.dataType.type === 'List') {
    //   const count = (item.value as ST.Exp.Lit.ListOfLit).items.length
    //   tags.push(<EntityTags dataType={`${count} items`} />)
    // }

    return (
      <div class={'node-title p-1 select-none ' + cl} onContextMenu={contextMenuHandler} onDblClick={() => triggerRename(item)} onClick={() => store.project.openDataItem(item.id)}>
        {renamingId === item.id
          ? (<NameEditor element={item} onChange={newName => { store.project.op.generic.renameElement(item, newName); setRenamingId(null) }} light />)
          : (<Fragment>{item.name}{tags}</Fragment>)}
      </div>
    )
  })

  const items = store.project.data.length === 0 ? '(no items)' : store.project.data.map((c: ST.Exp.Variable) => <ConstItem key={c.id} item={c} />)

  return (
    <Panel>
      <ListViewWrapper>
        {items}
      </ListViewWrapper>
      <div class='mt-1'>
        <IconButton icon='add' title='Add new data structure' onClick={() => store.ui.runCommand('NEW_WB_ITEM')} />
      </div>
    </Panel>
  )
})

const DatabaseSchema = observer(() => {
  const { store } = useContext(AppContext)

  // const [renamingId, setRenamingId] = useState<null | string>(null)

  // const triggerRename = (item: ST.Exp.Variable) => {
  //   store.project.openDataItem(item.id)
  //   setRenamingId(item.id)
  // }

  const DbTable = observer(({ item: table }: { item: Agjs2.DbTableDef }) => {
    const tableId = table.id

    let cl = ''
    if (tableId === store.project.currentDbTableId) {
      cl = ' focused'
    }

    // const tags = [<DataTypeTag dataType={item.dataType} />]

    const contextMenuHandler = (ev): void => {
      store.project.openDbTable(tableId)
      store.ui.contextMenu(
        ev,
        generateDbTableContextMenuFunc(table, store)
      )
    }

    /*
    if (item.dataType.type === 'List') {
      const count = (item.value as ST.Exp.Lit.ListOfLit).items.length
      tags.push(<EntityTags dataType={`${count} items`} />)
    }

    return (
      <div class={'node-title p-1 select-none ' + cl} onContextMenu={contextMenuHandler} onDblClick={() => triggerRename(item)} onClick={() => store.project.openDataItem(item.id)}>
        {renamingId === item.id
          ? (<NameEditor element={item} onChange={newName => { store.project.op.generic.renameElement(item, newName); setRenamingId(null) }} light />)
          : (<Fragment>{item.name}{tags}</Fragment>)}
      </div>
    )
*/
    return (
      <div class={'node-title p-1 select-none ' + cl} onContextMenu={contextMenuHandler} onClick={() => store.project.openDbTable(tableId)}>
        <Fragment>{table.name}</Fragment>
      </div>
    )
  })

  const items = store.project.dbTables.length === 0 ? '(no tables)' : store.project.dbTables.map(c => <DbTable item={c} key={c.id} />)

  const openAddNewTableDialog = (): void => {
    openModal((closeFn: CloseFn) => <Dialog.NewDbTable closeFn={closeFn} />)
  }

  return (
    <Panel>
      <ListViewWrapper>
        {items}
      </ListViewWrapper>
      <div class='mt-1'>
        <IconButton icon='add' title='Add new database table' onClick={() => openAddNewTableDialog()} />
      </div>
    </Panel>
  )
})

interface AddRecordDialogProps {
  // tableDef: Agjs2.DbTableDef
  table?: string
  tableDef: Agjs2.DbTableDef
  closeFn: CloseFn
  onSubmit: (record: ST.Exp.Lit.RecordOfLit, closeFn: CloseFn) => void
}

const AddRecordDialog = ({ tableDef, closeFn, onSubmit }: AddRecordDialogProps): VNode => {
  const { runtime } = useContext(AppContext)

  const dt = runtime.tc.makeRecordFromTableType(tableDef.id, { usedFor: 'insert' })
  const [currentExp, setCurrentExp] = useState<ST.Exp.Lit.RecordOfLit>(() => runtime.defaultValueForDataType(dt) as ST.Exp.Lit.RecordOfLit)

  const scope = runtime.nodeIndex.sc(tableDef)

  return (
    <StandardDialog
      title={`Add new row to table ${tableDef.name}`}
      onSubmit={() => onSubmit(currentExp, closeFn)} onCancel={closeFn}
    >
      <RecordInput
        name={tableDef.name}
        argOrPropId={tableDef.name}
        currentDt={dt}
        dataTypes={[dt]}
        value={currentExp}
        scope={scope}
        onChange={exp => setCurrentExp(exp as ST.Exp.Lit.RecordOfLit)}
        buffered={false}
      />
    </StandardDialog>
  )
}

const DataWorkbenchContent = observer(() => {
  const { store } = useContext(AppContext)

  const dataSource = store.project.currentDataItem

  if (dataSource != null) {
    const dt = dataSource.dataType
    const exp = dataSource.value

    const update = (newExp: ST.Exp.LiteralExpression): void => {
      store.project.op.generic.setAttr(dataSource, 'value', newExp)
    }

    return (
      <DataBrowser key={dataSource.id} name={dataSource.name} dataType={dt} exp={exp} onChange={update} />
    )
  } else if (store.project.currentDbTableId != null) {
    // TODO:
    const currentTable = store.project.dbTables.find(table => table.id === store.project.currentDbTableId)
    if (currentTable == null) throw new Error('table not found')

    const insertRecord = async (row: ST.Exp.Lit.RecordOfLit, closeFn: CloseFn): Promise<void> => {
      console.log('saving ', toJS(row))
      const result = await store.project.insertIntoDbTable(currentTable.id, row)
      closeFn()
    }

    const addRow = (): void => {
      openModal((closeFn: CloseFn) => <AddRecordDialog
        tableDef={currentTable}
        onSubmit={(row, closeFn) => { void insertRecord(row, closeFn) }}
        closeFn={closeFn}
                                      />
      )
    }

    return (
      <Fragment>
        <TableView key={currentTable.name} table={currentTable} onAddRecord={addRow} />
      </Fragment>
    )
  }

  return <div class='border border-1 rounded border-neutral-200 m-1 p-2 text-neutral-700 text-xs'>(no item selected)</div>
})

const DataWorkbench = observer(() => {
  const { store } = useContext(AppContext)

  // store sollte eigentlich nicht benötigt werden? maybe...
  const dm = useMemo(() => new DockManager(store), [store])

  dm.addPanel('left-container', 'Schema', () => (
    <Panel>
      <DataPicker />
    </Panel>
  ))

  const outerMouseMove = (ev: PointerEvent): void => {
    const movingDockable = store.ui.getMovingDockable()
    if (movingDockable != null) {
      dm.move(ev, movingDockable)
    }
  }

  return (
    <WorkbenchViewWrapper onMouseMove={outerMouseMove}>
      <SidebarDock name='left-container' manager={dm} />
      <BenchDesk>
        <DataWorkbenchContent />
      </BenchDesk>
      <SidebarDock name='right-container' manager={dm} />
      <FloatingPanels manager={dm} />
    </WorkbenchViewWrapper>
  )
})

export { DataWorkbench }
