import { Agjs2, ST } from './types'
import { isMountable, NodeIndex } from './node_index'
import { randomId } from './index'
import { getAttr } from './utils'

import * as JDP from 'jsondiffpatch'

import { observable, makeAutoObservable, toJS, runInAction } from 'mobx'
import { jsonDigest } from './json_digest'

/** Timeout in seconds. If squashable mutations come in within this timespan, they
 * will be squashed into one mutation (existing mutation will be updated and no new one
 * will be added */
const SQUASH_TIMEOUT = 3

type MutationAction = 'INSERT' | 'DELETE' | 'MOVE' | 'RENAME' | 'CHANGE' | 'NOOP'

interface MutationIdent {
  action: string
}

interface JDiffMutation extends MutationIdent {
  action: MutationAction
  base: Agjs2.MutationLocation
  delta: JDP.Delta
}

export interface InsertMutation extends JDiffMutation {
  action: 'INSERT'
}

export interface DeleteMutation extends JDiffMutation {
  action: 'DELETE'
}

export interface MoveMutation extends MutationIdent {
  action: 'MOVE'
  source: Agjs2.NodeLocation
  target: Agjs2.NodeLocation
}

export interface RenameMutation extends MutationIdent {
  action: 'RENAME'
  nodeId: string
  oldName: string
  newName: string
}

export interface ChangeMutation extends JDiffMutation {
  action: 'CHANGE'
}

export interface NoMutation extends MutationIdent {
  action: 'NOOP'
}

export type ElementMutation = InsertMutation | DeleteMutation | MoveMutation | RenameMutation | ChangeMutation | NoMutation
export type ElementMutationWithoutNoop = Exclude<ElementMutation, NoMutation>

interface MutGroupDescriptionTemplates { n: string, p: string }

type MutGroupType = MutationGroup['type']
type MutArgs = Record<string, string>

interface GenMutationGroup<T extends MutGroupType, A extends MutArgs> {
  id: string
  hid: string
  type: T
  args: A
  userId: string
  createdAtEpoch: number
  previousId: string
  muts: ElementMutation[]
}

export type MGNodeRename = GenMutationGroup<'node.rename', { oldName: string, newName: string, id: string }>
export type MGProjectCreate = GenMutationGroup<'project.create', {}>

export type MGPropValueSet = GenMutationGroup<'propvalue.set', { propName: string, propId: string, parentName: string, parentId: string }>
export type MGAttrSet = GenMutationGroup<'attr.set', { attrName: string, attrPath: string, parentName: string, parentId: string }>

export type MGWorkflowCreate = GenMutationGroup<'workflow.create', { name: string, id: string }>
export type MGWorkflowDelete = GenMutationGroup<'workflow.delete', { name: string, id: string }>

export type MGPageCreate = GenMutationGroup<'page.create', { name: string, id: string }>
export type MGPageDelete = GenMutationGroup<'page.delete', { name: string, id: string }>

export type MGPageElementCreate = GenMutationGroup<'page.element.create', { elementName: string, parentName: string, id: string, parentId: string }>
export type MGPageElementDelete = GenMutationGroup<'page.element.delete', { elementName: string, parentName: string, id: string, parentId: string }>
export type MGPageElementMove = GenMutationGroup<'page.element.move', { elementName: string, action: string, id: string, oldParentId: string, newParentId: string }>

export type MGConstCreate = GenMutationGroup<'const.create', { name: string, id: string }>
export type MGConstDelete = GenMutationGroup<'const.delete', { name: string, id: string }>

export type MGDbTableCreate = GenMutationGroup<'dbtable.create', { name: string, id: string }>
export type MGDbTableDelete = GenMutationGroup<'dbtable.delete', { name: string, id: string }>

export type MGStateCreate = GenMutationGroup<'state.create', { name: string, id: string }>
export type MGStateDelete = GenMutationGroup<'state.delete', { name: string, id: string }>

export type MGClassDefCreateFromElement = GenMutationGroup<'classdef.element.create_from', { name: string, id: string, elementName: string, elementId: string }>
export type MGClassDefClone = GenMutationGroup<'classdef.clone', { name: string, id: string, sourceName: string, sourceId: string }>
export type MGClassDefSetRenderContext = GenMutationGroup<'classdef.element.set_render_context', { name: string, id: string }>

export type MGClassDefPropDefCreate = GenMutationGroup<'classdef.propdef.create', { propName: string, name: string, id: string }>
export type MGClassDefPropDefDelete = GenMutationGroup<'classdef.propdef.delete', { propName: string, name: string, id: string }>
export type MGClassDefPropDefChange = GenMutationGroup<'classdef.propdef.change', { propName: string, name: string, id: string }>

const messages: Record<MutGroupType, MutGroupDescriptionTemplates> = {
  'project.create': { n: 'Create project', p: 'Project created' },
  'node.rename': { n: 'Rename %oldName to %newName', p: '%oldName renamed to %newName' },

  'propvalue.set': { n: 'Change %parentName\'s %propName', p: 'Changed %parentName\'s %propName' },
  'attr.set': { n: 'Change %parentName\'s %attrName', p: 'Changed %parentName\'s %attrName' },

  'workflow.create': { n: 'Create workflow %name', p: 'Workflow %name created' },
  'workflow.delete': { n: 'Delete workflow %name', p: 'Workflow %name deleted' },

  'page.create': { n: 'Create page %name', p: 'Page %name created' },
  'page.delete': { n: 'Delete page %name', p: 'Page %name deleted' },

  'dbtable.create': { n: 'Create database table %name', p: 'Database table %name created' },
  'dbtable.delete': { n: 'Delete database table %name', p: 'Database table %name deleted' },

  'page.element.create': { n: 'Insert %elementName into %parentName', p: '%elementName inserted into %parentName' },
  'page.element.delete': { n: 'Delete %elementName', p: '%elementName deleted' },
  'page.element.move': { n: 'Move %elementName %action', p: '%elementName moved %action' },

  'const.create': { n: 'Create static data %name', p: 'Static data %name created' },
  'const.delete': { n: 'Delete static data %name', p: 'Static data %name deleted' },

  'state.create': { n: 'Create state %name', p: 'State %name created' },
  'state.delete': { n: 'Delete state %name', p: 'State %name deleted' },

  'classdef.element.create_from': { n: 'Create custom element %name from %elementName', p: 'Custom element %name from %elementName created' },
  'classdef.clone': { n: 'Clone %sourceName to %name', p: '%name cloned from %sourceName' },
  'classdef.element.set_render_context': { n: 'Edit appearance of %name', p: 'Appearance of %name edited' },

  'classdef.propdef.create': { n: 'Create property %propName', p: 'Property %propName created' },
  'classdef.propdef.delete': { n: 'Delete property %propName', p: 'Property %propName deleted' },
  'classdef.propdef.change': { n: 'Edit property %propName', p: 'Property %propName edited' }
}

const mutationTypeChangesLibs = [
  'classdef.element.create_from',
  'classdef.clone',

  // this one doesn't require a reload, but a rerendering of pages using this element classDef.
  'classdef.element.set_render_context',

  // extended consistency checks required after updating these:
  'classdef.propdef.create',
  'classdef.propdef.delete',
  'classdef.propdef.changed'
]

export type MutationGroup =
  MGProjectCreate |
  MGNodeRename |
  MGPropValueSet |
  MGAttrSet |

  MGWorkflowCreate |
  MGWorkflowDelete |

  MGPageCreate |
  MGPageDelete |

  MGDbTableCreate |
  MGDbTableDelete |

  MGPageElementCreate |
  MGPageElementDelete |
  MGPageElementMove |

  MGConstCreate |
  MGConstDelete |

  MGStateCreate |
  MGStateDelete |

  MGClassDefCreateFromElement |
  MGClassDefClone |
  MGClassDefSetRenderContext |
  MGClassDefPropDefCreate |
  MGClassDefPropDefDelete |
  MGClassDefPropDefChange

interface MutationHistory {
  past: MutationGroup[]
  present: MutationGroup
  future: MutationGroup[]
}

type TMutateCallback = (mutation: MutationGroup, direction: 'undo' | 'squash' | 'redo' | 'add') => void
type DelegateToBackendMutationCallback = (mutGroupId: string, direction: 'undo' | 'redo') => void

interface IMutationCommandsOptions {
  disableSquashing?: boolean
  onMutate?: TMutateCallback
  onLibsChanged?: () => void
  onDelegateToBackend?: DelegateToBackendMutationCallback
}

export const FIRST_NOOP: MutationGroup = {
  type: 'project.create',
  args: {},
  userId: '',
  createdAtEpoch: 0,
  previousId: '',
  id: randomId(),
  hid: '',
  muts: [{
    action: 'NOOP'
  }]
}

export function mutationDescription (group: MutationGroup, tense: 'n' | 'p' = 'n'): string {
  const msgTemplate = messages[group.type][tense]
  return msgTemplate.replace(/%(\w+)\b/g, (_match: string, p1: string) => group.args[p1])
}

export function mutationDigest (group: MutationGroup): string {
  const hashableGroup: MutationGroup = {
    ...group,
    hid: '',
    userId: '',
    createdAtEpoch: 0
  }

  return jsonDigest(hashableGroup)
}

FIRST_NOOP.hid = mutationDigest(FIRST_NOOP)

export function generateUniqueFirstNoop (): MutationGroup {
  const group = { ...FIRST_NOOP, id: randomId() }
  group.hid = mutationDigest(group)
  return group
}

function squashedMutation <M extends ElementMutation> (a: M, c: ElementMutation, mc: MutationCommands): M | null {
  if (
    a.action === 'RENAME' &&
    c.action === 'RENAME' &&
    a.nodeId === c.nodeId
  ) return { ...a, newName: c.newName }

  if (
    a.action === 'CHANGE' &&
    c.action === 'CHANGE' &&
    a.base.attrPath === c.base.attrPath &&
    a.base.nodeId === c.base.nodeId
  ) {
    const deltaAB = a.delta
    const deltaBC = c.delta
    const objB = toJS(getAttr(mc.nodeIndex.lookup(a.base.nodeId), a.base.attrPath))
    // I don't understand why cloning is necessary here, but otherwise it seems like all objA/B/C are
    // identidcal.
    const objA = mc.jdiff.unpatch(mc.jdiff.clone(objB), deltaAB)
    const objC = mc.jdiff.patch(mc.jdiff.clone(objB), deltaBC)
    const deltaAC = mc.jdiff.diff(objA, objC)
    // console.log(toJS(objA), toJS(objB), toJS(objC), toJS(deltaAB), toJS(deltaBC), toJS(deltaAC))
    return { ...a, delta: deltaAC }
  }

  return null
}

function squashMGArgs<M extends MutationGroup> (a: M, b: M): M['args'] {
  switch (a.type) {
    case 'node.rename':
      return {
        ...a.args,
        newName: (b as MGNodeRename).args.newName
      }
    case 'propvalue.set':
    default:
      return a.args
  }
}

export class MutationCommands {
  onMutate: TMutateCallback
  onLibsChanged: () => void
  onDelegateToBackend: DelegateToBackendMutationCallback
  nodeIndex: NodeIndex
  jdiff: JDP.DiffPatcher
  disableSquashing: boolean

  lastAddition: number

  history: MutationHistory = {
    past: [],
    present: FIRST_NOOP,
    future: []
  }

  constructor (nodeIndex: NodeIndex, opts: IMutationCommandsOptions) {
    this.jdiff = JDP.create()
    this.nodeIndex = nodeIndex
    this.disableSquashing = opts.disableSquashing === true
    this.onMutate = opts.onMutate ?? (() => null)
    this.onLibsChanged = opts.onLibsChanged ?? (() => null)
    this.onDelegateToBackend = opts.onDelegateToBackend ?? (() => null)
    this.lastAddition = 0

    makeAutoObservable(this, {
      lastAddition: false,
      onMutate: false,
      onLibsChanged: false,
      nodeIndex: false,
      jdiff: false
    })
  }

  setHistory (records: MutationGroup[], previousId: string): void {
    this.resetSquashTimeout()
    this.history.past = []
    this.history.future = []
    let past = true
    records.forEach(mut => {
      if (past) {
        if (mut.id !== previousId) {
          this.history.past.push(mut)
        } else {
          this.history.present = mut
          past = false
        }
      } else {
        this.history.future.push(mut)
      }
    })
  }

  /** Creates an insert mutation. If objectKey is specified, location.index will be ignored
  * (in JsonDiffPatch) and location.parentAttr will be treated as a JSON object with keys (instead of an array)
  * This is used for the props object in ClassDefs for example.
  * */
  createInsertGroup <M extends MutationGroup>(type: M['type'], args: M['args'], location: Agjs2.NodeLocation, newElement: Agjs2.Node, objectKey?: string): M {
    let delta = {}
    if (objectKey != null) {
      delta[objectKey] = [newElement]
    } else {
      if (location.index == null) throw new Error('createInsertGroup(): no objectKey given and location.index is undefined.')
      if (location.index === -1) throw new Error('createInsertGroup(): location.index is -1')
      delta = { _t: 'a' }
      // delta[`${location.index ?? ''}`] = [newElement]
      delta[location.index.toString()] = [newElement]
    }

    // if (this.nodeIndex._dbg) console.log('creating insert: [delta] [objectkey] ', delta, objectKey)

    const mut: InsertMutation = {
      action: 'INSERT',
      base: { nodeId: location.parentId, attrPath: location.parentAttr },
      delta
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const group: M = {
      id: randomId(),
      hid: '',
      type,
      args,
      userId: '',
      createdAtEpoch: 0,
      previousId: this.history.present.id,
      muts: [mut]
    } as M

    group.hid = mutationDigest(group)

    this.applyMutation(group)
    return group
  }

  createChangeGroup <M extends MutationGroup>(type: M['type'], args: M['args'], node: Agjs2.Node, attrPath: string, newVal: unknown): void {
    const oldVal = getAttr(node, attrPath)

    const delta = this.jdiff.diff(oldVal, newVal)

    if (delta == null) {
      console.log('createChangeGroup: nothing to change')
      return
    }

    const mut: ChangeMutation = {
      action: 'CHANGE',
      base: {
        nodeId: node.id,
        attrPath
      },
      delta
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const group: M = {
      id: randomId(),
      hid: '',
      type,
      args,
      userId: '',
      createdAtEpoch: 0,
      previousId: this.history.present.id,
      muts: [mut]
    } as M

    group.hid = mutationDigest(group)

    this.applyMutation(group)
  }

  createRenameGroup (node: Agjs2.NamedNode, newName: string): void {
    const oldName = node.name

    const mut: RenameMutation = {
      action: 'RENAME',
      nodeId: node.id,
      oldName,
      newName
    }

    const group: MGNodeRename = {
      id: randomId(),
      hid: '',
      type: 'node.rename',
      args: { oldName, newName, id: node.id },
      userId: '',
      createdAtEpoch: 0,
      previousId: this.history.present.id,
      muts: [mut]
    }

    group.hid = mutationDigest(group)

    this.applyMutation(group)
  }

  createMoveGroup <M extends MutationGroup>(type: M['type'], customArgs: M['args'] | null, elementNode: Agjs2.Node, target: Agjs2.NodeLocation): void {
    const loc = this.nodeIndex.location(elementNode)
    const parent = this.nodeIndex.lookup(loc.parentId)

    const targetInsideSource = (currentNode: Agjs2.Node): boolean => {
      if (currentNode.id === elementNode.id) return true
      if (this.nodeIndex.location(currentNode).parentId === this.nodeIndex.project.id) return false

      const parentId = this.nodeIndex.location(currentNode).parentId
      return targetInsideSource(this.nodeIndex.lookup(parentId))
    }

    if (targetInsideSource(this.nodeIndex.lookup(target.parentId))) {
      console.log('Cannot move element into a child of itself.')
      return
    }

    const m: MoveMutation = {
      action: 'MOVE',
      source: {
        parentId: loc.parentId,
        parentAttr: loc.parentAttr,
        index: (getAttr(parent, loc.parentAttr) as Agjs2.Node[]).indexOf(elementNode)
      },
      target: {
        parentId: target.parentId,
        parentAttr: target.parentAttr,
        index: target.index
      }
    }

    const oldParent = this.nodeIndex.lookup(m.source.parentId) as Agjs2.NamedNode
    const newParent = this.nodeIndex.lookup(m.target.parentId) as Agjs2.NamedNode

    if (m.source.parentId === m.target.parentId &&
        m.source.parentAttr === m.target.parentAttr &&
        m.source.index === m.target.index) {
      console.log('not moving element.')
    } else {
      const args = (customArgs ?? {
        id: elementNode.id,
        elementName: (elementNode as Agjs2.NamedNode).name ?? '(unnamed)',
        oldParentId: oldParent.id,
        newParentId: newParent.id,
        action: oldParent.id === newParent.id ? `within ${oldParent.name}` : `from ${oldParent.name} to ${newParent.name}`
      }) as MGPageElementMove['args']
      const group: MGPageElementMove = {
        id: randomId(),
        hid: '',
        type: 'page.element.move',
        args,
        userId: '',
        createdAtEpoch: 0,
        previousId: '',
        muts: [m]
      }

      group.hid = mutationDigest(group)

      this.applyMutation(group)
    }
  }

  /** This handles deletion of record types (props, propDefs). So a key is given which is removed from an
   object stored in the parent under attrPath */
  createDeleteRecordGroup <M extends MutationGroup>(type: M['type'], args: M['args'], location: Agjs2.NodeLocation, recordKey: string): void {
    const parent = this.nodeIndex.lookup(location.parentId)

    const delta = {}
    delta[recordKey] = [(toJS(getAttr(parent, location.parentAttr) as Record<string, unknown>)[recordKey]), 0, 0]

    const mut: DeleteMutation = {
      action: 'DELETE',
      base: {
        nodeId: location.parentId,
        attrPath: location.parentAttr
      },
      delta
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const group: M = {
      id: randomId(),
      hid: '',
      type,
      args,
      userId: '',
      createdAtEpoch: 0,
      previousId: this.history.present.id,
      muts: [mut]
    } as M

    group.hid = mutationDigest(group)

    this.applyMutation(group)
  }

  /** for internal use, to automatically handle deletion of an arbitrary node, use op.generic.deleteGenericNode() */
  createDeleteGroup <M extends MutationGroup>(type: M['type'], deletedEl: Agjs2.Node): M {
    const location = this.nodeIndex.location(deletedEl)
    const parent = this.nodeIndex.lookup(location.parentId)
    // const [_, parentPropKey] = location.parentAttr.split('.')

    // type MGDelete = MGPageElementDelete
    let args: M['args']

    switch (type) {
      case 'page.element.delete':
        args = {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          elementName: deletedEl['name'] ?? '(n/a)',
          id: deletedEl.id,
          // eslint-disable-next-line @typescript-eslint/dot-notation
          parentName: parent['name'],
          parentId: parent.id
        } as MGPageElementDelete['args']
        break

      default:
        args = {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          name: deletedEl['name'] ?? '(n/a)',
          id: deletedEl.id
          // eslint-disable-next-line @typescript-eslint/dot-notation
        } as MGDbTableDelete['args']
        break
    }

    // FIXME: this can be refactored so that it doesn't need special care for root elements
    //
    // since index and parentId will be properly provided if NodeIndex is adjusted accordingly
    // if (this.isRootElement(deletedEl)) {
    //   getParent = () => this._full
    //   parentPropKey = 'pages'
    // }

    // problem ist hier, das getParent gesetzt wurde, als das parent noch nicht observable war. daher referenz auf alte kopie (non-observable)
    // const index = (getParent().propValues[parentPropKey] as Agjs2.Node).findIndex(item => item.id === deletedEl.id)
    const index = location.index

    if (typeof index !== 'number') {
      console.log('Location of deletedEL according to nodeIndex:', toJS(location))
      throw new Error(`Could not find to-be-deleted element ${deletedEl.id} in ${location.parentId}'s ${location.parentAttr}`)
    }

    const delta = { _t: 'a' }
    delta[`_${index}`] = [toJS(deletedEl), 0, 0]

    const base: Agjs2.MutationLocation = {
      nodeId: location.parentId,
      attrPath: location.parentAttr
    }

    if (location.index != null) base.index = index

    const mut: DeleteMutation = {
      action: 'DELETE',
      base,
      delta
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const group: M = {
      id: randomId(),
      hid: '',
      type,
      args,
      userId: '',
      createdAtEpoch: 0,
      previousId: this.history.present.id,
      muts: [mut]
    } as M

    group.hid = mutationDigest(group)

    this.applyMutation(group)
    return group
  }

  resetSquashTimeout (): void {
    this.lastAddition = 0
  }

  squashingOk (): boolean {
    const now = new Date().getTime()
    return !this.disableSquashing && (now - this.lastAddition < SQUASH_TIMEOUT * 1000)
  }

  squashMutationGroups<MG extends MutationGroup> (prev: MG, next: MutationGroup): MG | null {
    if (prev.type === next.type &&
        prev.muts.length === next.muts.length &&
        prev.muts.length === 1) {
      const squashedMut = squashedMutation(prev.muts[0], next.muts[0], this)
      if (squashedMut != null) {
        const group: MG = { ...prev, args: squashMGArgs(prev, next), muts: [squashedMut] }
        group.hid = mutationDigest(group)
        return group
      }
    }
    return null
  }

  applyMutation (group: MutationGroup): void {
    if (this.squashingOk()) {
      const squashedGroup = this.squashMutationGroups(this.history.present, group)

      if (squashedGroup != null) {
        // group.previousId = this.history.present.id
        // group.id = randomId()

        // group.createdAtEpoch = Date.now()

        // this.history.past.push(this.history.present)

        this.history.present = squashedGroup
        this.history.future = []

        group.muts.forEach(mut => this.patch(mut))

        // Optionally: comment that line, so if we are already squashing, we are only doing it for 3s max,
        // then a new mut group will be created no matter what.
        this.lastAddition = new Date().getTime()

        this.sendMutation(squashedGroup, 'squash')
        this.afterApplyMutationGroup(group)

        return
      }
    }

    this.lastAddition = new Date().getTime()

    group.previousId = this.history.present.id
    group.id = randomId()

    group.createdAtEpoch = Date.now()

    this.history.past.push(this.history.present)

    this.history.present = group
    this.history.future = []

    group.muts.forEach(mut => this.patch(mut))

    this.sendMutation(group, 'add')
    this.afterApplyMutationGroup(group)
  }

  afterApplyMutationGroup (group: MutationGroup): void {
    if (mutationTypeChangesLibs.includes(group.type)) this.onLibsChanged()
  }

  /* The normal undo() and redo() functions first apply the changes to the local data, then send them off for the
   * backend to also process them there.
   * applyBackendUndo / Redo() works the other way around (if the backend has processed an undo, apply this also to
   * the frontend). So here, the result is not transfered to the backend afterwards (because it is already there).
   * Also- of course it doesn't test if the mutationGroup needs to be handled by the backend (That would create a loop).
   */
  applyBackendUndo (mutationId: string): void {
    const group = this.history.present

    this.resetSquashTimeout()
    const previousMutGroupId = this.history.past.slice(-1)[0].id
    if (previousMutGroupId !== mutationId) {
      throw new Error(`Cannot sync with backend undo: Server's current mut group is ${mutationId} (after undo) while previous group in frontend is ${previousMutGroupId}`)
    } else {
      runInAction(() => {
        group.muts.reverse().forEach(mutNeedsUndo => {
          const mut = this.revertMutation(mutNeedsUndo)
          this.patch(mut)
        })

        this.history.future.unshift(group)
        const lastMut = this.history.past.pop()
        if (lastMut == null) throw new Error('Can\'t undo from here: already at first mutation')
        this.history.present = lastMut
      })

      this.afterApplyMutationGroup(group)
    }
  }

  applyBackendRedo (mutationId: string): void {
    this.resetSquashTimeout()
    const nextMutGroupId = this.history.future[0].id
    if (nextMutGroupId !== mutationId) {
      throw new Error(`Cannot sync with backend undo: Server's current mut group is ${mutationId} (after redo) while next group in frontend is ${nextMutGroupId}`)
    } else {
      runInAction(() => {
        const nextGroup = this.history.future.shift() as MutationGroup

        this.history.past.push(this.history.present)
        this.history.present = nextGroup

        nextGroup.muts.forEach(mut => this.patch(mut))
        this.afterApplyMutationGroup(nextGroup)
      })
    }
  }

  applyBackendMutation (group: MutationGroup): void {
    this.lastAddition = new Date().getTime()

    if (group.previousId !== this.history.present.id) {
      throw new Error(`applyBackendMutation: backend mutation's previous group id is ${group.previousId}, but locally it is ${this.history.present.id}.`)
    }

    runInAction(() => {
      this.history.past.push(this.history.present)
      this.history.present = group
      this.history.future = []
      group.muts.forEach(mut => this.patch(mut))
      this.afterApplyMutationGroup(group)
    })
  }

  undo ({ alreadyDelegated }: { alreadyDelegated?: boolean } = {}): void {
    this.resetSquashTimeout()
    const group = this.history.present
    if (group.type !== 'project.create') {
      if (alreadyDelegated == null) {
        const wasDelegated = this.delegateToBackend(group, 'undo')

        if (wasDelegated) {
          return
        }
      }

      runInAction(() => {
        group.muts.reverse().forEach(mutNeedsUndo => {
          const mut = this.revertMutation(mutNeedsUndo)
          this.patch(mut)
        })

        this.history.future.unshift(group)
        const lastMut = this.history.past.pop()
        if (lastMut == null) throw new Error('Can\'t undo from here: already at first mutation')
        this.history.present = lastMut
      })

      this.sendMutation(group, 'undo')
      this.afterApplyMutationGroup(group)
    }
  }

  redo (): void {
    this.resetSquashTimeout()
    if (this.history.future.length > 0) {
      const nextGroup = this.history.future[0]

      const wasDelegated = this.delegateToBackend(nextGroup, 'redo')

      if (wasDelegated) {
        return
      }

      runInAction(() => {
        this.history.future.shift()
        this.history.past.push(this.history.present)
        this.history.present = nextGroup

        nextGroup.muts.forEach(mut => this.patch(mut))
        this.sendMutation(nextGroup, 'redo')
        this.afterApplyMutationGroup(nextGroup)
      })
    }
  }

  revertMutation (mutation: ElementMutation): ElementMutation {
    if (mutation.action === 'NOOP') return mutation
    const r = (
      mutation: Exclude<ElementMutationWithoutNoop, MoveMutation | RenameMutation>,
      newAction: Exclude<MutationAction, 'NOOP' | 'MOVE' | 'RENAME'> | null = null
    ): ElementMutation => {
      return {
        ...mutation,
        action: newAction ?? mutation.action,
        delta: JDP.reverse(mutation.delta) as JDP.Delta
      }
    }
    switch (mutation.action) {
      case 'INSERT':
        return r(mutation, 'DELETE')
      case 'DELETE':
        return r(mutation, 'INSERT')
      case 'MOVE':
        return ({
          ...mutation,
          action: 'MOVE',
          source: mutation.target,
          target: mutation.source
        })
      case 'RENAME':
        return ({
          ...mutation,
          action: 'RENAME',
          oldName: mutation.newName,
          newName: mutation.oldName
        })
      case 'CHANGE':
        return r(mutation)
    }
  }

  sendMutation (mutation: MutationGroup, direction: 'add' | 'squash' | 'undo' | 'redo'): void {
    this.onMutate(mutation, direction)
  }

  /* Trigger call to backend if necessary (for all changes that cannot be applied locally, like changing the
     database schema) */
  delegateToBackend (mutation: MutationGroup, direction: 'undo' | 'redo'): boolean {
    switch (mutation.type) {
      case 'dbtable.create': {
        // VERY hacky way to isolate the DbTableDef object from delta object. Might break if the underlying structure
        // in project tree is changed (project.db.schema)
        const d = mutation.muts[0]
        if (d.action === 'INSERT') {
          // const keys = Object.keys(d.delta).filter(k => k !== '_t')
          // const change = d.delta[keys[0]][0] as Agjs2.DbTableDef
          this.onDelegateToBackend(mutation.id, direction)
          return true
        }
        break
      }
      case 'dbtable.delete': {
        if (mutation.muts[0].action === 'DELETE') {
          this.onDelegateToBackend(mutation.id, direction)
          return true
        }
        break
      }
      default:
        break
    }

    return false
  }

  patch (mutation: ElementMutation): void {
    if (mutation.action === 'NOOP') {
      throw new Error(`unsupported action ${mutation.action} in applyUntracked.`)
    }
    if (mutation.action === 'MOVE') {
      this.patchMove(mutation)
    } else if (mutation.action === 'RENAME') {
      this.patchRename(mutation)
    } else {
      this.beforePatch(mutation)
      const baseElement = this.lookup(mutation.base.nodeId)
      if (mutation.base.attrPath === '') {
        JDP.patch(baseElement, mutation.delta)
      } else {
        JDP.patch(getAttr(baseElement, mutation.base.attrPath), mutation.delta)
      }
      this.afterPatch(mutation)
    }
  }

  patchRename (mutation: RenameMutation): void {
    const node = this.lookup(mutation.nodeId) as Agjs2.NamedNode
    node.name = mutation.newName
    const sc = this.nodeIndex.sc(node)
    const scopeMember = sc.symbols.get(node.id) ?? sc.allSymbols().find(sm => sm.node.id === node.id)
    // TODO: is this kosher to just rename scope member here? I think so.
    // We need to chekc scopeMember.name, because some sm's have custom names (for example 'thisElement' and so on)
    if (scopeMember != null && scopeMember.name === mutation.oldName) {
      if (this.nodeIndex._dbg) console.log('renaming scope member too. | was: ', scopeMember.name)
      scopeMember.name = mutation.newName
    }
  }

  patchMove (mutation: MoveMutation): void {
    const oldParent = this.lookup(mutation.source.parentId)
    const newParent = this.lookup(mutation.target.parentId)

    // const node = (
    //   oldParent.propValues[mutation.source.parentPropKey] as Agjs.ElementList).splice(mutation.source.index, 1)[0]
    const sourceIndex = mutation.source.index as number
    const node = (getAttr(oldParent, mutation.source.parentAttr) as Agjs2.Node[]).splice(sourceIndex, 1)[0]

    if (node == null) {
      console.log(`no element at ${oldParent.id}.${mutation.source.parentAttr}[${mutation.source.index ?? ''}]`, oldParent)
      console.error('apparently fetched an empty element (which I was trying to move)')
      throw new Error('error moving element')
    }

    // const hydrated = this.onHydrateElement(node, { parentId: newParent.id, parentPropKey: mutation.target.parentPropKey });

    // TODO: is this correct?? build needs to be able to clear out previous stuff?
    // TODO: nodeIndex.mount
    const sc = this.nodeIndex.sc(node)
    if (sc == null) throw new Error(`No scope found for ${JSON.stringify(node)}`)
    this.nodeIndex.mount(node as Agjs2.IndexableNode, { parentId: newParent.id, parentAttr: mutation.target.parentAttr, index: mutation.target.index }, sc)

    // (newParent.propValues[mutation.target.parentPropKey] as Agjs.ElementList).splice(mutation.target.index, 0, node)
    const targetIndex = mutation.target.index as number
    (getAttr(newParent, mutation.target.parentAttr) as Agjs2.Node[]).splice(targetIndex, 0, node)
  }

  /**
   * Unmount to-be-removed element if necessary
   **/
  beforePatch (mutation: JDiffMutation): void {
    switch (mutation.action) {
      case 'DELETE': {
        // const baseElement = this.lookup(mutation.base.nodeId)
        const deletedKeys = Object.keys(mutation.delta)
        deletedKeys.filter(key => key !== '_t').forEach(key => {
          const node = mutation.delta[key][0] as Agjs2.NamedNode
          // We assume NamedNode, but it can also be a simple note like Exp.Literal etc.,
          // so make sure we have something that actually needs unmounting:
          this.nodeIndex.beforeRemoveNode(node)
          if (isMountable(node)) {
            this.nodeIndex.unmount(node)
          }
        })
      }
    }
  }

  /**
   * Make newly added data structures mobx observable,
   * mount newly added element if necessary and even trigger
   * a libReload if applicable.
   **/
  afterPatch (mutation: JDiffMutation): void {
    const baseElement = this.lookup(mutation.base.nodeId)

    // TODO: connection to reload libs in mutationCommands

    switch (mutation.action) {
      case 'INSERT': {
        const attrValue = getAttr(baseElement, mutation.base.attrPath) as Agjs2.Node

        let insertedKey: string | number
        if (mutation.delta._t === 'a') {
          insertedKey = Number.parseInt(Object.keys(mutation.delta)[0])
        } else {
          insertedKey = Object.keys(mutation.delta)[0]
        }

        attrValue[insertedKey] = observable(attrValue[insertedKey])

        const insertedNode = attrValue[insertedKey]

        if (this.nodeIndex._dbg) console.log('afterPatch', toJS(mutation))
        if (isMountable(insertedNode)) {
          const sc = this.nodeIndex.sc(baseElement)
          const loc: Agjs2.NodeLocation = {
            parentId: mutation.base.nodeId,
            parentAttr: mutation.base.attrPath
          }
          if (mutation.base.index != null) { loc.index = mutation.base.index }
          this.nodeIndex.mount(insertedNode as Agjs2.IndexableNode, loc, sc)
        }
        break
      }
      case 'CHANGE': {
        // FIXME: Instead of the generic change event, a specific event for changing props would be nice.
        // There (prop def) it should be possible to define if a remount is necessary or not!
        // Hardcoding for now is probably ok here, since the iterator also has hardcoded behavior in nodeIndex (creating the currentItem variable)
        if (mutation.base.attrPath === 'propValues.dataSource') {
          const n = this.nodeIndex.lookupAllowMissing(mutation.base.nodeId)
          if (n == null) throw new Error('The element referenced by a change mutation doesn\'t seem to exist.')
          if ((n as ST.Exp.Render.PageElement).cid === 'ElementIteratorClass') {
            this.nodeIndex.remount(n as ST.Exp.Render.PageElement, () => '')
          }
        }
        break
      }
    }
  }

  lookup (id: Agjs2.nodeId): Agjs2.Node {
    return this.nodeIndex.lookup(id)
  }

  get previousMutationDescription (): string | null {
    const present = this.history.present
    if (present == null || present.type === 'project.create') return null
    return mutationDescription(present)
  }

  get nextMutationDescription (): string | null {
    if (this.history.future.length === 0) return null
    const future = this.history.future[0]
    return mutationDescription(future)
  }
}
