import { VNode } from 'preact'
import { useContext, useMemo } from 'preact/hooks'
import { observer } from 'mobx-react'

import { AppContext } from '../app_context'
import { F } from '../agjs/factory'
import { Scope } from '../agjs/scope'
import { Agjs2, ST } from '../agjs/types'
import { Panel } from '../pcomponents'
import { PageElementLibrary } from './PageElementLibrary'

import { ClassDefInspector } from '../pcomponents/class_def_inspector'
import { DockManager, SidebarDock, FloatingPanels } from '../pcomponents/dockable_layout'

import { ExpressionEditor } from '../pcomponents/ExpressionEditor'
import { TabManager, TabButton, TabContent } from '../pcomponents/tabs'
import { TreeNode, TreeView } from '../pcomponents/tree_view'

type ElementsWorkbenchTabIds = 'visual' | 'source'

const ElementsEditorTabSelector = observer(({ classDef, manager }: { classDef: Agjs2.ClassDef, manager: TabManager<ElementsWorkbenchTabIds> }) => {
  const list = [
    { id: 'visual', title: 'Layout', help: 'View and edit this element class visually' },
    { id: 'source', title: 'Code', help: 'Edit the source code of this element class' }
  ]

  return (
    <div className='bg-content-bg d-flex flex-column text-guitext'>
      <div className='pb-2 text-xs font-bold block d-flex flex-column flex-fill'>
        {list.map(wb => <TabButton key={wb.id} title={wb.title} help={wb.help} active={manager.currentTab === wb.id} callback={() => { manager.currentTab = (wb.id as ElementsWorkbenchTabIds) }} />)}
        <div className='bg-content-bg p-1 border-ridge border-b border-r flex-fill' />
      </div>
    </div>
  )
})

const LibrariesTree = observer((): VNode<any> => {
  const { store } = useContext(AppContext)
  const runtime = store.project.app.runtime
  const nodes: TreeNode[] = []

  const addLib = (lib: Agjs2.Library, title?: string): void => {
    const children: TreeNode[] = lib.classDefs.map(cd => ({
      leaf: true,
      collapsed: false,
      children: [],
      id: cd.cid,
      title: cd.name
    }))
    nodes.push({
      leaf: false,
      collapsed: false,
      children,
      id: lib.id,
      title: title ?? lib.name
    })
  }

  const pickCid = (_, node: TreeNode): void => {
    console.log('changing cid.')
    store.project.openElementEditor(node.id)
  }

  addLib(store.project.library, 'This Project')
  runtime.libs.list.filter(lib => lib.id !== store.project.library.id).forEach(lib => addLib(lib))

  return <TreeView nodes={nodes} focusedNodeId={store.project.currentCid} onClick={pickCid} />
})

const ElementsWorkbench = observer((): VNode<any> => {
  const { store } = useContext(AppContext)
  const runtime = store.project.app.runtime

  const cid: Agjs2.nodeId = store.project.currentCid

  if (cid === '') {
    const ElementTile = ({ classDef }: { classDef: Agjs2.ClassDef }): VNode<any> => {
      return (
        <div
          class='bg-neutral-400 text-neutral-700 text-sm m-2 p-2 hover:bg-neutral-500 cursor-pointer'
          onClick={() => store.project.openElementEditor(classDef.id)}
        >
          {classDef.name}
        </div>
      )
    }

    const ObjMethodTile = ({ method }: { method: ST.Exp.ObjMethod }): VNode<any> => {
      return (
        <div class='bg-neutral-400 text-neutral-500 text-sm m-2 p-2 border-dashed'>
          {method.name} (Method)
        </div>
      )
    }

    const LibContent = ({ lib, title }: { lib: Agjs2.Library, title?: String }): VNode<any> => (
      <div class='bg-neutral-200 text-neutral-700 text-sm m-2 p-2'>
        {title ?? lib.name}
        <div class='d-flex flex-wrap'>
          {lib.classDefs.map(cd => <ElementTile classDef={cd} key={cd.id} />)}
          {lib.objMethodDefs.map(m => <ObjMethodTile method={m} key={m.id} />)}
        </div>
      </div>
    )

    return (
      <div class='bg-neutral-500 text-center w-full'>
        <div class='bg-neutral-200 text-neutral-700 text-sm m-2 p-2'>
          Select a page element you want to edit or inspect
        </div>
        <LibContent lib={store.project.library} title='This Project' />
        {runtime.libs.list.filter(lib => lib.id !== store.project.library.id).map(lib => <LibContent lib={lib} key={lib.id} />)}
      </div>
    )
  }

  const classDef = runtime.libs.getClassDef(cid)
  const classLib = runtime.libs.getLibraryFromClassDef(cid)

  console.log('element is contained in class:', classLib)

  const isExternal = classLib.id !== store.project.library.id

  if ((classDef as ST.Exp.PageElementClass).render == null) {
    return <div>legacy elements not supported for editing</div>
  }

  const tm = useMemo(() => new TabManager<ElementsWorkbenchTabIds>('source'), [store])
  const dm = useMemo(() => new DockManager(store), [store])

  dm.addPanel('left-container', 'Library Explorer',
    observer(() =>
      <Panel>
        <LibrariesTree />
      </Panel>
    )
  )
  dm.addPanel('left-container', 'Structure', () => <div>(Tree view)</div>)
  dm.addPanel('left-container', 'Library', () => <PageElementLibrary />)
  dm.addPanel('right-container', 'Inspector',
    // 'right-container', (<PageElementInspector element={store.ui.pageEditor.focusedElement} />)
    observer(() => { const x = store.project.currentCid; return (<ClassDefInspector classDef={classDef} readOnly={isExternal} />) })
  )

  const outerMouseMove = (ev): void => {
    // TODO: optimize
    const movingDockable = store.ui.getMovingDockable()
    if (movingDockable != null) {
      dm.move(ev, movingDockable)
    }
  }

  const iframeMouseMove = (ev): void => {
    const movingDockable = store.ui.getMovingDockable()
    if (movingDockable != null) {
      dm.move(ev, movingDockable)
    }
  }

  const updateRenderExp = (exp: ST.Exp.Expression): void => {
    // TODO: check if classdef is read only or part of the project.
    store.project.op.classDef.setRenderContext(cid, exp as ST.Exp.RenderContext)
  }

  // Generate a dedicated scope for the class with content from a hypthetical instance of such class.
  // should be a new scope where "thisElement" is added + its props.

  const globalScope = new Scope(null, '$global', runtime)
  // const elementScope = globalScope.sub(`$element`)

  // TODO: default prop values are missing
  // AND I guess if we add this to nodeIndex (see below) we also need to remove it at some point
  const defaultPropValues = {}
  const dummyInstance: ST.Exp.Render.PageElement = F.makeRenderPageElement(
    classDef.id,
    `DummyInstance${classDef.id}`, defaultPropValues, []
  )

  runtime.nodeIndex.mount(dummyInstance, { parentId: '~', parentAttr: 'interal-class-defs' }, globalScope)
  const elementScope = runtime.nodeIndex.sc(dummyInstance)
  // elementScope.add(dummyInstance, 'thisElement', '$thisElement')

  console.log('this code should only run once.')

  // wenn man das scope so added, dann error TextElementClass not found.
  // nehme an TextElementClass ist die id.. und das bisherige type check system versucht
  // dann unter der (class) id eine instanz in NodeIndex zu finden. gibt es dort natürlich nicht.
  // also doch eigenen nodeIndex erzeugen für das? also eigene runtime

  return (
    <div class='d-flex flex-fill' onMouseMove={outerMouseMove}>
      <SidebarDock name='left-container' manager={dm} />
      <ElementsEditorTabSelector manager={tm} classDef={classDef} />
      <TabContent id='visual' manager={tm}>
        <div className='h-0 min-h-full overflow-auto flex-fill'>
          (iframe with rendered page element)
        </div>
      </TabContent>
      <TabContent id='source' manager={tm}>
        {classDef.t === 'PageElementClass' ? (
        <ExpressionEditor
          exp={classDef.render}
          onSubmit={updateRenderExp}
          requiredType={F.makeRenderContextType()}
          scope={elementScope}
        />) : <div>editor not available</div>}
      </TabContent>
      <SidebarDock name='right-container' manager={dm} />
      <FloatingPanels manager={dm} />
    </div>
  )
})

export { ElementsWorkbench }
