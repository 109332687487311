import { Agjs2, ST } from './agjs/types'
import { header, subMenu, command, divider, MenuGenerator, MenuItem } from './pcomponents/menu'
import { Ide } from './types'

export function generatePageElementContextMenuFunc (elementNode: ST.Exp.Render.PageElement, store: Ide.Store): MenuGenerator {
  return (): MenuItem[] => {
    const cl = store.project.app.runtime.getElementClassDef(elementNode)

    const items: MenuItem[] = [
      header(elementNode.name),
      // menu['Show component source'] = [() => store.project.openElementEditor(elementNode.cid), null]
      command(`Create custom element from ${elementNode.name}`, () => store.project.op.classDef.createFromElement(elementNode)),
      command(`Clone ${cl.name} element definition`, () => store.project.op.classDef.cloneClassDef(elementNode.cid)),

      // Unrealistially nested menu to test automatic menu placement.
      // divider(),
      // subMenu('More...',
      //   [header('this is the submenu'), command('Click me to log', () => console.log('submenu item was clicked')),
      //     subMenu('More...',
      //       [header('this is the submenu'), command('Click me to log', () => console.log('submenu item was clicked')),
      //         subMenu('More...',
      //           [header('this is the submenu'), command('Click me to log', () => console.log('submenu item was clicked')),
      //             subMenu('More...',
      //               [header('this is the submenu'), command('Click me to log', () => console.log('submenu item was clicked'))
      //               ])
      //           ])
      //       ])
      //   ]),

      command('Delete', () => store.project.op.page.deleteElement(elementNode), 'Del'),
      command('Paste', () => store.ui.pasteInto(elementNode), 'Ctrl+V'),
      command('Paste as Text', () => store.ui.pasteInto(elementNode, 'text'))
    ]

    return items
  }
}

export function generatePageElementClassContextMenuFunc (classDef: ST.Exp.PageElementClass, store: Ide.Store): MenuGenerator {
  return (): MenuItem[] => [
    header(classDef.name),
    divider(),
    command('Edit', () => store.project.openElementEditor(classDef.id))
  ]
}

export function generateStaticDataElementContextMenuFunc (item: ST.Exp.Variable, store: Ide.Store): MenuGenerator {
  return () => [
    header(item.name),
    divider(),
    // 'Remove data': [() => store.ui.truncateData(item), null],
    command('Edit', () => store.ui.openConstEditor(item)),
    command('Delete', () => store.project.op.generic.deleteGenericNode(item))
    // 'Clone': [() => store.ui.pasteInto(item), null],
  ]
}

export function generateDbTableContextMenuFunc (item: Agjs2.DbTableDef, store: Ide.Store): MenuGenerator {
  return () => [
    header(item.name),
    divider(),
    // 'Remove data': [() => store.ui.truncateData(item), null],
    command('Edit', () => store.project.openDbTable(item.id)),
    command('Delete', async () => await store.project.remoteDropTable(item))
    // 'Clone': [() => store.ui.pasteInto(item), null],
  ]
}
