import { Agjs2, ST } from './../types'
import * as Factory from './../factory'

import { classFromSource } from './../utils'

import { DbClass, objMethods as dbTableObjMethods } from './db_class'
import { TimeClass } from './time_class'
import { LoggerClass } from './logger_class'
import { CookieClass } from './cookie_class'

const { F, randomId } = Factory

const TextElementClass: ST.Exp.PageElementClass = {
  t: 'PageElementClass',
  id: 'TextElementClass',
  cid: 'TextElementClass',
  name: 'Text',
  meta: {
    description: 'A text element',
    category: 'Typography',
    order: 0
  },
  props: {
    styling: F.makeStylingProp(),
    // eslint-disable-next-line no-template-curly-in-string
    content: F.makeParam('content', F.makePrimitiveDT('Text'), F.makeString('${name}'))
  },
  render: F.makeRenderContext([
    F.makeRenderHTMLTag('div', {
      elementStyling: F.makeDotOp(F.makeGetNode('$thisElement'), F.makeGetAttr('styling'))
    }, [
      F.makeRenderEval(
        F.makeDotOp(
          F.makeGetNode('$thisElement'),
          F.makeGetAttr('content')
        ))]
    )])
}

const H1: ST.Exp.PageElementClass = {
  t: 'PageElementClass',
  id: 'H1',
  cid: 'H1',
  name: 'Heading 1',
  meta: {
    description: 'A text element',
    category: 'Typography',
    order: 0
  },
  props: {
    styling: F.makeStylingProp(),
    // eslint-disable-next-line no-template-curly-in-string
    content: F.makeParam('content', F.makePrimitiveDT('Text'), F.makeString('${name}'))
  },
  render: F.makeRenderContext([
    F.makeRenderHTMLTag('h1', {
      elementStyling: F.makeDotOp(F.makeGetNode('$thisElement'), F.makeGetAttr('styling'))
    }, [
      F.makeRenderEval(
        F.makeDotOp(
          F.makeGetNode('$thisElement'),
          F.makeGetAttr('content')
        ))]
    )]
  )
}

const LinkElementClass: ST.Exp.PageElementClass = {
  t: 'PageElementClass',
  id: 'LinkElementClass',
  cid: 'LinkElementClass',
  name: 'Link',
  meta: {
    description: 'A link (anchor) element',
    category: 'Basic',
    order: 0
  },
  props: {
    // eslint-disable-next-line no-template-curly-in-string
    text: F.makeParam('text', F.makePrimitiveDT('Text'), F.makeString('${name}')),
    url: F.makeParam('url', F.makePrimitiveDT('String'), F.makeString('/putsomethingnice'))
  },
  render: F.makeRenderContext([
    F.makeRenderHTMLTag('a', {
      href: F.makeDotOp(F.makeGetNode('$thisElement'), F.makeGetAttr('url'))
    },
    [
      F.makeRenderEval(
        F.makeDotOp(
          F.makeGetNode('$thisElement'),
          F.makeGetAttr('text')
        )
      )
    ]
    )])
  // fc: ({ text, url, elementNode, customAttrs, _ide }) => {
  //   if (_ide) {
  //     const ideClick = ev => { ev.preventDefault(); console.log('IDE handler for clicking page links') }
  //     return outerTag('a', {'href': url.value(), 'onClick': ideClick}, customAttrs, html`${text.displayText()}`)
  //   } else {
  //     return outerTag('a', {'href': url.value()}, customAttrs, html`${text.displayText()}`)
  //   }
  // }
}

const GroupElementClass: ST.Exp.PageElementClass = {
  t: 'PageElementClass',
  id: 'GroupElementClass',
  cid: 'GroupElementClass',
  name: 'Group',
  meta: {
    description: 'A container with vertical or horizontal alignment of its children',
    category: 'Basic',
    order: 0
  },
  props: {
    children: F.makeChildrenProp(),
    // TODO: additional config for styling, to have a special group styling (enable flex layout options)
    styling: F.makeStylingProp(),
    isRow: F.makeParam('isRow', F.makePrimitiveDT('Boolean'), F.makeBoolean(false))
    /*
    flexDir: { id: 'flexDir', dataType: 'Enum', dynamic: false, name: 'Direction',
                            defaultValue: {_exp: 'EnumValue', value: 'column'},
                            allValues: [{name: 'Column (Vertical)', value: 'column'}, {name: 'Row (Horizontal)', value: 'row'}] },
                           */
  },
  /*
  fc: ({ children, isRow, flexDir, customAttrs }) => {
    // const dir = flexDir.value() === 'row' ? 'flex-direction: row;' : 'flex-direction: column'
    const dir = isRow.value() === true ? 'flex-direction: row;' : 'flex-direction: column'
    return outerTag('div', {'style': `display: flex;${dir}`}, customAttrs, html`${children}`)
  }, */

  /*
  render: F.makeRenderContext([{
    t: 'RenderHTMLTag',
    id: 'xxxx4324324324',
    tag: 'div',
    attributes: {
      // TODO: expressions, die zur auswertung nur auf statische props zugreifen, sind eigentlich ok, oder wie soll
      // das im editor gehandhabt werden? ausdrücke kann ja nicht ausgewertet werden, da zu gefährlich??
      style: F.makeString('style: display: flex; flex-direction: row;')
    },
    children: [{
      t: 'RenderEval',
      id: '856fa2fc43',
      // TODO: instead of $thisElement it should be $thisPageElementInstance or so (tentative) meaning the first instance that is
      // created by the project (and not an instance created INSIDE an instance as part of RenderPageElement).
      value: F.makeDotOp(F.makeGetNode('$thisElement'), F.makeGetAttr('propValues.children', undefined, 'children'))
    }]
  }])
 */

  render: F.makeRenderContext([
    F.makeRenderHTMLTag('div', {
      elementStyling: F.makeDotOp(F.makeGetNode('$thisElement'), F.makeGetAttr('styling'))
    }, [
      F.makeRenderEval(
        F.makeDotOp(F.makeGetNode('$thisElement'), F.makeGetAttr('propValues.children', undefined, 'children'))
      )
    ]
    )]
  )
}

const HtmlTagElementClass: ST.Exp.PageElementClass = {
  t: 'PageElementClass',
  id: 'HtmlTagElementClass',
  cid: 'HtmlTagElementClass',
  name: 'Html Tag',
  meta: {
    description: 'An arbitrary HTML element',
    category: 'Basic',
    order: 0
  },
  props: {
    children: F.makeChildrenProp(),
    // attributes: F.makeParam('attributes', F.makeT('DataType', { type: 'KVStrings' }), { t: 'KVStrings', id: randomId(), value: {} }),
    tag: F.makeParam('tag', F.makePrimitiveDT('String'), F.makeString('div'))
  },
  // fc: ({ children, attributes, tag, customAttrs }) => {
  //  const attrs = attributes.value()
  //  //const attrs = Object.keys(kv).map(key => `${key}="${kv[key].value}"`).join(' ')
  //  if(tag.length == 0) return null;
  //  return outerTag(tag.value(), attrs, customAttrs, html`${children}`)
  // }
  render: F.makeRenderContext([
    F.makeRenderHTMLTag(
      'div', {},
      [F.makeRenderEval(
        F.makeString('not converted to new render context yet.')
      )]
    )
  ])
}

const BigTestElementClass: ST.Exp.PageElementClass = {
  t: 'PageElementClass',
  id: 'BigTestElementClass',
  cid: 'BigTestElementClass',
  name: 'Big Box',
  meta: {
    description: 'An quite big box for testing',
    category: 'Basic',
    order: 0
  },
  props: {},
  render: F.makeRenderContext([
    F.makeRenderHTMLTag(
      'div',
      {
        // TODO: how to convert this to a elementStyling prop?
        // style: F.makeString("display: 'inline-block;', width: '100px', height: '100px', background: 'pink'")
      },
      [F.makeRenderEval(
        F.makeDotOp(
          F.makeGetNode('$thisElement'), F.makeGetAttr('name')
        )
      )]
    )
  ])
}

const ButtonElementClass: ST.Exp.PageElementClass = {
  t: 'PageElementClass',
  id: 'ButtonElementClass',
  cid: 'ButtonElementClass',
  name: 'Button',
  meta: {
    description: 'A Button',
    category: 'Forms',
    order: 0
  },
  props: {
    // eslint-disable-next-line no-template-curly-in-string
    label: F.makeParam('label', F.makePrimitiveDT('String'), F.makeString('${name}')),
    onClick: F.makeParam('onClick', F.makePrimitiveDT('EventCallback'), F.makeNull()),
    styling: F.makeStylingProp()
  },
  render: F.makeRenderContext([
    F.makeRenderHTMLTag(
      'button',
      {
        onClick: F.makeDotOp(
          F.makeGetNode('$thisElement'),
          F.makeGetAttr('onClick')
        ),
        elementStyling: F.makeDotOp(F.makeGetNode('$thisElement'), F.makeGetAttr('styling')),
        type: F.makeString('button')
        /* style: F.makeString("display: 'inline-block;', width: '100px', height: '100px', background: 'pink'") */
      },
      [F.makeRenderEval(
        F.makeDotOp(
          F.makeGetNode('$thisElement'), F.makeGetAttr('label')
        )
      )]
    )
  ])
  // fc: ({ elementNode, children, attributes, onClick, label, customAttrs }) => {
  //   return outerTag('button', {'onClick': () => onClick.trigger(), 'type': 'button'}, customAttrs, html`${label.displayText()}`)
  // }
}

const InputElementClass: ST.Exp.PageElementClass = {
  t: 'PageElementClass',
  id: 'InputElementClass',
  cid: 'InputElementClass',
  name: 'Input Field',
  meta: {
    description: 'An input field for text',
    category: 'Forms',
    order: 1
  },
  props: {
    value: F.makeParam('value', F.makePrimitiveDT('String'), F.makeString('')),
    placeholder: F.makeParam('placeholder', F.makePrimitiveDT('String'), F.makeString('')),
    onClick: F.makeParam('onClick', F.makePrimitiveDT('EventCallback'), F.makeNull())
  },
  render: F.makeRenderContext([
    F.makeRenderHTMLTag(
      'input',
      {
        type: F.makeString('text'),
        onClick: F.makeDotOp(
          F.makeGetNode('$thisElement'),
          F.makeGetAttr('onClick')
        ),
        onInput: F.makeString('__agl:setValueAttr'),
        placeholder: F.makeDotOp(
          F.makeGetNode('$thisElement'),
          F.makeGetAttr('placeholder')
        ),
        value: F.makeDotOp(
          F.makeGetNode('$thisElement'),
          F.makeGetAttr('value')
        )
      },
      []
    )
  ])
}

const cdList: ST.Exp.PageElementClass[] = []

const c = (source: string): void => { cdList.push(classFromSource(source)) }

// Visual Elements

/*
c(`
  $pageElement(
    name: "Text",
    cid: "TextElementClass",
    props: $[
      $param(content, Text, "\${name}"),
      $param(styling, ElementStyling, EMPTY)
    ],
    render: $render(
      <span elementStyling={thisElement.styling}>{thisElement.content}</span>
    ),
    metaCategory: "Typography", metaDescription: "A text element",
    metaOrder: 1)
`)
*/

c(`
  $pageElement(name: "Link", cid: "Link", render:
    $render(
      <br />
    ), metaCategory: "Visual Elements", metaDescription: "A text",
    metaOrder: 1)
`)

// Forms

c(`
  $pageElement(name: "Dropdown", cid: "Dropdown", render:
    $render(
      <br />
    ), metaCategory: "Forms", metaDescription: "Select items from a list of drop downs",
    metaOrder: 1)
`)

const StdLib: Agjs2.Library = {
  t: 'Library',
  id: 'stdlib',
  deletable: false,
  name: 'StdLib',
  classDefs: [H1, TextElementClass, LinkElementClass, GroupElementClass, HtmlTagElementClass, BigTestElementClass,
    ButtonElementClass, InputElementClass, DbClass, TimeClass, LoggerClass, CookieClass].concat(cdList),
  objMethodDefs: dbTableObjMethods,
  globalMethodDefs: []
}

export default StdLib
