import { useState, useContext } from 'preact/hooks'
import { ComponentChildren, VNode } from 'preact'

import { toJS } from 'mobx'
import { UITextInput } from './../index'
import { DataTypeEditor } from './../data_type_editor'
import { CustomStateEditor } from './../custom_state_editor'
import { DevConsole } from './../DevConsole'
import { Agjs2, ST } from './../../agjs/types'
import { F } from './../../agjs/factory'

import { AppContext } from '../../app_context'
import { DataBrowser } from './../data_browser'
import { toSnakeCase, formatAsElementName } from '../../agjs/string_utils'
import { CloseFn, OverlayContentFn } from '../../overlay_manager'
import { EditDbTableSchemaWithCallbacks } from './db_table_dialogs'

type DialogSize = 'sm' | 'lg' | 'xl'

const Wrapper = ({ children, size }: { children: VNode | VNode[], size?: DialogSize }): VNode => {
  return (
    <div className={`modal-dialog ${size != null ? 'modal-' + size : ''} modal-dialog-centered modal-dialog-scrollable ag-zoomin`}>
      <div className='modal-content'>
        {children}
      </div>
    </div>
  )
}

const DialogButton = ({ label, onClick, primary, disabled }: { label: string, onClick: () => void, primary?: boolean, disabled?: boolean }): VNode => {
  return (
    <button onClick={onClick} disabled={disabled} className={`btn ${primary != null ? 'btn-primary' : 'btn-secondary'}`}>{label}</button>
  )
}

export const RowWithLabel = ({ label, children }): VNode => (
  <div className='my-2'>
    <label className='text-xs'>{label}</label>
    <div>
      {children}
    </div>
  </div>
)

interface StandardDialogParams {
  title: string
  children?: ComponentChildren
  onCancel: () => void
  cancelLabel?: string
  onSubmit?: () => void
  submitLabel?: string
  submitDisabled?: boolean
  size?: DialogSize
}

export const StandardDialog = ({ title, children, onCancel, cancelLabel, onSubmit, submitLabel, size, submitDisabled }: StandardDialogParams): VNode => {
  const footer = (
    <>
      <DialogButton onClick={onCancel} label={cancelLabel ?? 'Cancel'} />
      {(onSubmit != null) && <DialogButton onClick={onSubmit} label={submitLabel ?? 'Save changes'} primary disabled={submitDisabled === true} />}
    </>
  )
  return (
    <Wrapper size={size}>
      <div className='modal-header'>
        <h5 className='modal-title'>{title}</h5>{(onCancel != null) && <button className='btn-close' onClick={onCancel} />}
      </div>
      <div className='modal-body'>
        {children}
      </div>
      <div className='modal-footer'>
        {footer}
      </div>
    </Wrapper>
  )
}

const NewPage = ({ closeFn }: { closeFn: () => void }): VNode => {
  const { store } = useContext(AppContext)
  const [name, setName] = useState(store.project.op.page.newPageName())

  const close = (): void => {
    closeFn()
  }

  const create = (): void => {
    store.project.op.page.create(name, { success: newPage => store.project.openPage(newPage.id) })
    closeFn()
  }

  return (
    <StandardDialog
      title='Create new page'
      onCancel={close}
      onSubmit={create}
      submitLabel='Create new page'
    >
      <RowWithLabel label='Name'>
        <UITextInput value={name} onInput={ev => setName((ev.target as HTMLInputElement).value)} />
      </RowWithLabel>
    </StandardDialog>
  )
}

interface EditDataTypeWithCallbacksParams {
  name: string
  title: string
  submitLabel: string
  dataType: ST.DT.TypeDef
  closeFn: CloseFn
  onChangeName: Agjs2.SimpleCallback<string>
  onChangeDataType: Agjs2.SimpleCallback<ST.DT.TypeDef>
  onSubmit: Agjs2.SimpleCallback<ST.DT.TypeDef>
}

const EditDataTypeWithCallbacks = ({ name, title, dataType, closeFn, onChangeName, onChangeDataType, onSubmit, submitLabel }: EditDataTypeWithCallbacksParams): VNode => {
  const hasInvalidType = (dt: ST.DT.TypeDef): boolean => {
    if (dt == null) return false
    if (dt.type === 'none') return true
    if (dt.type === 'List') return hasInvalidType(dt.itemType)
    if (dt.type === 'Record') return (dt.fields.length === 0 || dt.fields.find(field => hasInvalidType(field.dataType)) != null)
    return false
  }

  const invalid = hasInvalidType(dataType)

  return (
    <StandardDialog
      title={title}
      onCancel={closeFn}
      onSubmit={() => onSubmit(dataType)}
      submitDisabled={invalid}
      submitLabel={submitLabel}
    >
      <RowWithLabel label='Name'>
        <UITextInput value={name} onInput={ev => onChangeName((ev.target as HTMLInputElement).value)} />
      </RowWithLabel>
      <RowWithLabel label='Data type'>
        <DataTypeEditor dt={dataType} onPick={value => onChangeDataType(value)} />
      </RowWithLabel>
      {/* <div>
          <DataTypePreview dt={dt} />
          </div> */}
    </StandardDialog>
  )
}

export interface NamedDataTypeStruct {
  dataType: ST.DT.TypeDef
  name: string
}

interface NamedDataTypeParams<T extends NamedDataTypeStruct> {
  node: T
  title: string
  submitLabel: string
  closeFn: CloseFn
  onSubmit: Agjs2.SimpleCallback<T>
}

const NamedDataType = <T extends NamedDataTypeStruct,>({ node, closeFn, title, submitLabel, onSubmit }: NamedDataTypeParams<T>): VNode => {
  const [name, setName] = useState(node.name)
  const [dt, setDt] = useState(node.dataType)

  const submit = (): void => {
    onSubmit({ ...node, dataType: dt, name })
    closeFn()
  }

  return (
    <EditDataTypeWithCallbacks
      name={name}
      closeFn={closeFn}
      dataType={dt}
      onChangeName={setName}
      onChangeDataType={setDt}
      onSubmit={submit}
      title={title}
      submitLabel={submitLabel}
    />
  )
}

const NewConst = ({ closeFn }: { closeFn: CloseFn }): VNode => {
  const { store } = useContext(AppContext)

  const newConst = F.makeVariable(
    store.project.nodeIndex.newName('StaticData'),
    F.makeNoneType(),
    F.makeNull()
  )

  const create = (newConst: ST.Exp.Variable): void => {
    store.project.op.generic.createConst(newConst.name, newConst.dataType, { success: (item) => store.project.openDataItem(item.id) })
    closeFn()
  }

  return (
    <NamedDataType
      closeFn={closeFn}
      node={newConst}
      onSubmit={create}
      title='New static dataset'
      submitLabel='Create new static dataset'
    />
  )
}

const EditConst = ({ item, closeFn }: { item: ST.Exp.Variable, closeFn: CloseFn }): VNode | null => {
  const { store } = useContext(AppContext)
  if (item == null) return null

  const [name, setName] = useState(item.name)
  const [dt, setDt] = useState(item.dataType)

  const update = (): void => {
    if (name !== item.name) store.project.op.generic.renameElement(item, name)
    store.project.op.generic.setAttr(item, 'dataType', dt)
    // store.project.createConst(name, dt)
    closeFn()
  }

  return <EditDataTypeWithCallbacks title='Edit dataset' closeFn={closeFn} name={name} dataType={dt} onChangeName={setName} onChangeDataType={setDt} onSubmit={update} submitLabel='Save changes' />
}

const NewPropDef = ({ classDef, closeFn }: { classDef: Agjs2.ClassDef, closeFn: CloseFn }): VNode<any> => {
  const { store, runtime } = useContext(AppContext)

  const propNames = Object.keys(classDef.props)

  const newConst = F.makeVariable(
    runtime.nodeIndex.newName('Prop', { minNumber: 0, namesList: propNames }),
    F.makeNoneType(),
    F.makeNull()
  )

  const create = (newConst: ST.Exp.Variable): void => {
    const newPropDef = F.makeParam(
      newConst.name,
      newConst.dataType,
      F.makeNull()
    )
    store.project.op.classDef.createPropDef(classDef, newPropDef)
    closeFn()
  }

  return (
    <NamedDataType
      node={newConst}
      closeFn={closeFn}
      onSubmit={create}
      title='New property'
      submitLabel='Add property'
    />
  )
}

const NewElementState = ({ owner, closeFn }: { owner: ST.Exp.Render.PageElement, closeFn: CloseFn }): VNode => {
  const { store } = useContext(AppContext)

  const [name, setName] = useState(
    store.project.nodeIndex.newName(
      'CustomState', {
        scope: store.project.nodeIndex.sc(owner).findChildScope('$states$') ?? store.project.nodeIndex.sc(owner)
      }
    )
  )

  const [dt, setDt] = useState<ST.DT.TypeDef>(F.makeNoneType())

  const create = (): void => {
    store.project.op.page.createCustomState(name, dt, owner)
    closeFn()
  }

  return (
    <EditDataTypeWithCallbacks
      title='New custom state'
      name={name}
      dataType={dt}
      closeFn={closeFn}
      onChangeName={setName}
      onChangeDataType={setDt}
      onSubmit={create}
      submitLabel='Create new custom state'
    />
  )
}

const EditState = ({ state, closeFn }: { state: ST.Exp.Variable, closeFn: CloseFn }): VNode => {
  const { store } = useContext(AppContext)

  const [newExp, setNewExp] = useState(toJS(state.value))

  const update = (updatedExp: ST.Exp.LiteralExpression): void => setNewExp(updatedExp)

  const saveChanges = (): void => {
    store.project.op.generic.setAttr(state, 'value', newExp)
    closeFn()
  }

  return (
    <StandardDialog
      title={`Edit defaults for ${state.name}`}
      onCancel={closeFn}
      onSubmit={saveChanges}
    >
      <RowWithLabel label='Value'>
        <CustomStateEditor extExp={newExp} state={state} onSubmit={update} />
      </RowWithLabel>
    </StandardDialog>
  )
}

// TODO move this to db_table_browser, or at least 90% of it (just keep wrapper with dialog markup)
const EditTableRow = ({ title, rowRecord, tableDef, onSubmit, closeFn }: { title: string, closeFn: CloseFn, tableDef: Agjs2.DbTableDef, rowRecord: ST.Exp.Lit.RecordOfLit, onSubmit: (updatedRowRecord: ST.Exp.Lit.RecordOfLit) => void }): VNode => {
  const [newExp, setNewExp] = useState(toJS(rowRecord))

  const update = (updatedExp: ST.Exp.Lit.RecordOfLit): void => setNewExp(updatedExp)

  const saveChanges = (): void => {
    onSubmit(newExp)
    // store.project.op.generic.setAttr(state, 'value', newExp)
    closeFn()
  }

  const columnsAsRecordFields: ST.DT.RecordFieldDef[] = tableDef.columns.map(col => F.makeRecordField(col.name, F.makePrimitiveDT('String')))

  const dt = F.makeRecordDefType(columnsAsRecordFields)

  return (
    <StandardDialog
      title={title}
      onCancel={closeFn}
      onSubmit={saveChanges}
    >
      <RowWithLabel label='Value'>
        <DataBrowser key='new-table-row' name='My Record' dataType={dt} exp={rowRecord} onChange={update} />
      </RowWithLabel>
    </StandardDialog>
  )
}

const NewDbTable = ({ closeFn }: { closeFn: CloseFn }): VNode => {
  const { store } = useContext(AppContext)

  const nodeIndex = store.project.nodeIndex
  const newTableName = nodeIndex.newName(formatAsElementName('Table'), { scope: nodeIndex.globalScope })

  const newTable: Agjs2.DbTableDef = F.makeT('DbTableDef', {
    name: newTableName,
    pgName: toSnakeCase(newTableName),
    columns: [
      F.makeDbColDef('Id', F.makePrimitiveDT('String')),
      F.makeDbColDef('CreatedAt', F.makePrimitiveDT('Timestamp'), true),
      F.makeDbColDef('UpdatedAt', F.makePrimitiveDT('Timestamp'), true)
    ]
  })

  const create = async (tableDef: Agjs2.DbTableDef): Promise<void> => {
    await store.project.remoteSchemaChange(tableDef)
    // store.project.op.generic.createTable(tableDef.name, tableDef, { success: (item) => { console.log('store.project.openDbTable() not implemented?'); /*store.project.openDataItem(item.id)*/ } })
    closeFn()
  }

  const triggerCreate = (tableDef: Agjs2.DbTableDef): void => {
    void create(tableDef)
  }

  return (
    <EditDbTableSchemaWithCallbacks
      table={newTable}
      closeFn={closeFn}
      onChangeName={() => {}}
      onSubmit={triggerCreate}
      title='Create new table'
      submitLabel='Create'
    />
  )
}

const genDevConsole = (): OverlayContentFn => {
  return (closeFn) => {
    return (
      <StandardDialog
        title='Developer console'
        onCancel={() => closeFn()}
        cancelLabel='Close'
        size='xl'
      >
        <DevConsole />
      </StandardDialog>
    )
  }
}

const dialogs = {
  genDevConsole,
  genNewPage: (): OverlayContentFn => (closeFn: () => void): VNode | null => <NewPage closeFn={closeFn} />,
  genNewElementState: (owner: ST.Exp.Render.PageElement): OverlayContentFn => closeFn => <NewElementState owner={owner} closeFn={closeFn} />,
  genEditConst: (item: ST.Exp.Variable): OverlayContentFn => closeFn => <EditConst closeFn={closeFn} item={item} />,
  genNewConst: (): OverlayContentFn => closeFn => <NewConst closeFn={closeFn} />,
  genEditState: (state: ST.Exp.Variable): OverlayContentFn => closeFn => <EditState closeFn={closeFn} state={state} />,
  genNewPropDef: (classDef: Agjs2.ClassDef): OverlayContentFn => closeFn => <NewPropDef closeFn={closeFn} classDef={classDef} />,
  genInfo: (message: string): OverlayContentFn => (closeFn: CloseFn) => (
    <StandardDialog
      title='Info'
      onCancel={closeFn}
      cancelLabel='OK'
    >
      <span>{message}</span>
    </StandardDialog>
  ),
  genSpinner: (message: string | VNode): OverlayContentFn => () => (
    <Wrapper size='sm'>
      <div className='modal-body'>
        <div className='text-center'>
          <div className='spinner-border mb-2' />
        </div>
        <div className='text-center'>{message}</div>
      </div>
    </Wrapper>
  )
}

const Dialog = { NewElementState, NewDbTable, EditTableRow, NamedDataType }

export { Dialog, dialogs }
