import { Runtime } from './runtime'
import { Agjs2, ST } from './types'
import { returnStringLiteralValue, ensureStringLiteralValue, ensureLiteral } from './utils'
import { parser } from './lr_parser'
import { SyntaxNode, Tree } from '@lezer/common'
import { F, randomId } from './factory'
import { Scope } from './scope'

type ParserMetaInfo = Agjs2.ParserMetaInfo

/**
 * Keyvalue (Keyword) syntax can either contain an expression or statements as values,
 * depending on context (for $pageElement it conaints statements).
 */
type KwValueType = ST.Exp.Expression | ST.Lang
type ParsedKwArgs<T extends KwValueType> = Record<string, T>

interface KwNameType {
  name: string
  dt: ST.DT.TypeDef
}

const KW_ANY: KwNameType = {
  name: '*',
  dt: F.makeAnyDT()
}

const KW_UI_META_INFO_FIELDS: KwNameType[] = [
  { name: 'metaCategory', dt: F.makePrimitiveDT('String') },
  { name: 'metaDescription', dt: F.makePrimitiveDT('String') },
  { name: 'metaOrder', dt: F.makePrimitiveDT('Number') }
]

interface KwArgsAndErrors<T extends KwValueType> {
  kwArgs: ParsedKwArgs<T>
  errors: string[]
}

interface KwArgConstraints {
  required?: KwNameType[]
  optional?: KwNameType[]
}

type VarOrPropExpGenerator = (name: string, dataType: ST.DT.TypeDef, initValue: ST.Exp.Expression) => ST.Lang

export class Parser {
  runtime: Runtime
  source: string | null
  tree: Tree

  constructor (runtime: Runtime) {
    this.runtime = runtime
  }

  parse (source: string): Tree {
    this.source = source
    this.tree = parser.parse(source)
    return this.tree
  }

  createUIMetaInfo<T extends KwValueType> (kwArgs: ParsedKwArgs<T>): Agjs2.UIMetaInfo {
    const metaInfo: Agjs2.UIMetaInfo = {
      category: 'none'
    }
    ensureStringLiteralValue(kwArgs.metaDescription, value => { metaInfo.description = value })
    ensureStringLiteralValue(kwArgs.metaCategory, value => { metaInfo.category = value })

    ensureLiteral<ST.Exp.Lit.Number>(
      kwArgs.metaOrder, 'Number', lit => { metaInfo.order = lit.value }
    )

    return metaInfo
  }

  extractNameAndChildren (node: SyntaxNode, source: string): { name: string, children: SyntaxNode[], text: string } {
    const cursor = node.cursor()

    const [s, e] = [cursor.from, cursor.to]
    const children: SyntaxNode[] = []
    const name = '' + cursor.type.name
    if (cursor.firstChild()) {
      children.push(cursor.node)
      while (cursor.nextSibling()) {
        children.push(cursor.node)
      }
    }

    const text = source.slice(s, e)
    return {
      name,
      children,
      text
    }
  }

  /**
   * Treats a list of SyntaxNodes as a keyword list. (the grammar has two different kind of keyword lists, with 'expr' and with 'statement')
   * The optional constraints parameter can be used to specify required and optional keywords.
   * The `errors` array will be populated with missing, unknown or wrongly typed keyword values (attributes)
   *
   * @param constraints - An object specifying an `required` and/or `optional` array of `KwNameType` objects.
   * Only keywords listed in required or optional are accepted, other keywords will be marked with an
   * 'unknown attribute' error.
   * To allow arbitrary keywords, pass `KW_ANY` as value for `optional`.
   * `KW_ANY` can be combined with other `KwNameType` entries to have type requirements for some optional keywords,
   * but allow any type for other keywords.
   *
   * @example
   * The inner syntax nodes of a $pageElement(<nodes>) expression, which are supposed to be of the format
   * `name: "literal string", cid: "literal string"` etc.
   */
  extractKwArgsAndErrors<T extends KwValueType> (kwArgNodes: SyntaxNode[], source: string, scope: Scope, constraints: KwArgConstraints = {}): KwArgsAndErrors<T> {
    const errors: string[] = []
    const kwArgs: ParsedKwArgs<T> = {}

    // TODO: here harkts. props werden nicht gefunden wenn hier die kwpargs von $pageElement übergeben werden. die anderen sachen schon.
    // d.h. kwArgNodes enthält alles nur nicht die props ODER die props sind in einem format das extractedKey.text null ist oder so?!
    // z.b. falls kvPairNode.children.length === 0
    kwArgNodes.forEach((item) => {
      const kvPairNode = this.extractNameAndChildren(item, source)

      // if the kw list is empty, the first kvPairNode is still returned, but has not children.
      if (kvPairNode.children.length > 0) {
        const extractedKey = this.extractNameAndChildren(kvPairNode.children[0], source)
        const attrValue = this.astToExp(kvPairNode.children[1], source, scope) as T & ParserMetaInfo
        kwArgs[extractedKey.text] = attrValue
      }
    })

    if (constraints.required != null) {
      constraints.required.forEach(({ name }) => {
        if (kwArgs[name] == null) {
          errors.push(`Required attribute "${name}" is missing.`)
        }
      })
    }

    const allAttrs = (constraints.optional ?? []).concat(constraints.required ?? [])

    allAttrs.forEach(({ name, dt }) => {
      if (kwArgs[name] != null) {
        const givenTypes = this.runtime.tc.inferType(kwArgs[name])
        const res = this.runtime.tc.isCompatible([dt], givenTypes)

        if (!res.ok) {
          kwArgs[name] = { ...kwArgs[name], errors: res.messages }
        }
      }

      // if (kwArgs[name] != null && kwArgs[name].t !== type) {
      //   kwArgs[name] = { ...kwArgs[name], errors: [`${name} needs to be a ${type} (was: ${type})`] }
      // }
    })

    if (!(constraints.optional ?? []).includes(KW_ANY)) {
      Object.keys(kwArgs).forEach(keyword => {
        if (!allAttrs.map(a => a.name).includes(keyword)) {
          errors.push(`Unknown attribute "${keyword}".`)
        }
      })
    }

    return { kwArgs, errors }
  }

  resolveSymbolOrSymbolInvoc (rightNode: SyntaxNode, source: string, scope: Scope, symbolFinderFn: (symbolName: string) => Array<ST.Exp.GetAttr | ST.Exp.ObjMethod | ST.Exp.Method>): ST.Exp.Expression & ParserMetaInfo {
    const withRaw = this.makeWithRawFn(rightNode)

    const from = rightNode.from
    const to = rightNode.to

    const raw = source.slice(from, to)
    let needle = ''

    const parsedArgs: ST.Exp.Expression[] = []
    const parsedKwArgs: ST.Exp.KwArgs = {}

    switch (rightNode.name) {
      case 'Symbol':
        needle = source.slice(from, to)
        break
      case 'SymbolInvoc': {
        const symbolNode = rightNode.firstChild
        const argNode = rightNode.lastChild

        if (symbolNode == null || argNode == null) throw new Error('Error parsing SymbolInvoc, node is missing Symbol and Argument nodes')

        needle = source.slice(symbolNode.from, symbolNode.to)
        switch (argNode.name) {
          case 'NoArgs':
            break
          case 'KwArgs': {
            const { children } = this.extractNameAndChildren(argNode, source)

            const { kwArgs, errors } = this.extractKwArgsAndErrors<ST.Exp.Expression>(
              children, source, scope,
              {
                optional: [KW_ANY]
              }
            )

            // const e = F.makeRecordFromArgs(kwArgs as Record<string, ST.Exp.Expression>)
            // if (errors.length > 0) e.errors = errors

            Object.assign(parsedKwArgs, kwArgs)
            break
          }
          case 'ListArgs':
          case 'MixedArgs': {
            const { children } = this.extractNameAndChildren(argNode, source)
            const kwArgNodes: SyntaxNode[] = []
            children.forEach(itemNode => {
              if (itemNode.name === 'KwExpr') {
                kwArgNodes.push(itemNode)
              } else {
                // leezer grammer makes sure this list has only expressions, not statements
                parsedArgs.push(this.astToExp(itemNode, source, scope) as ST.Exp.Expression)
              }
            })
            const { kwArgs, errors } = this.extractKwArgsAndErrors<ST.Exp.Expression>(
              kwArgNodes, source, scope,
              {
                optional: [KW_ANY]
              }
            )

            // const e = F.makeRecordFromArgs(kwArgs as Record<string, ST.Exp.Expression>)
            // if (errors.length > 0) e.errors = errors
            Object.assign(parsedKwArgs, kwArgs)
            break
          }
          default:
            throw new Error(`Unexpected node type when parsing arguments for symbol invocation: ${argNode.name}`)
        }
        break
      }
      default:
        // throw new Error(`Unexpected node type when parsing right side of DotOp from leezer syntax tree: ${rightNode.name}`)
        // we should not throw an error here- if the syntax is incomplete or incorrect in the editor, it should be okay,
        // we just return an incomplete statement here:
        return withRaw(F.makeIncomplete(raw), raw)
    }

    const exactMatches = symbolFinderFn(needle)

    if (exactMatches.length === 1) {
      const match = exactMatches[0]

      switch (match.t) {
        case 'Method':
          return withRaw(F.makeCallMethod(match.name, parsedArgs, parsedKwArgs, match.id), raw, from, to)
        case 'ObjMethod':
          return withRaw(F.makeCallObjMethod(match.name, parsedArgs, parsedKwArgs, match.id), raw, from, to)
        case 'GetAttr':
          // TODO: if args are given, create an error (because just fetching props doesn't take arguments but the grammer cannot know)
          return withRaw(match, raw, from, to)
        default:
          break
      }
    }
    // use 'raw' intead of 'needle' here. Otherwise, the unresolved expression will not contain paranthesis, if the user has put them in.
    return withRaw(F.makeUnresolved(raw), raw, from, to)
  }

  /**
   * Given that `children` are nodes in a $var or $param expression (first item defines a
   * name, second item a data type, and third an initialization value),
   * data type and initialization value get type checked, a callback is invoked to generate the expression
   * and the expression's error field is populated if any type check errors occured.
   * @param source is the whole source code (where the nodes position refers to)
   * @param text is the source code for the node that initiated the call to this function. only used in case of an error (incomplete)
   */
  varOrPropToExp (children: SyntaxNode[], source: string, text: string, scope: Scope, generateExpCb: VarOrPropExpGenerator): ST.Lang {
    const varName = this.extractNameAndChildren(children[0], source).text

    if (children[1] == null || children[2] == null) {
      return F.makeIncomplete(text)
    }

    const propDT = this.astToExp(children[1], source, scope) as unknown as ST.DT.TypeDef
    let defaultValue = this.astToExp(children[2], source, scope) as unknown as ST.Exp.Lit.All | ST.Exp.ElementStyling

    if (defaultValue.t === 'EmptyDefault' && propDT.type === 'ElementStyling') {
      defaultValue = F.makeEmptyStyling()
    }

    const defaultValueType = this.runtime.tc.inferType(defaultValue)

    const tcRes = this.runtime.tc.isCompatible([propDT], defaultValueType, {
      r: varName,
      i: 'initial value'
    })

    const e = generateExpCb(varName, propDT, defaultValue)
    if (!tcRes.ok) e.errors = ["The initial value doesn't match the specified data type."].concat(tcRes.messages)

    return e
  }

  makeWithRawFn (defaultNode: SyntaxNode): <T extends ST.Lang>(exp: T, raw: string, from?: number, to?: number) => T & ParserMetaInfo {
    const withRaw = <T extends ST.Lang>(exp: T, raw: string, from?: number, to?: number): T & ParserMetaInfo => {
      const meta: ParserMetaInfo['parser'] = {
        raw,
        from: from === undefined ? defaultNode.cursor().from : from,
        to: to === undefined ? defaultNode.cursor().to : to,
        parent: null
      }
      return ({ ...exp, parser: meta })
    }
    return withRaw
  }

  /**
   * Converts parse tree (from lezer) to an Agjs Expression
   */
  astToExp (node: SyntaxNode, source: string, scope: Scope, id?: Agjs2.nodeId): ST.Lang & ParserMetaInfo {
    const withRaw = this.makeWithRawFn(node)
    const { children, name, text } = this.extractNameAndChildren(node, source)

    switch (name) {
      case '⚠':
        return withRaw(F.makeIncomplete(text), text)
      case 'T':
        return withRaw(this.astToExp(children[0], source, scope, id), text)
      case 'Number':
        return withRaw(F.makeNumber(Number.parseInt(text)), text)
      case 'Null':
        return withRaw(F.makeNull(), text)
      case 'EmptyDefault':
        return withRaw(F.makeEmptyDefault(), text)
      case 'Boolean':
        return withRaw(F.makeBoolean(text === 'true'), text)
      case 'String':
        return withRaw(F.makeString(JSON.parse(text)), text)
      case 'Timestamp': {
        const { text: innerText } = this.extractNameAndChildren(children[0], source)
        // TODO: set timezone if string tz is available.
        const date = new Date(innerText)
        return withRaw(F.makeTimestamp(date), text)
      }
      case 'Symbol': {
        const needle = text
        const res = scope.allSymbols().filter(sm => sm.name === needle).map(this.runtime.nodeIndex.getReferenceFor)
        if (res.length === 1) {
          return withRaw(res[0], needle)
        }
        return withRaw(F.makeUnresolved(needle), needle)
      }
      case 'SymbolInvoc': {
        return this.resolveSymbolOrSymbolInvoc(node, source, scope, (symbolName: string): ST.Exp.Method[] => {
          return scope.allSymbols().filter(sm => sm.name === symbolName && sm.node.t === 'Method').map(sm => sm.node as ST.Exp.Method)
        })
      }
      case 'DotOp': {
        // TODO: make sure that grammar doesn't allow the left side of an DotOp to be anything else than an expression.
        // That is why I'm safely typecasting here.
        const left = this.astToExp(children[0], source, scope) as ST.Exp.Expression
        // if (left.t === '$incomplete') return withRaw(F.makeIncomplete(text), text)

        const rawRight = source.slice(children[1].from, children[1].to)
        const right = withRaw(this.resolveSymbolOrSymbolInvoc(children[1], source, scope, (symbolName: string) => {
          const allResults = this.runtime.nodeIndex.suggestDotOpRightSide(left)
          return allResults.filter(res => ((res as ST.Exp.GetAttr)._name ?? (res as Agjs2.NamedNode).name) === symbolName)
        }) as ST.Exp.DotOpRightSide & ParserMetaInfo, rawRight, children[1].from, children[1].to)

        const dop = withRaw({
          t: 'DotOp',
          id: randomId(),
          left,
          right
        }, text) as ST.Exp.DotOp & ParserMetaInfo

        (dop.left as ST.Exp.Expression & ParserMetaInfo).parser.parent = dop
        ;(dop.right as ST.Exp.DotOpRightSide & ParserMetaInfo).parser.parent = dop

        return dop
      }
      case 'List':
        return withRaw(F.makeList<ST.Exp.Expression>(children.map(itemNode => this.astToExp(itemNode, source, scope, id) as ST.Exp.Expression)), text)
      case 'DefsList':
        // DefsList is "$[]" and has special use when defining pageElements (and params etc.)
        return withRaw(F.makeDefsList(children.map(itemNode => this.astToExp(itemNode, source, scope, id) as ST.Param)), text)
      case 'Record': {
        const { kwArgs, errors } = this.extractKwArgsAndErrors(
          children, source, scope,
          {
            optional: [KW_ANY]
          }
        )

        const e = F.makeRecord(kwArgs as Record<string, ST.Exp.LiteralExpression>)
        if (errors.length > 0) e.errors = errors

        return withRaw(
          e,
          text
        )
      }
      case 'Group':
        // Group only contains expressions as confirmed in grammar:
        // leezer: Group { "(" expr ")" }
        return withRaw(F.makeGroup(this.astToExp(children[0], source, scope, id) as ST.Exp.Expression), text)
      case 'BinaryOp': {
        const op = this.astToExp(children[1], source, scope)
        return withRaw({
          t: 'BinaryOp',
          id: randomId(),
          left: this.astToExp(children[0], source, scope) as ST.Exp.Expression,
          op: (op as ST.Exp.Lit.Incomplete).value as ST.Exp.BinaryOperator,
          right: this.astToExp(children[2], source, scope) as ST.Exp.Expression
        }, text)
      }
      case 'MultOp':
      case 'AddOp':
      case 'CompareOp':
      case 'BoolOp':
        return withRaw(F.makeIncomplete(text), text)
      case 'DTPrimitive':
        // TODO: check if text is any of the allowed type strings.
        return withRaw(F.makePrimitiveDT(text as ST.DT.PrimitiveTypeDef['type']) as unknown as ST.Exp.Expression, text)
      case 'DTList': {
        const itemType = this.astToExp(children[0], source, scope) as unknown as ST.DT.TypeDef
        return withRaw(F.makeListType(itemType) as unknown as ST.Exp.Expression, text)
      }
      case 'DTRecord': {
        const items = children.map(keyDtItem => {
          const itemNode = this.extractNameAndChildren(keyDtItem, source)
          const name = this.extractNameAndChildren(itemNode.children[0], source).text
          return F.makeRecordField(name, this.astToExp(itemNode.children[1], source, scope) as unknown as ST.DT.TypeDef)
        })
        return withRaw(F.makeRecordDefType(items) as unknown as ST.Exp.Expression, text)
      }
      case 'DefVar': {
        const e = this.varOrPropToExp(
          children,
          source,
          text,
          scope,
          (name, dataType, initValue) => F.makeVariable(name, dataType, initValue as ST.Exp.LiteralExpression)
        )
        return withRaw(e, text)
      }
      case 'DefParam': {
        const e = this.varOrPropToExp(
          children,
          source,
          text,
          scope,
          (name, dataType, initValue) => {
            const propDefExp = F.makeParam(name, dataType, initValue as ST.Exp.LiteralExpression)

            const { kwArgs, errors } = this.extractKwArgsAndErrors<ST.Lang>(
              children.slice(3),
              source,
              scope, { required: [], optional: KW_UI_META_INFO_FIELDS }
            )

            propDefExp.meta = this.createUIMetaInfo<ST.Lang>(kwArgs)

            if (errors.length > 0) {
              propDefExp.errors = propDefExp.errors ?? []
              propDefExp.errors = propDefExp.errors.concat(errors)
            }

            return propDefExp
          }
        )
        return withRaw(e, text)
      }
      case 'RenderContext':
        return withRaw(
          F.makeRenderContext(children.map(item => this.astToExp(item, source, scope) as ST.Exp.Render.All)),
          text)
      case 'RenderHTMLTag': {
        // creates HTML tags with lower case tag name
        // and page elements with upper case page name.
        const openTagNode = this.extractNameAndChildren(children[0], source)
        const tagName = this.extractNameAndChildren(openTagNode.children[0], source).text

        const tagAttrs: Record<string, ST.Exp.Expression> = {}

        const attrListNode = this.extractNameAndChildren(openTagNode.children[1], source)

        const reservedAttrs = ['name']
        const propRecord: ST.Exp.KwArgs = {}

        attrListNode.children.forEach((item) => {
          const kvPairNode = this.extractNameAndChildren(item, source)
          const extractedKey = this.extractNameAndChildren(kvPairNode.children[0], source)
          const attrValue = this.astToExp(kvPairNode.children[1], source, scope) as ST.Exp.Expression

          const keyName = extractedKey.text

          if (reservedAttrs.includes(keyName)) {
            tagAttrs[keyName] = attrValue
          } else {
            propRecord[keyName] = attrValue
          }
        })

        let childExps: ST.Exp.Render.All[] = []

        // Do not attempt to read a content node (HTMLTagContent) unless this is an OpenTag
        // (because a HTMLSelfClosingTag doesn't have content)
        if (openTagNode.name === 'OpenTag') {
          const contentNode = this.extractNameAndChildren(children[1], source)

          childExps = contentNode
            .children
            .map(i => this.astToExp(i, source, scope)) as ST.Exp.Render.All[]
        }

        const firstChar = tagName.charAt(0)

        if (firstChar === firstChar.toUpperCase()) {
          // TODO: check if class really exists? must be checked in a second path I guess
          //
          // Also, we need a concept of parser errors... if name is not a literal string etc.
          // TODO: 12th may 2023: yes, if name attribute is missing, this needs to be handled here (currently throws exception)

          // TODO: states!
          return withRaw(
            F.makeRenderPageElement(
              tagName,
              returnStringLiteralValue(tagAttrs.name),
              propRecord,
              childExps as ST.Exp.Render.PageElement[]
            ), text)
        }

        return withRaw(F.makeRenderHTMLTag(tagName, { ...tagAttrs, ...propRecord }, childExps), text)
      }
      case 'RenderEval':
        return withRaw(
          F.makeRenderEval(
            this.astToExp(children[0], source, scope) as ST.Exp.Expression
          ),
          text)
      case 'DefPageElement': {
        const { kwArgs, errors } = this.extractKwArgsAndErrors<ST.Lang>(children, source, scope, {
          required: [
            { name: 'name', dt: F.makePrimitiveDT('String') },
            { name: 'cid', dt: F.makePrimitiveDT('String') },
            { name: 'render', dt: F.makeRenderContextType() }
          ],
          optional: KW_UI_META_INFO_FIELDS.concat([
            { name: 'props', dt: F.makeDefsListType(F.makeParamType()) }
          ])
        })

        const metaInfo = this.createUIMetaInfo<ST.Lang>(kwArgs)

        let cid = 'UnspecifiedClassDef'
        let cname = 'UnnamedClassDef'
        ensureStringLiteralValue(kwArgs.cid as ST.Exp.Expression, value => { cid = value })
        ensureStringLiteralValue(kwArgs.name as ST.Lang, value => { cname = value })

        const props: ST.KwParams = {}

        if (kwArgs.props?.errors == null) {
          (((kwArgs.props as unknown) ?? F.makeDefsList([])) as ST.Exp.ListGen<ST.Param>).items.forEach(p => {
            props[p.name] = p
          })
        } else {
          if (kwArgs.props.errors.length === 0) throw new Error('error attr set, but empty')
          kwArgs.props.errors.forEach(propErr => {
            errors.push(
              this
                .runtime
                .tc.interpolate(propErr, `${cname}.props`, 'given expression')
            )
          })
        }

        const classDef: ST.Exp.PageElementClass = {
          t: 'PageElementClass',
          // FIXME: bei classes auch cid (wie bei pageelements) verwenden? id
          // bezieht sich dann überall NUR auf den JSON-tree und kann jedes mal anders sein.
          // (taucht ja nicht im AGL auf)
          id: cid, // F.random()
          cid,
          name: cname,
          meta: metaInfo,
          props,
          // TODO: error checking: need the possibility to enforce a RenderExp here, if not error needs to be stored.
          render: kwArgs.render as ST.Exp.RenderContext
        }

        if (errors.length > 0) {
          classDef.errors = errors
        }

        return withRaw(classDef as unknown as ST.Exp.Expression, text)
      }
      default:
        return withRaw(F.makeIncomplete(text), text)
    }
  }
}
