import { openModal } from '../overlay_manager'
import { StandardDialog } from '../pcomponents/dialogs'

export class AutocompleteAssistant {
  // store: Ide.Store

  constructor () {
    // this.store = store
  }

  openDatabaseAssistant (): void {
    openModal((closeFn) => (
      <StandardDialog title='Help with database interaction' onCancel={closeFn}>
        <div>To interact with database tables, type the name of the table followed by a dot.</div>
      </StandardDialog>
    ))
  }
}
