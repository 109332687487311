import { useContext } from 'preact/hooks'
import { Fragment, ComponentChildren, VNode } from 'preact'

import { ItemButton, DropdownMenu, PopoverButton } from './index'

import { ProjectStructureMenu } from './project_structure_menu'

import { observer } from 'mobx-react'
import { AppContext } from '../app_context'
import { RemoteJobStatus } from '../stores/project_state'
import { mutationDescription, MutationGroup } from '../agjs/mutation_commands'
import {command, divider} from './menu'

const UndoHistory = observer(() => {
  const { store } = useContext(AppContext)

  const HistoryItem = ({ item, pos }: { item: MutationGroup, pos: 'past' | 'present' | 'future' }): VNode => {
    const f = pos === 'future'
    const cellCl = 'px-2 py-2' + (f ? ' text-muted' : '')
    return (
      <tr className='border-b border-ridge'>
        <td className='px-1 py-2'>{pos === 'present' ? <span className='material-symbols-outlined'>chevron_right</span> : ''}</td>
        <td className={cellCl}>{mutationDescription(item, f ? 'n' : 'p')}</td>
        <td className={cellCl}>{item.id}</td>
      </tr>
    )
  }

  const history = store.project.history.history

  const ObservableHistoryList = observer(() => (
    <table>
      {history.past.map(item => <HistoryItem key={item.id} item={item} pos='past' />)}
      <HistoryItem item={history.present} pos='present' />
      {history.future.map(item => <HistoryItem key={item.id} item={item} pos='future' />)}
    </table>
  ))

  return (
    <PopoverButton icon='history' title='Show history' placement='bottom-center' popoverTitle='Command history' disabled={history.past.length === 0 && history.future.length === 0}>
      <ObservableHistoryList />
    </PopoverButton>
  )
})

const UndoButtons = observer((): VNode => {
  const { store } = useContext(AppContext)

  const previous = store.project.history.previousMutationDescription
  const next = store.project.history.nextMutationDescription
  return (
    <Fragment>
      <ItemButton icon='undo' title={previous != null ? `Undo "${previous}".` : undefined} disabled={previous == null} onClick={() => store.project.undo()} />
      <ItemButton icon='redo' title={next != null ? `Redo "${next}".` : undefined} disabled={next == null} onClick={() => store.project.redo()} />
      <UndoHistory />
    </Fragment>
  )
})

interface IRemoteJobButton {
  icon: string
  job: RemoteJobStatus
  onClick: (ev: MouseEvent) => void
  children: ComponentChildren
}

const RemoteJobButton = observer(({ icon, onClick, job, children = [] }: IRemoteJobButton): VNode => {
  const isRunning = !['idle', 'failed', 'completed'].includes(job.status)

  return (
    <ItemButton icon={isRunning ? 'animated-spinner' : icon} onClick={onClick} disabled={isRunning}>
      {children}
    </ItemButton>
  )
})

const PreviewButton = (): VNode => {
  const { store } = useContext(AppContext)

  const openPreview = (): void => {
    const { project } = store
    // window.open(`${project.config.urls.dev}${project.currentPage?.propValues.url.value ?? ''}`, 'ag-page-preview')
    // TODO: determine the correct URL. (slashes need to be removed on or the other)
    window.open(project.config.urls.dev, 'ag-page-preview')
  }

  const triggerBuild = (): void => {
    const { project } = store
    void project.triggerBuild()
  }

  return (
    <>
      <ItemButton icon='preview' onClick={openPreview}>

        Preview
      </ItemButton>
      <RemoteJobButton icon='cloud_upload' onClick={triggerBuild} job={store.project.remoteJobs.prodDeploy}>
        Publish
      </RemoteJobButton>
    </>
  )
}

const AccountButton = (): VNode => {
  const menu = () => [
    command('Manage projects', () => window.open('/')),
    divider(),
    command('Logout', () => null)
  ]
  return (
    <DropdownMenu icon='perm_identity' name='Account' generator={menu} />
  )
}

const SyncStatus = observer((): VNode => {
  const { store } = useContext(AppContext)

  const cl: string [] = []

  let title = 'All changes saved.'
  let icon = 'published_with_changes'
  let click = (): void => {}

  if (store.project.offline) {
    icon = 'sync_problem'
    title = 'No internet connection. Some editor features might be unavailable.'
    cl.push('bg-yellow-600')
    cl.push('text-yellow-200')
  } else {
    switch (store.project.syncState) {
      case 'synced':
        if (!store.project.queueEmpty) {
          icon = 'cloud_sync'
          title = 'Unsaved changes.'
        }
        cl.push('text-neutral-500')
        break
      case 'syncing':
        icon = 'cloud_sync'
        title = 'Saving changes...'
        break
      case 'error':
        icon = 'sync_problem'
        title = 'Could not save changes. Click to retry.'
        click = () => store.project.syncPending(true)
        cl.push('bg-red-700')
        cl.push('text-red-200')
        cl.push('cursor-pointer')
    }
  }

  return (
    <span
      className={`d-flex align-items-center border-end px-1 select-none ${cl.join(' ')}`}
      onClick={click}
      title={title}
    >
      <span className='material-symbols-outlined'>{icon}</span>
    </span>
  )
})

const MiddleSectionContent = (): VNode => {
  return <div className='flex-fill border-end px-2'>&nbsp;</div>
}

export const TopMenu = observer((): VNode => {
  return (
    <div className='border-bottom d-flex flex-row bg-body'>
      <ProjectStructureMenu />
      <MiddleSectionContent />
      <UndoButtons />
      <SyncStatus />
      <PreviewButton />
      <AccountButton />
    </div>
  )
})
