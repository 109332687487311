import { Agjs2, ST } from '../types'
import { MGWorkflowCreate } from '../mutation_commands'
import { OpCollection } from './op_collection'
import { F } from '../factory'

export class WorkflowOp extends OpCollection {
  createOnPage (name: string, pageId: string): Agjs2.Workflow {
    const page = this.nodeIndex.lookup(pageId) as ST.Exp.Render.PageRootElement

    if (name === '') {
      name = this.nodeIndex.newName('Workflow', { namesList: page.propValues.workflows.items.map(wf => wf.name) })
    }

    const newWf = F.makeWorkflow(name)

    this.config.history.createInsertGroup<MGWorkflowCreate>('workflow.create', {
      name: newWf.name,
      id: newWf.id
    }, {
      parentId: page.id,
      parentAttr: 'propValues.workflows.items',
      index: page.propValues.workflows.items.length
    }, newWf)

    // this.openWorkflow(newId)
    return newWf
  }

  createWorkflowStatement (workflow: Agjs2.Workflow, wfType: string): void {
    throw new Error('not migrated')
  }
}
