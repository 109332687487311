import { ST } from './../types'
import * as Factory from './../factory'
import { Compiler } from '../compiler'
import { theredoc } from '../string_utils'
import { CompilationContext } from '../compiler_utils'
import { SourceGeneratorFn } from '../types/index'

const { F } = Factory

const category = 'Methods/Database'
const dbImports = [{ name: 'database as db', source: '@/app/lib/database' }]

interface WfMethodOpts {
  async?: boolean
}

/**
 * Creates a workflow-only static class method
 */
function mkWfMethod (
  prefix: string,
  name: string,
  description: string,
  typeVars: ST.DT.TypeVar[],
  parameters: ST.Param[],
  returnType: ST.DT.TypeDef,
  js: SourceGeneratorFn,
  opts: WfMethodOpts = {}
): ST.Exp.Method {
  return {
    t: 'Method',
    id: `${prefix}.${name}`,
    name,
    meta: {
      description,
      category,
      order: 1
    },
    js,
    typeVars,
    parameters,
    kwParameters: {},
    async: opts.async ?? false,
    workflowStmt: true,
    returnType
  }
}

const insert = mkWfMethod(
  'db',
  'insert',
  'Insert a new row into a database table.',
  [F.makeTypeVar('T', [F.makeAnyDbTableDT()])],
  [
    F.makeParam('table', F.makeTypeVarRef('T')),
    F.makeParam('fields',
      F.makeUtilityType('DbRecordForInsert', F.makeTypeVarRef('T'))
    )
  ],
  F.makeNoneType(),
  () => theredoc`
  const now = new Date();
  const fields = {
    ...args[1],
    UpdatedAt: now,
    CreatedAt: now
  };
  const result = await db().insert(args[0].drizzleTable).values(fields).returning();
  return result;
  `,
  { async: true }
)

const updateById = mkWfMethod(
  'db',
  'updateById',
  'Update a row in a database table.',
  [F.makeTypeVar('T', [F.makeAnyDbTableDT()])],
  [
    F.makeParam('table', F.makeTypeVarRef('T')),
    F.makeParam('id', F.makePrimitiveDT('Number')),
    F.makeParam('fields',
      F.makeUtilityType('DbRecordForUpdate', F.makeTypeVarRef('T'))
    )
  ],
  F.makeNoneType(),
  () => theredoc`
  throw new Error('implement me')
  const now = new Date();
  const fields = {
    ...args[1],
    UpdatedAt: now,
    CreatedAt: now
  };
  const result = await db().insert(args[0].drizzleTable).values(fields).returning();
  return result;
  `,
  { async: true }
)

const deleteById = mkWfMethod(
  'db',
  'deleteById',
  'Delete a row in a database table.',
  [],
  [
    F.makeParam('table', F.makeAnyDbTableDT()),
    F.makeParam('id', F.makePrimitiveDT('Number'))
  ],
  F.makePrimitiveDT('Boolean'),
  () => theredoc`
  throw new Error('implement me')
  const now = new Date();
  const fields = {
    ...args[1],
    UpdatedAt: now,
    CreatedAt: now
  };
  const result = await db().insert(args[0].drizzleTable).values(fields).returning();
  return result;
  `,
  { async: true }
)

/* Possible policy for deciding wether to us ObjMethod vs StaticClass:
 * Whenever possible (methods returning data originating from one source) will be
 * implemented as ObjMethods.
 * These can then be used in expressions (like setting a prop value).
 * These expression should not have side effects but should be functional/'pure' as possible
 * (apart from getting time and database records)
 *
 * Methods that MODIFY data (like insert/delete/update) should not be used inside such an
 * expression (more difficult to debug code) but the user will be forced to use them as
 * statements in a workflow.
 */
const dbTableFind: ST.Exp.ObjMethod = {
  t: 'ObjMethod',
  id: 'DbTable.find',
  name: 'find',
  meta: {
    description: 'Find rows from this table and return them in a list.',
    category,
    order: 1
  },
  js: (compiler: Compiler, cc: CompilationContext): string => 'return await db().query[object.identifierName].findMany()',
  parameters: [],
  kwParameters: {},
  typeVars: [F.makeTypeVar('T', [F.makeAnyDbTableDT()])],
  objType: F.makeTypeVarRef('T'),
  returnType: F.makeUtilityType('DbTableRows', F.makeTypeVarRef('T')),
  // returnTypeJs: genericTableReturnType,
  jsImports: dbImports,
  async: true,
  serverSide: true
}

const objMethods: ST.Exp.ObjMethod[] = [dbTableFind]

const DbClass: ST.Exp.StaticClass = {
  t: 'StaticClass',
  name: 'Db',
  cid: 'DbClass',
  id: 'DbClass',
  meta: {
    summary: 'Methods for interacting with the database.',
    description: 'Db provides several methods for interacting with the database. Search tables for records (rows) containing certain values, create new records, update or delete existing records.',
    searchHint: 'CRUD,search table,create records,create rows,add rows,insert rows,update rows,save rows,delete rows,remove rows,database,table',
    category: 'Stdlib'
  },
  props: {},
  methods: [
    insert, updateById, deleteById
  ],
  jsImports: dbImports
}

export { DbClass, objMethods }
