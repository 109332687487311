export function capitalizeFirstLetter (input: string): string {
  if (input.length === 0) return ''
  // @ts-expect-error
  const [first, ...rest]: [first: string, rest: string[]] = input
  return [first.toUpperCase(), ...rest].join('')
}

export function formatAsElementName (name: string): string {
  const validName = capitalizeFirstLetter(name.replace(/^\d+/, '').replace(/[^A-Za-z0-9]/g, ''))
  return validName
}

export function toCamelCase (str: string): string {
  return str.toLowerCase().replace(/-([a-z])/g, (_, group1) => group1.toUpperCase())
}

export function toSnakeCase (str: string): string {
  // Source: https://www.w3resource.com/javascript-exercises/fundamental/javascript-fundamental-exercise-120.php
  const m = str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)

  if (m != null) return m.map(x => x.toLowerCase()).join('_')
  return ''
}

// Taken from https://github.com/testdouble/theredoc/blob/main/index.js
/**
 *   console.log(theredoc`
 *         I want to write multipline lines
 *         but don't want to mess up my indenting.
 *           Ok?
 *       `)
 *
 * Will generate a correctly formatted string without the identations from the code.
 *
 * https://github.com/testdouble/theredoc
 */

export function theredoc (strings: TemplateStringsArray, ...values: any[]): string {
  const lines = withoutLeadingAndTrailingBlankLines(
    zipString(strings, values).split('\n')
  )
  return stripIndent(lines, smallestIndent(lines)).join('\n')
}

function zipString (strings: TemplateStringsArray, values: any[]): string {
  let s: string = ''
  strings.forEach((string, i) => {
    s += string + (values[i] as string ?? '')
  })
  return s
}

function smallestIndent (lines: string[]): number {
  let smallest: number | null = null
  lines.forEach(line => {
    const indent = line.search(/[^ ]/)
    if (indent !== -1 && (smallest === null || indent < smallest)) {
      smallest = indent
    }
  })
  return smallest ?? 0
}

function stripIndent (lines: string[], spacesToStrip: number): string[] {
  const findIndent = new RegExp(`^ {${spacesToStrip}}`)
  return lines.map(line => {
    if (findIndent.test(line)) {
      return line.replace(findIndent, '')
    } else {
      return line
    }
  })
}

// Written verbosely to avoid the cost of slice (array copy) if unnecessary
function withoutLeadingAndTrailingBlankLines (lines: string[]): string[] {
  const leadingBlankLine = isWhitespace(lines[0])
  const trailingBlankLine = isWhitespace(lines[lines.length - 1])
  if (leadingBlankLine || trailingBlankLine) {
    return lines.slice(
      leadingBlankLine ? 1 : 0,
      trailingBlankLine ? lines.length - 1 : lines.length
    )
  } else {
    return lines
  }
}

function isWhitespace (s: string): boolean {
  return /^\s*$/.test(s)
}
// End of import form https://github.com/testdouble/theredoc/blob/main/index.js
