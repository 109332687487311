import { VNode, ComponentChildren } from 'preact'

import { runInAction, makeAutoObservable } from 'mobx'
import { randomId } from './agjs'
import { observer } from 'mobx-react'
import { useEffect, useLayoutEffect, useRef, useState } from 'preact/hooks'

export type CloseFn = () => void

export type PlacementType = 'left-start' | 'left-center' | 'left-end' | 'top-start' | 'top-center' | 'top-end' | 'right-start' | 'right-center' | 'right-end' | 'bottom-start' | 'bottom-center' | 'bottom-end' | 'default'
export type MarkerType = 'none' | 'start' | 'end' | 'both'
export type BendType = 'hfirst' | 'wfirst' | 'none'

interface ArrowDef {
  id: string
  origin: HTMLElement
  target: HTMLElement
  from: PlacementType
  to: PlacementType
  marker: MarkerType
  bend: BendType
  redraw: string
}

export class ArrowManager {
  arrows: ArrowDef[] = []

  connect = (origin: HTMLElement, target: HTMLElement, from: PlacementType, to: PlacementType, marker: MarkerType, bend: BendType = 'wfirst'): void => {
    const id = randomId()
    runInAction(() => {
      const item: ArrowDef = { id, origin, target, from, to, marker, bend, redraw: '' }

      this.arrows.push(item)
    })
    // return () => this.closeOverlay(id)
  }

  constructor () {
    makeAutoObservable(this)
  }

  disconnect (ref: HTMLElement[]): void {
    runInAction(() => {
      const isRef = (needle: HTMLElement): boolean => ref.find(hay => hay === needle) != null
      this.arrows = this.arrows.filter(item => !isRef(item.target) && !isRef(item.origin))
    })
  }

  redrawAllLines = (): void => {
    runInAction(() => {
      console.log('redrawing all lines')
      this.arrows.forEach(item => { item.redraw = randomId() })
    })
  }
}

/**
 * The rect refers to the reference element (where the overlay is attahed to).
 * The resulting coordinates signify where the 'pointer' of the overlay should point to.
 */
function anchorPoint (placement: PlacementType, rect: DOMRect): { x: number, y: number } {
  let x = 0
  let y = 0

  switch (placement) {
    case 'bottom-start':
      x = rect.left
      y = rect.bottom
      break
    case 'bottom-center':
      x = rect.left + (rect.width / 2)
      y = rect.bottom
      break
    case 'bottom-end':
      x = rect.right
      y = rect.bottom
      break
    case 'top-start':
      x = rect.left
      y = rect.top
      break
    case 'top-center':
      x = rect.left + (rect.width / 2)
      y = rect.top
      break
    case 'top-end':
      x = rect.right
      y = rect.top
      break
    case 'left-start':
      x = rect.left
      y = rect.top
      break
    case 'left-center':
      x = rect.left
      y = rect.top + (rect.height / 2)
      break
    case 'left-end':
      x = rect.left
      y = rect.bottom
      break
    case 'right-start':
      x = rect.right
      y = rect.top
      break
    case 'right-center':
      x = rect.right
      y = rect.top + (rect.height / 2)
      break
    case 'right-end':
      x = rect.right
      y = rect.bottom
      break
    case 'default':
      x = rect.left
      y = rect.bottom
      break
  }
  return { x, y }
}

//   // Here are some potential performance optimizations- adjustPosition could be piped through useCallback, otherwise
//   // the observer will be destroyed and recreated in every re-render.
//   // This is necessary because the adjustPosition callback is different every time.
//   useEffect(() => {
//     const resizeObserver = new ResizeObserver(adjustPosition)
//     resizeObserver.observe(document.getElementsByTagName('body')[0], { box: 'border-box' })
//     if (item.ref != null) resizeObserver.observe(item.ref, { box: 'border-box' })
//     if (overlayRef.current != null) resizeObserver.observe(overlayRef.current, { box: 'border-box' })
//     return () => resizeObserver.disconnect()
//   }, [item.ref, overlayRef.current, adjustPosition])
//
//   useLayoutEffect(() => adjustPosition(), [])
//
//   const hiddenVisibility = layoutPhase === 'positioning' ? 'opacity: 0;' : 'opacity: 1;'
//
//   const Outline = (): Preact.VNode | null => {
//     if (layoutPhase !== 'outline' || overlayRef.current == null) return null
//
//     const ref = refOrPositionRect()
//     if (ref == null) return null
//
//     const overlayRect = overlayRef.current.getBoundingClientRect()
//
//     // if we would just use the overlayRect here, we would still get the original position
//     // (if it was repositioned during layout phase), because the div is rendered after the outline element.
//     // so manually calculate the starting pos. We assume width and height stay the same since there is no support
//     // to adjust these yet.
//     const target = new DOMRect(anchorX + ofsX, anchorY + ofsY, overlayRect.width, overlayRect.height)
//     const refCenter = new DOMRect(ref.x + (ref.width / 2), ref.y + (ref.height / 2), 0, 0)
//     return (<AnimatedOutline origin={refCenter} target={target} onFinish={() => setLayoutPhase('completed')} />)
//   }
//
//   return (
//     <>
//       <div
//         className={`position-absolute ${item.anmiatedOutline === true ? 'animated-overlay' : ''}`}
//         ref={overlayRef}
//         style={`transform: translate3d(${anchorX + ofsX}px, ${anchorY + ofsY}px, 0);${hiddenVisibility}`}
//       >
//         {children}
//       </div>
//       <Outline />
//     </>
//   )
// }

const SVGWrapper = ({ children }: { children: ComponentChildren }): VNode => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' style='width: 100%; height: 100%'>
      <g stroke-width='8' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'>
        {children}
      </g>
      <defs>
        <marker markerWidth='5' markerHeight='5' refX='2.5' refY='2.5' orient='auto' id='arrowhead'>
          <polygon points='0,5 1.6666666666666667,2.5 0,0 5,2.5' fill='currentColor' />
        </marker>
      </defs>
    </svg>
  )
}

const mkLine = (x1: number, y1: number, x2: number, y2: number, marker?: MarkerType, bend?: BendType): VNode => {
  // const props = { x1, y1, x2, y2 }
  // const props = { d:  }
  let d = `M ${x1},${y1} L ${x2},${y2}`
  if (bend === 'wfirst') {
    d = `M ${x1},${y1} L ${x2},${y1} L ${x2},${y2}`
  } else if (bend === 'hfirst') {
    d = `M ${x1},${y1} L ${x1},${y2} L ${x2},${y2}`
  }

  const props = { d }

  if (marker != null) {
    const bothEnds = marker === 'both'
    if (marker === 'start' || bothEnds) props['marker-start'] = 'url(#arrowhead)'
    if (marker === 'end' || bothEnds) props['marker-end'] = 'url(#arrowhead)'
  }
  // return <line {...props}></line>
  return <path {...props} />
}

const SVGArrow = ({ arrowDef, relativeTo }: { arrowDef: ArrowDef, relativeTo: HTMLElement | null }): VNode => {
  const originRect = arrowDef.origin.getBoundingClientRect()
  const targetRect = arrowDef.target.getBoundingClientRect()

  const calcOffset = (rect: DOMRect): DOMRect => {
    if (relativeTo != null) {
      const parentRect = relativeTo.getBoundingClientRect()
      return new DOMRect(rect.x - parentRect.x, rect.y - parentRect.y, rect.width, rect.height)
    } else {
      return rect
    }
  }

  const startP = anchorPoint(arrowDef.from, calcOffset(originRect))
  const endP = anchorPoint(arrowDef.to, calcOffset(targetRect))
  return <>{mkLine(startP.x, startP.y, endP.x, endP.y, arrowDef.marker, arrowDef.bend)}</>
}

/**
 * Make sure that this element is inside a relative positioned parent element to have the canvas only
 * convering its parent
 */
export const ArrowCanvas = observer(({ manager }: { manager: ArrowManager }): VNode => {
  const items = manager.arrows.slice()
  const divRef = useRef<HTMLDivElement>(null)

  const [refKnown, setRefKnown] = useState(false)

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => manager.redrawAllLines())
    // TODO: besser: divRef parent oder so
    const parent = divRef.current?.parentElement

    if (parent == null) return

    resizeObserver.observe(parent, { box: 'border-box' })

    manager.arrows.forEach(arrow => {
      resizeObserver.observe(arrow.origin, { box: 'border-box' })
      resizeObserver.observe(arrow.target, { box: 'border-box' })
    })

    return () => resizeObserver.disconnect()
  }, [manager.arrows])

  useLayoutEffect(() => {
    if (divRef.current != null) setRefKnown(true)
  }, [])

  items.forEach(item => item.redraw)

  return (
    <div ref={divRef} className='position-absolute' style='top: 0; left: 0; width: 100%; height: 100%;'>
      <SVGWrapper>
        {refKnown && items.map(item => <SVGArrow arrowDef={item} key={item.id} relativeTo={divRef.current} />)}
      </SVGWrapper>
    </div>
  )
})
