import { Agjs2, ST } from './../types'
import * as Factory from './../factory'

const { F } = Factory

const TimestampDT = F.makeRecordDefType([
  F.makeRecordField('millisecond', F.makePrimitiveDT('Number')),
  F.makeRecordField('second', F.makePrimitiveDT('Number')),
  F.makeRecordField('minute', F.makePrimitiveDT('Number')),
  F.makeRecordField('hour', F.makePrimitiveDT('Number')),
  F.makeRecordField('weekday', F.makePrimitiveDT('Number')),
  F.makeRecordField('date', F.makePrimitiveDT('Number')),
  F.makeRecordField('month', F.makePrimitiveDT('Number')),
  F.makeRecordField('year', F.makePrimitiveDT('Number')),
  F.makeRecordField('epoch', F.makePrimitiveDT('Number'))
])

const now: ST.Exp.Method = {
  t: 'Method',
  id: 'Time.now',
  name: 'now',
  meta: {
    description: 'Get the current time and date.',
    category: 'Time',
    order: 1
  },
  js: () => {
    // const now = new Date()
    // return F.makeRecord({
    //   millisecond: F.makeNumber(now.getMilliseconds()),
    //   second: F.makeNumber(now.getSeconds()),
    //   minute: F.makeNumber(now.getMinutes()),
    //   hour: F.makeNumber(now.getHours()),
    //   weekday: F.makeNumber(now.getDay()),
    //   date: F.makeNumber(now.getDate()),
    //   month: F.makeNumber(now.getMonth()),
    //   year: F.makeNumber(now.getFullYear()),
    //   epoch: F.makeNumber(now.valueOf())
    // })
    return `const now = new Date();
      return {
       millisecond: now.getMilliseconds(),
       second: now.getSeconds(),
       minute: now.getMinutes(),
       hour: now.getHours(),
       weekday: now.getDay(),
       date: now.getDate(),
       month: now.getMonth(),
       year: now.getFullYear(),
       epoch: now.valueOf()
      }`;
  },
  parameters: [],
  kwParameters: {},
  returnType: TimestampDT
}

const toISOString: ST.Exp.Method = {
  t: 'Method',
  id: 'Time.toISOString',
  name: 'toISOString',
  meta: {
    description: 'The toISOString() method returns a string representing the current date in the date time string format, a simplified format based on ISO 8601, which is always 24 or 27 characters long (YYYY-MM-DDTHH:mm:ss.sssZ or ±YYYYYY-MM-DDTHH:mm:ss.sssZ, respectively). The timezone is always UTC, as denoted by the suffix Z.',
    category: 'Time',
    order: 1
  },
  js: () => {
    // const now = new Date()
    // return F.makeString(now.toISOString())
    return 'return (new Date()).toISOString();'
  },
  parameters: [],
  kwParameters: {},
  returnType: F.makePrimitiveDT('String')
}

const TimeClass: ST.Exp.StaticClass = {
  t: 'StaticClass',
  name: 'Time',
  cid: 'TimeClass',
  id: 'TimeClass',
  meta: {
    summary: 'Methods for dealing with times and dates.',
    description: 'Time provides methods for getting the current time and date, extract certain fields out of a timestamp and calculate distances between timestamps.',
    searchHint: 'current time,current date,today,now,week,year,distance between dates',
    category: 'Stdlib'
  },
  props: {},
  methods: [
    now,
    toISOString
  ]
}

export { TimeClass }
