import { Agjs2, ST } from './types'
import { toSnakeCase } from './string_utils'

const MINIMAL_UI_META_INFO: Agjs2.UIMetaInfo = {
  category: 'none'
}

const randomId = (prefix: string = 'i'): string => prefix + Math.floor((Math.random() * (36 ** 6))).toString(36)

const F = {
  /*
  makeT: function <TypeName extends ST.DT.TypeName, Fields extends Record<string, any>>(type: TypeName, args: Fields): ST.DT.TypeDef {
    return { ...args, t: 'DataType', type: type, id: (args.id || randomId()) }
  },
 */
  randomId,
  makeT: function <T, Fields>(t: T, args: Fields) { return { t, id: randomId(), ...args } },
  makeExp: function <E, Fields>(exp: E, args: Fields) { return { t: exp, id: randomId(), ...args } },
  makeProps: function <Fields>(args: Fields): Fields { return args },
  makeWorkflowRef: function (nodeId: Agjs2.nodeId, name?: string): ST.Exp.WorkflowRef { return F.makeT('WorkflowRef', { refId: nodeId, _name: name ?? '', kwArgs: {} }) },
  makePrimitiveDT: <TypeName extends ST.DT.PrimitiveTypeDef['type']>(typeName: TypeName): ST.DT.PrimitiveTypeDefGen<TypeName> => ({
    t: 'DataType',
    id: randomId(),
    type: typeName
  }),
  makeTypeVar: (name: string, dataTypes: ST.DT.TypeDef[]): ST.DT.TypeVar => F.makeT('DataType', {
    type: 'TypeVar',
    name,
    dataTypes
  }),
  makeTypeVarRef: (varname: string): ST.DT.TypeVarRef => F.makeT('DataType', {
    type: 'TypeVarRef',
    varname
  }),
  makeUtilityType: (op: ST.DT.UtilityType['op'], dataTypeArg: ST.DT.TypeDef): ST.DT.UtilityType => F.makeT('DataType', {
    op,
    type: 'UtilityType',
    dataTypeArg
  }),
  makeAnyDbTableDT: (): ST.DT.AnyDbTable => F.makeT('DataType', { type: 'AnyDbTable' }),
  makeDbTableDT: (tableDef: Agjs2.DbTableDef): ST.DT.DbTable => F.makeT('DataType', { type: 'DbTable', refId: tableDef.id, tableName: tableDef.name }),
  makeDbForeignKeyDT: (tableId: string): ST.DT.DbForeignKey => F.makeT('DataType', { type: 'DbForeignKey', refId: tableId }),
  makeAnyDT: (): ST.DT.Any => F.makeT('DataType', { type: '$any' }),
  makeRecordDefType: (fields: ST.DT.RecordFieldDef[]): ST.DT.RecordTypeDef => F.makeT('DataType', { type: 'Record', fields }),
  makeRecordField: (name: string, dataType: ST.DT.TypeDef, optional: boolean = false): ST.DT.RecordFieldDef => F.makeT('DataType', { type: 'RecordFieldDef', name, dataType, optional }),
  makeLit: <T extends string, V>(typeName: T, value: V): ST.Exp.Lit.AbstractLiteral<T, V> => ({
    id: randomId(),
    t: typeName,
    value
  }),
  makeGroup: (content: ST.Exp.Expression, id?: Agjs2.nodeId): ST.Exp.Group => ({
    id: id ?? randomId(),
    t: 'Group',
    child: content
  }),
  makeList: <ItemType extends ST.Exp.Expression>(items: ItemType[]): ST.Exp.ListGen<ItemType> => ({
    id: randomId(),
    t: 'List',
    items
  }),
  makeDefsList: (items: ST.Param[]): ST.DefsList => ({
    id: randomId(),
    t: 'DefsList',
    items
  }),
  makeRecord: (fields: Record<string, ST.Exp.LiteralExpression>): ST.Exp.Lit.RecordOfLit => ({
    id: randomId(),
    t: 'Record',
    fields
  }),
  makeNoneType: (id?: string): ST.DT.None => ({
    t: 'DataType',
    id: id ?? randomId(),
    type: 'none'
  }),
  makeNeverType: (id?: string): ST.DT.Never => ({
    t: 'DataType',
    id: id ?? randomId(),
    type: '$never'
  }),
  makeTypeError: (message: string): ST.DT.Error => ({
    t: 'DataType',
    id: randomId(),
    message,
    type: '$error'
  }),
  makePageElementInstanceType: (refId: Agjs2.nodeId): ST.DT.PageElementInstance => ({
    t: 'DataType',
    id: randomId(),
    refId,
    type: 'PageElementInstance'
  }),
  makeStaticClassType: (refId: Agjs2.nodeId): ST.DT.StaticClass => ({
    t: 'DataType',
    id: randomId(),
    refId,
    type: 'StaticClass'
  }),
  makeUnknownType: (exp: ST.Exp.Expression): ST.DT.Unknown => ({
    t: 'DataType',
    id: randomId(),
    type: '$unknown',
    exp
  }),
  makeListType: (itemType: ST.DT.TypeDef): ST.DT.ListTypeDef => ({
    t: 'DataType',
    id: randomId(),
    type: 'List',
    itemType
  }),
  makeDefsListType: (itemType: ST.DT.TypeDef): ST.DT.DefsListTypeDef => ({
    t: 'DataType',
    id: randomId(),
    type: 'DefsList',
    itemType
  }),
  makeRenderContextType: (): ST.DT.RenderContext => ({
    t: 'DataType',
    id: randomId(),
    type: 'RenderContext'
  }),
  makeRenderExpressionType: (): ST.DT.RenderExpression => ({
    t: 'DataType',
    id: randomId(),
    type: 'RenderExpression'
  }),
  makeIncomplete: (str?: string): ST.Exp.Lit.Incomplete => F.makeLit('$incomplete', str ?? ''),
  makeUnresolved: (str?: string): ST.Exp.Unresolved => F.makeT('$unresolved', { value: str ?? '' }),
  makeNull: (): ST.Exp.Lit.Null => F.makeLit('Null', null),
  makeEmptyDefault: (): ST.Exp.Lit.EmptyDefault => F.makeLit('EmptyDefault', null),
  makeString: (value: string): ST.Exp.Lit.String => F.makeLit('String', value),
  makeNumber: (value: number): ST.Exp.Lit.Number => F.makeLit('Number', value),
  makeText: (value: string): ST.Exp.Lit.Text => F.makeLit('Text', value),
  makeBoolean: (value: boolean): ST.Exp.Lit.Boolean => F.makeLit('Boolean', value),
  /**
   * @arg dateArg this is treated as an UTC date unless a timezone is provided. This is different from using Date.parse()
   * which treats the date as local to the computer's timezone.
   */
  makeTimestamp: (dateArg: string | Date, timezone?: string): ST.Exp.Lit.Timestamp => {
    // investige TC39 temporal polyfill
    const dateObj = (typeof dateArg === 'string' ? new Date(dateArg) : dateArg)
    const ts: ST.Exp.Lit.Timestamp = F.makeLit('Timestamp', dateObj)
    if (timezone != null) ts.timezone = timezone
    return ts
  },
  /** The type of a propDef of a class (what $param() returns) */
  makeParamType: (): ST.DT.Param => ({
    t: 'DataType',
    id: randomId(),
    type: 'Param'
  }),
  makeParam: (nameAndId: string,
    dataType: ST.DT.TypeDef,
    defaultValue?: ST.Lang,
    meta: Agjs2.UIMetaInfo = MINIMAL_UI_META_INFO): ST.Param => F.makeT('Param',
    {
      id: nameAndId,
      name: nameAndId,
      meta,
      noDelete: false,
      dataTypes: [dataType],
      defaultValue: defaultValue ?? nullExp
    }
  ),
  makeParamWithMultipleDTs: (nameAndId: string,
    dataTypes: ST.DT.TypeDef[],
    defaultValue?: ST.Exp.Expression,
    meta: Agjs2.UIMetaInfo = MINIMAL_UI_META_INFO): ST.Param => F.makeT('Param',
    {
      id: nameAndId,
      name: nameAndId,
      meta,
      noDelete: false,
      dataTypes,
      defaultValue: defaultValue ?? nullExp
    }
  ),
  makeListPropDef: (nameAndId: string,
    typeName: 'WorkflowList',
    defaultValueAsArray: ST.Exp.LiteralExpression[],
    meta: Agjs2.UIMetaInfo = MINIMAL_UI_META_INFO): ST.Param => (
    {
      t: 'Param',
      id: nameAndId,
      name: nameAndId,
      meta,
      noDelete: false,
      dataTypes: [{
        t: 'DataType',
        id: randomId(),
        type: typeName
      }],
      defaultValue: F.makeList(defaultValueAsArray)
    }
  ),

  makeGetNode: (namedNodeId: Agjs2.nodeId, name?: string): ST.Exp.GetNode => F.makeExp('GetNode', { refId: namedNodeId, _name: name ?? namedNodeId }),
  makeGetAttr: (attrPath: string, attrListItemId?: Agjs2.nodeId, name?: string): ST.Exp.GetAttr => {
    const rec = { attrPath, _name: name ?? attrPath }
    if (attrListItemId != null) Object.defineProperty(rec, 'attrListItemId', { value: attrListItemId })
    return F.makeExp('GetAttr', rec)
  },

  makeDotOp: (left: ST.Exp.Expression, right: ST.Exp.DotOpRightSide): ST.Exp.DotOp => F.makeExp('DotOp', { left, right }),
  makeBinaryOp: (left: ST.Exp.Expression, op: ST.Exp.BinaryOperator, right: ST.Exp.Expression): ST.Exp.BinaryOp => F.makeExp('BinaryOp', { left, op, right }),
  // FIXME: identical to makeCallObjMethod
  makeCallMethod: (methodName: string, args: ST.Exp.Expression[], kwArgs: Record<string, ST.Exp.Expression> = {}, methodId: string | null = null): ST.Exp.CallMethod => F.makeExp('CallMethod', {
    _name: methodName,
    methodId: methodId ?? `$fixme_${methodName}`,
    args,
    kwArgs
  }),
  makeCallObjMethod: (methodName: string, args: ST.Exp.Expression[], kwArgs: Record<string, ST.Exp.Expression> = {}, methodId: string | null = null): ST.Exp.CallObjMethod => F.makeExp('CallObjMethod', {
    _name: methodName,
    methodId: methodId ?? `$fixme_${methodName}`,
    args,
    kwArgs
  }),
  makeVariable: (name: string, dataType: ST.DT.TypeDef, value: ST.Exp.LiteralExpression): ST.Exp.Variable => F.makeExp('VariableStmt', {
    name,
    dataType,
    value
  }),
  makePageElementClass: (name: string, cid: string, props: ST.KwParams = {}, render: ST.Exp.RenderContext, metaInfo?: Agjs2.UIMetaInfo): ST.Exp.PageElementClass => F.makeT('PageElementClass', {
    name,
    cid,
    meta: metaInfo ?? {
      category: 'Unspecified',
      description: '',
      order: 0
    },
    props,
    render
  }),

  makePageElementClassType: (cid: string): ST.DT.PageElementClass => F.makeT('DataType', { type: 'PageElementClass', cid }),

  makeIterableProp: (name: string):
  ST.ParamGen<Agjs2.DTGen<'IterableList'>, any> =>
    F.makeParam(name,
      F.makePrimitiveDT('IterableList'),
      F.makeList([])
    ) as ST.ParamGen<Agjs2.DTGen<'IterableList'>, ST.Exp.IterableList>,

  // FIXME: ST.Exp.Lit.ListOfLit is not the correct type for the default attribute, should be PageElementList.
  makeChildrenProp: (children: ST.Exp.Render.PageElement[] = []):
  ST.ParamGen<Agjs2.DTGen<'PageElementList'>, ST.Exp.Lit.ListOfLit> =>
    F.makeParam('children',
      F.makePrimitiveDT('PageElementList'),
      F.makeList(children)
    ) as ST.ParamGen<Agjs2.DTGen<'PageElementList'>, ST.Exp.Lit.ListOfLit>,
  makeStylingProp: (name: string = 'styling') => F.makeParam(
    name,
    F.makePrimitiveDT('ElementStyling'),
    F.makeEmptyStyling()
  ),
  makeEmptyStyling: (): ST.Exp.ElementStyling => F.makeT('ElementStyling', {
    t: 'ElementStyling',
    funClasses: [],
    cssClasses: [],
    cssText: ''
  }),

  makeDefaultTheme: (): Agjs2.ThemeNode => {
    return {
      preset: 'default'
    }
  },

  makeDbColDef: (
    name: string,
    dataType: ST.DT.DbCompatible,
    notNull: boolean = false,
    unique: boolean = false): Agjs2.DbColDef => F.makeT('DbColDef', {
    name,
    pgName: toSnakeCase(name),
    dataType,
    notNull,
    unique
  }),

  makeUsersTable: (): Agjs2.DbTableDef => F.makeT('DbTableDef', {
    name: 'Users',
    pgName: toSnakeCase('Users'),
    columns: [
      F.makeDbColDef('Id', F.makePrimitiveDT('String')),
      F.makeDbColDef('Login', F.makePrimitiveDT('String'), false, true),
      F.makeDbColDef('EncryptedPassword', F.makePrimitiveDT('String')),
      F.makeDbColDef('Email', F.makePrimitiveDT('String')),
      F.makeDbColDef('CreatedAt', F.makePrimitiveDT('Timestamp'), true),
      F.makeDbColDef('UpdatedAt', F.makePrimitiveDT('Timestamp'), true)
    ]
  }),

  makeDbDescription: (): Agjs2.DbDescription => F.makeT('DbDescription', {
    tables: [F.makeUsersTable()],
    migrations: []
  }),

  makeProject: (name: string, pages: ST.Exp.Render.PageRootElement[] = []): Agjs2.Project => F.makeT('Project', {
    format: '1.0.0',
    name: name ?? '',
    pages,
    workflows: [],
    data: [],
    library: F.makeLibrary(),
    db: F.makeDbDescription(),
    imports: [F.makeImport('StdLib', 'v1.0.0', true)],
    theme: F.makeDefaultTheme()
  }),

  makeLibrary: (): Agjs2.Library => F.makeT('Library', {
    name: '',
    deletable: false,
    classDefs: [],
    objMethodDefs: [],
    globalMethodDefs: []
  }),

  makePageRoot: (name: string, opts: { states?: ST.Exp.Variable[] } = {}, children: ST.Exp.Render.PageElement[] = []): ST.Exp.Render.PageRootElement => F.makeT('RenderPageElement', {
    cid: 'PageRootClass',
    name,
    states: opts.states ?? [],
    isVisible: true,
    propValues: {
      workflows: F.makeList([]),
      children: F.makeList(children),
      url: F.makeString('/'),
      title: F.makeString('title')
    }
  }),

  makeWorkflow: (name?: string): Agjs2.Workflow => F.makeT('Workflow', {
    name: name ?? '',
    inputs: [],
    outputs: [],
    statements: []
  }),

  makeImport: (libraryId: Agjs2.nodeId,
    libraryVersionId: Agjs2.nodeId,
    noDelete: boolean): Agjs2.Import => F.makeT('Import',
    {
      libraryId,
      libraryVersionId,
      noDelete
    }),

  makeRenderContext: (exps: ST.Exp.Render.All[]): ST.Exp.RenderContext => F.makeExp('RenderContext', { items: exps }),
  makeRenderEval: (exp: ST.Exp.Expression): ST.Exp.Render.Eval => F.makeExp('RenderEval', { value: exp }),
  makeRenderHTMLTag: (tag: string,
    attributes: ST.Exp.Render.HTMLElement['attributes'],
    children: ST.Exp.Render.HTMLElement['children']): ST.Exp.Render.HTMLElement =>
    F.makeExp('RenderHTMLTag', {
      tag, attributes, children
    }),
  makeRenderPageElement: (cid: string, name: string,
    propValues: ST.Exp.KwArgs,
    children: ST.Exp.Render.PageElement[]): ST.Exp.Render.PageElement => {
    const allStates: ST.Exp.Variable[] = []
    const allPropValues: ST.Exp.Render.PageElement['propValues'] = {
      workflows: F.makeList([]),
      children: F.makeList(children),
      ...propValues
    }
    return F.makeExp('RenderPageElement', {
      cid, name, propValues: allPropValues, states: allStates, isVisible: true
    })
  }
}

const nullExp = F.makeNull()

export { F, randomId }
