import { ProjectConfig } from './index'
import { NodeIndex } from '../node_index'

export class OpCollection {
  config: ProjectConfig
  nodeIndex: NodeIndex

  constructor (config: ProjectConfig) {
    this.config = config
    this.nodeIndex = config.nodeIndex
  }
}
