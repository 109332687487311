import { ComponentChildren, Fragment, VNode } from 'preact'
import { useContext, useEffect, useRef } from 'preact/hooks'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'

import { F } from '../agjs/factory'
import { AppContext } from '../app_context'
import { Scope } from '../agjs/scope'
import { Agjs2, ST } from '../agjs/types'
import { randomId } from '../agjs_studio'
import { Ide } from '../types'
import { IconButton, UITextInput, UISelect, Icon } from '../pcomponents'

import { WorkflowInspector } from '../pcomponents/workflow_inspector'
import { ExpressionEditor } from '../pcomponents/ExpressionEditor'
import { ArgsModalDialog } from '../pcomponents/arguments_editor'
import { command, divider, MenuItem, subMenu } from '../pcomponents/menu'
import { ArrowCanvas, ArrowManager, CloseFn } from '../arrow_manager'
import { BenchDesk, SidebarContainer, WorkbenchViewWrapper } from '../pcomponents/workbench_ui'
import { openModal } from '../overlay_manager'
import { StandardDialog } from '../pcomponents/dialogs'
import { type Runtime } from '../agjs'
import { deepClone } from '../agjs/utils'

interface WFComponentParams {
  workflows: Agjs2.Workflow[]
  workflowId: Agjs2.nodeId
  onCreate: () => void
}

function nodeActionDescription (st: ST.NodeAction): string {
  const nodeName = st.nodeId
  switch (st.actionId) {
    case 'setInput':
      return `Set input value of ${nodeName}`
    default:
      return `(Node Action ${st.actionId})`
  }
}

function statementInfo (stmt: ST.Statement, runtime: Runtime): { title: string, description: string } {
  let title: string = `(${stmt.t})`
  let description = ''
  switch (stmt.t) {
    case 'GotoPage':
      title = `Go to page`
      break
    case 'Log':
      title = 'Log output to console'
      break
    case 'NodeAction':
      title = nodeActionDescription(stmt)
      break
    case 'CallMethodST':
      const m = runtime.nodeIndex.lookup(stmt.callExp.methodId) as ST.Exp.Method
      title = m.name
      description = m.meta?.description ?? ''
      break
  }
  return { title, description }
}

const StatementDescription = ({ stmt }: { stmt: ST.Statement }): VNode => {
  const { runtime } = useContext(AppContext)
  const d = statementInfo(stmt, runtime)

  return (
    <>
      <h6>{d.title}</h6>
      {d.description.length > 0 ? <span>{d.description}</span> : null}
    </>
  )
}

type MarkerType = 'start' | 'end' | 'both'

const Flow = {
  line: (x1: number, y1: number, x2: number, y2: number, marker?: MarkerType): VNode => {
    const props = { x1, y1, x2, y2 }

    if (marker != null) {
      const bothEnds = marker === 'both'
      if (marker === 'start' || bothEnds) props['marker-start'] = 'url(#arrowhead)'
      if (marker === 'end' || bothEnds) props['marker-end'] = 'url(#arrowhead)'
    }
    return <line {...props} />
  },

  vLine: (start: number, end: number, x: number, marker?: MarkerType): VNode => {
    return Flow.line(x, start, x, end, marker)
  },

  hLine: (start: number, end: number, y: number, marker?: MarkerType): VNode => {
    return Flow.line(start, y, end, y, marker)
  },

  // TODO: braucht noch attribut um festzulegen, wo die ecke sein soll.
  // besser polyline oder sowas benutzen?
  cornerLine: (x1: number, y1: number, x2: number, y2: number, marker?: MarkerType): VNode => {
    return <>{Flow.line(x1, y1, x2, y1)}{Flow.line(x2, y1, x2, y2, marker)}</>
  }
}

const FlowSVG = ({ children }: { children: ComponentChildren }): VNode => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='100px' height='60px'>
      <g stroke-width='8' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'>
        {children}
      </g>
      <defs>
        <marker markerWidth='5' markerHeight='5' refX='2.5' refY='2.5' orient='auto' id='arrowhead'>
          <polygon points='0,5 1.6666666666666667,2.5 0,0 5,2.5' fill='currentColor' />
        </marker>
      </defs>
    </svg>
  )
}

const StatementIcon = ({ stmt }: { stmt: ST.Statement }): VNode => {
  let icon: string = 'extension'
  let color = 'text-bg-warning'
  let flip = false

  switch (stmt.t) {
    case 'CallMethodST':
      color = 'text-bg-primary'
      icon = 'extension'
      break
    case 'IfElse':
      icon = 'alt_route'
      flip = true
      break
    case 'Block':
      icon = 'folder_open'
      break
    case 'SetState':
      icon = 'save'
      break
    case 'GotoPage':
    case 'GotoExtPage':
      icon = 'open_in_browser'
      break
    case 'Log':
      icon = 'feed'
      break
    case 'TriggerWorkflow':
      icon = 'feed'
      break
  }

  return (
    <div
      style={`height: 50px; width: 50px;${flip ? 'transform: rotate(180deg);' : ''}`}
      className={`d-inline-flex ${color} material-symbols-outlined fs-2 align-items-center justify-content-center rounded`}
    >
      {icon}
    </div>
  )
}

const WorkflowsAsTabs = observer(({ workflows, workflowId, onCreate }: WFComponentParams) => {
  const { store } = useContext(AppContext)

  interface TabParams {
    title: string
    active: boolean
    onClick?: () => void
    noDelete?: boolean
    onDelete?: () => void
  }
  const Tab = ({ title, active, onClick, noDelete, onDelete }: TabParams): VNode => {
    const cl = 'nav-link' + (active ? ' active' : '')

    return (
      <li className='nav-item'>
        <a href='#' className={cl} onClick={onClick}>{title}</a>
        {/*! noDelete && <IconButton icon={'delete'} title={'Delete statement'} light={true} onClick={onDelete}/> */}
      </li>
    )
  }

  return (
    <ul className='nav nav-tabs px-1 my-1'>
      {workflows.map((workflow, index) => <Tab
        key={workflow.id}
        title={workflow.name}
        active={workflow.id === workflowId}
        onDelete={() => store.project.op.generic.deleteGenericNode(workflow)}
        onClick={() => store.project.openWorkflow(workflow.id)}
                                          />)}
      <Tab title='+ Add workflow' active={false} noDelete onClick={onCreate} />
    </ul>
  )
})

export interface IStatementComp<ST> {
  stmt: ST
  scope: Scope
  onDelete: () => void
  onChange: (newStatement: ST.Statement) => void
}

const StName = ({ children }): VNode => <div class='text-xs'>{children}</div>

const GotoPageStatement = observer(({ stmt, onChange }: IStatementComp<ST.GotoPage>) => {
  const { store } = useContext(AppContext)

  const pl = [emptyItem('page')].concat(
    store.project.pages.map(page => ({
      id: page.id,
      label: page.name,
      value: page.id
    }))
  )

  const setPageId = (pageId: string): void => {
    const uStmt = toJS(stmt)
    uStmt.pageId = pageId
    onChange(uStmt)
  }

  return (
    <Fragment>
      <StName>Go to page</StName>
      <UISelect options={pl} selected={stmt.pageId} onSelect={setPageId} />
    </Fragment>
  )
})

function emptyItem (name: string) {
  return {
    id: '$none',
    label: `(Select a ${name})`,
    value: '$none'
  }
}

const NodeAction = observer(({ stmt, onChange, scope }: IStatementComp<ST.NodeAction>) => {
  const setExp = (exp: ST.Exp.Expression): void => {
    const uStmt = toJS(stmt)
    uStmt.arguments = [exp]
    onChange(uStmt)
  }

  const setNodeId = (nodeId: string): void => {
    const uStmt = toJS(stmt)
    uStmt.nodeId = nodeId
    onChange(uStmt)
  }

  const elements = (scope.parent != null)
    ? scope.parent.allSymbols().map(s => {
      if (s.node.t === 'RenderPageElement' && s.node.cid === 'InputElementClass') {
        return s.node
      } else { return null }
    }
    ).filter(e => e != null) as ST.Exp.Render.PageElement[]
    : []

  const inputList = elements.map(el => ({
    id: el.id,
    label: el.name,
    value: el.id
  }))

  inputList.unshift(emptyItem('input element'))

  return (
    <Fragment>
      <StName>NodeAction {stmt.actionId}</StName>
      <UISelect options={inputList} selected={stmt.nodeId} onSelect={setNodeId} />
      <ExpressionEditor scope={scope} exp={stmt.arguments[0]} onSubmit={setExp} light />
    </Fragment>
  )
})

const LogStatement = observer(({ stmt, onChange, scope }: IStatementComp<ST.Log>): VNode => {
  const setExp = (exp: ST.Exp.Expression): void => {
    const uStmt = toJS(stmt)
    uStmt.output = exp
    onChange(uStmt)
  }

  return (
    <Fragment>
      <StName>Output to log</StName>
      <ExpressionEditor scope={scope} exp={stmt.output} onSubmit={setExp} light />
    </Fragment>
  )
})

const WaitStatement = observer(({ stmt, onChange }: IStatementComp<ST.Wait>): VNode => {
  const setD = (ev): void => {
    const uStmt = toJS(stmt)
    uStmt.delay = Number.parseInt(ev.target.value)
    if (uStmt.delay > 0) {
      onChange(uStmt)
    }
  }

  return (
    <Fragment>
      <StName>Wait</StName>
      <UITextInput value={stmt.delay.toString()} onInput={setD} />
    </Fragment>
  )
})

const TriggerWorkflowStatement = observer(({ stmt, onChange, scope }: IStatementComp<ST.TriggerWorkflow>): VNode => {
  const pl = [
    emptyItem('workflow')
  ]

  scope.symbols.forEach((symbol, key) => {
    if ((symbol.node as Agjs2.Workflow).t === 'Workflow') {
      const wf = symbol.node as Agjs2.Workflow
      pl.push({
        id: wf.id,
        label: wf.name,
        value: wf.id
      })
    }
  })

  const setWfId = (newId: string): void => {
    const uStmt = toJS(stmt)
    uStmt.workflowId = newId
    onChange(uStmt)
  }

  return (
    <Fragment>
      <StName>Trigger workflow</StName>
      <UISelect options={pl} selected={stmt.workflowId} onSelect={setWfId} />
    </Fragment>
  )
})

const SetStateStatement = observer(({ stmt, onChange, scope }: IStatementComp<ST.SetState>): VNode => {
  const { runtime } = useContext(AppContext)

  const setExp = (exp: ST.Exp.Expression): void => {
    const uStmt = toJS(stmt)
    uStmt.assignment = exp
    onChange(uStmt)
  }

  let stateDT: ST.DT.TypeDef = F.makeAnyDT()

  const pl = ([
    emptyItem('state')
  ]).concat(runtime.nodeIndex.getStates().map(state => {
    const l = runtime.nodeIndex.location(state)
    const fullName = (runtime.nodeIndex.lookup(l.parentId) as Agjs2.NamedNode).name + '.' + state.name

    // FIXME: implement proper, this will not work when selecting a different state
    if (state.id === stmt.stateId) stateDT = state.dataType

    return ({
      id: state.id,
      label: fullName,
      value: state.id
    })
  }))

  const setStateId = (stateId: string): void => {
    const uStmt = toJS(stmt)
    uStmt.stateId = stateId
    onChange(uStmt)
  }

  return (
    <Fragment>
      <StName>Set</StName>
      <div><UISelect options={pl} selected={stmt.stateId} onSelect={setStateId} /></div>
      <StName> to </StName>
      <ExpressionEditor exp={stmt.assignment} scope={scope} requiredType={stateDT} onSubmit={setExp} light />
    </Fragment>
  )
})

const BlockStatement = observer(({ stmt, onChange, scope }: IStatementComp<ST.Block>): VNode => {
  const { store } = useContext(AppContext)

  const setVal = (slist: ST.Statement[]): void => {
    const uStmt = toJS(stmt)
    uStmt.statements = slist
    onChange(uStmt)
  }

  return (
    <Fragment>
      <div class='d-flex flex-column'>
        <StatementList store={store} scope={scope} statements={stmt.statements} onChange={setVal} onInsert={() => console.log('implement me')}/>
      </div>
    </Fragment>
  )
})

const StatementWrapper = ({ children }): VNode => (
  <div className='d-flex justify-content-center'>
    <div class='bg-base-4 border rounded-3 shadow' style='width: 350px;'>
      {children}
    </div>
  </div>
)

const IfElseStatement = observer(({ stmt, onChange, scope, onDelete }: IStatementComp<ST.IfElse>): VNode => {
  const { store } = useContext(AppContext)

  const stmtBox = useRef<HTMLDivElement>(null)
  const thenBox = useRef<HTMLDivElement>(null)
  const elseBox = useRef<HTMLDivElement>(null)
  const afterConditional = useRef<HTMLDivElement>(null)

  // const setCode = (slist: ST.Statement[]): void => {
  //   const uStmt = toJS(stmt)
  //   uStmt.then.statements = slist
  //   onChange(uStmt)
  // }

  const setCond = (exp: ST.Exp.Expression): void => {
    const uStmt = toJS(stmt)
    uStmt.condition = exp
    onChange(uStmt)
  }

  const configureArrows = (connect: boolean): undefined | (() => void) => {
    if (stmtBox.current == null || thenBox.current == null || elseBox.current == null || afterConditional.current == null) return

    if (connect) {
      am.connect(stmtBox.current, thenBox.current, 'left-center', 'top-center', 'none')
      am.connect(stmtBox.current, elseBox.current, 'right-center', 'top-center', 'none')

      am.connect(thenBox.current, afterConditional.current, 'bottom-center', 'left-center', 'none', 'hfirst')
      am.connect(elseBox.current, afterConditional.current, 'bottom-center', 'right-center', 'none', 'hfirst')
      am.redrawAllLines()
    } else {
      am.disconnect([stmtBox.current, thenBox.current, elseBox.current, afterConditional.current])
    }

    if (connect) return () => configureArrows(false)
  }

  useEffect(() => configureArrows(true), [])

  const StatementListContainer = ({ children }): VNode => <div className='' style='min-width: 350px;'>{children}</div>

  const ConditionalBranch = ({ branch }: { branch: 'then' | 'else' }): VNode | null => {
    if (stmt[branch] == null) return null

    const insertInBlock = (index: number | null, statement: ST.Statement): void => {
      console.log('using insertInBlock (creates a change instead of insert mutation)')
      // since the block is not in nodeIndex, we cannot address it right now... unless the key would
      // support indexes (does it?). So right now update the whole workflow's statement list.
      // store.project.op.generic.attrInsertListItem(stmt, 'statements', index, statement)
      const newList = toJS((stmt[branch] as ST.Block).statements)

      if (index == null) {
        newList.push(statement)
      } else {
        newList.splice(index, 0, statement)
      }

      changeBlock(newList)
    }

    const changeBlock = (slist: ST.Statement[]): void => {
      const uStmt = toJS(stmt)
      if (uStmt[branch] == null) return
      (uStmt[branch] as ST.Block).statements = slist
      onChange(uStmt)
    }

    return (
      <StatementList
        store={store}
        scope={scope}
        statements={(stmt[branch] as ST.Block).statements}
        onChange={changeBlock}
        onInsert={insertInBlock}
      />
    )
  }

  return (
    <div>
      <div className='d-flex justify-content-center'>
        <div ref={stmtBox}>
          <StatementWrapper>
            <div className='d-flex p-2'>
              <StatementIcon stmt={stmt} />
              <div className='ps-2 flex-fill'>
                <StatementDescription stmt={stmt} />
              </div>
              <div>
                <IconButton icon='delete' title='Delete statement' light onClick={() => onDelete()} />
              </div>
            </div>
            <StName>Condition</StName>
            <ExpressionEditor scope={scope} exp={stmt.condition} onSubmit={setCond} light />
          </StatementWrapper>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-start'>
        <div ref={thenBox}>
          <StatementListContainer>
            <div className='d-flex justify-content-center'><div className='badge text-bg-success'>TRUE</div></div>
            <ConditionalBranch branch='then' />
          </StatementListContainer>
        </div>
        <div style='width: 50px;'>x</div>
        <div ref={elseBox}>
          <StatementListContainer>
            <div className='d-flex justify-content-center'><div className='badge text-bg-danger'>FALSE</div></div>
            <ConditionalBranch branch='else' />
          </StatementListContainer>
        </div>
      </div>
      <div className='d-flex justify-content-center'>
        <div ref={afterConditional} className='d-inline-block'>
          <div style='width: 1.25rem; height: 1.25rem; background: var(--bs-gray-300);' className='rounded-circle select-none' />
        </div>
      </div>
    </div>
  )
})

const CallMethodStatementWidget = observer(({ stmt, onChange, scope }: IStatementComp<ST.CallMethodST>) => {
  const { runtime } = useContext(AppContext)

  const m = runtime.nodeIndex.lookup(stmt.callExp.methodId) as ST.Exp.Method

  const argsOnly: ST.Exp.ArgsOnly = {
    args: stmt.callExp.args,
    kwArgs: stmt.callExp.kwArgs,
  }

  const paramsOnly: ST.ParamsOnly = {
    parameters: m.parameters,
    kwParameters: m.kwParameters,
    returnType: m.returnType,
    typeVars: m.typeVars,
    meta: m.meta
  }

  const title = m.name

  const updateArgs = (newArgs: ST.Exp.ArgsOnly): void => {
    const newStmt = deepClone(stmt) as ST.CallMethodST
    newStmt.callExp.args = newArgs.args
    newStmt.callExp.kwArgs = newArgs.kwArgs

    if (!newArgs.incomplete) onChange(newStmt)
  }

  const openArgsModal = (): void => {
    openModal((closeFn: CloseFn) => (
          <ArgsModalDialog
            wrapper='dialog'
            closeFn={closeFn}
            argsOnly={argsOnly}
            paramsOnly={paramsOnly}
            title={`Edit ${title}`}
            onSubmit={updateArgs}
            scope={scope}
          />
          ))
  }

  return (
    <div className='btn btn-light' onClick={openArgsModal}>Configure</div>
  )
})

const EditableStatement = ({ stmt, onChange, onDelete, scope }: IStatementComp<ST.Statement>): VNode => {
  const { runtime } = useContext(AppContext)
  let st: VNode | null = null

  const unbufferedEditing = { onChange, scope, onDelete }

  let mode: 'popover' | 'direct' | 'modal' = 'modal'

  const title = statementInfo(stmt, runtime).title

  switch (stmt.t) {
    case 'IfElse':
      return <IfElseStatement stmt={stmt} {...unbufferedEditing} />
    case 'CallMethodST':
      st = <CallMethodStatementWidget stmt={stmt} {...unbufferedEditing} />
      mode = 'direct' // will render the popbutton in the widget, so will be a modal to edit the args.
      break
    case 'GotoPage':
      st = <GotoPageStatement stmt={stmt} {...unbufferedEditing} />
      mode = 'direct'
      break
    case 'Log':
      st = <LogStatement stmt={stmt} {...unbufferedEditing} />
      break
    case 'Block':
      st = <BlockStatement stmt={stmt} {...unbufferedEditing} />
      break
    case 'Wait':
      st = <WaitStatement stmt={stmt} {...unbufferedEditing} />
      break
    case 'SetState':
      st = <SetStateStatement stmt={stmt} {...unbufferedEditing} />
      break
    case 'TriggerWorkflow':
      st = <TriggerWorkflowStatement stmt={stmt} {...unbufferedEditing} />
      break
    case 'NodeAction':
      st = <NodeAction stmt={stmt} {...unbufferedEditing} />
      break
    default:
      st = <Fragment>{JSON.stringify(stmt)}</Fragment>
  }

  const openInModal = (): void => {
    openModal((closeFn: CloseFn) => (
      <StandardDialog title={`Edit ${title}`} onCancel={() => closeFn()} onSubmit={() => console.log('tbd! but was probably saved anyway')}>
        {st}
      </StandardDialog>
    ))
  }

  return (
    <StatementWrapper>
      <div className='d-flex p-2'>
        <StatementIcon stmt={stmt} />
        <div className='ps-2 flex-fill'>
          <StatementDescription stmt={stmt} />
        </div>
        <div>
          <IconButton icon='delete' title='Delete statement' light onClick={() => onDelete()} />
        </div>
      </div>
      <div className='p-2'>
        {mode === 'modal'
          ? (
            <div className='btn btn-light' onClick={openInModal}>Configure</div>
            )
          : (<>{st}</>
            )}
      </div>
    </StatementWrapper>
  )
}

const WorkflowCallerList = observer(({ wfId, store }: { wfId: string, store: Ide.App['store'] }): VNode => {
  const callers = store.project.nodeIndex.getEventsCallingWorkflow(wfId ?? 'totallynonlegitid').map(cl => {
    const el = store.project.nodeIndex.lookup(cl.elementId) as ST.Exp.Render.PageElement
    const gotoTrigger = (): void => {
      const page = store.project.nodeIndex.getParentPage(el)
      store.project.openPage(page.id, true)
      store.ui.focusPageElement(el)
    }
    return <a href='#' className='icon-link' onClick={gotoTrigger} key={cl.elementId}>{`${el.name}.${cl.propKey}`}<Icon icon='shortcut' /></a>
  })

  return (
    <div className={callers.length === 0 ? 'alert alert-warning' : 'alert alert-light'}>
      {callers.length === 0 ? 'This workflow is not used anywhere.' : <>Workflow is triggered by {callers}</>}
    </div>
  )
})

interface IStatementList {
  store: Ide.App['store']
  scope: Scope
  statements: ST.Statement[]
  wfId?: string
  onChange: (newStatementList: ST.Statement[]) => void
  onInsert: (index: number | null, newStatementList: ST.Statement) => void
}

const StatementList = observer(({ store, statements, onChange, onInsert, scope, wfId }: IStatementList): VNode => {
  // TODO: stattdessen ein onDelete call, so dass man außen als command auch 'lösche statement' statt change hat?
  const deleteStmt = (index: number): void => {
    const sList = toJS(statements)
    sList.splice(index, 1)
    onChange(sList)
  }

  const updateStmt = (newStmt: ST.Statement, index: number): void => {
    const sList = toJS(statements)
    sList[index] = newStmt
    onChange(sList)
  }

  const addStmt = (index: number | null, st: ST.Statement): void => {
    // const sList = toJS(statements) as ST.Statement[]
    // sList.push(st)
    // onChange(sList)
    onInsert(index, st)
  }

  const makeStmtPickerMenu = (index: number | null): MenuItem[] => {
    const items: MenuItem[] = [
      command('Trigger workflow', () => addStmt(index, { t: 'TriggerWorkflow', workflowId: '$none', id: randomId() })),
      command('Wait', () => addStmt(index, { t: 'Wait', delay: 100, id: randomId() })),
      // 'Repeat workflow': [() => addStmt({t: 'RepeatWorkflow', id: randomId()})],
      command('Set state', () => addStmt(index, { t: 'SetState', stateId: '$none', assignment: F.makeIncomplete(), id: randomId() })),
      command('Reset input', () => addStmt(index, { t: 'NodeAction', nodeId: '$none', actionId: 'setInput', arguments: [F.makeString('')], id: randomId() })),
      // 'Set variable': [() => addStmt(index, {t: 'SetVariable', stateId: '$none', assignment: F.makeIncomplete(), id: randomId()})],
      command('Log output', () => addStmt(index, { t: 'Log', level: 'info', output: { t: 'String', id: randomId('ex'), value: 'Hi' }, id: randomId() })),
      command('Block', () => addStmt(index, { t: 'Block', statements: [], id: randomId() })),
      command('If/Else', () => addStmt(index, {
        t: 'IfElse',
        then: { t: 'Block', statements: [], id: randomId() },
        else: { t: 'Block', statements: [], id: randomId() },
        condition: { t: 'Boolean', value: true, id: randomId() },
        id: randomId('st')
      })),
      command('Goto Page', () => addStmt(index, { t: 'GotoPage', pageId: '', id: randomId() })),
      divider()
    ]

    store.project.nodeIndex.runtime.libs.flattenedClassDefs().forEach(cd => {
      const methods: ST.Exp.Method[] = []
      if (cd.t === 'StaticClass') {
        cd.methods.forEach(m => {
          if (m.workflowStmt === true) methods.push(m)
        })
        if (methods.length > 0) {
          items.push(subMenu(cd.name, methods.map(m => command(m.name, () => addStmt(index, { t: 'CallMethodST', callExp: F.makeCallMethod(m.name, [], {}, m.id), id: randomId() })))))
        }
      }
    })

    // store.project.nodeIndex.runtime.libs.getStmtMethods().forEach(m => {
    //   items.push(command(m.name, () => addStmt(index, { t: 'CallMethod', callExp: F.makeCallMethod(m.name, [], {}, m.id), id: randomId() })))
    // })
    return items
  }

  const BetweenStatements = ({ nextIndex, marker }: { nextIndex: number | null, marker?: boolean }): VNode => {
    return (
      <div className='d-flex justify-content-center align-items-center' style='height: 60px'>
        <div className='position-absolute'>
          <FlowSVG>
            {Flow.vLine(0, 60, 50, marker === true ? 'end' : undefined)}
          </FlowSVG>
        </div>
        <div style='width: 2rem; height: 2rem;' className='position-absolute bg-secondary rounded-circle cursor-pointer select-none d-flex justify-content-center align-items-center' title='Insert statement' onClick={ev => store.ui.contextMenu(ev, () => makeStmtPickerMenu(nextIndex))}>
          <Icon icon='add' />
        </div>
      </div>
    )
  }

  useEffect(() => { console.log('redraw lines from statement list'); am.redrawAllLines() })

  return (
    <Fragment>
      {statements.map((stmt, index) => (
        <Fragment key={stmt.id}>
          <BetweenStatements nextIndex={index} marker />
          <EditableStatement stmt={stmt} scope={scope} onChange={newStmt => updateStmt(newStmt, index)} onDelete={() => deleteStmt(index)} />
        </Fragment>
      ))}
      <div className='text-center'>
        <BetweenStatements nextIndex={null} />
      </div>
    </Fragment>
  )
})

const WorkflowContent = observer(({ workflow, scope }: { workflow: Agjs2.Workflow | null, scope: Scope | null }): VNode => {
  const { store } = useContext(AppContext)

  if (workflow == null || scope == null) {
    return (
      <div className='p-1 flex-fill'>
        <div className='alert alert-info'>(no workflow selected)</div>
      </div>
    )
  }

  const updateStatements = (newStatements: ST.Statement[]): void => {
    // FIXME: remove typecast
    store.project.op.generic.setAttr(workflow, 'statements', newStatements as unknown as ST.Exp.PropValue)
  }

  const insertStatement = (index: number | null, statement: ST.Statement): void => {
    // FIXME: remove typecast
    store.project.op.generic.attrInsertListItem(workflow, 'statements', index, statement as unknown as ST.Exp.PropValue)
  }

  const statements = (workflow.statements as unknown) as ST.Statement[]

  // const compiler = new Compiler(runtime)
  // const source = compiler.compileWorkflow(workflow)

  return (
    <div className='bg-canvas p-2 flex-fill d-flex flex-column min-h-0 overflow-auto'>
      <div className='bg-canvas-dotted flex-fill position-relative'>
        <WorkflowCallerList wfId={workflow.id} store={store} />
        <ArrowCanvas manager={am} />
        <div className='position-relative'>
          <StatementList statements={statements} store={store} scope={scope} onInsert={insertStatement} onChange={updateStatements} wfId={workflow.id} />
        </div>
      </div>
    </div>
  )
})

const am = new ArrowManager()

export const WorkflowsWorkbench = observer((): VNode | null => {
  const { store } = useContext(AppContext)

  if (store.project.currentPage == null) return null

  const workflows = store.project.currentPage.propValues.workflows.items

  let currentWorkflowId = store.project.currentWorkflowId
  if (
    (currentWorkflowId != null && (workflows.find(wf => wf.id === currentWorkflowId) == null)) ||
     (currentWorkflowId == null)
  ) {
    currentWorkflowId = workflows[0]?.id
  }

  let wf: Agjs2.Workflow | null = null

  if (currentWorkflowId != null) wf = store.project.nodeIndex.lookup(currentWorkflowId) as Agjs2.Workflow

  const createWf = (): void => {
    const wf = store.project.op.workflow.createOnPage('', store.project.currentPageId ?? '')
    store.project.openWorkflow(wf.id)
  }

  const pageScope = (wf != null) ? store.project.nodeIndex.sc(wf) : null

  return (
    <WorkbenchViewWrapper>
      <BenchDesk className='d-flex flex-column flex-fill'>
        <WorkflowsAsTabs workflows={workflows} workflowId={currentWorkflowId} onCreate={createWf} />
        <WorkflowContent workflow={wf} scope={pageScope} />
      </BenchDesk>
      <SidebarContainer>
        <WorkflowInspector workflow={wf} />
      </SidebarContainer>
    </WorkbenchViewWrapper>
  )
})
