import BuiltIn from './builtin_lib'
import { Agjs2, ST } from './types'
import { Runtime } from './runtime'

export interface CategoryWithComponents {
  name: string
  components: Agjs2.ClassDef[]
}

/** Libs manages a list of libraries */
class Libs {
  runtime: Runtime
  list: Agjs2.Library[] = []

  constructor (runtime: Runtime) {
    this.runtime = runtime
    this.reset()
  }

  loadBuiltIn (): void {
    this.list.push({
      t: 'Library',
      id: 'builtinlib',
      deletable: false,
      name: 'BuiltIn',
      classDefs: BuiltIn.classDefs,
      objMethodDefs: BuiltIn.objMethodDefs,
      globalMethodDefs: BuiltIn.globalMethodDefs
    })
  }

  reset (): void {
    this.list = []
    this.loadBuiltIn()
  }

  builtIn (): Agjs2.Library {
    const builtinlib = this.list.find(lib => lib.id === 'builtinlib')
    if (builtinlib != null) return builtinlib
    throw new Error('BuiltIn lib not loaded')
  }

  add (library: Agjs2.Library): void {
    const index = this.list.findIndex(l => l.id === library.id)
    if (index > -1) {
      this.list[index] = library
    } else {
      this.list.push(library)
    }
  }

  findObjMethods (dataType: ST.DT.TypeDef): ST.Exp.ObjMethod[] {
    return this.runtime.tc.filterObjMethods(dataType, this.flattenedObjMethodDefs())
  }

  getClassDef (classId: string): Agjs2.ClassDef {
    if (classId == null) throw new Error('[getClassDef]: class name/ id not set')

    // TODO:
    // after project imports have been parsed, all classes should be part of nodeIndex!
    // so just lookup cid then from nodeIndex etc.

    // TODO: use a Map here (in Library) to speed things up
    const classDef = this.flattenedClassDefs().find(cd => cd.id === classId)

    if (classDef == null) {
      console.error('Unknown class:', classId)
      throw new Error(`getClassDef: Unknown class "${classId}"`)
    }
    return classDef
  }

  getLibraryFromClassDef (classId: string): Agjs2.Library {
    const libMatch = this.list.find(
      library => library.classDefs.find(
        cd => cd.id === classId
      )
    )

    if (libMatch == null) {
      console.error('No library found with class:', classId)
      throw new Error(`getLibraryFromClassDef: No lib with class "${classId}"`)
    }
    return libMatch
  }

  getObjMethodDef (id: string): ST.Exp.ObjMethod {
    if (id == null) throw new Error('[getObjMethodDef]: method name/ id not set')
    //
    // TODO: use a Map here (in Library) to speed things up
    const method = this.flattenedObjMethodDefs().find(m => m.id === id)

    if (method == null) {
      throw new Error(`getMethodDef: Unknown class "${id}"`)
    }
    return method
  }

  flattenedClassDefs (): Agjs2.ClassDef[] {
    return this.list.map(library => library.classDefs).flat()
  }

  flattenedObjMethodDefs (): ST.Exp.ObjMethod[] {
    return this.list.map(library => library.objMethodDefs).flat()
  }

  getStmtMethods (): ST.Exp.Method[] {
    const result: ST.Exp.Method[] = []
    this.flattenedClassDefs().forEach(comp => {
      if (comp.t === 'StaticClass') {
        comp.methods.forEach(m => {
          if (m.workflowStmt === true) result.push(m)
        })
      }
    })
    return result
  }

  classesByCategories (): CategoryWithComponents[] {
    const categories: CategoryWithComponents[] = []
    this.flattenedClassDefs().forEach(comp => {
      if (comp?.meta.category != null) {
        const catIdx = categories.findIndex(cat => cat.name === comp.meta.category)
        if (catIdx > -1) {
          categories[catIdx].components.push(Object.assign({}, comp))
        } else {
          categories.push({ name: comp.meta.category, components: [Object.assign({}, comp)] })
        }
      }
    })
    return categories
  }
}

export { Libs }
