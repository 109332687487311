import { expToJS } from '../agjs/expression'
import { h } from 'preact'
import * as Preact from 'preact'
import { Agjs2, ST } from '../agjs/types'
import { F } from '../agjs/factory'
import { toJS } from 'mobx'
import { Ide } from '../types'
import { observer } from 'mobx-react'
import { Runtime } from '../agjs/runtime'
import { generatePageElementContextMenuFunc } from './../helpers'

import { AppContext } from './../app_context'
import { useContext } from 'preact/hooks'
import { throttle } from './../agjs_studio'

import { referenceToPropValue } from './../agjs/utils'
import { generateElementStyling } from '../agjs/style_compiler'

const isRootElement = (e: ST.Exp.Render.PageElement): boolean => e.cid === 'PageRootClass'

export type GetOuterAttrsFunc = (element: ST.Exp.Render.PageElement) => Record<string, any>

type HTMLElementProps = Preact.JSX.HTMLAttributes
type HTMLDivElementProps = Preact.JSX.HTMLAttributes<HTMLDivElement>

interface IPageElement {
  elementNode: ST.Exp.Render.PageElement
  parent?: ST.Exp.Render.PageElement
  parentPropKey?: string
  notEditable?: boolean
  runtime: Runtime
}

// TODO have runtime and store passed down, so PageElement can be rendered of custom runtime/project combi later
// (for elements editor etc.)
// UI.setHover needs to be a custom callback for this too. (UI not needed).
// basically what is needed is a custom nodeIndex? (only to instantiate component?)
// ANYWAY: pass nodeIndex and setHover here so getOuterAttrs doesn't have any dependencies.
const getOuterAttrs: GetOuterAttrsFunc = (elementNode): HTMLElementProps => {
  const { store } = useContext(AppContext)

  const UI = store.ui

  const cl = ['_pe']
  const ide = store.project.nodeIndex.ca(elementNode) as Ide.CustomAttrs

  if (ide.hover) cl.push('pe-hover')
  if (ide.focus) cl.push('pe-focus')
  if (ide.dragTarget) cl.push('pe-dragtarget')

  if (isRootElement(elementNode) && ide.dragMarker != null) {
    if ((elementNode as ST.Exp.Render.PageRootElement).propValues.children.items.length === 0) {
      cl.push('_pe-placement-inside')
    }
  }

  if (ide.dragMarker?.marker != null) { cl.push(`_pe-placement-${ide.dragMarker.marker}`) }

  // This handler is correctly triggering on mouseOver instead of mouseEnter.
  // When using mouseEnter, it triggers back and forth when entering nested elements.
  // It also prevents the onClick from working at all, strangely.
  const mouseEnter = (ev: MouseEvent): void => {
    ev.stopPropagation()
    UI.setHover(elementNode)
  }

  const mouseLeave = (ev: MouseEvent): void => {
    ev.stopPropagation()
    UI.setHover(null)
  }

  const clickInstance = (ev: MouseEvent | FocusEvent): void => {
    ev.stopPropagation()
    UI.focusPageElement(elementNode)
  }

  const dragOverOpts = isRootElement(elementNode) ? { elementNode } : { parent: elementNode, parentPropKey: 'children' }
  const dragOverFn = (ev: DragEvent): void => { globalDragOver(ev, store, dragOverOpts) }

  let customAttrs: HTMLElementProps = {
    onDragOver: dragOverFn,
    onDrop: (ev: DragEvent) => dropAtDragTarget(ev, store),
    className: cl.join(' '),
    onMouseOver: mouseEnter,
    onMouseLeave: mouseLeave,
    onContextMenu: (ev: MouseEvent) => store.ui.contextMenu(
      ev,
      generatePageElementContextMenuFunc(elementNode, store),
      {
        iframe: document.querySelector('#page-editor-iframe') as HTMLIFrameElement
      }),
    tabIndex: 0,
    onFocus: clickInstance,
    onClick: clickInstance
  }

  if (!isRootElement(elementNode)) {
    customAttrs = {
      ...customAttrs,
      draggable: true,
      onDragStart: (event: DragEvent) => { event.stopPropagation(); store.ui.dndBuffer.startDragging(event, elementNode) },
      onDragEnd: () => { store.ui.dndBuffer.endDragging() }
    }
  }

  return customAttrs
}

// Drag/Drop handler code, consider moving to module

interface DragHandlerOpts {
  parent?: ST.Exp.Render.PageElement
  parentPropKey?: string
  elementNode?: ST.Exp.Render.PageElement
}

const throttledDragHandler = throttle((ev, opts: DragHandlerOpts, store: Ide.Store) => {
  resetDragOverTimeout(store)

  const setDragTarget = (...args: Parameters<typeof store.ui.dndBuffer.targetPageElement>): void => store.ui.dndBuffer.targetPageElement(...args)

  if (opts.parent == null && opts.elementNode == null && opts.parentPropKey == null) throw new Error('neither parent nor elementNode + propkey was set')

  // opts either have parentid/propkey and or elementNode.
  if (opts.parent == null) {
    if (opts.elementNode != null && isRootElement(opts.elementNode)) {
      const siblings = (opts.elementNode as ST.Exp.Render.PageRootElement).propValues.children.items
      setDragTarget(opts.elementNode, 'children', siblings[siblings.length - 1], 'bottom')
    } else {
      console.warn('I do not understand this dragover event: no parent but not root element.', toJS(opts.elementNode))
    }
  } else if (opts.elementNode != null) {
    // element node and parent exist, so insert at the parent/propkey (next to this element)

    // get coordinates relative to element dimensions.
    const rect = ev.target.getBoundingClientRect()
    const relX = (ev.x - rect.left) / rect.width
    const relY = (ev.y - rect.top) / rect.height

    const topLeft = ((1 - relX) - relY) >= 0
    const bottomRight = !topLeft
    const topRight = (relX - relY) >= 0
    const bottomLeft = !topRight

    // const d = 0.3
    // const topLeft = ((1-relX) - relY) >= d
    // const bottomRight = ((1-relX) - relY) < d
    // const topRight = (relX - relY) >= d
    // const bottomLeft = (relX - relY) < d

    let p: Ide.DragMarkerRef | null = null

    // create a deadzone to prevent flickering. if drag position is inside the deadzone,
    // the previous dragposition is taken.
    //
    // considerations: the deadzone should be as big as the size of the position marker attached to the
    // edge of the adjacent element.
    // the deadzone shouldn't be bigger than half the object size.
    const widestSidePx = Math.max(rect.width, rect.height)
    const shortestSidePx = Math.min(rect.width, rect.height)
    const markerWidthInPx = 15
    const minWidthPx = shortestSidePx / 2

    const d = Math.min(
      minWidthPx, markerWidthInPx
    ) / widestSidePx

    if (Math.abs(relX - relY) > d &&
        Math.abs((1 - relX) - relY) > d) {
      if (topLeft && topRight) { p = 'top' }
      if (bottomLeft && bottomRight) { p = 'bottom' }
      if (topLeft && bottomLeft) { p = 'left' }
      if (topRight && bottomRight) { p = 'right' }
    }

    if (p !== null) {
      setDragTarget(opts.parent, opts.parentPropKey, opts.elementNode, p)
    }
  } else {
    // ok element node fehlt hier.
    console.log('no element node, but parent, this is an empty child placeholder, so insert here.', opts)
    // no element node, but parent, this is an empty child placeholder, so insert here.
    setDragTarget(opts.parent, opts.parentPropKey)
  }
}, 100)

const globalDragOver = (ev: DragEvent, store: Ide.Store, opts: DragHandlerOpts): void => {
  ev.stopPropagation()
  ev.preventDefault() // otherwise onDrop event will not fire here.

  throttledDragHandler(ev, opts, store)
}

const dropAtDragTarget = (_ev: DragEvent, store: Ide.Store): void => {
  removeDragOverTimeout()
  store.ui.dndBuffer.dropOnTarget()
}

let lastTimerId: NodeJS.Timeout | null = null

function removeDragOverTimeout (): void {
  if (lastTimerId != null) {
    clearTimeout(lastTimerId)
  }
}

function resetDragOverTimeout (store: Ide.Store): void {
  if (lastTimerId != null) {
    clearTimeout(lastTimerId)
  }
  lastTimerId = setTimeout(() => {
    store.ui.dndBuffer.targetPageElement(null)
  }, 2500)
}

/* End drag/drop handler code (above) */

// const triggerEventCallback = (workflowRef: Agjs2.NodeRef): void => {
//   console.log(`triggered a workflow (via a prop with EventCallback data type). This is not implemented yet. Would call node ${workflowRef._name} / ${workflowRef.refId}`)
// }

const renderHTMLTag = (thisElement: ST.Exp.Render.PageElement, exp: ST.Exp.Render.HTMLElement, runtime: Runtime, opts: RenderingOpts): Preact.VNode<any> => {
  const attrs: HTMLElementProps = {}
  const elementStylings: ST.Exp.ElementStyling[] = []
  Object.keys(exp.attributes).forEach(key => {
    const attrExp = exp.attributes[key]
    // TODO: interpret this thoroughly... for now only static text matters?

    // FIXME: consolidate with renderEval maybe?
    const propName = referenceToPropValue(attrExp)
    let dt: ST.DT.TypeDef = F.makeUnknownType(attrExp)

    if (propName != null) {
      const content = thisElement.propValues[propName]
      const propDef = runtime.getPropDef(thisElement, propName)
      dt = propDef.dataTypes[0]

      // TODO: translate appgen data types into html attribute strings? for now
      // just separate callbacks and strings
      if (dt.type === 'EventCallback') {
        attrs[key] = () => {
          // TODO: implement triggering the callback (probably not in IDE mode):
          // triggerEventCallback(content as Agjs2.NodeRef)
          console.log(`RenderPageElement callback for ${thisElement.name}.${key} triggered.`)
        }
      } else if (attrExp.t === 'String') {
        // FIXME: kann das überhaupt sein? Wenn attrExp ein string ist, dann ist propName ja NULL
        // (denn nur wenn attrExp ein dotOp ist, dann ist propName gesetzt).
        console.log('Guess what, this never happens.')
        attrs[key] = expToJS(attrExp)
      } else {
        switch (dt.type) {
          case 'String':
            // TODO: have some kind of eval function that converts a literal to a JS value (surely does exist somewhere already?)
            attrs[key] = (content as ST.Exp.Lit.String).value
            break
          case 'ElementStyling':
            elementStylings.push(content as ST.Exp.ElementStyling)
            break
          default:
            console.log(`Unsupported data type for html attribute (via prop value ${propName})`, key, JSON.stringify(attrExp), `(content of referenced node: ${JSON.stringify(content)})`)
            break
        }
      }
    } else {
      // hier eigentl. noch mal die gleiche unterscheidung ob string ODER event callback.
      if (attrExp.t === 'String') {
        attrs[key] = expToJS(attrExp)
      } else {
        console.log('Unsupported data type for html attribute (in-place and not via prop value)', key, toJS(attrExp))
      }
    }
  })

  if (elementStylings.length > 0) {
    if (elementStylings.length > 1) throw new Error('More than one element styling property is currently not supported.')
    const { classNames, styles } = generateElementStyling(elementStylings[0], attrs.class?.toString() ?? '', attrs.style?.toString() ?? '')
    if (classNames.length > 0) attrs['class'] = classNames.join(' ')
    if (styles.length > 0) attrs.style = styles
  }

  // TODO: typ des props ist einfach ein ListDT mit itemtype RenderPageElement?
  /*
  if ((exp.children as ST.Exp.Render.PageElementListProp).t === 'RenderPageElementListProp') {
    return h(exp.tag, attrs, renderExpression(thisElement, exp.children as ST.Exp.Render.PageElement[], runtime))
  } else
  */

  if (Array.isArray(exp.children)) {
    return h(exp.tag, attrs as Record<string, any>, exp.children.map(child => renderExpression(thisElement, child, runtime, opts)))
  } // TODO: else when no children are set?
  console.log('Unknown conditions for renderHTMLTag:', exp)
  return <div>Unknown conditions for renderHTMLTag (see console)</div>
}

// TODO: hier auch den typ anpassen, array funzt hier wahrscheinlich nicht (RenderPageElement[])
// TODO: 17.4.23: 'exp' ist immer eine AGL-List expression, die dann RenderPageElement enthält....
const PageElementListPropWrapper = observer(({ thisElement, exp, runtime, notEditable = false }: { thisElement: ST.Exp.Render.PageElement, exp: ST.Exp.Render.PageElement[] | ST.Exp.Render.Eval, runtime: Runtime, notEditable: boolean }): Preact.VNode<any> | null => {
  const { store } = useContext(AppContext)
  // const path: string = runtime.formatter.expToString(exp.value)

  if (((exp as ST.Exp.Render.Eval).value).t === 'DotOp') {
    const evalExp = (exp as ST.Exp.Render.Eval)
    const dotOp: ST.Exp.DotOp = evalExp.value as ST.Exp.DotOp
    const gn = dotOp.left as ST.Exp.GetNode
    if (gn.t !== 'GetNode') { return <div>ERROR: PageElementListPropWrapper left side of dotop needs to be GetNode</div> }
    if (gn.refId !== '$thisElement') { return <div>ERROR: PageElementListPropWrapper left side of dotOp should reference $thisElement</div> }
    const ga = dotOp.right as ST.Exp.GetAttr
    if (ga.t !== 'GetAttr') { return <div>ERROR: PageElementListPropWrapper right side of dotop needs to be GetAttr</div> }

    const attrPath = ga._name

    // TODO: typecheck
    // 17.4.23
    const elements: ST.Exp.Render.PageElement[] = (thisElement.propValues[ga._name] as ST.Exp.PageElementList).items

    if (elements.length > 0) {
      return (
        <Preact.Fragment>{elements.map(el =>
          <PageElement
            key={el.id}
            elementNode={el}
            parent={thisElement}
            parentPropKey={attrPath}
            runtime={runtime}
            notEditable={notEditable}
          />)}
        </Preact.Fragment>
      )
    }

    // TODO: hookup with
    const ca = runtime.nodeIndex.ca(thisElement) as Ide.CustomAttrs
    const cl = ['_pe-placeholder-dropzone']
    // TODO: ist das korrekt so? oder enthält attr auch propValues.children anstatt nur 'children'?
    if ((ca.dragMarker != null) && ca.dragMarker.attr === attrPath) {
      cl.push('_pe-placement-inside')
    }

    return (
      <div
        class={cl.join(' ')}
        onDragOver={ev => globalDragOver(ev, store, { parent: thisElement, parentPropKey: attrPath })}
        onDrop={(ev) => dropAtDragTarget(ev, store)}
      >
        {`${thisElement.name}.${ga._name}`}
      </div>
    )
  } else {
    console.warn('Unsupported expression (was expecting DotOp):', exp)
    return null
  }
})

const renderEval = (thisElement: ST.Exp.Render.PageElement, exp: ST.Exp.Render.Eval, runtime: Runtime, opts: RenderingOpts): Preact.VNode => {
  // TODO:
  // 1. if it is a reference to a prop value, render prop value as source code and
  // prepare to make it editable in-place or link to that prop.
  // set data type from the prop type.
  //
  // if it isn't a reference to a prop value but an expression, evaluate this expression and
  // set data type from expression.
  //
  // 2. if data type is a page element or list of page elements, render them accordingly, else
  // render them as a string.
  const propName = referenceToPropValue(exp.value)

  let dt: ST.DT.TypeDef = F.makeUnknownType(exp.value)

  if (propName != null) {
    const content = thisElement.propValues[propName]
    const propDef = runtime.getPropDef(thisElement, propName)
    dt = propDef.dataTypes[0]

    if (['String', 'Text', 'Number', 'Boolean'].includes((content as ST.Exp.LiteralExpression).t)) {
      // TODO: be able to link this to an prop or so?
      return <Preact.Fragment>{(content as ST.Exp.Lit.Text).value}</Preact.Fragment>
    }

    if (dt.type === 'PageElementList') {
      // return <Preact.Fragment>(render children with childwrapper)</Preact.Fragment>
      // TODO evaluate prop??
      // hier ist aber ein child wrapper nötig, damit man weitere elemente hier hinzufügen kann.
      // hier muss auch ein direktes mapping zu einem prop existieren, damit der editor weiß, wo ein element eingefügt
      // werden soll!

      // TODO 17.4.23: hier muss doch statt exp der wert von content übergeben werden????

      return (
        <PageElementListPropWrapper
          thisElement={thisElement}
          exp={exp}
          runtime={runtime}
          notEditable={opts.notEditable ?? false}
        />
      )
    }

    if (dt.type === 'PageElementInstance') {
      return <Preact.Fragment>Returning a PageElementInstance from RenderEval is not implemented.</Preact.Fragment>
    }

    return <Preact.Fragment>{runtime.formatter.toString(content as ST.Exp.Expression)}</Preact.Fragment>
  } else {
    // Value is not referencing a prop, so gather datatype...
    // TODO: does this need the scope or so? (for when this render expression is inside a page element which in turn is
    // inside a class def?
    const dts = runtime.tc.inferType(exp.value)
    if (dts.length !== 1) return <Preact.Fragment>Multiple possible data types for RenderEval expression</Preact.Fragment>
    dt = dts[0]
    if (dt.type === 'PageElementList') return <Preact.Fragment>rendering children WITHOUT child wrapper or ability to edit (hardcoded render expression in classdef)</Preact.Fragment>
    if (dt.type === 'PageElementInstance') return <Preact.Fragment>Returning a PageElementInstance from RenderEval WITHOUT child wrapper not implemented</Preact.Fragment>

    // TODO: implement evaluation (interpreter!)
    return <Preact.Fragment>Evaluating: {runtime.formatter.toString(exp.value)}</Preact.Fragment>
  }
}

interface RenderingOpts {
  notEditable?: boolean
}
// TODO: vielleicht muss man an dieser stelle schon weg von react und direkt entweder compiltes HTML ausgeben oder was anderes?
const renderExpression = (thisElement: ST.Exp.Render.PageElement, exp: ST.Exp.Render.All, runtime: Runtime, opts: RenderingOpts = {}): Preact.VNode<any> => {
  switch (exp.t) {
    case 'RenderNothing':
      return <Preact.Fragment />
    case 'RenderEval': {
      return renderEval(thisElement, exp, runtime, opts)
    }
    case 'RenderPageElement':
      // hier kein childwrapper nötig (wird schon unten in PageElement gewrapped, d.h. wenn das PageElement auf oberster ebene aufgerufen wird).
      return (
        <PageElement
          elementNode={exp}
          parentPropKey=''
          runtime={runtime}
          notEditable={opts.notEditable}
        />
      )
    case 'RenderHTMLTag':
      return renderHTMLTag(thisElement, exp, runtime, opts)
    default:
      return <div>Unsupported: {exp.t}</div>
  }
}

const ElementIterator = observer(({ elementNode, parent, parentPropKey, runtime, notEditable = false }: IPageElement): Preact.VNode<any> => {
  const { store } = useContext(AppContext)

  // This will ALWAYS be cid=ElementIteratorClass anyway
  // const classDef = runtime.libs.getClassDef(elementNode.cid)

  // const tempInner = classDef.render.items.map(renderItem => renderExpression(elementNode, renderItem, runtime))
  const children = elementNode.propValues.children as ST.Exp.PageElementList
  // const tempInner = [1, 2, 3].map(item => renderExpression(elementNode, children.items, runtime))

  const exp = F.makeRenderEval(
    F.makeDotOp(
      F.makeGetNode('$thisElement'),
      F.makeGetAttr('children')
    ))

  const ds = elementNode.propValues.dataSource
  const noItemType = (ds.t === 'List' && ds.items.length === 0)

  const editableItem = noItemType
    ? (
      <div>Define a data source for ${elementNode.name} to get started.</div>
      )
    : (
      <PageElementListPropWrapper
        thisElement={elementNode}
        exp={exp}
        runtime={runtime}
        notEditable={notEditable}
      />
      )

  const exampleItems = [1, 2, 3].map(_item => {
    const renderedItem = (
      <div style='pointer-events: none; opacity: 0.5;'>
        {children.items.length > 0 ? children.items.map(child => <PageElement elementNode={child} key={child.id} parent={elementNode} parentPropKey='children' runtime={runtime} notEditable />) : <div>(Example Item)</div>}
      </div>
    )
    return renderedItem
  })

  const outerAttrs = getOuterAttrs(elementNode)

  return (
    <div
      {...outerAttrs}
      onDragOver={ev => globalDragOver(ev, store, { parent, parentPropKey, elementNode })}
      onDrop={(ev) => dropAtDragTarget(ev, store)}
    >
      {editableItem}
      {exampleItems}
    </div>
  )
})

// Braucht ein flag, ob elementNode direkt aus projekt markup kommt, dann props umwandeln in strings mit expToString, ansonsten (wenn dieses
// PageElement als teil von PageElementClass's renderExp gerendert wird, nicht (in dem Fall dann expressions evaluaten).
export const PageElement = observer(({ elementNode, parent, parentPropKey, runtime, notEditable = false }: IPageElement): Preact.VNode<any> => {
  if (isRootElement(elementNode)) {
    const children = (elementNode as ST.Exp.Render.PageRootElement).propValues.children.items

    // this is all bogus code, get rid later...
    const attrs: HTMLElementProps = notEditable ? {} : getOuterAttrs(elementNode)
    if (attrs.className == null) attrs.className = ''
    if (typeof attrs.className === 'string') {
      attrs.className = 'page-editor-page-root ' + attrs.className
    } else {
      throw new Error('totally unexpected non string type for className')
    }

    const wrappedChildren = (
      children.map(child => <PageElement
        key={child.id}
        elementNode={child}
        parent={elementNode}
        parentPropKey='children'
        runtime={runtime}
        notEditable={notEditable}
                            />)
    )

    if (wrappedChildren.length === 0) {
      return (
        <div {...(attrs as HTMLDivElementProps)} style='display: flex;'>
          <div className='_pe empty-page-placeholder'>Drag an element into this page to get started.</div>
        </div>
      )
    } else {
      return (<div {...(attrs as HTMLDivElementProps)}>{wrappedChildren}</div>)
    }
  } else if (elementNode.cid === 'ElementIteratorClass') {
    return <ElementIterator elementNode={elementNode} parent={parent} parentPropKey={parentPropKey} runtime={runtime} notEditable={notEditable} />
  } else {
    // this is the ACTUAL new rendering code:
    const classDef = runtime.libs.getClassDef(elementNode.cid)
    if (classDef.t !== 'PageElementClass') throw new Error(`${classDef.cid} is not a PageElementClass`)
    if (classDef.render === undefined) {
      console.log('legacy class:', classDef)
      return <div>[Unsupported Legacy PageElement] {elementNode.name}: {classDef.name}</div>
    }
    // TODO: hier entscheiden, ob expToString oder evaluation
    // hier noch mal wrapper mit getOuterAttrs???
    const { store } = useContext(AppContext)
    const tempInner = classDef.render.items.map(renderItem => renderExpression(elementNode, renderItem, runtime, { notEditable }))
    const outerAttrs: HTMLElementProps = notEditable ? { className: '' } : getOuterAttrs(elementNode)

    return (
      <div
        {...(outerAttrs as HTMLDivElementProps)}
        onDragOver={ev => globalDragOver(ev, store, { parent, parentPropKey, elementNode })}
        onDrop={(ev) => dropAtDragTarget(ev, store)}
      >
        {tempInner}
      </div>
    )
  }
})
