import { useContext, useState } from 'preact/hooks';
import { toJS, runInAction, observable  } from "mobx"
import { observer } from "mobx-react"

import { AppContext } from "../app_context"
import { Agjs2, ST } from '../agjs/types'
import { randomId } from '../agjs'
import { Ide } from '../types'
import { IconButton } from '../pcomponents'

import { ConstEditor } from '../pcomponents/const_editor';

const setViewPref = (dataType: ST.DT.TypeDef, pref: string) => {
  runInAction(() => dtPref[JSON.stringify(dataType)] = pref)
}

const getViewPref = (dataType: ST.DT.TypeDef) => {
  const oldPref = dtPref[JSON.stringify(dataType)]

  if (oldPref) return oldPref;

  if (dataType.type === 'List') {
    let preset = 'list'
    if (dataType.itemType.type === 'Record') {
      preset = 'table'
    }
    runInAction(() => dtPref[JSON.stringify(dataType)] = preset)
  }
  return dtPref[JSON.stringify(dataType)]
}

const dtPref = observable({})

interface IExpBrowser<DT extends ST.DT.TypeDef, ExpType extends ST.Exp.LiteralExpression> {
  dataType: DT
  exp: ExpType
  onChange: (exp: ExpType) => void
}


interface IRecordAsRow {
  dataType: ST.DT.RecordTypeDef
  recordExp: ST.Exp.Lit.RecordOfLit
  onSubmit: (exp: ST.Exp.LiteralExpression) => void
  onDelete: () => void
}

const RecordAsRow = ({ dataType, recordExp, onSubmit, onDelete }: IRecordAsRow) => {
  const storeEditedField = (exp: ST.Exp.LiteralExpression, fieldName: string) => {
    const newRecordExp = toJS(recordExp)
    newRecordExp.fields[fieldName] = exp
    onSubmit(newRecordExp)
  }

  return (
    <tr>
      {dataType.fields.map(field => <td class="border border-neutral-200 text-sm p-1">
        <DataBrowser name={field.name}
                     exp={recordExp.fields[field.name]}
                     dataType={field.dataType}
                     onChange={exp => storeEditedField(exp, field.name)} />
      </td>)}
      <td class="border border-neutral-200 py-1 pr-1">
        <IconButton icon={'delete'} title={'Delete row'} light={true} onClick={onDelete}/>
      </td>
    </tr>
  )
}

const ListViewPrefHeader = ({ dataType, pref, colSpan }) => {
  return (
    <th class="border border-neutral-200 text-sm py-1 pr-1 text-right" colSpan={colSpan}>
      <IconButton pressed={pref === 'table'} icon={'table_chart'} title={'View as table'} light={true} onClick={() => setViewPref(dataType, 'table')}/>
      <IconButton pressed={pref === 'list'} icon={'table_rows'} title={'View as list'} light={true} onClick={() => setViewPref(dataType, 'list')}/>
    </th>
  )
}

type IListBrowser = IExpBrowser<ST.DT.ListTypeDef, ST.Exp.Lit.ListOfLit>

const ListAsTableBrowser = observer(({ dataType, exp, onChange }: IListBrowser) => {
  if (dataType.itemType.type !== 'Record') throw 'ListOfRecordsBrowser expects a List with itemType Record as datatype'

  const [tempRow, setTempRow] = useState({})

  const items: ST.Exp.LiteralExpression[] = exp.items

  interface IEditableField {
    placeholder: string
    exp: ST.Exp.Lit.Primitive
    dataType: ST.DT.TypeDef
    onInput: (exp: ST.Exp.Lit.Primitive) => void
  }
  const EditableField = ({ placeholder, exp, dataType, onInput }: IEditableField) => {
    return (<td class="border border-neutral-200 text-sm">
      <ConstEditor placeholder={placeholder} editMode={true} exp={exp} dataType={dataType} onInput={onInput} />
    </td>)
  }

  const fields = dataType.itemType.fields

  const deleteRow = (index: number) => {
    const currentListExp = toJS(exp)
    const strippedList = currentListExp.items
    strippedList.splice(index, 1)
    onChange({ ...currentListExp, items: strippedList })
  }

  const addRow = () => {
    const currentListExp = toJS(exp)

    let newRowFields = {}
    fields.forEach(field => { newRowFields[field.name] = tempRow[field.name] })
    const rowExp: ST.Exp.Lit.RecordOfLit = {t: 'Record', id: randomId(), fields: newRowFields}

    const oneItemList = [rowExp]
    const newListExp = { ...currentListExp, items: currentListExp.items.concat(oneItemList) }
    onChange(newListExp)

    setTempRow({})
  }

  const updateRow = (rowExp: ST.Exp.LiteralExpression, index: number) => {
    const currentListExp = toJS(exp)
    currentListExp.items[index] = rowExp
    onChange(currentListExp)
  }
  const addCol = () => { console.log('aaa') }

  const tempRowValue = (fieldName: string, value: ST.Exp.LiteralExpression) => {
    tempRow[fieldName] = value
    setTempRow(tempRow)
  }

  let rowItems = items as ST.Exp.Lit.RecordOfLit[]

  return (<div>
    <table class="table-auto border-collapse border border-neutral-200">
      <thead>
        <tr><ListViewPrefHeader dataType={dataType} pref={'table'} colSpan={fields.length + 1}/></tr>
        <tr>
          {fields.map(field => <th class="border border-neutral-200 text-xs text-left p-1">{field.name}</th>)}
          <th class="border border-neutral-200 text-sm py-1 pr-1">
            <IconButton disabled={true} icon={'settings'} title={'Edit columns'} light={true} onClick={addCol}/>
          </th>
        </tr>
      </thead>
      <tbody>
        {rowItems.map((item, index) => <RecordAsRow
          recordExp={item}
          dataType={dataType.itemType as ST.DT.RecordTypeDef}
          onSubmit={exp => updateRow(exp, index)} onDelete={() => deleteRow(index)} />)}
        <tr>
          {fields.map(field => <EditableField exp={tempRow[field.name]}
                                              dataType={field.dataType}
                                              placeholder={field.name}
                                              onInput={newExp => tempRowValue(field.name, newExp)}/>)}
          <th class="border border-neutral-200 text-sm py-1 pr-1">
            <IconButton icon={'add'} title={'Add this row'} light={true} onClick={addRow}/>
          </th>
        </tr>
      </tbody>
    </table>
  </div>)
})


const ListBrowser = observer(({ dataType, exp, onChange }: IListBrowser) => {
  const { runtime } = useContext(AppContext) as Ide.App
  const newItemPreset = runtime.defaultValueForDataType(dataType.itemType)

  const [newItemExp, setNewItemExp] = useState(newItemPreset)

  const items: ST.Exp.LiteralExpression[] = exp.items

  const deleteItem = (index: number) => {
    const currentListExp = toJS(exp)
    const strippedList = currentListExp.items
    strippedList.splice(index, 1)
    onChange({ ...currentListExp, items: strippedList })
  }

  const addItem = () => {
    const currentListExp = toJS(exp)

    const newListExp = { ...currentListExp, items: currentListExp.items.concat([newItemExp]) }
    onChange(newListExp)
    setNewItemExp(newItemPreset)
  }

  const updateItem = (rowExp: ST.Exp.LiteralExpression, index: number) => {
    const currentListExp = toJS(exp)
    currentListExp.items[index] = rowExp
    onChange(currentListExp)
  }
  const addCol = () => { console.log('aaa') }

  const header = dataType.itemType.type === 'Record' ? (<thead>
    <tr>
      <ListViewPrefHeader dataType={dataType} pref={'list'} colSpan={3}/>
    </tr>
    </thead>) : null

  const RowNumber = ({ index, pending }) => (
    <th class={'border border-neutral-200 text-xs p-2 text-right ' + (pending ? 'text-neutral-300 italic' : 'text-neutral-500')}>
      {index + 1}
    </th>
  )

  const name = 'name not set (search me)'

  return (<div>
    <table class="table-auto border-collapse border border-neutral-200">
      {header}
      <tbody>
        {items.map((item, index) => <tr>
          <RowNumber index={index} pending={false} />
          <td class="border border-neutral-200 text-xs">
            <DataBrowser name={name} dataType={dataType.itemType} exp={item} onChange={newExp => updateItem(newExp, index)} />
          </td>
          <td class="border border-neutral-200 py-1 pr-1">
            <IconButton icon={'delete'} title={'Delete item'} light={true} onClick={() => deleteItem(index)}/>
          </td>
        </tr>)
        }
        <tr>
          <RowNumber index={items.length} pending={true} />
          <td class="border border-neutral-200 text-xs">
            <DataBrowser name={name} dataType={dataType.itemType} exp={newItemExp} onChange={newExp => setNewItemExp(newExp)} key={'newItem'}/>
          </td>
          <th class="border border-neutral-200 text-xs py-1 pr-1">
            <IconButton icon={'add'} title={'Add this row'} light={true} onClick={addItem}/>
          </th>
        </tr>
      </tbody>
    </table>
  </div>)
})

type TChangeCallback = (exp: ST.Exp.LiteralExpression) => void

interface IConstExpBrowser {
  dataType: ST.DT.TypeDef
  name: string
  exp: ST.Exp.LiteralExpression
  onChange: TChangeCallback
  key?: string
}

type ISingleRecordBrowser = IExpBrowser<ST.DT.RecordTypeDef, ST.Exp.Lit.RecordOfLit>

const SingleRecordBrowser = observer(({ dataType, exp, onChange }: ISingleRecordBrowser) => {
  const update = (fieldName: string, fieldExp: ST.Exp.LiteralExpression) => {
    const currentRecordExp = toJS(exp)
    currentRecordExp.fields[fieldName] = fieldExp
    onChange(currentRecordExp)
  }

  const RecordRow = ({ field, value }) => (
    <div class="my-1 ml-1 mb-2">
      <label class="text-xs text-neutral-700 mb-1">{field.name}</label>
      <div class="rounded-sm bg-white text-xs">
        <DataBrowser
          dataType={field.dataType}
          name={field.name}
          exp={value}
          onChange={newExp => update(field.name, newExp)} />
      </div>
    </div>
  )
  return (<div class="border border-2 border-neutral-200 rounded-sm bg-neutral-200 p-1">
    {dataType.fields.map(field => <RecordRow field={field} value={exp.fields[field.name]}/>)}
  </div>)
})

const PrimitiveDataTypeBrowser = observer(({ dataType, name, exp, onChange }: IConstExpBrowser) => {
  return <ConstEditor placeholder={name}
                     exp={exp as ST.Exp.Lit.Primitive}
                     dataType={dataType}
                     onSubmit={onChange} />
})
const DataBrowser = observer((props: IConstExpBrowser) => {
  const dataType = props.dataType
  switch(dataType.type) {
    case 'List': {
      const vp = getViewPref(props.dataType)
      const newProps =  {
        ...props,
        dataType,
        exp: props.exp as ST.Exp.Lit.ListOfLit
      }

      return vp === 'list' ?  <ListBrowser {...newProps} /> : <ListAsTableBrowser {...newProps} />
    }
    case 'Record':
      return <SingleRecordBrowser {...props} {...{ dataType, exp: props.exp as ST.Exp.Lit.RecordOfLit }}/>
    case 'String':
    case 'Text':
    case 'Number':
    case 'Boolean':
    return <PrimitiveDataTypeBrowser {...props} />
    default:
      return <div>No editor for {props.dataType.type} yet.</div>
  }
})

export { DataBrowser }
