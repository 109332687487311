import { FetchRequest } from '@rails/request.js'
import { MutationGroup } from './agjs/mutation_commands'
import { Agjs2 } from './agjs/types'
import { ProjectState } from './stores/project_state'

interface BackendResultWithError {
  status: 'error'
  errorCode: string
  errorDescription: string
}

interface BackendResultWithSuccess {
  status: 'success'
}

export type BackendResult = BackendResultWithError | BackendResultWithSuccess

interface PayloadCurrentMutationId {
  currentMutationId?: string
}

interface PayloadMutationGroup {
  mutationGroup?: MutationGroup
}

const resultSuccess = (): BackendResultWithSuccess => ({
  status: 'success'
})

const resultError = (code: string, description: string): BackendResultWithError => ({
  status: 'error',
  errorCode: code,
  errorDescription: description
})

export class BackendAPI {
  project: ProjectState

  projectUrl (endpoint: string): string {
    return `/api/projects/${this.project.projectId}/${endpoint}`
  }

  constructor (project: ProjectState) {
    this.project = project
  }

  async createDbTable (table: Agjs2.DbTableDef): Promise<BackendResult & PayloadMutationGroup> {
    const request = new FetchRequest(
      'POST',
      this.projectUrl('db_tables'),
      {
        body: JSON.stringify(
          {
            table_def_json: table,
            table: table.pgName,
            columns: table.columns.map(colDef => ({
              name: colDef.pgName,
              not_null: colDef.notNull,
              unique: colDef.unique,
              type: colDef.dataType.type
            }))
          }
        )
      })

    const response = await request.perform()
    if (response.ok != null) {
      try {
        const json = await response.json
        if (json.result === 'ok') {
          return { ...resultSuccess(), mutationGroup: json.data.mutationGroup }
        }
      } catch (e) {
        console.log('error creating new db table')
        return resultError('failed', 'Remote request completed, but result couldn\'t be parsed.')
      }
    }
    return resultError('failed', 'Remote request failed.')
  }

  async dropDbTable (table: Agjs2.DbTableDef): Promise<BackendResult & PayloadMutationGroup> {
    const request = new FetchRequest(
      'DELETE',
      this.projectUrl(`db_tables/${table.pgName}`))

    const response = await request.perform()
    if (response.ok != null) {
      try {
        const json = await response.json
        if (json.result === 'ok') {
          return { ...resultSuccess(), mutationGroup: json.data.mutationGroup }
        }
      } catch (e) {
        console.log('error dropping db table')
        return resultError('failed', 'Remote request completed, but result couldn\'t be parsed.')
      }
    }
    return resultError('failed', 'Remote request failed.')
  }

  async runAsBackendMutation (mutationId: string, direction: 'undo' | 'redo'): Promise<BackendResult & PayloadCurrentMutationId> {
    const request = new FetchRequest(
      'POST',
      this.projectUrl(`mutations/${mutationId}/backend-${direction}`)
    )

    const response = await request.perform()
    if (response.ok != null) {
      try {
        const json = await response.json
        if (json.result === 'ok') {
          return { ...resultSuccess(), currentMutationId: json.data.currentMutationGroupId }
        }
      } catch (e) {
        console.log('error delegating mutation group to backend')
        return resultError('failed', 'Remote request completed, but result couldn\'t be parsed.')
      }
    }
    return resultError('failed', 'Remote request failed.')
  }
}
