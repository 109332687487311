import { useContext, useState, useEffect } from 'preact/hooks';

import { AppContext } from "../app_context"
import { Agjs2, ST } from '../agjs/types'
import { F } from '../agjs/factory'
import { Ide } from '../types'
import {VNode} from 'preact';

interface IConstEditor<ExpType extends ST.Exp.LiteralExpression> {
  placeholder?: string
  editMode?: boolean
  exp: ExpType
  dataType: ST.DT.TypeDef
  onSubmit?: (exp: ExpType) => void
  onInput?: (exp: ExpType) => void
}

const ConstEditor = (props: IConstEditor<ST.Exp.Lit.Primitive>): VNode => {
  switch(props.dataType.type) {
    case 'Boolean':
      return <BooleanEditor {...props} exp={props.exp as ST.Exp.Lit.Boolean} />
    case 'String':
    case 'Text':
    case 'Number':
      return <StringOrNumberEditor {...props} exp={props.exp as ST.Exp.Lit.String}/>
  }
  throw new Error(`No ConstEditor for ${props.dataType.type}`)
}

const BooleanEditor = ({ placeholder, exp, dataType, onSubmit, onInput }: IConstEditor<ST.Exp.Lit.Boolean>) => {
  const { runtime } = useContext(AppContext) as Ide.App
  const prefilledExp = (exp || runtime.defaultValueForDataType(dataType)) as ST.Exp.Lit.Boolean

  let cl = 'shrink min-w-0 appearance-none bg-neutral-200 p-0_5 pl-1 rounded-sm text-xs text-neutral-800 focus:ring-2 focus:outline-focusblue'

  cl = "text-neutral-800 p-1"

  const select = (ev) => {
    // const newId = ev.target.value
    // const selectedItem = options.find(item => item.id === newId)
    // onSelect(selectedItem.value)
    const newExp: ST.Exp.Lit.Boolean = F.makeLit('Boolean', ev.target.value === 'true' ? true : false)
    onSubmit && onSubmit(newExp)
    onInput && onInput(newExp)
  }

  const strValue = prefilledExp.value === true ? 'true' : 'false'

  const options = [{label: 'True', value: 'true'}, {label: 'False', value: 'false'}]

  return (
    <select title={placeholder} class={cl} onInput={select}>
      {options.map(item => <option value={item.value} selected={item.value === strValue}>{item.label}</option>)}
    </select>
  )
}

type StringOrNumberExp = ST.Exp.Lit.String | ST.Exp.Lit.Number
const StringOrNumberEditor = ({ editMode, placeholder, exp, dataType, onSubmit, onInput }: IConstEditor<StringOrNumberExp> ) => {
  const { runtime } = useContext(AppContext) as Ide.App

  const [newExp, setNewExp] = useState<StringOrNumberExp>(exp || runtime.defaultValueForDataType(dataType) as StringOrNumberExp)
  const [editing, setEditing] = useState(!!editMode)

  const reset = () => {
    setNewExp(exp || runtime.defaultValueForDataType(dataType) as StringOrNumberExp)
    setEditing(!!editMode)
  }

  useEffect(() => reset(), [exp, dataType])

  let cl = 'shrink min-w-0 appearance-none bg-neutral-200 p-0_5 pl-1 rounded-sm text-xs text-neutral-800 focus:ring-2 focus:outline-focusblue'

  cl = "text-neutral-800 p-1"
  const inputProps = { placeholder } //{ value, placeholder, onInput, autofocus, ref }
  const keyhandler = (ev:KeyboardEvent) => {
    // if (ev.key === 'Escape' && onEsc) { ev.stopPropagation(); ev.preventDefault(); onEsc() }
    if (ev.key === 'Enter' && onSubmit) { ev.stopPropagation(); ev.preventDefault(); submit() }
  }

  const submit = () => {
    if(!editMode) setEditing(false)
    onSubmit && onSubmit(newExp)
  }

  const change = (ev) => {
    let newVal
    switch(dataType.type) {
      case 'Number':
        newVal = Number.parseFloat(ev.target.value) || 0
        break
      case 'String':
      case 'Text':
        newVal = ev.target.value
        break
      default:
        newVal = ev.target.value
    }
    const ex = { ...newExp, value: newVal }
    setNewExp(ex)
    onInput && onInput(ex)
  }

  const blur = () => submit()

  const strVal = newExp.value as string

  if (editing) {
    return (
      <input {...inputProps}
        onKeyDown={keyhandler}
        onInput={change}
        onBlur={blur}
        value={strVal}
        class={cl}
      />
    )
  } else {
    return (
      <input {...inputProps}
        readonly={true}
        onDblClick={() => setEditing(true)}
        value={strVal}
        class={'focus:outline-none cursor-pointer ' + cl}
      />
    )
  }
}

export { ConstEditor }
