import { Agjs2, ST } from '../types'
import { F } from '..'
import { MGAttrSet, MGConstCreate, MGConstDelete, MGPageElementDelete, MGPropValueSet, MGStateDelete, MGWorkflowDelete } from '../mutation_commands'
import { OpCollection } from './op_collection'
import { ModifyProjectParams } from '.'
import { toJS } from 'mobx'
import { getAttr } from '../utils'

export class GenericOp extends OpCollection {
  deleteGenericNode (node: Agjs2.IndexableNode, opts: ModifyProjectParams = {}): void {
    switch (node.t) {
      case 'RenderPageElement':
        this.config.history.createDeleteGroup<MGPageElementDelete>('page.element.delete', node)
        break
      case 'VariableStmt': {
        const loc = this.config.nodeIndex.location(node)
        if (loc.parentAttr === 'states') {
          // Node is most likely part of an element (so custom state)
          this.config.history.createDeleteGroup<MGStateDelete>('state.delete', node)
        } else {
          this.config.history.createDeleteGroup<MGConstDelete>('const.delete', node)
        }
        break
      }
      case 'Workflow': {
        const refs = this.config.nodeIndex.selectNodes('WorkflowRef', { refId: node.id })
        console.log('TODO: warn/clean these references: the following places point to this workflow:', refs.map(r => [toJS(r.parent), toJS(r.node)]))
        this.config.history.createDeleteGroup<MGWorkflowDelete>('workflow.delete', node)
        break
      }
      default:
        throw new Error(`Unsupported node type for delete mutation: ${node.t}`)
    }

    if (opts.success != null) opts.success(node)
  }

  createConst (name: string, dataType: ST.DT.TypeDef, opts: ModifyProjectParams = {}): void {
    const newConst: ST.Exp.Variable = F.makeVariable(name,
      dataType,
      this.config.runtime.defaultValueForDataType(dataType)
    )

    this.config.history.createInsertGroup<MGConstCreate>('const.create',
      { name: newConst.name, id: newConst.id }, {
        parentId: this.nodeIndex.project.id,
        parentAttr: 'data',
        index: this.nodeIndex.project.data.length
      }, newConst)

    if (opts.success != null) opts.success(newConst)
  }

  renameElement (element: Agjs2.NamedNode, newName: string): void {
    this.config.history.createRenameGroup(element, newName)
  }

  setProp (element: Agjs2.InstanceNode, propId: Agjs2.nodeId, newExp: ST.Exp.PropValue): void {
    this.config.history.createChangeGroup<MGPropValueSet>('propvalue.set', {
      propName: propId,
      propId,
      parentName: element.name,
      parentId: element.id
    },
    element,
    `propValues.${propId}`,
    newExp
    )
  }

  setAttr (element: Agjs2.InstanceNode | Agjs2.Workflow | ST.Exp.Variable, attrPath: Agjs2.nodeId, newExp: ST.Exp.PropValue | ST.DT.TypeDef): void {
    let attrDescription = attrPath
    if (attrPath === 'dataType') attrDescription = 'data type'
    this.config.history.createChangeGroup<MGAttrSet>('attr.set', {
      attrName: attrDescription,
      attrPath,
      parentName: element.name,
      parentId: element.id
    },
    element, attrPath, newExp
    )
  }

  // currently not used anywhere, only use case was replaced by using attrInsertListItem below. But function is ready to go if needed.
  //
  // attrSetListItem (element: Agjs2.InstanceNode | Agjs2.Workflow | ST.Exp.Variable, attrPath: Agjs2.nodeId, index: 'before' | 'after' | number, newExp: ST.Exp.PropValue): void {
  //   const newList = toJS(getAttr(element, attrPath)) as ST.Exp.ListGen<typeof newExp>

  //   switch (index) {
  //     case 'before':
  //       newList.items.unshift(newExp)
  //       break
  //     case 'after':
  //       newList.items.push(newExp)
  //       break
  //     default:
  //       newList.items[index] = newExp
  //   }

  //   this.config.history.createChangeGroup<MGAttrSet>('attr.set', {
  //     attrName: attrPath,
  //     attrPath,
  //     parentName: element.name,
  //     parentId: element.id
  //   },
  //   element, attrPath, newList
  //   )
  // }

  /** Insert newExp into an attr holding an array (not AGL List).
   * @param index has same meaning as in the JavaScript splice function (newExp will be available at the given index. The element that was previously at this
   * index will come after newExp. So essentially, the element is inserted before index.
   * Negative values are inserted from the end of the array. It can also be set to null, in that case, the element is pushed to the array.
   */
  attrInsertListItem (element: Agjs2.InstanceNode | Agjs2.Workflow | ST.Exp.Variable, attrPath: Agjs2.nodeId, index: number | null, newExp: ST.Exp.PropValue): void {
    const updatedArray = toJS(getAttr(element, attrPath)) as Array<typeof newExp>

    if (index == null) {
      updatedArray.push(newExp)
    } else {
      updatedArray.splice(index, 0, newExp)
    }

    this.config.history.createChangeGroup<MGAttrSet>('attr.set', {
      attrName: attrPath,
      attrPath,
      parentName: element.name,
      parentId: element.id
    },
    element, attrPath, updatedArray
    )
  }
}
