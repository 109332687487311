import { Utils } from '..'
import { Agjs2, ST } from '../types'
import { InsertElementParams, MoveElementParams, ModifyProjectParams } from './index'
import { randomId, F } from '../factory'
import { MoveMutation, MGPageCreate, MGPageElementCreate, MGPageElementMove, MGStateCreate, mutationDigest } from '../mutation_commands'
import { OpCollection } from './op_collection'
import { formatAsElementName } from '../string_utils'

export class PageOp extends OpCollection {
  newPageName (): string {
    return this.nodeIndex.newName('Page', { minNumber: this.nodeIndex.project.pages.length + 1 })
  }

  newElementName (target: Agjs2.NodeLocation, prefix: string): string {
    const parent = this.nodeIndex.lookup(target.parentId)
    const sc = this.nodeIndex.sc(parent)
    return this.nodeIndex.newName(formatAsElementName(prefix), { scope: sc })
  }

  create (name: string, opts: Partial<InsertElementParams>): void {
    const newPageId = randomId()
    const newPage = this.config.runtime.setDefaultProps(F.makePageRoot(name))
    newPage.id = newPageId
    newPage.propValues.url = F.makeString(`/${name.toLowerCase()}`)
    newPage.propValues.title = F.makeString(`Title of ${name}`)

    this.config.history.createInsertGroup<MGPageCreate>('page.create',
      { name: newPage.name, id: newPage.id }, {
        parentId: this.nodeIndex.project.id,
        parentAttr: 'pages',
        index: this.nodeIndex.project.pages.length
      }, newPage)

    // this.nodeIndex.mountPage(newPage)
    if (opts.success != null) opts.success(newPage)
  }

  createElement (cid: string, opts: InsertElementParams): void {
    const parent = this.nodeIndex.lookup(opts.target.parentId) as Agjs2.NamedNode
    const pageElementTemplate: ST.Exp.Render.PageElement = {
      t: 'RenderPageElement',
      cid,
      id: randomId(),
      name: this.newElementName(opts.target, opts.namePrefix),
      isVisible: true,
      states: [],
      // TODO: element templates (coming right from the library)
      propValues: (opts.propValues != null ? opts.propValues : {})
    }

    const newElement = this.config.runtime.setDefaultProps(pageElementTemplate)

    this.config.history.createInsertGroup<MGPageElementCreate>(
      'page.element.create',
      {
        id: newElement.id,
        elementName: newElement.name,
        parentName: parent.name,
        parentId: parent.id
      },
      opts.target,
      newElement
    )

    // FIXME: Dieser code ist korrekt ABER muss doch eigentlich ausgeführt werden, wenn die MutationGroup
    // applied wird? (denn bei redo() wird ja nicht createElement aufgerufen, aber die MutationGroup abgearbeitet.
    // const parentScope = this.nodeIndex.sc(parent)
    // this.nodeIndex.mount(newElement, opts.target, parentScope)
    if (opts.success != null) opts.success(newElement)
  }

  deleteElement (el: ST.Exp.Render.PageElement, opts: ModifyProjectParams = {}): void {
    this.config.getOp().generic.deleteGenericNode(el, opts)
  }

  delete (page: ST.Exp.Render.PageRootElement, opts: ModifyProjectParams = {}): void {
    this.config.getOp().generic.deleteGenericNode(page, opts)
  }

  moveElement (elementNode: Agjs2.Node, opts: MoveElementParams): void {
    this.config.history.createMoveGroup<MGPageElementMove>(
      'page.element.move',
      null,
      elementNode,
      opts.target
    )
    if (opts.success != null) opts.success(elementNode)
  }

  createCustomState (name: string, dataType: ST.DT.TypeDef, element: ST.Exp.Render.PageElement): void {
    const newConst: ST.Exp.Variable = F.makeVariable(name,
      dataType,
      this.config.runtime.defaultValueForDataType(dataType)
    )

    this.config.history.createInsertGroup<MGStateCreate>('state.create', {
      name,
      id: newConst.id
    }, {
      parentId: element.id,
      parentAttr: 'states',
      index: element.states.length
    }, newConst)
    // this.openDataItem(newConst.id)
  }
}
