import * as Preact from 'preact'
import { Agjs2, ST } from '../agjs/types'
import { Ide } from '../types'
import { App } from '../app_context'
import { Clipboard } from '../clipboard'
import { DndBuffer } from './dnd_buffer'
import { getAttr } from '../agjs/utils'
import { makeAutoObservable, runInAction, observable, action, toJS } from 'mobx'
import { ToastManager } from './toast_manager'
import { CloseFn, openModal } from '../overlay_manager'
import { dialogs } from '../pcomponents/dialogs'
import { openContextMenu, MenuGenerator } from '../pcomponents/menu'

interface IDialogState {
  timerId: ReturnType<typeof setTimeout> | null
  uiBlocks: CloseFn[]
}

interface BlockUIOpts {
  onCancel?: () => void
  delay?: number
}

interface IContextMenuOpts { iframe?: HTMLIFrameElement }

interface TPageEditor {
  highlightedElement: ST.Exp.Render.PageElement | null
  focusedElement: ST.Exp.Render.PageElement | null
}

export class UiState {
  dockables: Map<string, Ide.IDockableState> = new Map()

  pageEditor: TPageEditor = {
    highlightedElement: null,
    focusedElement: null
  }

  // .struct makes sure observer won't be signaled unless the
  // dimensions object changed in a deepEqual manner.
  windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight
  }

  app: App
  dialog: IDialogState = {
    uiBlocks: [],
    timerId: null,
  }

  // appgen specific:
  activeWorkbench: Ide.WorkspaceIds = 'page'
  wbList = []

  dndBuffer: DndBuffer

  toastManager: ToastManager

  constructor (app: App) {
    makeAutoObservable(this, { store: false, getDockableState: action })

    this.app = app
    this.toastManager = new ToastManager()

    this.dndBuffer = new DndBuffer(this.app)

    this.loadWorkbenchFromUrl(window.location.toString())
  }

  getDockableState (dockableName: string, parentContainer?: string): Ide.IDockableState {
    let result = this.dockables.get(dockableName)
    if (result == null) {
      result = observable({
        name: dockableName,
        floating: !parentContainer,
        moving: false,
        attachTo: 'left' as ('left' | 'right'),
        order: 0,
        parentContainer,
        potentialParent: null,
        width: 0,
        height: 0,
        oldX: 0,
        oldY: 0,
        startOffsX: 0,
        startOffsY: 0,
        x: 0,
        y: 0
      }) as Ide.IDockableState
      this.dockables.set(dockableName, result)
    }
    return result
  }

  /*
  setDockableState(dockableName: string, updates: Partial<Ide.IDockableState>) {
    this.dockables.set(dockableName, observable({...result, ...updates}))
  }
 */

  getDockedWindows (parentContainer: string): Ide.IDockableState[] {
    // TODO: check optimization with computed()
    return Array.from(this.dockables.values()).filter(dw => dw.parentContainer === parentContainer)
  }

  getMovingDockable (): Ide.IDockableState | null {
    return Array.from(this.dockables.values()).find(ds => ds.moving) ?? null
  }

  showInfoDialog (message: string) {
    openModal(dialogs.genInfo(message))
  }

  contextMenu (event: MouseEvent, menuGenerator: MenuGenerator, opts: IContextMenuOpts = {}) {
    event.stopPropagation()
    event.preventDefault()

    let relX = 0
    let relY = 0

    if (opts.iframe != null) {
      const rel = opts.iframe.getBoundingClientRect()
      const iframeBody = opts.iframe.contentWindow?.document.body
      relX = rel.left - (iframeBody?.scrollLeft ?? 0)
      relY = rel.top - (iframeBody?.scrollTop ?? 0)
    }

    openContextMenu(
      menuGenerator,
      event.pageX + relX,
      event.pageY + relY
    )
  }

  openConstEditor (item: ST.Exp.Variable) {
    openModal(dialogs.genEditConst(item))
  }

  openNewPropDefEditor (classDef: Agjs2.ClassDef) {
    openModal(dialogs.genNewPropDef(classDef))
  }

  openCustomStateEditor (element: ST.Exp.Render.PageElement) {
    // Is this a potential memory leak when a reference to the current element
    // (which is the currently focused one) is stored and then lets say we press undo while the dialog is open?
    openModal(dialogs.genNewElementState(element))
  }

  showCustomStateEditor (state: ST.Exp.Variable) {
    openModal(dialogs.genEditState(state))
  }

  truncateData (item: ST.Exp.Variable) {
    // TODO: sollte doch in project oder??
    console.log(item.value)
  }

  runCommand (command: string) {
    switch (command) {
      case 'NEW_PAGE':
        openModal(dialogs.genNewPage())
        break
      case 'NEW_WB_ITEM':
        switch (this.activeWorkbench) {
          case 'page':
            openModal(dialogs.genNewPage())
            break
          case 'data':
            openModal(dialogs.genNewConst())
            break
        }
        break
      case 'DELETE':
        if (this.activeWorkbench == 'page' && (this.pageEditor.focusedElement != null)) {
          const focusedUI = document.activeElement
          // This checks if element is also focused in browser. Works, but unnecessarily strict, so just the iframe needs to be focused instead.
          // if (focusedUI.tagName === 'IFRAME' && focusedUI.contentDocument.activeElement.dataset.nodeId === this.pageEditor.focusedElement.id) {
          if (focusedUI?.tagName === 'IFRAME') {
            this.store.project.op.page.deleteElement(this.pageEditor.focusedElement)
          }
        }
        break
      default:
        console.log('Unknown UI command', command)
    }
  }

  get store () {
    return this.app.store
  }

  ca (elementNode: Agjs2.InstanceNode): Ide.CustomAttrs {
    return this.store.project.nodeIndex.ca(elementNode) as Ide.CustomAttrs
  }

  openDevConsole () {
    openModal(dialogs.genDevConsole())
  }

  /**
   * Immediately opens in invisible overlay to prevent the user from interacting with the UI.
   * Only if the hide command hasn't been called in time, a visible message is shown
   * as well */
  blockUI (message: string, opts: BlockUIOpts = {}): void {
    this.dialog.timerId = setTimeout(() => {
      const closeBlock = openModal(dialogs.genSpinner(message))
      runInAction(() => {
      this.dialog.uiBlocks.push(closeBlock)
      })
    }, opts.delay ?? 0)
  }

  unblockUI (): void {
    // have the option to have timerId == null to work around TS issues with nodejs vs. browser dom JS
    if (this.dialog.timerId != null) clearTimeout(this.dialog.timerId)
    this.dialog.uiBlocks.forEach(closeBlock => closeBlock())
    this.dialog.uiBlocks = []
  }

  pasteInto (elementNode: ST.Exp.Render.PageElement, mode: 'text' | 'html' | 'auto' = 'auto') {
    this.store.ui.showInfoDialog(`TODO: Restore clipboard functionality`)
    return;
    const clip = new Clipboard({ store: this.store })
    let parentId: Agjs2.nodeId
    let parentAttr: Agjs2.nodeId
    let index: number
    if (this.app.runtime.isRootElement(elementNode)) {
      parentId = elementNode.id
      parentAttr = 'propValues.children.items'
      index = (getAttr(elementNode, parentAttr) as ST.Exp.Render.PageElement[]).length
    } else {
      const loc = this.store.project.nodeIndex.location(elementNode)
      const parent = this.store.project.nodeIndex.lookup(loc.parentId) as ST.Exp.Render.PageElement

      // folgende zeile eingefügt um TS zum laufen zu bekommen, aber ist das korrekt?
      // parentAttr war vorher nicht gesetzt (null). wahrscheinlich ok, wandert letztendlich in mutation.createInsert oder so
      parentAttr = 'propValues.children.items'
      parentId = loc.parentId
      // FIXME 17.4.23 wird so nicht funzen- da children attribut jetzt nicht mehr ein array ist.
      index = (getAttr(parent, loc.parentAttr) as ST.Exp.PageElementList).items.indexOf(elementNode)
    }

    if (mode === 'text') {
      clip.pasteAsText({ parentId, parentAttr, index })
    } else {
      clip.paste({ parentId, parentAttr, index })
    }
  }

  setHover (elementNode: ST.Exp.Render.PageElement | null) {
    runInAction(() => {
      if (this.pageEditor.highlightedElement != null) {
        this.ca(this.pageEditor.highlightedElement).hover = false
      }
      if (elementNode != null) {
        this.ca(elementNode).hover = true
      }
      this.pageEditor.highlightedElement = elementNode
    })
  }

  focusPageElement (elementNode: ST.Exp.Render.PageElement) {
    if (this.pageEditor.focusedElement != null) {
      this.ca(this.pageEditor.focusedElement).focus = false
    }
    this.ca(elementNode).focus = true
    this.pageEditor.focusedElement = elementNode
  }

  openWorkbench (wb: Ide.WorkspaceIds) {
    if (wb === this.activeWorkbench) return
    window.location.hash = `#${wb}`
  }

  loadWorkbenchFromUrl (newURL: string) {
    const wb = new URL(newURL).hash.substring(1)
    if (['page', 'data', 'workflows', 'elements'].includes(wb)) {
      this.activeWorkbench = wb as Ide.WorkspaceIds
    }
  }
}
