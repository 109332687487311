import { DocSnippet, NodeIndex } from '../agjs/node_index'
import { Agjs2 } from '../agjs/types'
import { render, VNode } from 'preact'
import { useState } from 'preact/hooks'

interface IFunuruDocPage {
  doc: DocSnippet
}

export const FunuruDocPage = ({ doc }: IFunuruDocPage): VNode => {
  const [history, setHistory] = useState<DocSnippet[]>([doc])
  const [pos, setPos] = useState(0)

  const getDoc = (): DocSnippet => history[pos]

  const mkVisitFn = (newDoc: DocSnippet): (ev: MouseEvent) => void => {
    return (ev: MouseEvent) => {
      console.log('going further...')
      ev.stopPropagation()
      ev.preventDefault()
      setHistory(history.concat(newDoc))
      setPos(pos + 1)
    }
  }

  const MethodList = (): VNode => (
    <>
      <h6>Methods</h6>
      <p>{getDoc().methods.map(m => <a href='#' onClick={mkVisitFn(m.getTarget())}>{m.text}</a>)}</p>
    </>
  )

  const MiniNav = (): VNode | null => (
    <div className='bg-base-1 d-flex'>
      <div className='ms-auto'>Kompetenzvermutung</div>
    </div>
  )

  return (
    <div className='bg-canvas'>
      {history.length > 1 && <MiniNav />}
      <h5>{getDoc().itemName}</h5>
      <hr />
      <p>{getDoc().originSummary}</p>
      <hr />
      <p>{getDoc().summary}</p>
      <hr />
      {getDoc().methods.length > 0 && <MethodList />}
    </div>
  )
}

/**
 * Returns an HTMLElement containing a minimalistic rendered version of the documentation
 * (used for preview in auto complete or hovers...)
 * See also the full blown doc browser as React component (to be done :))
 * @returns An HTMLElement or null, if no documentation is available.
 */
export function renderFunuruDoc (nodeIndex: NodeIndex, node: Agjs2.Node): HTMLElement | null {
  const doc = nodeIndex.docs(node)

  if (doc.itemName == null) return null

  const container = document.createElement('div')
  render(<FunuruDocPage doc={doc} />, container)

  return container.firstChild as HTMLElement
}
