import { useContext } from 'preact/hooks'
import { VNode } from 'preact'
import { observer } from 'mobx-react'

import { AppContext } from '../app_context'
import { Agjs2 } from '../agjs/types'
import { CollapsibleSection, Panel, IconButton } from '../pcomponents'

import { NameEditor, PropDefEditor } from '../pcomponents/prop_editor'

// todo consolidate with ElementInspector et al
const InspectorRow = ({ children }): VNode<any> => (
  <div className='p-2 border-ridge border-b'>{children}</div>
)

const ReadOnlyClassDefMessage = ({ classDef, onClick }: { classDef: Agjs2.ClassDef, onClick: () => void }): VNode<any> => (
  <div class='p-2 bg-yellow-200 text-neutral-900'>
    This element is part of an external library and cannot be modified. To make changes, create a copy and import it into this project as a custom element.
    <IconButton icon='download' light label={`Import "${classDef.name}"`} onClick={() => onClick()} />
  </div>
)

const ClassDefInspector = observer(({ classDef, readOnly }: { classDef: Agjs2.ClassDef | null, readOnly: boolean }): VNode<any> => {
  const { store } = useContext(AppContext)

  if (classDef != null) {
    const changeName = (newName: string): void => {
      store.project.op.generic.renameElement(classDef, newName)
    }

    const importClassDef = (): void => {
      const newId = store.project.op.classDef.cloneClassDef(classDef.cid)
      store.project.openElementEditor(newId)
    }

    return (
      <Panel>
        {readOnly && <ReadOnlyClassDefMessage classDef={classDef} onClick={importClassDef} />}
        <div class='p-2'>
          <NameEditor element={classDef} onChange={changeName} />
        </div>
        <CollapsibleSection title='Properties'>
          {/* propDefsWithValue(classDef, store).map(p => <PropEditor element={element} propDefValue={p} />) */}
          {Object.keys(classDef.props).map(p => <PropDefEditor classDef={classDef} propDef={classDef.props[p]} key={p} onDelete={() => store.project.op.classDef.deletePropDef(classDef, classDef.props[p])} />)}
          <InspectorRow>
            <IconButton icon='add' label='Add property' onClick={() => store.ui.openNewPropDefEditor(classDef)} />
          </InspectorRow>
        </CollapsibleSection>
        {/* <CollapsibleSection title={'Custom states'}>
          {element.states.map(p => <CustomState state={p} />)}
          <InspectorRow>
            <IconButton icon={'add'} label={'Add custom state'} onClick={() => store.ui.openCustomStateEditor()}/>
          </InspectorRow>
        </CollapsibleSection>
      */}
      </Panel>
    )
  } else {
    return (
      <Panel>
        <div class='m-2 text-center'>
          (No element selected)
        </div>
      </Panel>
    )
  }
})

export { ClassDefInspector }
