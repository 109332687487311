import { Agjs2, ST } from './../types'
import * as Factory from './../factory'

const { F } = Factory

const info: ST.Exp.Method = {
  t: 'Method',
  id: 'Logger.info',
  name: 'info',
  meta: {
    description: 'Log output with severity "info". Returns a string of the output.',
    category: 'Logging',
    order: 1
  },
  js: () => {
    return `console.info(args[0]); return JSON.stringify(args[0])`
  },
  parameters: [F.makeParam('message', F.makePrimitiveDT('String'))],
  kwParameters: {},
  returnType: F.makePrimitiveDT('String'),
}

const LoggerClass: ST.Exp.StaticClass = {
  t: 'StaticClass',
  name: 'Logger',
  cid: 'LoggerClass',
  id: 'LoggerClass',
  meta: {
    summary: 'Methods for printing messages to the console or the application logs.',
    description: '',
    searchHint: 'console.log,logging,warning,debug,debugging,error tracking',
    category: 'Stdlib'
  },
  props: {},
  methods: [
    info
  ]
}

export { LoggerClass }
