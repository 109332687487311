import consumer from './consumer'
import { globalApp } from './../app_context'
import { Backend } from './../types'

window.addEventListener('DOMContentLoaded', () => {
  // TODO: create listener INSIDE store.projectState.openProject or so- add it here:
  // app.store.project.openProject(body.dataset.projectId)
  //
  const projectId = document.body.dataset.projectId
  consumer.subscriptions.create({ channel: 'PageChannel', project_id: projectId }, {
    connected () {
      // Called when the subscription is ready for use on the server
      console.log('PageChannel subscription connected to', projectId)
    },

    disconnected () {
      // Called when the subscription has been terminated by the server
      console.log('PageChannel subscription disconnected.')
    },

    received (data: Backend.StatusUpdate) {
      globalApp.store.project.processBackendMessage(data)
    },

    update_nodes: function () {
      return this.perform('update_nodes')
    }
  })
})
