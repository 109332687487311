import { Agjs2, ST } from './types'
import { F } from './factory'
import { Runtime } from './runtime'
import { WipeIds } from './cursor'
import { deepClone } from '../agjs/utils'

interface ReqAndInputNames {
  r: string
  i: string
}

type TypeDef = ST.DT.TypeDef
type TypeList = ST.DT.TypeList

interface TypeCheckResult {
  ok: boolean
  messages: string[]
  types: TypeList
}

interface AssignOrCheckTypeVarResult {
  status: 'assigned' | 'read' | 'error' | 'no_var'
  varTypes: ST.DT.TypeList | null
  assignedTypes: ST.DT.TypeList | null
  varname: string | null
  messages: string[]
}

export interface TypeContext {
  typeVars: ST.DT.TypeVar[]
  assignments: Record<string, ST.DT.TypeDef[]>
}

const interpolateError = (message: string, desc?: ReqAndInputNames): string => {
  return (desc != null) ? message.replaceAll('%I', desc.i).replaceAll('%R', desc.r).replace(/ +/g, ' ') : message
}

const resultOk = (types: TypeList): TypeCheckResult => ({ ok: true, messages: [], types })

const resultError = (message: string, desc?: ReqAndInputNames): TypeCheckResult => {
  return { ok: false, messages: [interpolateError(message, desc)], types: [] }
}

export class TypeCheck {
  runtime: Runtime

  constructor (runtime: Runtime) {
    this.runtime = runtime
  }

  /**
   * Gives access to the interpolation feature outside of TypeCheck module (used in Parser)
   */
  interpolate (message: string, requiredName: string, inputName: string): string {
    return interpolateError(message, { r: requiredName, i: inputName })
  }

  toS (dataType: TypeDef | TypeList): string {
    const formatForTypeCheck = (dt: TypeDef): string => {
      if (dt.type === 'StaticClass') {
        const sc = this.runtime.nodeIndex.lookup(dt.refId) as ST.Exp.StaticClass
        return sc.name
      } else {
        return this.runtime.formatter.toString(dt)
      }
    }
    if (Array.isArray(dataType)) {
      return dataType.map(dt => formatForTypeCheck(dt)).join(' | ')
    } else {
      return formatForTypeCheck(dataType)
    }
  }

  areIdentical (typeA: TypeDef, typeB: TypeDef): boolean {
    // I added the deepClone in Jan 2024 after discovering that WipeIds would remove the ids of the existing dataType
    // structures in the project.
    // This wasn't an issue before because originally dataType wouldn't use the id field (was not set).
    // When fixing some type script issues I just thought it easier to add the id but already thought that this might
    // give trouble, also in case the datatype would be regenerated for whatever reason (unneccesary changes)
    const a = WipeIds(deepClone(typeA))
    const b = WipeIds(deepClone(typeB))

    return JSON.stringify(a) === JSON.stringify(b)
  }

  inferFromBinOp (left: TypeList, op: ST.Exp.OpList, right: TypeList): TypeList {
    if (left.length !== 1 || right.length !== 1) throw new Error('Multiple return types for BinOp args. Inference not yet supported')

    const l = left[0]
    const r = right[0]

    const opMismatchError = (): ST.DT.Error[] => [
      F.makeTypeError(
        this.areIdentical(l, r)
          ? `Operator "${op}" cannot be used between ${this.toS(l)}s`
          : `Operator "${op}" cannot be used between ${this.toS(l)} and ${this.toS(r)}`
      )
    ]
    // Strategy: scan lib for suitable ObjMethod, then determine its return type.
    // At the end, checks for eq/neq of different types, that haven't been covered before, are considered bool.
    // (could also be modeled with builtin via a wildard type for every object)
    const methodReturnTypes = this.matchObjMethodBySignature(l, op, [[r]])
    if (methodReturnTypes.ok) return methodReturnTypes.types

    // Arriving here means there is no specialized method to check equality for these two,
    // but during runtime we will just fall back to the built-in eq checker (by value),
    // so we can safely declare a bool return type at this point.
    if (op === '==' || op === '!=') return [F.makePrimitiveDT('Boolean')]

    // For all other ops it is not clear how to handle them.
    return opMismatchError()
  }

  /**
   * Creates a single record (hash with fields for each database column/field)
   * from the given DbTable.
   */
  makeRecordFromTableType (tableId: string, opts: { usedFor?: 'insert' | 'update' } = {}): ST.DT.RecordTypeDef {
    const table = this.runtime.nodeIndex.lookup(tableId) as Agjs2.DbTableDef

    let optionalFields: string[] = []
    const hiddenFields: string[] = []
    if (opts?.usedFor != null) {
      hiddenFields.push('Id', 'CreatedAt', 'UpdatedAt')
      if (opts.usedFor === 'update') {
        optionalFields = table.columns.map(col => col.name)
      }
      if (opts.usedFor === 'insert') {
        // should work, but for now lets not require fields that cannot be null in the database...
        // those missing fields will be filled with the default value.
        // optionalFields = table.columns.filter(col => col.notNull == null).map(col => col.name)
        // instead, behave the same as with update:
        optionalFields = table.columns.map(col => col.name)
      }
    }

    return F.makeRecordDefType(
      table.columns
        .filter(col => !hiddenFields.includes(col.name))
        .map(col => F.makeRecordField(
          col.name,
          col.dataType,
          optionalFields.includes(col.name))
        )
    )
  }

  /**
   * Returns a list of type var names that are necessary to resolve the type.
   */
  dependsOnTypeVars (dt: ST.DT.TypeDef, ctx: TypeContext, varlist: string[] = []): string[] {
    if (dt.type === 'UtilityType') {
      switch (dt.op) {
        case 'DbRecord':
        case 'DbRecordForInsert':
        case 'DbRecordForUpdate':
        case 'DbTableRows':
          return varlist.concat(this.dependsOnTypeVars(dt.dataTypeArg, ctx, varlist))
      }
    } else if (dt.type === 'TypeVar') {
      return varlist.concat([dt.name])
    } else if (dt.type === 'TypeVarRef') {
      const tv = ctx.typeVars.find(tv => tv.name === dt.varname)
      if (tv == null) {
        throw new Error(`Type variable ${dt.varname} not found in type context [${ctx.typeVars.map(tv => tv.name).join(', ')}].`)
      }
      return varlist.concat(this.dependsOnTypeVars(tv, ctx))
    }
    return []
  }

  /**
   * Resolving a type means applying utility types to transform a given type or
   * resolve referenced type variables.
   * @see inferType for determining the return type of an expression
   */
  resolveTypeVar (dt: ST.DT.TypeDef, ctx: TypeContext): ST.DT.TypeDef[] {
    if (dt.type === 'UtilityType') {
      const tableIdFromArg = (ut: ST.DT.UtilityType): string => {
        const res = this.resolveTypeVar(ut.dataTypeArg, ctx)
        if (res.length !== 1) throw new Error('DbRecord utility type argument did not resolve to one (1) type')
        if (res[0].type !== 'DbTable') throw new Error(`DbRecord utility type argument needs to resolve to DbTable but was ${res[0].type}`)
        return res[0].refId
      }
      switch (dt.op) {
        case 'DbRecord':
          return [this.makeRecordFromTableType(tableIdFromArg(dt))]
        case 'DbRecordForInsert':
          return [this.makeRecordFromTableType(tableIdFromArg(dt), { usedFor: 'insert' })]
        case 'DbRecordForUpdate':
          return [this.makeRecordFromTableType(tableIdFromArg(dt), { usedFor: 'update' })]
        case 'DbTableRows':
          return [F.makeListType(
            this.makeRecordFromTableType(tableIdFromArg(dt))
          )]
        // default:
        //   throw new Error(`Unknown UtilityType op: ${dt.op}`)
      }
    } else if (dt.type === 'TypeVar') {
      // TODO: do I need the scope for that expression here?
      // probably sufficient to have it in TypeContext
      const assignedDts = ctx.assignments[dt.name]
      if (assignedDts == null) {
        return dt.dataTypes.map(d => this.resolveTypeVar(d, ctx)).flat()
      } else {
        // NOT checking if assigned type is correct (assignable to dt). This should happen
        // somewhere up in the stack (where resolveTypeVar is called from)
        return assignedDts
      }
    } else if (dt.type === 'TypeVarRef') {
      const tv = ctx.typeVars.find(tv => tv.name === dt.varname)
      if (tv == null) {
        throw new Error(`Type variable ${dt.varname} not found in type context [${ctx.typeVars.map(tv => tv.name).join(', ')}].`)
      }
      // TODO: handling von utility types etc.?
      return this.resolveTypeVar(tv, ctx)
    }
    return [dt]
  }

  /**
   * Takes a list of ObjMethod definitions and returns these that are callable
   * with on object of dataType.
   * This is used for example for auto completion suggestions.
   * @see matchObjMethodBySignature for a stricter filter that considers argument types as well.
   */
  filterObjMethods (dataType: ST.DT.TypeDef, objMethods: ST.Exp.ObjMethod[]): ST.Exp.ObjMethod[] {
    const results: ST.Exp.ObjMethod[] = []
    objMethods.forEach(m => {
      if (m.objType.type === 'TypeVarRef') {
        // TODO: proper resolve algorithm. CHECK for multiple typevars!!!
        if (m.typeVars == null) throw new Error(`ObjMethod ${m.id}: TypeVarRef but no type variables specified`)
        const tv = m.typeVars[0]
        let match = false
        tv.dataTypes.forEach(dt => {
          if (!match) {
            const result = this.isAssignable(dt, dataType)
            if (result.ok) {
              results.push(m)
              match = true
            }
          }
        })
      } else {
        const result = this.isAssignable(m.objType, dataType)
        if (result.ok) results.push(m)
      }
    })
    return results
  }

  /**
   * Assigns or checks a type to a type variable.
   * If the parameter is a reference to a type variable, this variable is assigned the type of
   * the argument (if it wasn't assigend prior). Or the argument is checked against the existing type var value.
   * @param paramDTs TypeList of the parameter. If it is not a TypeRef, nothing will happen.
   * @param argDTs TypeList of the argument (for the parameter).
   * @param argname Description of the argument (used for type error messages interpolation)
   * @param ctx The TypeContext to use. This will be modified (assignments) if necessary.
   * @returns a AssignOrCheckTypeVarResult object containing what happened during the process.
   * @see AssignOrCheckTypeVarResult for details about what is returned; briefly:
   *   assignedTypes contains the type that was assinged to the type variable (or computed type if a utility type was inbetween)
   *   varTypes contains the possible types for the type variables.
   */
  assignOrCheckTypeVar (paramDTs: ST.DT.TypeList, argDTs: ST.DT.TypeList, argname: string, ctx: TypeContext): AssignOrCheckTypeVarResult {
    const result: AssignOrCheckTypeVarResult = {
      status: 'no_var',
      varTypes: null,
      assignedTypes: null,
      varname: null,
      messages: []
    }

    // When a utility type is involved, only try to check if the argDT is correct.
    // If the utility type could not be resolved, return an error.
    // Indirectly assigning a type variable that is read from a utility type is not supported.
    if (paramDTs.length === 1 && paramDTs[0].type === 'UtilityType') {
      const varnames = this.dependsOnTypeVars(paramDTs[0], ctx)

      // if all required type variables are set:
      if (varnames.every(v => ctx.assignments[v] != null)) {
        const requiredDT = this.resolveTypeVar(paramDTs[0], ctx)
        const utCheckResult = this.isCompatible(requiredDT, argDTs, {
          r: `utility type expression for "${argname}"`,
          i: argname
        })

        if (!utCheckResult.ok) {
          result.status = 'error'
          result.messages = utCheckResult.messages
        } else {
          result.varname = paramDTs[0].t + '(' + varnames.join(', ') + ')'
          result.assignedTypes = requiredDT
          result.status = 'read'
        }
      } else {
        result.status = 'error'
        result.messages.push(`${argname} uses a utility type, but the utility type depends on unassigned type variable(s) ${varnames.join(', ')}.`)
      }
      return result
    }
    if (paramDTs.length === 1 && paramDTs[0].type === 'TypeVarRef') {
      const varname = paramDTs[0].varname
      const tv = ctx.typeVars.find(t => t.name === varname)
      if (tv == null) throw new Error(`Referenced type variable not found: ${varname}.`)

      result.varname = varname
      result.varTypes = tv.dataTypes

      const assignedTypes = ctx.assignments[varname]

      if (assignedTypes != null) {
        result.status = 'read'
        result.assignedTypes = assignedTypes
      }

      // Use type that was assigned to type var previously, or type of variable itself if it isn't assigned yet.
      const typeInVariable: ST.DT.TypeList = assignedTypes ?? tv.dataTypes
      const tvCheckResult = this.isCompatible(typeInVariable, argDTs, {
        r: `type variable "${varname}"`,
        i: argname
      })

      if (!tvCheckResult.ok) {
        result.status = 'error'
        result.messages = tvCheckResult.messages
        return result
      } else {
        if (ctx.assignments[varname] == null) {
          ctx.assignments[varname] = argDTs
          result.status = 'assigned'
        }
      }
    }
    return result
  }

  /** Finds an ObjMethod for the given objType and matches the given argument datatypes.
   * (dtsForArguments). This is an array (one entry for each Argument) where as each
   * array item itself is also an array (List of DataTypes for that particular Argument)
   * Match is only positive if for each of the arguments, all possible dataTypes are assignable to
   * the respective parameter of the method.
   *
   * @todo because this already supports method overloading, this method should also be used
   * during runtime to determine which method to call (or pin a method by id prior to code execution)
   *
   * @returns see result's `types` field for return types of method call.
  * */
  matchObjMethodBySignature (objType: TypeDef, methodName: string, dtsForArguments: TypeList[]): TypeCheckResult {
    // FIXME: maybe it is way more efficient to search by method NAME (same as op) an then look at the types.
    // or cache the whole thing. For example Number + Number will be looked for a lot of times potentially.
    const methods = this.filterObjMethods(objType, this.runtime.libs.flattenedObjMethodDefs()).filter(m => m.name === methodName)

    if (methods.length === 0) return resultError(`${this.toS(objType)} does not have a method '${methodName}'.`)

    return this.matchMethodBySignature(methods, this.toS(objType), methodName, dtsForArguments, {}, objType)
  }

  /**
   * Compares the given arguments with the methods and their parameter requirements and returns the method's return type,
   * if a method exists, or returns an error messages about type matching problems.
   * @param methods A list of methods from where to pick one depending on the arguments. This list has to be machted by name already!
   * @param className Name of the class the method is called on (this is only used to generate the error message text)
   * @param methodName Name of the method (only used to generate error messages)
   * @returns the method's return type
   */
  matchMethodBySignature (
    methods: Array<ST.Exp.Method | ST.Exp.ObjMethod>,
    className: string,
    methodName: string,
    dtsForArguments: TypeList[],
    dtsForKwArguments: Record<string, TypeList>,
    objType?: TypeDef
  ): TypeCheckResult {
    const determineReturnTypes = (m: ST.Exp.Method | ST.Exp.ObjMethod, typeContext: TypeContext): ST.DT.TypeList => {
      if (m.returnTypeJs != null) {
        // TODO: muss hier noch berücksichtigt werden dass reutnTypeJs auch einen fehler zurückgeben könnte?
        return m.returnTypeJs(this.runtime, dtsForArguments, dtsForKwArguments, objType ?? F.makeNoneType())
      } else {
        // TODO: resolveType if necessary.
        // return [m.returnType]
        return this.resolveTypeVar(m.returnType, typeContext)
      }
    }

    let lastMethodError = ''
    for (let i = 0; i < methods.length; i++) {
      const m = methods[i]
      const isOp = m.isBinOp === true &&
        m.parameters.length === 1 &&
        dtsForArguments.length === 1 &&
        Object.keys(m.kwParameters).length === 0 &&
        Object.keys(dtsForKwArguments).length === 0

      // TODO: check keyword arguments as well...
      if (m.parameters.length !== dtsForArguments.length) {
        lastMethodError = `Wrong number of arguments for '${methodName}'. ` +
          `Expected ${m.parameters.length}, given ${dtsForArguments.length}.`
      } else {
        if (isOp) {
          const opCheckResult = this.isCompatible(m.parameters[0].dataTypes, dtsForArguments[0], {
            r: 'right operand',
            i: ''
          })
          // TODO: build typeContext in this aswell
          if (opCheckResult.ok) return resultOk(determineReturnTypes(m, { typeVars: [], assignments: {} }))
          return resultError(`'${className} ${methodName} ${this.toS(dtsForArguments[0])}': ${opCheckResult.messages.join(' ')}`)
        } else {
          const ctx: TypeContext = {
            typeVars: m.typeVars ?? [],
            assignments: {}
          }

          // make list of arguments which aren't assignable (!ok) to the parameter at
          // same index. If this list is empty, the signature is compatible.
          // First, assign types to type variables or check if later arguments types are
          // compatible with assigned types.
          if (objType != null) {
            const objm = m as ST.Exp.ObjMethod
            const res = this.assignOrCheckTypeVar([objm.objType], [objType], className, ctx)
            if (res.status === 'error') lastMethodError = `Type mismatch for '${methodName}'. ${res.messages.join(' ')}`
          }
          dtsForArguments.forEach(
            (argDts, idx) => {
              const paramDTs = m.parameters[idx].dataTypes
              const res = this.assignOrCheckTypeVar(paramDTs, argDts, `argument #${idx + 1}`, ctx)
              if (res.status === 'error') lastMethodError = `Type mismatch for '${methodName}'. ${res.messages.join(' ')}`
            }
          )

          // This loop checks type compatibility again, but can now use the TypeContext constructed above.
          if (lastMethodError === '') {
            const signatureOk = dtsForArguments.filter(
              (argDt, idx) => {
                const paramDTs = m.parameters[idx].dataTypes.map(pDT => this.resolveTypeVar(pDT, ctx)).flat()

                const argCheckResult = this.isCompatible(paramDTs, argDt, {
                  r: `Parameter #${idx + 1}`,
                  i: `argument #${idx + 1}`
                })

                if (!argCheckResult.ok) lastMethodError = `Type mismatch for '${methodName}'. ${argCheckResult.messages.join(' ')}`
                return !argCheckResult.ok
              }
            ).length === 0

            if (signatureOk) return resultOk(determineReturnTypes(m, ctx))
          }
        }
      }
    }
    return resultError(lastMethodError ?? `Cannot determine call signature match for '${className}.${methodName}'`)
  }

  inferFromDotOp (exp: ST.Exp.DotOp): TypeList {
    const tLeft = this.inferType(exp.left)

    // TODO: A step is missing where PageElement as a type is accepted IF the right side will result in an
    // attribute access or so, that is valid.
    const r = exp.right
    if (r.t === 'GetAttr') {
      // const leftElem: Agjs2.NamedNode = this.runtime.nodeIndex.lookup((exp.left as ST.Exp.GetNode).refId) as Agjs2.NamedNode
      if (tLeft.length > 1) throw new Error(`Multiple return types for left side of DotOp not supported (${JSON.stringify(tLeft)})`)

      const leftType = tLeft[0]

      switch (leftType.type) {
        case 'PageElementInstance': {
          const el = this.runtime.nodeIndex.lookup(leftType.refId) as ST.Exp.Render.PageElement
          // console.log('fetching attr (to get dataType): ', r.attrPath)
          const [path, attrName] = r.attrPath.split('.')
          if (path === 'propValues') {
            const classDef = this.runtime.libs.getClassDef(el.cid)
            return classDef.props[attrName].dataTypes
          } else if (path === 'states') {
            const typeOfEachState = el.states.map(state => F.makeRecordField(state.name, state.dataType))
            return [F.makeRecordDefType(typeOfEachState)]
          }
          break
        }
        case 'Record': {
          const recordField = leftType.fields.find(f => f.name === r.attrPath)
          if (recordField == null) {
            return [F.makeTypeError(`Unknown record field '${r.attrPath}' (available fields: ${leftType.fields.map(f => f.name).join(', ')})`)]
          } else {
            return [recordField.dataType]
          }
        }
        default:
          throw new Error(`DotOp+GetAttr not implemented: ${this.runtime.formatter.toString(r)}.`)
      }
    } else if (r.t === 'CallObjMethod' || r.t === 'CallMethod') {
      const argDts = r.args.map(arg => this.inferType(arg))

      let lastError = ''

      // TODO: hier könnte man auch nach id matchen? wie läuft das mit dem renaming von methods sonst...?
      // aber passiert in der praxis wahrscheinlich auch nie.
      for (const dt of tLeft) {
        if (dt.type === 'StaticClass') {
          const staticClass = this.runtime.nodeIndex.lookup(dt.refId) as ST.Exp.StaticClass
          const retTypes = this.matchMethodBySignature(staticClass.methods.filter(m => m.name === r._name), staticClass.name, r._name, argDts, {})

          if (retTypes.ok) return retTypes.types
          lastError = retTypes.messages.join(' ')
        } else {
          const retTypes = this.matchObjMethodBySignature(dt, r._name, argDts)

          if (retTypes.ok) return retTypes.types
          lastError = retTypes.messages.join(' ')
        }
      }

      return [F.makeTypeError(lastError.length > 0 ? lastError : `${this.toS(tLeft)} has no method '${r._name}'`)]
    } else if (r.t === '$unresolved') {
      return [F.makeTypeError(`Unresolved Symbol '${this.runtime.formatter.toString(r)}'`)]
    } else {
      // console.log('X unsupported right side of dotop:', r)
      return [F.makeTypeError(`Unsupported right side of DotOp: ${this.runtime.formatter.toString(r)}`)]
    }
    return [F.makeTypeError(`Cannot infer dotOp type of: ${this.runtime.formatter.toString(exp)}`)]
  }

  /* 4.1.2024- found out that this is actually not used anywere?
   *
  inferFromNodeWithT (node: Agjs2.IndexableNode): TypeList {
    switch (node.t) {
      case 'RenderPageElement':
        return [F.makePageElementInstanceType(node.id)]
      // TODO: 'method collection' object (in global space)
      default:
        return [F.makeTypeError(`Cannot infer type for ${JSON.stringify(node)}`)]
    }
  }
 */

  inferType (exp: Agjs2.IndexableNode): TypeList {
    switch (exp.t) {
      case 'Null':
        return [F.makePrimitiveDT('Null')]
      case 'String':
        return [F.makePrimitiveDT('String')]
      case 'Text':
        return [F.makePrimitiveDT('Text')]
      case 'Number':
        return [F.makePrimitiveDT('Number')]
      case 'Boolean':
        return [F.makePrimitiveDT('Boolean')]
      case 'Timestamp':
        return [F.makePrimitiveDT('Timestamp')]
      case 'Group':
        return this.inferType(exp.child)
      case 'List': {
        if (exp.items.length === 0) return [F.makeListType(F.makeNeverType())]

        const typesOfFirstItem = this.inferType(exp.items[0])

        if (typesOfFirstItem.length > 1) {
          return [
            F.makeTypeError(`List item cannot have more than one type ${JSON.stringify(exp)}`)
          ]
        }

        const listItemType = typesOfFirstItem[0]

        for (let i = 1; i < exp.items.length; i++) {
          const res = this.isCompatible(
            [listItemType],
            this.inferType(exp.items[i]),
            {
              r: 'List item type',
              i: `list item #${i + 1}`
            }
          )

          if (!res.ok) {
            return [F.makeTypeError(res.messages[0])]
          }
        }

        // return itemTypes.map(it => F.makeListType(it))
        return [F.makeListType(listItemType)]
      }
      case 'DefsList': {
        // TODO: same as List above, consolidate List and DefsList code
        if (exp.items.length === 0) return [F.makeDefsListType(F.makeNeverType())]

        const typesOfFirstItem = this.inferType(exp.items[0])

        if (typesOfFirstItem.length > 1) {
          return [
            F.makeTypeError(`DefsList item cannot have more than one type ${JSON.stringify(exp)}`)
          ]
        }

        const listItemType = typesOfFirstItem[0]
        if (listItemType.type === 'String') throw new Error('defslist hat stirng als type:' + exp.items[0].t)

        for (let i = 1; i < exp.items.length; i++) {
          const res = this.isCompatible(
            [listItemType],
            this.inferType(exp.items[i]),
            {
              r: 'DefsList item type',
              i: `list item #${i + 1}`
            }
          )

          if (!res.ok) {
            return [F.makeTypeError(res.messages[0])]
          }
        }

        return [F.makeDefsListType(listItemType)]
      }
      case 'Record': {
        const fields: ST.DT.RecordFieldDef[] = []
        Object.keys(exp.fields).forEach(fname => {
          const tlist = this.inferType(exp.fields[fname])
          if (tlist.length > 1) throw new Error('Multiple possible types for record field, not supported yet.')
          fields.push(
            F.makeRecordField(
              fname,
              tlist[0]
            )
          )
        })
        return [F.makeRecordDefType(fields)]
      }
      case 'DotOp':
        return this.inferFromDotOp(exp)
      case 'GetNode': {
        if (exp.refId === '$thisElement') {
          throw new Error('$thisElement not supported yet')
        } else {
          const target = this.runtime.nodeIndex.lookup(exp.refId) // as Agjs2.NamedNode
          // if a Param is referenced by GetNode, we want to retrieve the value assigned to that param.
          // (this is different from the type inferred from a Param node itself, when is defined by $param syntax).
          if (target.t === 'Param') {
            return target.dataTypes
          }
          // FIXME: normally this works, in normal code refId references an PageElementInstance (for example).
          // but when this is called in the elements workbench, (i.e. on thisElement.content or so), $thisElement
          // resolves to the CLASS not the instance. (for ex. TextElementClass). The class is not part of nodeIndex however
          // (and if it would be, it would be problematic).
          return this.inferType(target)
        }
      }
      case 'CallMethod':
      case 'CallObjMethod': {
        // TODO: maybe this code is untested.
        throw new Error('is this used? maybe only for root level methods, rest will pile up in inferFromDotOp')
        // return this.inferType(this.runtime.nodeIndex.lookup(exp._name) as Agjs2.NamedNode)
      }
      case 'BinaryOp': {
        const tLeft = this.inferType(exp.left)
        const tRight = this.inferType(exp.right)
        return this.inferFromBinOp(tLeft, exp.op, tRight)
      }
      case 'RenderContext': {
        // const tExp = this.inferType(exp.value)

        // const result = this.isCompatible([F.makeRenderExpressionType()], tExp)

        // if (result.ok) {
        //   return [F.makeRenderContextType()]
        // } else {
        //   return [F.makeTypeError(`$render() requires a render expression as parameter: ${result.messages.join(' ')}`)]
        // }

        // Previously renderContext had only one renderExpression. It is more convenient to allow a list of renderExps,
        // but then we cannot easily pass down the type of these.
        // However, the renderExp can possibly not constructed with incompatible types, because the parser doesn't allow that,
        // so we can assume it will be valid (and hence return the renderContextType).
        return [F.makeRenderContextType()]
      }
      case 'RenderEval':
      case 'RenderHTMLTag': {
        return [F.makeRenderExpressionType()]
      }
      case 'PageElementClass':
        return [F.makePageElementClassType(exp.cid)]
      // Statements (currently also stored in an Expression):
      case 'Param':
        return [F.makeParamType()]
      case 'VariableStmt':
        return [exp.dataType]
      case 'RenderPageElement':
        return [F.makePageElementInstanceType(exp.id)]
      case 'ElementStyling':
        return [F.makePrimitiveDT('ElementStyling')]
      case 'StaticClass':
        return [F.makeStaticClassType(exp.id)]
      case 'DbTableDef':
        return [F.makeDbTableDT(exp)]
      case '$incomplete':
        return [F.makeTypeError(`Incomplete expression ${JSON.stringify(exp.value)}`)]
      case '$unresolved':
        return [F.makeTypeError(`Unresolved symbol ${JSON.stringify(exp.value)}`)]
      default:
        return [F.makeTypeError(`Cannot infer type for ${JSON.stringify(exp)}`)]
      // default:
      //   return [F.makeUnknownType(exp)]
    }
  }

  /**
   * Will check if inputType can be assigned to requiredType. For this the types either need to be the same or
   * inputType will have to be a wildcard flavor (like a List of $any or AnyDbTable).
   * @see isCompatible for a version that deals with multiple types for required and input.
   * @param desc can be used to create more meaningful error messages (@see isCompatible for full description)
   * @returns The returned TypeCheckResult will never contain any types,
   *   but have the boolean 'ok' flag set and might hold error messages describing why types are incompatible if so.
   */
  isAssignable (requiredType: TypeDef, inputType: TypeDef, desc?: ReqAndInputNames): TypeCheckResult {
    if (inputType.type === '$error') return resultError(`%R requires a ${requiredType.type}, but %I has an error: ${inputType.message}`, desc)

    const inputOf = (...args: ST.DT.TypeName[]): boolean => args.includes(inputType.type)

    const ok = resultOk([])

    if (requiredType.type === 'List' && inputType.type !== 'List') {
      return resultError('%R is a List, use an add/push/unshift.', desc)
    }

    if (requiredType.type !== 'List' && inputType.type === 'List') {
      return resultError('%R is not a List, extract a specific element from %I.', desc)
    }

    if (requiredType.type === 'List' && inputType.type === 'List') {
      const itemResult = this.isAssignable(requiredType.itemType, inputType.itemType)
      if (itemResult.ok) return itemResult
      const itemTypeErrorMessage = interpolateError(itemResult.messages[0], { r: 'List item', i: 'given list item' })
      return resultError(`%R expects a list of ${requiredType.itemType.type}s: ${itemTypeErrorMessage}`, desc)
    }

    if (requiredType.type === 'DefsList' && inputType.type === 'DefsList') {
      const itemResult = this.isAssignable(requiredType.itemType, inputType.itemType)
      if (itemResult.ok) return itemResult
      const itemTypeErrorMessage = interpolateError(itemResult.messages[0], { r: 'DefsList item', i: 'given list item' })
      return resultError(`%R expects a list of ${requiredType.itemType.type}s: ${itemTypeErrorMessage}`, desc)
    }

    switch (requiredType.type) {
      case '$any':
        return ok
      case 'Null':
      case 'Boolean':
      case 'RenderExpression':
      case 'RenderContext':
      case 'Param':
      case 'Timestamp':
      case 'ElementStyling':
        if (inputOf(requiredType.type)) return ok
        return resultError(`%R requires a ${requiredType.type}, but %I is a ${inputType.type}.`, desc)
      case 'String':
      case 'Text':
        if (inputOf('String', 'Text')) return ok
        return resultError(`%R requires a String, but %I is a ${inputType.type}.`, desc)
      case 'Number':
        if (inputOf('Number')) return ok
        if (inputOf('String', 'Text')) {
          return (
            resultError(`%R requires a Number, but %I is a ${inputType.type}. Use a Cast to explicitly convert it into a number.`, desc)
          )
        }

        return resultError(`%R requires a Number, but %I is a ${inputType.type}.`, desc)
      case 'Record': {
        if (inputOf('Record')) {
          const inputRecord = inputType as ST.DT.RecordTypeDef
          const errors: string[] = []

          const addFieldError = (requiredFieldName: string, inputFieldName: string, message: string): void => {
            errors.push(
              interpolateError(message, {
                r: requiredFieldName,
                i: inputFieldName
              })
            )
          }

          inputRecord.fields.forEach(inputField => {
            if (!requiredType.fields.map(f => f.name).includes(inputField.name)) {
              errors.push(`%R does not expect a field ${inputField.name}, but %I defines one.`)
            }
          })

          requiredType.fields.forEach(field => {
            const inputFieldType = inputRecord.fields.find(inpf => inpf.name === field.name)
            if (inputFieldType == null) {
              if (field?.optional !== true) {
                errors.push(`%I does not provide a field value for %R.${field.name}.`)
              }
            } else {
              const fieldRes = this.isCompatible([field.dataType], [inputFieldType.dataType])
              if (!fieldRes.ok) {
                fieldRes.messages.forEach(m =>
                  addFieldError(`%R.${field.name}`, `%I.${field.name}`, m)
                )
              }
            }
          })

          if (errors.length === 0) {
            return ok
          }

          return {
            ok: false,
            types: [],
            messages: errors.map(message => interpolateError(message, desc))
          }
        }
        return resultError(`%R requires a ${requiredType.type}, but %I is a ${inputType.type}.`, desc)
      }
      case 'AnyDbTable':
        if (inputOf('DbTable')) return ok
        return resultError(`%R requires a database table, but %I is a ${inputType.type}.`, desc)
      default:
        return resultError(`No type checking implemented for this combination: %R requires a ${requiredType.type}, but %I is a ${inputType.type}.`, desc)
    }
  }

  /**
   * Will check if all of the inputTypes are compatible with at least one of the
   * required types
   * @see isAssignable
   * @param desc holds an ReqAndInputNames structure that is passed to the isAssignable call.
   *   allows setting a key for 'r' (wording for the field having the required type) and 'i' (wording for the input having the actual type).
   * @returns The returned TypeCheckResult will never contain any types,
   *   but have the boolean 'ok' flag set and might hold error messages describing why types are incompatible if so.
   */
  isCompatible (required: TypeList, inputTypes: TypeList, desc?: ReqAndInputNames): TypeCheckResult {
    let errors: string[] = []
    inputTypes.forEach(inputType => {
      const localErrors: string[] = []
      if (!required.some(requiredType => {
        const r = this.isAssignable(requiredType, inputType, desc)
        if (!r.ok) localErrors.push(r.messages[0])
        return r.ok
      })) {
        errors = errors.concat(localErrors)
      }
    })

    if (errors.length > 0 && (required.length > 1 || inputTypes.length > 1)) {
      errors.push(`The ${inputTypes.length} input type(s) needs to be assignable to at least one of the ${required.length} required type(s).`)
    }

    return { ok: errors.length === 0, messages: errors, types: [] }
  }
}
