type GroupType = 'HORIZONTAL' | 'BLOCK_WITH_IDENT' | 'BLOCK' | 'LINE'

export interface NestingParams {
  open: IdentationItem[]
  inner: IdentationItem[]
  close: IdentationItem[]
}

export interface Group {
  items: IdentationItem[]
  type: GroupType
}

export interface TokenString {
  token: string
}

export type IdentationItem = TokenString | Group

export interface IdentationOpts {
  lineWidth?: number
}

const TABCHAR = '  '

const identedLine = (str: string, identLevel: number): string => {
  return TABCHAR.repeat(Math.max(0, identLevel)) + str
}

export class Identation {
  root: Group
  opts: Required<IdentationOpts>

  constructor (opts: IdentationOpts = {}) {
    this.opts = { lineWidth: 80, ...opts }
    this.reset()
  }

  reset (): void {
    this.root = {
      items: [] as IdentationItem[],
      type: 'BLOCK'
    }
  }

  /** Adds a single token to a group (hgroup or line) */
  token (value: string): IdentationItem {
    return { token: value }
  }

  /** Adds a new horizontal group with optional wrapping/identation.
   * The items contained in this group will be concatenated token by token
   * (in one line). If the resulting line is longer than opts.lineWidth,
   * the tokens are rendered line by line instead and wrapped in an
   * identation block.
   */
  hgroup (...items: IdentationItem[]): Group {
    return { items, type: 'HORIZONTAL' }
  }

  // TODO: this currently just outputs an hgroup,
  // as long as no support for proper nesting is given.
  nesting (args: NestingParams): Group {
    return {
      items: args.open.concat(args.inner, args.close),
      type: 'HORIZONTAL'
    }
  }

  /** Adds an identated block (new lines) containing items. (Adds an
  * additional identation to the current ident level where this ident
  * is added to.) */
  ident (...items: IdentationItem[]): Group {
    return { items, type: 'BLOCK_WITH_IDENT' }
  }

  /** Adds a new line and aligns the items horizontally.
   * If the line becomes too long, the remaining part wraps around
   * to the next line, idented (but the beginning is not idented)
   */
  line (...items: IdentationItem[]): Group {
    return { items, type: 'LINE' }
  }

  /** Creates an hgroup with the given listOfItems,
  * interspaced with the separator string (will be converted to a TokenString  */
  join (listOfItems: IdentationItem[], separator: string): Group {
    const result: Group = {
      items: [] as IdentationItem[],
      type: 'HORIZONTAL'
    }

    const numItems = listOfItems.length
    listOfItems.forEach((item, index) => {
      result.items.push(item)
      if (index < numItems - 1) result.items.push(this.token(separator))
    })

    return result
  }

  parens (open: string, inner: Group | Group[], close: string): Group {
    return this.nesting({
      open: [this.token(open)],
      inner: Array.isArray(inner) ? inner : [inner],
      close: [this.token(close)]
    })
  }

  /** Adds a group to the root node. Basically the whole output is constructed
   * by calling line/hgroup/token etc. and building a data structure of Group,
   * then, the result is added via add() once and toString can be called on this
   * instance of Identation */
  add (...groups: Group[]): void {
    this.root.items = this.root.items.concat(groups)
  }

  toString (): string {
    const lines = this.formatIdent(this.root, 0)
    return lines.map(l => l.trimEnd()).join('\n')
  }

  formatIdent (base: Group, identLevel: number): string[] {
    let lines: string[] = []

    switch (base.type) {
      case 'HORIZONTAL':
        return this.attemptHorizontalFitting(base, identLevel, false)
      case 'LINE':
        return this.attemptHorizontalFitting(base, identLevel, true)
      case 'BLOCK':
      case 'BLOCK_WITH_IDENT': {
        const a = base.type === 'BLOCK_WITH_IDENT' ? 1 : 0
        base.items.forEach(item => {
          const ts = item as TokenString
          if (ts.token != null) {
            lines.push(identedLine(ts.token, identLevel + a))
          } else {
            const blockLines = this.formatIdent(item as Group, identLevel + a)
            lines = lines.concat(blockLines)
          }
        })
        return lines
      }
      default:
        return []
    }
  }

  attemptHorizontalFitting (base: Group, identLevel: number, identWrappedLines: boolean, firstLine: string = ''): string[] {
    let lines: string[] = []

    let currentLine = firstLine
    const flushCurrentLine = (): void => {
      if (currentLine.length > 0) {
        lines.push(identedLine(currentLine, identLevel))
        currentLine = ''
      }
    }

    const addTokenToCurrentLineOrWrap = (ts: TokenString): void => {
      const currentLineLengthPlusNextTokenLength =
        currentLine.length + ts.token.trimEnd().length + identLevel * TABCHAR.length
      if (currentLineLengthPlusNextTokenLength <= this.opts.lineWidth) {
        currentLine += ts.token
      } else {
        flushCurrentLine()
        currentLine = identedLine(ts.token, identLevel + (identWrappedLines ? 1 : 0))
      }
    }

    base.items.forEach(item => {
      const ts = item as TokenString
      if (ts.token != null) {
        addTokenToCurrentLineOrWrap(ts)
      } else {
        const group = item as Group
        if (group.type === 'HORIZONTAL') {
          // if an item in base is a horizontal group, then continue to add those items
          // to the currentLine (do not create a new line)
          // Set identLevel to 0 and add the identation level to every returned line by ourselves.
          // Otherwise, currentLine might get duplicate identation from the nested call.
          const continuedLines = this.attemptHorizontalFitting(group, 0, true, currentLine)

          currentLine = continuedLines.pop() ?? ''
          continuedLines.forEach(cl => lines
            .push(identedLine(cl, identLevel))
          )
        } else if (group.type === 'LINE' && base.type === 'LINE') {
          // We are already rendering a line, so if a line contains a further line item,
          // the item of the subline should be treated as if they were part of this (base) line.
          // Otherwise, there will be another identation level for the sub line.
          flushCurrentLine()
          const blockLines = this.formatIdent(group, identLevel)
          lines = lines.concat(blockLines)
        } else {
          flushCurrentLine()
          // TODO: refactor
          const blockLines = this.formatIdent(group, identLevel + 1)

          const longestLine = blockLines.reduce((maxLength, line) => Math.max(maxLength, line.length), 0)

          if (longestLine <= this.opts.lineWidth) {
            lines = lines.concat(blockLines)
          } else {
            const itemsAsLines: Group = { items: (item as Group).items, type: 'LINE' }
            // const wrappedLines = this.formatIdent({ items: [itemsAsLines], type: 'BLOCK_WITH_IDENT' }, identLevel + 1)
            const wrappedLines = this.formatIdent(itemsAsLines, identLevel + 1)
            lines = lines.concat(wrappedLines)
          }
        }
      }
    })

    flushCurrentLine()
    return lines
  }
}
