import { PopoverButton } from './index'
import { observer } from 'mobx-react'
import { useContext } from 'preact/hooks'
import { AppContext } from '../app_context'
import { createRef, VNode } from 'preact'
import { ST } from '../agjs/types'

const ProjectStructureMenu = observer((): VNode => {
  const { store } = useContext(AppContext)

  const closeFnRef = createRef<() => void>()
  const closeMenu = (): void => { closeFnRef.current?.() }

  const deletePage = (ev: MouseEvent, page: ST.Exp.Render.PageRootElement): void => {
    ev.stopPropagation()
    ev.preventDefault()
    store.project.op.page.delete(page)
    closeMenu()
  }

  const disableDelete = store.project.pages.length === 1

  const ResourceButton = ({ children, onClick }): VNode => <div class='bg-neutral-500 p-2 m-1 select-none cursor-pointer' onClick={onClick}>{children}</div>
  const PageButton = ({ page, disableDelete }): VNode => (
    <ResourceButton onClick={() => { closeMenu(); store.project.openPage(page.id) }}>
      <div class='flex items-center'>
        <div class='grow'>{page.name}</div>
        {disableDelete === false ? <div class='material-icons text-neutral-400 hover:text-guitext' onClick={ev => deletePage(ev, page)}>delete</div> : null}
      </div>
    </ResourceButton>
  )

  const name = <><span class='text-muted'>Page </span>{store.project.currentPage?.name}</>
  return (
    <PopoverButton icon='arrow_drop_down' name={name} placement='bottom-start' closeRef={closeFnRef}>
      {store.project.pages.map(p => <PageButton page={p} disableDelete={disableDelete} key={p.id} />)}
      <ResourceButton onClick={() => { closeMenu(); store.ui.runCommand('NEW_PAGE') }}>New page...</ResourceButton>
    </PopoverButton>
  )
})

export { ProjectStructureMenu }
