import { HexToRgb, RGBAColor, RgbToHex } from '../utils/color_tools'
import { PresetList, type ThemeService } from './theme_service'
import { Agjs2, ST } from './types'

type ColorMode = 'none' | 'palette' | 'semantic' | 'custom'

export function colorMode (color?: Agjs2.ColorSettings): ColorMode {
  if (color == null || color.value === '' || color.value === 'none') return 'none'
  if (color.value[0] === '#') return 'custom'
  if (color.value[0] === '$') return 'semantic'
  return 'palette'
}

export type StylePresetType = 'value' | 'unknown' |
'font-family' | 'font-size' | 'font-weight' | 'background-position' |
'background-size' | 'blur' | 'border-radius' | 'border-width' |
'box-shadow' | 'drop-shadow' | 'color' | 'spacing' |
'width' | 'min-width' | 'max-width' |
'height' | 'min-height' | 'max-height'

export interface ValueOrPresetResult {
  value: string
  type: StylePresetType
}

const PRESET_TYPES: Record<string, StylePresetType> = {
  ff: 'font-family',
  fs: 'font-size',
  fw: 'font-weight',
  bp: 'background-position',
  bs: 'background-size',
  bl: 'blur',
  br: 'border-radius',
  bw: 'border-width',
  bshd: 'box-shadow',
  ds: 'drop-shadow',
  sp: 'spacing',
  w: 'width',
  h: 'height',
  minw: 'min-width',
  minh: 'min-height',
  maxw: 'max-width',
  maxh: 'max-height',
  c: 'color'
}

function prefixForPresetType (camelCasedValue: string): string | null {
  const dashedValue = camelCasedValue.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`)

  for (const key in PRESET_TYPES) {
    if (PRESET_TYPES[key] === dashedValue) {
      return key
    }
  }

  return null
}

/**
 * @label the name of certain presets are inspired from tailwind and therefore contain illegal characters for css * custom propnames (like "/" or ",").
 * @returns a name that can be used for custom props (unsafe characters replaced with "_")
 */
export function sanitizePropName (label: string): string {
  return label.replace(/[^a-zA-Z0-9-_]/g, '_')
}

export function presetToCssProperty (presetName: string, type: StylePresetType): string {
  const m = Object.keys(PRESET_TYPES).find(key => PRESET_TYPES[key] === type)

  if (m != null) return `var(--${m}-${sanitizePropName(presetName)})`

  throw new Error(`Preset type "${type}" not mapped to PRESET_TYPES!`)
}

export function valueOrPresetFromCssProperty (propValue: string): ValueOrPresetResult {
  const match = propValue.match(/var\(--([_a-zA-Z0-9-]+)\)/)
  if (match != null) {
    const content = match[1]
    const [prefix, value] = content.split(/-(.+)/)
    const type = PRESET_TYPES[prefix]
    if (type != null) return { value, type }
    return { value: content, type: 'unknown' }
  }
  return { value: propValue, type: 'value' }
}

/**
 * Glue code to transition to direct CSS definitions in styles. Palette colors there
 * will use CSS variables. This function will convert these into color strings (just the palette color name)
 * so that the ColorPicker can understand.
 * Maybe this functionality will be moved into ColorPickerWidget or so.
 */
export function colorFromCssProperty (colorProp: string): Agjs2.ColorSettings {
  // Check if color is specified by referencing a custom property (-> palette color)
  let match = colorProp.match(/var\(--c-([a-zA-Z0-9-]+)\)/)
  if (match != null) return { value: match[1] }

  // Or is it in format rgb() or rgba() (-> try to convert it to hex)
  const rgbRegex = /^(rgba?)\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*([\d.]+)\s*)?\)$/
  match = colorProp.match(rgbRegex)
  if (match != null) {
    const [, , r, g, b, a] = match
    const color: RGBAColor = {
      r: parseInt(r, 10),
      g: parseInt(g, 10),
      b: parseInt(b, 10),
      a: typeof a !== 'undefined' ? parseFloat(a) : 1
    }
    return { value: RgbToHex(color) }
  }

  // else it is probably hex (TODO: check if it is a color constant or something else unsupported.
  // but with the current implementation this shouldn't be possible since the browser parses the color and always returns
  // rgb().)
  return { value: colorProp }
}

export function colorToCssProperty (color: Agjs2.ColorSettings): string {
  switch (colorMode(color)) {
    case 'none':
      return ''
    case 'custom':
      return color.value
    case 'semantic':
      throw new Error('Semantic colors not implemented yet')
      // return `var(--${color.value.slice(1)})`
    case 'palette':
      return `var(--c-${color.value})`
  }
}

export function getRgbFromColor (color: Agjs2.ColorSettings, theme: ThemeService): RGBAColor {
  switch (colorMode(color)) {
    case 'none':
      return { r: -1, g: -1, b: -1, a: -1 }
    case 'custom':
      return HexToRgb(color.value)
    case 'palette':
      return RgbFromPaletteColor(color.value, theme)
    case 'semantic':
      throw new Error('Semantic colors not implemented')
  }
}

export function RgbFromPaletteColor (colorId: string, theme: ThemeService): RGBAColor {
  const ccRecord = theme.collapsedColors.find(cc => cc.label === colorId)

  if (ccRecord != null) {
    try {
      return HexToRgb(ccRecord.rawValue)
    } catch {
      console.log('failed conversion:', ccRecord.rawValue)
      return HexToRgb('#AA0000')
    }
  } else {
    console.log(`WARNING! Palette color ${colorId} not found in theme.`)
    return { r: -2, g: 0, b: 0, a: 1 }
  }
}

export function generateCSSVariables (theme: ThemeService): string {
  let variables = theme.collapsedColors.map(cc => `  --c-${cc.label}: ${cc.rawValue};`)

  const addList = (attrName: string): void => {
    const prefix = prefixForPresetType(attrName)
    if (prefix == null) throw new Error(`no preset prefix for ${attrName}`)
    const pl = theme[attrName] as PresetList
    if (!Array.isArray(pl)) throw new Error(`Expected an array for ${attrName}, but got: ${JSON.stringify(pl)}.`)
    variables = variables.concat(pl.map(pvitem => ` --${prefix}-${sanitizePropName(pvitem.name)}: ${pvitem.rawValue};`))
  }

  addList('fontFamily')
  addList('fontSize')
  addList('fontWeight')

  addList('borderRadius')
  addList('borderWidth')

  return `:root {\n${variables.join('\n')}\n}`
}

export interface GeneratedElementStyling {
  classNames: string[]
  styles: string
}

// TODO: should only require one ElementStyling element. If a component uses separate styling props then they will probably be
// assigned to separate HTML components? Or how is this dealt with for complex components that have nested attributes?
// I probably need a 'parent' attribute for funuru style classes, so that if a component (like calendar) offers several
// ElementStyling-props, the overarching funuru style class has a value for each of those props.
export function generateElementStyling (styling: ST.Exp.ElementStyling, existingClasses: string = '', existingStyles: string = ''): GeneratedElementStyling {
  const asi = (text: string): string => {
    if (text.length > 0) {
      return (text.trim().slice(-1) !== ';' ? text.trim() + '; ' : text)
    } else {
      return ''
    }
  }

  return {
    classNames: existingClasses.split(' ').concat(styling.cssClasses),
    styles: [asi(existingStyles), asi(styling.cssText)].join(' ')
  }
}
