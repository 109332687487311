import { Ide } from './types'
import { Agjs2, ST } from './agjs/types'
import { F, randomId } from './agjs/factory'

class Clipboard {
  store: Ide.Store
  constructor ({ store }: { store: Ide.Store }) {
    this.store = store
  }

  get project () {
    return this.store.project
  }

  convertTextToPageElement (text: string): ST.Exp.Render.PageElement {
    const pageElementTemplate: ST.Exp.Render.PageElement = {
      t: 'RenderPageElement',
      cid: 'TextElementClass',
      id: randomId(),
      name: 'MyTextFromConvertTextToPageElement',
      isVisible: true,
      states: [],
      propValues: {
        content: F.makeText(text)
      }
    }
    return pageElementTemplate
  }

  convertHtmlToPageElement (input: HTMLElement): ST.Exp.Render.PageElement | null {
    let inner: (ST.Exp.Render.PageElement)[] = []
    if (input.hasChildNodes()) {
      inner = Array.from(input.childNodes).map(child => {
        switch (child.nodeType) {
          case Node.ELEMENT_NODE:
            return this.convertHtmlToPageElement(child as HTMLElement)
          case Node.TEXT_NODE:
            return this.convertTextToPageElement(child.textContent ?? '')
          default:
            console.log('Unknown node type while importing HTML:', child)
            return null
        }
      }).filter(i => i) as ST.Exp.Render.PageElement[]
    }

    const propValues = {
      tag: F.makeString(input.tagName.toLowerCase()),
      children: F.makeList([] as ST.Exp.Render.PageElement[])
    }

    const attrNames = input.getAttributeNames()

    attrNames.forEach(attrName => {
      console.log('TODO: add attributes using a record type')
      // propValues['attributes'] ||= F.makeLit('KVStrings', {})
      // propValues['attributes'].value[attrName] = input.getAttribute(attrName)
    })

    if (inner.length > 0) {
      propValues.children.items = inner
    }

    return {
      id: randomId(),
      name: input.tagName.toLowerCase(),
      t: 'RenderPageElement',
      cid: 'HtmlTagElementClass',
      isVisible: true,
      states: [],
      propValues
    }
  }

  async paste (target: Agjs2.NodeLocation) {
    try {
      const clipboardContents = await navigator.clipboard.read()
      for (const item of clipboardContents) {
        if (!item.types.includes('text/html')) {
          throw new Error('Clipboard does not contain HTML.')
        }
        const blob = await item.getType('text/html')
        const text = await blob.text()
        const html = new DOMParser().parseFromString(text, 'text/html')
        const rootNode = this.convertHtmlToPageElement(html.body)

        if (rootNode != null) {
          this.store.project.op.page.createElement('HtmlTagElementClass', {
            target,
            namePrefix: 'PastedHtml',
            propValues: rootNode.propValues,
            success: () => null
          })
        }
      }
    } catch (error) {
      this.clipboardError(error)
    }
  }

  async pasteAsText (target: Agjs2.NodeLocation) {
    try {
      // PermissionName is wrongly typed: https://github.com/microsoft/TypeScript/issues/33923
      const permissionName = 'clipboard-write' as PermissionName
      const permission = await navigator.permissions.query({ name: permissionName })
      if (permission.state === 'denied') {
        throw new Error('Not allowed to read clipboard.')
      }
      const clipboardText = await navigator.clipboard.readText()
      this.store.project.op.page.createElement('TextElementClass', {
        target,
        namePrefix: 'PastedText',
        propValues: {
          content: F.makeText(clipboardText)
        },
        success: () => null
      })
    } catch (error) {
      this.clipboardError(error)
    }
  }

  clipboardError (error: Error) {
    this.store.ui.showInfoDialog(`Cannot access the clipboard. Check your browser settings and whitelist this URL to allow clipboard access. (Error message from browser: ${error.message})`)
    console.error(error.message)
  }
}

export { Clipboard }
