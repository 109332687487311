import { ST } from '../agjs/types'
import { AppContext } from '../app_context'
import { useContext, useEffect, useState } from 'preact/hooks'
import { Fragment } from 'preact/jsx-runtime'
import { F, Runtime } from '../agjs'
import { FriendlyInput } from './expression_editors'
import { CloseFn, openPopover } from '../overlay_manager'
import { TypeCheck, TypeContext } from '../agjs/TypeCheck'
import { deepClone } from '../agjs/utils'
import { Scope } from '../agjs/scope'
import { VNode } from 'preact'
import { StandardDialog } from './dialogs'
import { toJS } from 'mobx'

type ArgChangeFn = (updatedArgument: ST.Exp.Expression) => void

export function openArgumentsEditor <T extends ST.Exp.CallMethod | ST.Exp.CallObjMethod> (callExp: T, scope: Scope, ref: HTMLElement, onSubmit: (newCall: T) => void): CloseFn {
  return openPopover((closeFn: () => void) => {
    const { runtime } = useContext(AppContext)

    const m = runtime.nodeIndex.lookup(callExp.methodId) as ST.Exp.Method

    const argsOnly: ST.Exp.ArgsOnly = {
      args: callExp.args,
      kwArgs: callExp.kwArgs
    }

    const paramsOnly: ST.ParamsOnly = {
      parameters: m.parameters,
      kwParameters: m.kwParameters,
      returnType: m.returnType,
      typeVars: m.typeVars,
      meta: m.meta
    }

    const title = m.name

    const updateArgs = (newArgs: ST.Exp.ArgsOnly): void => {
      const newExp = deepClone(callExp) as T
      newExp.args = newArgs.args
      newExp.kwArgs = newArgs.kwArgs

      if (newArgs.incomplete !== true) onSubmit(newExp)
    }

    return (
      <ArgsModalDialog
        wrapper='dialog'
        closeFn={closeFn}
        argsOnly={argsOnly}
        paramsOnly={paramsOnly}
        title={`Edit ${title}`}
        onSubmit={updateArgs}
        scope={scope}
      />
    )
    // return (
    //   <PopoverContent title='Edit arguments'>
    //     <p>This will open a ValueEditor for each parameter of that function.</p>
    //     {Object.keys(callExp.kwArgs).map(key => (
    //       <p key={key}>{key}: {callExp.kwArgs[key].value}</p>
    //     ))}
    //     <button className='btn btn-secondary' onClick={() => onSubmit(callExp)}>Insert frogs</button>
    //     <button className='btn btn-primary' onClick={closeFn}>Close</button>
    //   </PopoverContent>
    // )
  }, ref, 'bottom-start')
}

export interface ArgsModalProps {
  wrapper: 'dialog' | 'none'
  title: string
  argsOnly: ST.Exp.ArgsOnly
  paramsOnly: ST.ParamsOnly
  scope: Scope
  onSubmit: (argsOnly: ST.Exp.ArgsOnly) => void
  closeFn: CloseFn
}

export const ArgsModalDialog = ({ wrapper, title, argsOnly, paramsOnly, scope, onSubmit, closeFn }: ArgsModalProps): VNode => {
  const [rc, setRc] = useState<number>(0)
  const [ao, setAo] = useState<ST.Exp.ArgsOnly>(argsOnly)

  useEffect(() => { setAo(argsOnly); console.log('resetting ao buffer') }, [argsOnly])

  const updateArgs = (newArgs: ST.Exp.ArgsOnly): void => {
    setAo(newArgs)
    setRc(rc + 1)
  }

  const inner = (
    <ArgumentsEditor
      argsOnly={ao}
      paramsOnly={paramsOnly}
      scope={scope}
      onChange={updateArgs}
    />
  )

  if (wrapper === 'dialog') {
    return (
      <StandardDialog
        title={`Edit ${title}`}
        onCancel={() => closeFn()}
        onSubmit={() => { closeFn(); onSubmit(ao) }}
      >
        {inner}
      </StandardDialog>
    )
  } else {
    return inner
  }
}

// maybe extract this into some kind of external library (JSX independent)
interface PInfoInt {
  arg: ST.Exp.Expression | null
  argStatus: 'arg_undefined' | 'needs_type_var_assignment' | 'arg_exists' | 'from_default'
  dependsOnArgName: string[]
  dataTypes: ST.DT.TypeList
  status: 'assignable' | 'read_from_assigned' | 'unassigned' | 'no_var' | 'type_error'
}

/**
 * The SignatureProcessor prepares a list of typevars, parameters and optional argumentes to
 * have all info ready for rendering an argument list/kw args editor.
 * Task is mainly to establish the types (if a type of a param depends on a typevar) and default values,
 * as well as generate meaningful error messages.
 */
class SignatureProcessor {
  ctx: TypeContext

  // Maps typevar name to param id which assigns this.
  // This way the first param that assigns a type var can be detected.
  // (because this will allow to show the widget even if nothing is selected AND disable other widgets that just 'read'
  // this typevar)
  typevarParamMapping: Record<string, string> = {}
  tc: TypeCheck

  pInfo: Record<string, PInfoInt> = {}

  constructor (readonly runtime: Runtime, readonly paramsOnly: ST.ParamsOnly, readonly argsOnly: ST.Exp.ArgsOnly) {
    this.tc = runtime.tc
    this.ctx = {
      typeVars: paramsOnly.typeVars ?? [],
      assignments: {}
    }
  }

  allParams (): ST.Param[] {
    return this.paramsOnly.parameters.concat(Object.keys(this.paramsOnly.kwParameters).map(key => this.paramsOnly.kwParameters[key]))
  }

  // allParamInfos (): PInfoInt[] {
  //   this.paramsOnly.parameters.map(param => this.pInfo
  // }

  /**
  * Determines the status of a parameter/argument pair.
  * What is the data type (does it depend on type vars), is an argument given or is it missing, etc.
  *
  * @param param The parameter definition (either normal or keyword param)
  * @param givenArg The argument (or null if not specified)
  * @param argumentName A string that will be used for type error messages for refering to the paramter
  */
  determineArgStatus (param: ST.Param, givenArg: ST.Exp.Expression | null, argumentName: string): void {
    const defaultExp = (dt: ST.DT.TypeDef): ST.Exp.Expression => {
      // const rdt = runtime.tc.resolveTypeVar(dt, ctx)
      // if (rdt[0].type === 'AnyDbTable') {
      //   const table = store.project.dbTables[0]
      //   console.log('returning default table!')
      //   return F.makeGetNode(table.id, table.name)
      // }
      return this.runtime.defaultValueForDataType(dt, { allowNull: true })
    }

    const pi: PInfoInt = {
      arg: null,
      argStatus: 'arg_undefined',
      dependsOnArgName: [],
      dataTypes: param.dataTypes,
      status: 'no_var'
    }

    if (givenArg != null) {
      pi.arg = givenArg
      pi.argStatus = 'arg_exists'
    }

    const arg = pi.arg

    // Mark parameters that read a type variable for the first time (no prior parameter did this).
    const varnames = param.dataTypes.map(t => this.tc.dependsOnTypeVars(t, this.ctx)).flat()
    varnames.forEach(varname => {
      if (this.typevarParamMapping[varname] == null) this.typevarParamMapping[varname] = param.id
    })

    // Argument exists for this parameter?
    if (arg != null && pi.argStatus === 'arg_exists') {
      const dts = this.tc.inferType(arg)
      const res = this.tc.assignOrCheckTypeVar(param.dataTypes, dts, argumentName, this.ctx)

      switch (res.status) {
        case 'assigned':
          pi.dataTypes = res.varTypes ?? []
          pi.status = 'assignable'
          break
        case 'read':
          pi.dataTypes = res.assignedTypes ?? []
          pi.status = 'read_from_assigned'
          break
        case 'no_var':
          pi.dataTypes = param.dataTypes
          pi.status = 'no_var'
          break
        case 'error':
          pi.dataTypes = res.assignedTypes ?? res.varTypes ?? param.dataTypes
          pi.status = 'type_error'
          console.log('WARNING! Type error for', param.name, res.messages, this.ctx)
          break
        default:
          break
      }
    } else {
      // See if all variables this type depends on are either assigned by this parameter (or have been defined prior).
      const allDependenicesAreSetHereOrAreDefinedPrior = (varnames.length > 0 && varnames.every(n => this.typevarParamMapping[n] === param.id || this.ctx.assignments[n] != null))
      if (allDependenicesAreSetHereOrAreDefinedPrior) {
        pi.status = 'assignable'
        pi.argStatus = 'arg_undefined'

        const assignedDt = param.dataTypes.map(t => this.tc.resolveTypeVar(t, this.ctx)).flat()
        pi.dataTypes = assignedDt
        pi.arg = defaultExp(assignedDt[0])
      } else {
        // This parameter (with missing arg) is not the first to assign the type var, so we just need to decide
        // wether to hide the arg widget (until dependenices have been assigned) or if it is ok to generate
        // a default value because the dependenices (type vars) are assigned.
        if (varnames.some(n => this.ctx.assignments[n] == null)) {
          pi.status = 'read_from_assigned'
          pi.argStatus = 'needs_type_var_assignment'
        } else {
          // This else triggers if this parameter has no type var dependencies.
          pi.argStatus = 'from_default'
          const assignedDt = param.dataTypes.map(t => this.tc.resolveTypeVar(t, this.ctx)).flat()
          pi.dataTypes = assignedDt
          pi.arg = defaultExp(assignedDt[0])
        }
      }
    }
    this.pInfo[param.id] = pi
  }

  /**
  * If the data type of this param couldn't be determined because of a missing typevar assignment,
  * calling this method will add the name of the parameter that this param will depend on to the list
  * (dependsOnArgName). Not mandatory to call this method, but used for rendering meaningful error messages/warnings.
  * @param param Parameter that should be investigated and needs the list of dependenices found
  * @param paramList a list of all parameters in this signature (currently not handled in this class)
  */
  determineDependencies (param: ST.Param): void {
    if (this.pInfo[param.id].argStatus === 'needs_type_var_assignment') {
      const paramList = this.allParams()
      const neededvars = param.dataTypes.map(t => this.tc.dependsOnTypeVars(t, this.ctx)).flat()
      neededvars.forEach(varname => {
        const pDependency = paramList.find(p => this.typevarParamMapping[varname] === p.id)
        this.pInfo[param.id].dependsOnArgName.push(pDependency?.name ?? '')
      })
    }
  }
}

// end of extract-o-worthy code

interface ArgumentsEditorProps {
  argsOnly: ST.Exp.ArgsOnly
  paramsOnly: ST.ParamsOnly
  onChange: (newArgs: ST.Exp.ArgsOnly) => void
  // In comparison to onChange, onChangeArg will only be called with the argument that was actually edited.
  // This is used in PageElementInspector to be able to describe the undo events better (to say which paramter was changed).
  onChangeArg?: (paramId: string, value: ST.Exp.Expression) => void
  scope: Scope
}

export const ArgumentsEditor = ({ argsOnly, paramsOnly, onChange, onChangeArg, scope }: ArgumentsEditorProps): VNode => {
  const { runtime } = useContext(AppContext)

  const allArgs = argsOnly

  const mkArgChange = (paramId: string): ArgChangeFn => {
    return (arg: ST.Exp.Expression) => {
      const newArgs = allArgs

      const paramIndex = paramsOnly.parameters.findIndex(p => p.id === paramId)

      if (paramIndex === -1) {
        const kwParamId = Object.keys(paramsOnly.kwParameters).find(pk => paramsOnly.kwParameters[pk].id === paramId)
        if (kwParamId == null) throw new Error(`Param id ${paramId} is neither a param nor kwParam for (render list of param ids)`)
        newArgs.kwArgs[kwParamId] = arg
      } else {
        if (paramIndex > 0 && allArgs.args[paramIndex - 1] == null) {
          throw new Error('Cannot set the n-th argument when (n-1) is not set.')
        } else {
          newArgs.args[paramIndex] = arg
        }
      }
      if (onChangeArg != null) onChangeArg(paramId, arg)
      onChange(newArgs)
    }
  }

  // Similar functionality exists already in TypeCheck where I check the method signature compatibility.
  const sp = new SignatureProcessor(runtime, paramsOnly, argsOnly)

  paramsOnly.parameters.forEach((param, idx) => {
    sp.determineArgStatus(param, allArgs.args[idx], `argument #${idx + 1}`)
  })

  Object.keys(paramsOnly.kwParameters).forEach(pkey => {
    const param = paramsOnly.kwParameters[pkey]
    sp.determineArgStatus(param, allArgs.kwArgs[pkey], `argument for ${param.name}`)
  })

  paramsOnly.parameters.forEach(param => sp.determineDependencies(param))
  Object.keys(paramsOnly.kwParameters).forEach(pkey => sp.determineDependencies(paramsOnly.kwParameters[pkey]))

  // Object.keys(argsOnly.kwArgs).filter(pkey => pkey === 'dataSource').forEach(pkey => console.log('ds:', paramsOnly.kwParameters[pkey]))
  // Object.keys(argsOnly.kwArgs).forEach(pkey => console.log(':.:', pkey, ':.:', toJS(argsOnly.kwArgs[pkey])))

  // current problem:
  // when rendering value editors for each parameters, if that parameter assignes a type var, the type range is always the type
  // of the typeVar (not assigned value).
  // but parameters that READ the type var are restricted to the type of the assigned type var (or will not be shown if nothing was assinged).
  // (theoretical) problem is now what controls where a variable is set or read. currently the first argument will set a param, a later will read.
  // (in type script this is more flexible). but this implicit behavior is ok for funuru I guess.
  return (
    <Fragment>
      <div className='mb-3'>{paramsOnly.meta.description}</div>
      <hr />
      {sp.allParams().map(p => (sp.pInfo[p.id].argStatus === 'needs_type_var_assignment'
        ? <div className='mb-3' key={p.id}><h6>{p.name}</h6><input type='text' value={`(depends on ${sp.pInfo[p.id].dependsOnArgName.join(', ')})`} className='form-control' disabled /></div>
        : <div className='mb-3' key={p.id}>
          <FriendlyInput
            argOrPropId={p.id}
            key={p.id}
            dataTypes={sp.pInfo[p.id].dataTypes}
            name={p.name}
            value={sp.pInfo[p.id].arg ?? F.makeNull()}
            scope={scope}
            onChange={mkArgChange(p.id)}
          />
          </div>
      ))}
    </Fragment>
  )
}
