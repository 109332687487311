import { Agjs2, ST } from '../agjs/types'
import { NodeIndex } from '../agjs/node_index'
import { LibResolver } from '../agjs/lib_resolver'
import { Ide, Backend, LayoutFocusConfig, LayoutUiConfig, TableQueryResult } from '../types'
import { App } from '../app_context'
import { getAttr, getNextElementOrParent, getNextNode } from '../agjs/utils'

import { Op } from '../agjs/op'

import { toJS, makeAutoObservable, observable, runInAction } from 'mobx'
import { FetchRequest } from '@rails/request.js'

import { F } from '../agjs'

import { MutationCommands, MutationGroup } from './../agjs/mutation_commands'
import { jsonDigest } from '../agjs/json_digest'
import { CategoryWithComponents } from '../agjs/libs'
import { generateCSSVariables } from '../agjs/style_compiler'
import { BackendAPI } from '../backend_api'
import { defaultThemeService, ThemeService } from '../agjs/theme_service'

const DELEGATED_MUTATION_UI_MESSAGE = 'Waiting for server to modify database schema...'

interface RemoteMutation {
  groupId: string
  group: MutationGroup | null
  projectHash: string
  direction: 'add' | 'squash' | 'undo' | 'redo'
}

export interface RemoteJobStatus {
  status: 'idle' | 'triggered' | 'running' | 'completed' | 'failed'
  message: string
  percentage: number
  payload: Record<string, any>
}

export class ProjectState {
  backendAPI: BackendAPI
  queuedMutations: RemoteMutation[] = []
  stylesheet: CSSStyleSheet | undefined
  latestCss: string = ''
  twCachedClasses: string[] = []
  queueEmpty: boolean = true
  history: MutationCommands
  projectId: Agjs2.nodeId
  dbDescription: Agjs2.DbDescription
  projectState = 'notset'
  syncState: 'synced' | 'syncing' | 'error' = 'synced'
  browserOnlineStatus: 'unknown' | 'online' | 'offline' = 'unknown'
  currentPageId: Agjs2.nodeId | null = null
  currentWorkflowId: Agjs2.nodeId | null = null
  currentDataItemId: Agjs2.nodeId | null = null
  currentDbTableId: Agjs2.nodeId | null = null
  currentCid: Agjs2.nodeId = ''
  config: Backend.ProjectConfig = { urls: { prod: '', dev: '' } }
  lastSyncTimer: ReturnType<typeof setTimeout>
  op: Op

  remoteJobs: {
    devDeploy: RemoteJobStatus
    prodDeploy: RemoteJobStatus
    exportApp: RemoteJobStatus
  }

  app: App
  libReloads = 0
  themeService: ThemeService
  _full: Agjs2.Project = F.makeProject('')

  constructor (app: App) {
    const makeIdleRemoteJobStatus = (): RemoteJobStatus => {
      return {
        status: 'idle',
        message: '',
        percentage: 0,
        payload: {}
      }
    }

    this.remoteJobs = {
      devDeploy: makeIdleRemoteJobStatus(),
      prodDeploy: makeIdleRemoteJobStatus(),
      exportApp: makeIdleRemoteJobStatus()
    }

    makeAutoObservable(this, {
      backendAPI: false,
      queuedMutations: false,
      stylesheet: false,
      twCachedClasses: false,
      latestCss: false,
      history: false,
      store: false,
      nodeIndex: false,
      op: false,
      lastSyncTimer: false,
      pages: false,
      library: false
      // TODO: data auch hier ausklammern?
    })

    this.themeService = defaultThemeService
    this.app = app
    this.nodeIndex.beforeRemoveNode = (node: Agjs2.Node) => this.beforeRemoveNodeSwitchFocus(node)
    this.history = new MutationCommands(this.nodeIndex, {
      onMutate: this.persistMutation.bind(this),
      onLibsChanged: () => this.reloadLibs(),
      onDelegateToBackend: (mutationId, direction) => this.delegateMutationToBackend(mutationId, direction)
    })

    this.op = new Op(this.nodeIndex, this.history, this.app.runtime)
    this.backendAPI = new BackendAPI(this)

  }

  // processStudioSessionEvent(data: RemoteJobStatus & { type: string }) {
  //   console.log(data.type, data.status, data.message)
  //   switch(data.type) {
  //     case 'job:deploy-dev':
  //       Object.assign(this.remoteJobs.devDeploy, data)
  //       if (data.status === 'completed') {
  //         console.log('dev version of your app can be viewed at', data.payload.url)
  //       }
  //     break
  //     default:
  //       console.log('Unhandled studio sesseion event received:', data)
  //   }
  // }

  processBackendMessage (data: Backend.StatusUpdate): void {
    console.log('processBackendMessage:', data)
    switch (data.type) {
      case 'userapp.status.container': {
        const toastId = `container-status-${data.deployId}`

        switch (data.status) {
          case 'starting': {
            this.store.ui.toastManager.add('info', {
              title: data.env === 'prod' ? 'Published App' : 'Development server',
              body: 'Container with your app is starting...',
              pinned: true,
              id: toastId,
              icon: 'web_asset',
              progress: 20
            })
            break
          }
          case 'running': {
            this.store.ui.toastManager.update(toastId, {
              body: `Container is running. Visit the ${data.env} URL.`,
              progress: 100
            })
            break
          }
          case 'stopping':
          case 'stopped':
            this.store.ui.toastManager.remove(toastId)
        }
        break
      }
      case 'userapp.status.deploy': {
        let status: RemoteJobStatus['status'] = 'idle'

        const toastId = `deploy-status-${data.deployId}`

        switch (data.key) {
          case 'studio.deploy.preparing_update': {
            status = 'running'
            // TODO: have a low key indicator that the code update is going on, also have
            // custom message that update was finished - well, can be handled via devDeployJob
            console.log('userapp-manager: updating code in dev container')
            break
          }
          case 'deploy.update.completed': {
            status = 'completed'
            console.log('userapp-manager: updating code complete')
            break
          }
          case 'studio.deploy.bundling':
            this.store.ui.toastManager.remove(toastId)
            this.store.ui.toastManager.add('info', {
              title: data.env === 'prod' ? 'Publishing' : 'Development server',
              body: data.message,
              pinned: true,
              id: toastId,
              icon: 'cloud_upload',
              progress: 0
            })
            break
          case 'studio.deploy.preparing':
          case 'studio.deploy.queueing':
          case 'deploy.started':
          case 'deploy.update.started':
          case 'container.building':
          case 'container.updating_code':
          case 'container.stopping-previous':
          case 'container.starting':
          case 'container.running':
          case 'container.removing-previous':
          case 'webserver.config':
            console.log('webserver.config -> data.progress', data.progress)
            status = 'running'
            this.store.ui.toastManager.update(toastId, {
              body: data.message,
              progress: data.progress
            })
            break
          case 'deploy.completed':
          case 'studio.error':
            status = 'completed'
            // status = 'failed'
            this.store.ui.toastManager.update(toastId, {
              title: data.error === true ? 'Publishing app failed!' : 'New version published!',
              body: data.message,
              pinned: false,
              progress: undefined
            })
            break
          default:
            console.log('Unknown backend message key for deploy status:', data)
        }

        const update = {
          status,
          message: data.message,
          percentage: data.progress,
          payload: {}
        }

        Object.assign(data.env === 'dev' ? this.remoteJobs.devDeploy : this.remoteJobs.prodDeploy, update)
        break
      }
      default:
        console.log('Unhandled backend message received:', data)
    }
  }

  get pages (): ST.Exp.Render.PageRootElement[] {
    // TODO: migrate everything to Agjs2.project structure for starters, then take care of expressions & statements
    return this._full.pages
  }

  set pages (value) {
    this._full.pages = value
  }

  get data (): ST.Exp.Variable[] {
    return this._full.data
  }

  set data (value) {
    this._full.data = value
  }

  get dbTables (): Agjs2.DbTableDef[] {
    return this._full.db.tables
  }

  set dbTables (value: Agjs2.DbTableDef[]) {
    this._full.db.tables = value
  }

  /** * this is the project's built in library only */
  get library (): Agjs2.Library {
    return this._full.library
  }

  set library (value: Agjs2.Library) {
    this._full.library = value
  }

  setOnlineStatus (status: 'online' | 'offline'): void {
    this.browserOnlineStatus = status
  }

  get store (): Ide.Store {
    return this.app.store
  }

  get nodeIndex (): NodeIndex {
    return this.app.runtime.nodeIndex
  }

  get offline (): boolean {
    return this.browserOnlineStatus === 'offline'
  }

  async remoteSchemaChange (table: Agjs2.DbTableDef): Promise<boolean> {
    this.store.ui.blockUI(DELEGATED_MUTATION_UI_MESSAGE)

    const result = await this.backendAPI.createDbTable(table)
    // result = await this.backendAPI.dropDbTable(table)

    this.store.ui.unblockUI()

    if (result.status === 'success' && result.mutationGroup != null) {
      this.history.applyBackendMutation(result.mutationGroup)
      return true
    } else {
      console.log('db changes: backend process returned an ERROR.')
      // return true
      // TODO: show error message.
      return false
    }
  }

  async remoteDropTable (table: Agjs2.DbTableDef): Promise<boolean> {
    this.store.ui.blockUI(DELEGATED_MUTATION_UI_MESSAGE)

    const result = await this.backendAPI.dropDbTable(table)

    this.store.ui.unblockUI()

    if (result.status === 'success' && result.mutationGroup != null) {
      this.history.applyBackendMutation(result.mutationGroup)
      return true
    } else {
      console.log('db table dropping: backend process returned an ERROR.')
      // return true
      // TODO: show error message.
      return false
    }
  }

  // Internal use by ProjectState only
  async delegateMutationToBackend (mutationId: string, direction: 'undo' | 'redo'): Promise<void> {
    this.store.ui.blockUI(DELEGATED_MUTATION_UI_MESSAGE)
    const result = await this.backendAPI.runAsBackendMutation(mutationId, direction)

    if (result.status === 'success' && result.currentMutationId != null) {
      if (direction === 'undo') {
        this.history.applyBackendUndo(result.currentMutationId)
      } else {
        this.history.applyBackendRedo(result.currentMutationId)
      }
    } else {
      console.error('API call to delegate undo returned an error', result)
    }
    this.store.ui.unblockUI()
  }


  persistMutation (group: MutationGroup, direction: 'undo' | 'squash' | 'redo' | 'add'): void {
    const projectHash = jsonDigest(this._full)

    this.queuedMutations.push({
      groupId: group.id,
      group: ['add', 'squash'].includes(direction) ? group : null,
      direction,
      projectHash
    })

    runInAction(() => {
      this.queueEmpty = false
      this.syncPending()
    })
  }

  syncPending (force: boolean = false): void {
    if (force) {
      if (this.syncState === 'error') { this.syncState = 'synced' }
    }
    if (this.syncState === 'syncing') {
      console.log('syncPending(): sync in progress, ignoring request to sync.')
      return
    }
    clearTimeout(this.lastSyncTimer)

    this.lastSyncTimer = setTimeout(() => {
      if (this.syncState === 'synced' && this.queuedMutations.length > 0) {
        runInAction(() => {
          this.queueEmpty = true
          void this.postProjectChanges(this.queuedMutations.splice(0, this.queuedMutations.length))
        })
      }
    }, 800)
  }

  openProject (id: Agjs2.nodeId): void {
    this.projectId = id
    this.projectState = 'loading'
    // this.nodeIndex._dbg = true
    void this.fetchProjectData()
  }

  openPage (id: Agjs2.nodeId, openWorkbench: boolean = true): void {
    this.store.ui.focusPageElement(this.history.lookup(id) as ST.Exp.Render.PageElement)
    this.currentPageId = id
    if (openWorkbench) {
      this.store.ui.openWorkbench('page')
      this.storeLayout()
    }
  }

  openWorkflow (id: Agjs2.nodeId): void {
    this.currentWorkflowId = id
    this.store.ui.openWorkbench('workflows')
  }

  openDataItem (id: Agjs2.nodeId): void {
    this.currentDataItemId = id
    this.currentDbTableId = null
    this.store.ui.openWorkbench('data')
  }

  openDbTable (id: Agjs2.nodeId): void {
    this.currentDataItemId = null
    this.currentDbTableId = id
    this.store.ui.openWorkbench('data')
  }

  openElementEditor (cid: string): void {
    this.currentCid = cid
    this.store.ui.openWorkbench('elements')
  }

  reloadLibs (): void {
    this.nodeIndex.reloadProjectLibs()
    this.triggerComponentCategories()
  }

  undo (): void {
    this.history.undo()
  }

  redo (): void {
    this.history.redo()
  }

  triggerComponentCategories (): void {
    this.libReloads += 1
  }

  async triggerBuild (): Promise<void> {
    // Compilation now happens in backend.
    // The following snippet compiles the code in frontend which might come handy for easier debugging later:
    //
    // resolveAllSymbolsInLib(this.app.runtime)
    // const compiler = new Compiler(this.app.runtime)
    // const bundle = compiler.compileProject(this._full, false)

    // const fileList = bundle.files.map(f => {
    //   // console.log(`--- START OF ${f.path}/${f.name} ---`)
    //   // console.log(f.toString())
    //   // console.log(`--- END OF ${f.path}/${f.name} ---`)
    //   // console.log('')
    //   return {
    //     path: f.path,
    //     type: f.type,
    //     name: f.name,
    //     content: f.toString()
    //   }
    // })

    const request = new FetchRequest(
      'post',
      `/api/projects/${this.projectId}/build`,
      {
        body: JSON.stringify(
          {
            // bundle: fileList,
            studio_session_id: this.app.studioSessionId
          }
        )
      })

    this.remoteJobs.prodDeploy.status = 'triggered'
    const response = await request.perform()

    if (response.ok == null) {
      // TODO: display error message
      this.remoteJobs.prodDeploy.status = 'failed'
    }
  }

  async fetchProjectData (): Promise<void> {
    this.store.ui.blockUI('Loading project...')
    const request = new FetchRequest('get', `/api/projects/${this.projectId}`)

    const response = await request.perform()
    if (response.ok != null) {
      const json = await response.json
      runInAction(() => {
        this._full = json.data.project as Agjs2.Project

        this.dbTables = observable(this.dbTables)

        this.pages = observable(this.pages)
        this.data = observable(this.data)
        this.library = observable(this.library)

        this.history.setHistory(json.data.history, json.data.currentMutationGroupId)

        const libResolver = new LibResolver()
        this.nodeIndex.buildFromProject(this._full, libResolver.resolve)
        this.reloadLibs()

        const { focus, ui } = json.data.layout as Backend.ProjectLayout

        const pconfig = json.data.config as Backend.ProjectConfig

        this.config.urls.dev = pconfig.urls.dev
        this.config.urls.prod = pconfig.urls.prod

        // updateStyleSheetFromProjectPalette(this._full.theme)

        // this.regenerateTwCache()
        // void this.generateCss()
        this.generateCss()

        this.store.ui.unblockUI()
        this.projectState = 'loaded'

        this.openPage(this.pages[0].id, false)
        this.restoreFocus(focus, ui)

      })
    }
  }

  restoreFocus (focus: Partial<LayoutFocusConfig>, ui: Partial<LayoutUiConfig>): void {
    if (focus.currentPageId != null) {
      this.currentPageId = focus.currentPageId
      this.store.ui.focusPageElement(this.history.lookup(this.currentPageId) as ST.Exp.Render.PageElement)
    }

    if (ui.currentWorkspace != null) {
      if (window.location.hash.length === 0) this.store.ui.openWorkbench(ui.currentWorkspace)
    }
  }

  async storeLayout (): Promise<void> {
    const layout: Backend.ProjectLayout = {
      ui: {
        currentWorkspace: 'page'
      },
      focus: {
        currentPageId: this.currentPageId ?? undefined
      }
    }

    const request = new FetchRequest(
      'post',
      `/api/projects/${this.projectId}/layout`, {
        body: JSON.stringify({ layout: layout })
    })

    const response = await request.perform()
    if (response.ok != null) {
      // const json = await response.json
    } else {
      console.log('error storing layout config')
    }
  }

  registerStylesheet (sheet: CSSStyleSheet): void {
    sheet.replace(this.latestCss)
    this.stylesheet = sheet
  }

  updateStylesheet (css: string): void {
    this.latestCss = css
    if (this.stylesheet != null) this.stylesheet.replace(this.latestCss)
  }

  generateCss(): void {
    const css = generateCSSVariables(this.themeService)
    this.updateStylesheet(css)
  }

 /**
  * This is deprecated code for tailwind support.
  * Passes the used classes to the backend which then creates the tailwind css. This css is then injected
  * back here in the frontend.
  * New paradigm is that we (potentially have our own static sheet) and just generate custom properties that hold
  * color values etc.
  * 
  */
  /*
  regenerateTwCache (): void {
    this.twCachedClasses = []
    // TODO: this probably also needs special treatment for RenderPageElement nodes inside of class defs
    this.nodeIndex.getByElementType<ST.Exp.Render.PageElement>('RenderPageElement').forEach(element => {
      const esProps: ST.Exp.ElementStyling[] = []
      for (const p of this.app.runtime.getPropDefValues(element)) {
        if (p.prop.dataTypes[0].type === 'ElementStyling') {
          esProps.push(p.propValue as ST.Exp.ElementStyling)
        }
      }
      if (esProps.length > 0) {
        const res = generateElementStyling(esProps, '', '')
        res.classNames.forEach(cl => this.twCachedClasses.push(cl))
      }
    })
  }
  */

 /*
  async generateCss (): Promise<void> {
    const request = new FetchRequest(
      'post',
      `/api/projects/${this.projectId}/css`,
      {
        body: JSON.stringify(
          { theme: this._full.theme.twTheme, classes: this.twCachedClasses }
        )
      })

    const response = await request.perform()
    if (response.ok != null) {
      try {
        const json = await response.json
        if (json.result === 'ok') {
          // this.updateStylesheet(json.data.stylesheet)
          this.updateStylesheet(generateCSSVariables(this._full.theme) + '\n' + json.data.stylesheet)
        }
      } catch (e) {
        console.log('error fetching stylesheet')
      }
    }
  }

  /*
  updateTwCache(classList: string[]): void {
    // The cached list is not 100% accurate, over time (when styles are removed from an element)
    // unused styles/class names will pile up. Not really a problem unless extreme unthinkable cases.
    // Yet, auto garbage collection might be desirable at some point
    classList.forEach(cl => {
      if (!this.twCachedClasses.includes(cl)) this.twCachedClasses.push(cl)
    })
    void this.generateCss()
  }
 */

  async updateDbRow (table: Agjs2.DbTableDef, row: ST.Exp.Lit.RecordOfLit) {
    // TODO: same code as in insertIntoTable or so...
    const fieldValues: Record<string, string | number | boolean | Date> = {}
    Object.keys(row.fields).forEach(key => {
        const col = this.findDbCol(table, { name: key })
        const field = row.fields[key]
        switch (field.t) {
          case 'String':
          case 'Number':
          case 'Boolean':
            fieldValues[col.pgName] = field.value
            break
          case 'Timestamp':
            console.log(toJS(field))
            fieldValues[col.pgName] = (field as ST.Exp.Lit.Timestamp).value.toString()
            break;
          default:
            throw new Error(`Unsupported field value: ${field.t}`)
        }
      }
    )

    const request = new FetchRequest(
      'put',
      `/api/projects/${this.projectId}/db_row/${table.pgName}`,
      {
        body: JSON.stringify(
          {
            table: table.pgName,
            fields: fieldValues
          }
        )
      })

    const response = await request.perform()
    if (response.ok != null) {
      try {
        const json = await response.json
        if (json.result === 'ok') {
          return true
        }
      } catch (e) {
        console.log('error inserting into db table')
        return false
      }
    }
    return false
  }

  async deleteDbRow (table: Agjs2.DbTableDef, rowId: string): Promise<boolean> {
    const request = new FetchRequest(
      'delete',
      `/api/projects/${this.projectId}/db_row/${table.pgName}`,
      {
        query: { row_id: rowId }
      })

    const response = await request.perform()
    if (response.ok != null) {
      try {
        const json = await response.json
        return (json.cmd_tuples != 0)
      } catch (e) {
        console.log('error fetching db_row', e)
        return false
      }
    }
    return false
  }

  async fetchDbTable (table: Agjs2.DbTableDef): Promise<TableQueryResult> {
    // this.store.ui.blockUI('Generating fake data')
    const empty: TableQueryResult = { columns: [], rows: [], offset: 0, limit: 0}
    const request = new FetchRequest(
      'get',
      `/api/projects/${this.projectId}/db_row`,
      {
        query: { table: table.pgName } //, fake: true }
      })

    const response = await request.perform()
    if (response.ok != null) {
      try {
        const json = await response.json
        if (json.result === 'ok') {
          const columnsInOrder: Agjs2.DbColDef[] = [];
          (json.data.fields as string[]).forEach(pgColName => {
            const col = this.findDbCol(table, { pgName: pgColName })
            columnsInOrder.push(col)
          })

          const res: TableQueryResult = {
            columns: columnsInOrder.map(c => c.name),
            rows: [],
            offset: 0,
            limit: 10
          }

          json.data.rows.forEach((row: any[]) => {
            const rowRecord: ST.Exp.Lit.RecordOfLit = F.makeRecord({})
            row.forEach((cellContent: any, index: number) => {
              const field = columnsInOrder[index]
              switch (field.dataType.type) {
                case 'String':
                  rowRecord.fields[field.name] = F.makeString(cellContent)
                  break;
                case 'Timestamp':
                  // TODO: need to parse considering timestamp!
                  rowRecord.fields[field.name] = F.makeTimestamp(cellContent)
                  break;
                case 'Number':
                  rowRecord.fields[field.name] = F.makeNumber(cellContent)
                  break;
                case 'Boolean':
                  rowRecord.fields[field.name] = F.makeBoolean(cellContent)
                  break;
                default:
                  throw new Error(`Unsupported field value: ${field.t}`)
              }
            })
            res.rows.push(rowRecord)
          })
          this.store.ui.unblockUI()
          return res;
        }
      } catch (e) {
        this.store.ui.unblockUI()
        console.log('error fetching db_row', e)
        return empty
      }
    }
    this.store.ui.unblockUI()
    return empty
  }

  findDbCol (table: Agjs2.DbTableDef, { pgName, name }: { pgName?: string, name?: string }): Agjs2.DbColDef {
    if (pgName != null) {
      const field = table.columns.find(col => col.pgName === pgName)
      if (field == null) throw new Error(`No field with pgName ${pgName}`)
      return field
    } else {
      const field = table.columns.find(col => col.name === name)
      if (field == null) throw new Error(`No field with name ${name}`)
      return field
    }
  }

  findTableById (tableId: string): Agjs2.DbTableDef {
    const table = this.dbTables.find(t => t.id === tableId)
    if (table == null) throw new Error(`DbTable not found with id ${tableId}`)
    return table
  }

  async insertIntoDbTable (tableId: string, row: ST.Exp.Lit.RecordOfLit): Promise<boolean> {
    const table = this.findTableById(tableId)
    const fieldValues: Record<string, string | number | boolean | Date> = {}
    Object.keys(row.fields).forEach(key => {
        const col = this.findDbCol(table, { name: key })
        const field = row.fields[key]
        switch (field.t) {
          case 'String':
          case 'Number':
          case 'Boolean':
          case 'Timestamp':
            fieldValues[col.pgName] = field.value
            break;
          default:
            throw new Error(`Unsupported field value: ${field.t}`)
        }
      }
    )
    const request = new FetchRequest(
      'post',
      `/api/projects/${this.projectId}/db_row`,
      {
        body: JSON.stringify(
          {
            table: table.pgName,
            fields: fieldValues
          }
        )
      })

    const response = await request.perform()
    if (response.ok != null) {
      try {
        const json = await response.json
        if (json.result === 'ok') {
          return true
        }
      } catch (e) {
        console.log('error inserting into db table')
        return false
      }
    }
    return false
  }

  async postProjectChanges (mutationGroups: RemoteMutation[]): Promise<void> {
    if (this.syncState === 'syncing') {
      console.log('postProjectChanges(): WARNING: postProjectChanges called while syncState was still syncing')
      return
    }
    this.syncState = 'syncing'

    const request = new FetchRequest(
      'post',
      `/api/projects/${this.projectId}/mutations/sync`,
      {
        body: JSON.stringify(
          { mutationGroups, frontEndEpoch: Date.now() }
        )
      })

    const response = await request.perform()
    if (response.ok != null) {
      try {
        const json = await response.json

        runInAction(() => {
          if (mutationGroups.map(mg => mg.groupId).toString() !== json.data.ids.toString()) {
            throw new Error('Server did not return all mutation group ids as processed!')
          }
          this.syncState = 'synced'

          // Call sync again to handle any mutations that came in while sync was in progress
          this.syncPending()
        })
      } catch (e) {
        runInAction(() => {
          console.log('Error while awaiting response.json', e)
          this.syncState = 'error'
          this.queuedMutations.unshift(...mutationGroups)
          this.queueEmpty = this.queuedMutations.length === 0
        })
      }
    } else {
      runInAction(() => {
        this.syncState = 'error'
        this.queuedMutations.unshift(...mutationGroups)
        this.queueEmpty = this.queuedMutations.length === 0
      })
    }
  }

  /** If the given node is focused in the UI, select next best element (so given element can be deleted) */
  beforeRemoveNodeSwitchFocus (node: Agjs2.Node): void {
    const el = node as Agjs2.IndexableNode
    // console.log('beforeRemoveNodeSwitchFocus:', toJS(el))

    if (this.currentDataItemId === node.id) {
      const idx = this.data.findIndex(p => p.id === node.id)
      const nextEl = getNextNode(this.data, idx)
      // this.currentDataItemId = this.data[idx === this.data.length - 1 ? idx - 1 : idx + 1].id
      this.currentDataItemId = nextEl?.id ?? null
    }

    if (this.currentDbTableId === node.id) {
      const idx = this.dbTables.findIndex(p => p.id === node.id)
      const nextEl = getNextNode(this.dbTables, idx)
      this.currentDbTableId = nextEl?.id ?? null
    }

    switch (el.t) {
      case 'RenderPageElement':
        if (el.cid === 'PageRootClass') {
          const pageId = el.id
          const pIdx = this.pages.findIndex(p => p.id === pageId)
          const newPage = this.pages[pIdx === this.pages.length - 1 ? pIdx - 1 : pIdx + 1]
          this.openPage(newPage.id)
        } else {
          const location = this.nodeIndex.location(el)
          const parent = this.nodeIndex.lookup(location.parentId) as ST.Exp.Render.PageElement
          // const p = location.parentAttr.split('.')[1]
          // const idx = (parent.propValues[p] as ST.Exp.PageElementList).items.indexOf(el)
          if (location.parentAttr !== 'propValues.children.items') {
            console.log('WARNING: beforeRemoveNodeSwitchFocus(): parentAttr is not the usual propValues.children.items, check if this is handled correctly.')
          }
          const peList = getAttr(parent, location.parentAttr) as ST.Exp.Render.PageElement[]
          const idx = peList.findIndex(item => item.id === el.id)
          // TODO: refactor to use index from location
          this.store.ui.pageEditor.highlightedElement = null
          console.log('requesting next element:', toJS(parent), toJS(peList), idx)
          const nextEl = getNextElementOrParent(parent, location.parentAttr, idx)
          console.log('next element to focus:', toJS(nextEl))
          this.store.ui.focusPageElement(nextEl)
        }
        break
      default:
        break
    }
  }

  getElementClassDef (node: Agjs2.InstanceNode): Agjs2.ClassDef {
    return this.app.runtime.getElementClassDef(node)
  }

  getPropDef (element: Agjs2.InstanceNode, propKey: Agjs2.nodeId): ST.Param {
    return this.app.runtime.getPropDef(element, propKey)
  }

  get currentPage (): ST.Exp.Render.PageRootElement | null {
    const pageIdx = this.pages.findIndex(p => p.id === this.currentPageId)
    if (pageIdx === -1) {
      if (this.currentPageId != null) {
        runInAction(() => { this.currentPageId = this.pages[0].id })
        return this.pages[0]
      } else {
        return null
      }
    } else {
      return this.pages[pageIdx]
    }
  }

  get currentDbTable (): Agjs2.DbTableDef | null {
    if (this.currentDataItemId != null) {
      return this.nodeIndex.lookup(this.currentDataItemId) as Agjs2.DbTableDef
    } else {
      return null
    }
  }

  get currentDataItem (): ST.Exp.Variable | null {
    if (this.currentDataItemId != null) {
      return this.nodeIndex.lookup(this.currentDataItemId) as ST.Exp.Variable
    } else {
      return null
    }
  }

  get currentWorkflow (): Agjs2.Workflow | null {
    if (this.currentWorkflowId != null) {
      return this.nodeIndex.lookup(this.currentWorkflowId) as Agjs2.Workflow
    } else {
      return null
    }
  }

  get componentsByCategories (): CategoryWithComponents[] {
    console.log('Regenerating component list:', this.libReloads)
    return this.app.runtime.libs.classesByCategories()
  }
}
