import { VNode, render, Fragment, ComponentChildren } from 'preact'
import { useContext, useRef, useEffect, useMemo } from 'preact/hooks'
import { observer } from 'mobx-react'

import { AppContext } from '../app_context'
import { Agjs2, ST } from '../agjs/types'
import { Ide } from '../types'
import { Panel, EntityTags, ListViewWrapper } from '../pcomponents'
import { PageElementLibrary } from './PageElementLibrary'

import { generatePageElementContextMenuFunc } from '../helpers'
import { PageIframeContent } from './page_iframe_content'
import { PageElementInspector } from '../pcomponents/page_element_inspector'
import { makeHotKeyHandler, makeElementKeyHandler } from '../hotkeys'
import { BenchDesk, SidebarContainer, WorkbenchViewWrapper } from '../pcomponents/workbench_ui'

interface FunuruWindowExt {
  iframeStylesheetUrl: string
}

const IframeHeader = ({ css }: { css: string }): VNode => {
  return (
    <Fragment>
      <title>Page Editor (iframe)</title>
      <link rel='preconnect' href='https://fonts.googleapis.com' />
      <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin='' />
      <link href='https://fonts.googleapis.com/css2?family=Barlow:wght@700&family=Inter&display=swap' rel='stylesheet' />
      <meta content='width=device-width,initial-scale=1' name='viewport' />
      <link rel='stylesheet' href={(window as unknown as FunuruWindowExt).iframeStylesheetUrl} />
      <style title='project'>{css}</style>
      <script>document.adoptedStyleSheets = [new CSSStyleSheet()]</script>
    </Fragment>
  )
}

const PageIframe = observer(({ globalMouseMove, registerStylesheet }) => {
  const app = useContext(AppContext)
  const iframe = useRef<HTMLIFrameElement>(null)
  const { projectId, currentPageId } = app.store.project

  useEffect(() => {
    if (iframe.current != null) {
      const iframeWindow = iframe.current.contentWindow
      if (iframeWindow == null) throw new Error('iframe.current.contentWindow is null')

      render(<AppContext.Provider value={app}><IframeHeader css='' /></AppContext.Provider>, iframeWindow.document.head)
      render(<AppContext.Provider value={app}><PageIframeContent globalMouseMove={globalMouseMove} /></AppContext.Provider>, iframeWindow.document.body)

      const findProjectSheet = (): CSSStyleSheet => {
        // for (const sheet of iframeWindow.document.styleSheets) {
        //   const owner = sheet.ownerNode

        //   if (owner != null && (owner as HTMLElement).title === 'project') {
        //     return sheet
        //   }
        // }
        // throw new Error('project stylesheet tag not found in iframe')
        return iframeWindow.document.adoptedStyleSheets[0]
      }

      iframeWindow.addEventListener('keypress', keyHandler)

      registerStylesheet(findProjectSheet())

      return () => {
        iframeWindow.removeEventListener('keypress', keyHandler)
      }
    }
  }, [projectId, currentPageId])

  const keyHandler = useMemo(() => makeHotKeyHandler(app.store), [app.store])

  if (projectId != null && currentPageId != null) {
    return (
      <div className='min-w-100 min-h-100 w-100 h-100 position-relative'>
        <iframe id='page-editor-iframe' ref={iframe} className='position-absolute top-0 start-0 w-100 h-100' />
      </div>
    )
  } else {
    return <span>No page selected</span>
  }
})

const TreeNode = observer(({ elementNode, children, collapsed }: { elementNode: ST.Exp.Render.PageElement, children: ComponentChildren, collapsed?: boolean }) => {
  const { store, runtime } = useContext(AppContext)

  // if (!children || (Array.isArray(children) && children.length == 0)) return null

  const hasChildren = !(children == null || (Array.isArray(children) && children.length === 0))

  const ca = store.project.nodeIndex.ca(elementNode) as Ide.CustomAttrs
  // FIXME: investigate re-rendering whenever we are hovering in page
  // console.log('excessively rendering tree node', elementNode.id)

  const cl = 'ps-1 node-title ' +
    (ca.hover ? ' highlighted' : '') +
    (ca.focus ? ' focused' : '') +
    (!hasChildren ? ' no-children' : '')

  const childWrapperCl: string[] = []

  const marker = ca.dragMarker?.marker
  if (marker != null) {
    if (marker === 'top' || marker === 'left') childWrapperCl.push(' border-2 border-top border-t-focusblue pt-1 mt-1')
    if (marker === 'bottom' || marker === 'right') childWrapperCl.push(' border-2 border-bottom border-b-focusblue pb-1 mb-1')
  }

  const classDef = store.project.getElementClassDef(elementNode)
  const classTitle = <EntityTags classDef={classDef} />

  const title = (
    <span title={`ID ${elementNode.id}`}>{elementNode.name} {classTitle}</span>
  )

  const mouseEnter = (ev: MouseEvent): void => { ev.stopPropagation(); store.ui.setHover(elementNode) }
  const click = (): void => { store.ui.focusPageElement(elementNode) }

  const drags = (event: DragEvent): void => { event.stopPropagation(); store.ui.dndBuffer.startDragging(event, elementNode) }
  const drage = (): void => { store.ui.dndBuffer.endDragging() }

  const loc = store.project.nodeIndex.location(elementNode)

  const parent = store.project.nodeIndex.lookup(loc.parentId) as ST.Exp.Render.PageElement | Agjs2.Project
  const parentAttr = loc.parentAttr

  const setDragTarget = (...args: Parameters<typeof store.ui.dndBuffer.targetPageElement>): void => store.ui.dndBuffer.targetPageElement(...args)

  const drop = (): void => {
    store.ui.dndBuffer.dropOnTarget()
  }

  const drag = (ev: DragEvent): void => {
    ev.stopPropagation()
    ev.preventDefault() // otherwise onDrop event will not fire here.
    // beginn throttle
    if (parent.t === 'Project') {
      if (elementNode != null && runtime.isRootElement(elementNode)) {
        setDragTarget(elementNode, 'children')
      } else { console.warn('I do not understand this dragover event2: no parent but not root element.', elementNode) }
    } else if (elementNode != null) {
      // element node and parent exist, so insert at the parent/propkey (next to this element)

      // get coordinates relative to element dimensions.
      const rect = (ev.target as HTMLElement).getBoundingClientRect()
      const relY = (ev.y - rect.top) / rect.height

      if (parentAttr !== 'propValues.children.items') throw new Error(`Unexpected parentAttr: ${parentAttr}`)
      // formerly, I used parentAttr directly (instead of 'children') but after some unknown refactoring, or maybe I just
      // had never tested it properly, it started feeding the full path here (propValues.children.items).
      // I think in the page/element context I decided that for simplicity, I just pass an attribute name which of course
      // refers to a key in propValues (and most likely children). In PageElement drag/drop code, 'children' is hardcoded
      // in all places, so just do the same here (by making sure that we only translate propValues.children.items)
      setDragTarget(parent, 'children', elementNode, relY < 0.5 ? 'top' : 'bottom')
    } else {
      // no element node, but parent, this is an empty child placeholder, so insert here.
      if (parentAttr !== 'propValues.children.items') throw new Error(`Unexpected parentAttr: ${parentAttr}`)
      setDragTarget(parent, 'children')
    }
    // end throttle
  }

  const dragStartHandlers = runtime.isRootElement(elementNode)
    ? {}
    : {
        draggable: true,
        onDragstart: drags,
        onDragend: drage
      }

  const contextMenuHandler = (ev: MouseEvent): void => store.ui.contextMenu(
    ev,
    generatePageElementContextMenuFunc(elementNode, store)
  )

  return (
    <div
      className={collapsed != null ? childWrapperCl.join(' ') + ' collapsed' : childWrapperCl.join(' ')}
      onDragOver={drag}
      onDrop={drop}
    >
      <div
        className={cl}
        tabIndex={0}
        onKeyDown={makeElementKeyHandler(store, elementNode)}
        onContextMenu={contextMenuHandler}
        onMouseEnter={mouseEnter}
        onMouseLeave={() => store.ui.setHover(null)}
        onFocus={click} {...dragStartHandlers}
      >
        {title}
      </div>
      <div className='ps-2 ms-2 border-2 border-start node-content'>{children}</div>
    </div>
  )
})

const PageElementsTree = observer(({ elementNodes }: { elementNodes: ST.Exp.Render.PageElement[] }): VNode | null => {
  if (elementNodes.length === 0) return null
  const inner = elementNodes.map(el => {
    const childNodes = el.propValues.children as ST.Exp.PageElementList

    return (
      <TreeNode
        elementNode={el}
        key={el.id}
      >
        {childNodes != null && <PageElementsTree elementNodes={childNodes.items} />}
      </TreeNode>
    )
  })
  return <Fragment>{inner}</Fragment>
})

// Old Page workbench pre bootstrap
// const PageWorkbenchWithDock = observer(() => {
//   const { store } = useContext(AppContext)
//
//   const dm = useMemo(() => new DockManager(store), [store])
//
//   const mkDw = (title: string, name: string, parentContainer: string, content: VNode) => {
//     const ds = store.ui.getDockableState(name, parentContainer)
//
//     return [ds,
//       <DockableWindow title={title} dockableState={ds} manager={dm}>
//         {content}
//       </DockableWindow>]
//   }
//
//   const dockableViews = [
//     mkDw('Structure', 'tree-window',
//       'left-container',
//       (<Panel>
//         {(store.project.currentPage != null) &&
//           <ListViewWrapper>
//             <PageElementsTree elementNodes={[store.project.currentPage]} />
//           </ListViewWrapper>}
//        </Panel>)
//     ),
//     mkDw('Library', 'library-window',
//       'left-container',
//       (<PageElementLibrary />)
//     ),
//     mkDw('Inspector', 'inspector-window',
//       'right-container', (<PageElementInspector element={store.ui.pageEditor.focusedElement} />)
//     )
//   ]
//
//   const outerMouseMove = (ev) => {
//     // TODO: optimize
//     const movingDockable = store.ui.getMovingDockable()
//     if (movingDockable != null) {
//       dm.move(ev, movingDockable)
//     }
//   }
//
//   const iframeMouseMove = (ev) => {
//     const movingDockable = store.ui.getMovingDockable()
//     if (movingDockable != null) {
//       dm.move(ev, movingDockable)
//     }
//   }
//
//   return (
//     <div className='d-flex flex-fill' onMouseMove={outerMouseMove}>
//       <FloatingWindows views={dockableViews} manager={dm} />
//       <ToolbarGripper />
//       <VerticalDock name='left-container' attachFrom='right' views={dockableViews} manager={dm} />
//       <div className='h-0 min-h-full overflow-auto flex-grow'>
//         <PageIframe globalMouseMove={iframeMouseMove} registerStylesheet={sheet => store.project.registerStylesheet(sheet)} />
//       </div>
//       <VerticalDock name='right-container' attachFrom='left' views={dockableViews} manager={dm} />
//     </div>
//   )
// })

const PageWorkbench = observer(() => {
  const { store } = useContext(AppContext)

  return (
    <WorkbenchViewWrapper>
      <SidebarContainer>
        <Panel>
          {(store.project.currentPage != null) &&
            <ListViewWrapper>
              <PageElementsTree elementNodes={[store.project.currentPage]} />
            </ListViewWrapper>}
        </Panel>
        <PageElementLibrary />
      </SidebarContainer>
      <BenchDesk>
        <PageIframe globalMouseMove={() => null} registerStylesheet={sheet => store.project.registerStylesheet(sheet)} />
      </BenchDesk>
      <SidebarContainer>
        <PageElementInspector element={store.ui.pageEditor.focusedElement} />
      </SidebarContainer>
    </WorkbenchViewWrapper>
  )
})

export { PageWorkbench }
