import { makeAutoObservable } from 'mobx'
import { VNode } from 'preact'

const DEFAULT_TIMEOUT = 5000

type ToastType = 'info' | 'error' | 'job'

interface ToastDef {
  title: string
  icon: string
  body: string | VNode
  id?: string
  progress?: number
  pinned?: boolean
}

export class Toast {
  id: string
  type: ToastType
  content: ToastDef
  onRemove: () => void
  timerRef: NodeJS.Timeout

  constructor (type: ToastType, content: ToastDef, onRemove: () => void) {
    this.type = type
    this.id = content.id ?? `${Math.random() * 100000}`
    this.content = content
    this.onRemove = onRemove
    if (this.content.pinned == null) {
      this.timerRef = setTimeout(() => { console.log(`Removing: ${content.body as string}`); this.remove() }, DEFAULT_TIMEOUT)
    }
    makeAutoObservable(this, { onRemove: false })
  }

  remove (): void {
    clearTimeout(this.timerRef)
    this.onRemove()
  }
}

export class ToastManager {
  toasts: Toast[]
  constructor () {
    this.toasts = []
    makeAutoObservable(this)
  }

  add (type: ToastType, content: ToastDef): Toast {
    const tid = content.id ?? `t${Math.random() * 100000}`
    const toast = new Toast(type, { ...content, id: tid }, () => this.remove(tid))
    this.toasts.push(toast)
    return toast
  }

  remove (tid: string): void {
    const idx = this.toasts.findIndex(t => t.id === tid)
    if (idx > -1) this.toasts.splice(idx, 1)
  }

  update (tid: string, content: Partial<ToastDef>): void {
    const idx = this.toasts.findIndex(t => t.id === tid)
    if (idx > -1) {
      Object.assign(this.toasts[idx].content, content)
    }
  }

  /***
   * @returns An ordered list of toasts. The oldest (regular) slices will be returned as the first elements.
   * After all regular slices, the pinned slices will follow, also with the oldest pinned slice as first elements.
   * This is suited for rendering a toast slice for each list element just by traversing the list.
   */
  get list (): Toast[] {
    const pinnedToasts: Toast[] = []
    const remainingToasts: Toast[] = []

    this.toasts.forEach(toast => {
      if (toast.content.pinned != null) {
        pinnedToasts.push(toast)
      } else {
        remainingToasts.push(toast)
      }
      return toast
    })
    return remainingToasts.concat(pinnedToasts)
  }

  get empty (): boolean {
    return this.toasts.length === 0
  }
}
