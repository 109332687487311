import { VNode, ComponentChildren } from 'preact'
import { observer } from 'mobx-react'

import { ListViewWrapper } from '../pcomponents'

// import { generatePageElementContextMenuFunc } from '../helpers'
// import { makeHotKeyHandler, makeElementKeyHandler } from '../hotkeys'

export type TreeItemCallback = <E>(ev: E, node: TreeNode) => void

export interface TreeNode {
  leaf: boolean
  collapsed: boolean
  id: string
  children: TreeNode[]
  title: string
}

interface TreeViewParams {
  nodes: TreeNode[]
  focusedNodeId: string | null
  onClick?: TreeItemCallback
  onContextMenu?: TreeItemCallback
}

interface TreeNodeMarkupParams {
  node: TreeNode
  focusedNodeId: string | null
  onClick: TreeItemCallback
  onContextMenu: TreeItemCallback
}

const TreeNodeMarkup = observer(({ node, onClick, onContextMenu, focusedNodeId }: TreeNodeMarkupParams): VNode => {
  const cl = 'pl-1 node-title' + (focusedNodeId === node.id ? 'focused' : '')

  const childWrapperCl: string[] = []

  // const classTitle = <EntityTags classDef={classDef} />

  const title = (
    <span title={`ID ${node.id}`}>{node.title}</span>
  )

  // const contextMenuHandler = (ev) => store.ui.contextMenu(
  //   ev,
  //   generatePageElementContextMenuFunc(elementNode, store)
  // )
  let children: ComponentChildren = null

  if (node.children.length > 0) {
    children = (
      <div className='pl-1 border-l-2 border-l-neutral-400 node-content'>
        {node.children.map(child => <TreeNodeMarkup key={child.id} node={child} onClick={onClick} onContextMenu={onContextMenu} focusedNodeId={focusedNodeId} />)}
      </div>
    )
  }

  return (
    <div
      className={node.collapsed ? childWrapperCl.join(' ') + ' collapsed' : childWrapperCl.join(' ')}
    >
      <div
        className={cl}
        tabIndex={0}
        onFocus={(ev) => onClick(ev, node)}
      >
        {title}
      </div>
      {children}
    </div>
  )
})

export const TreeView = observer(({ nodes, focusedNodeId, onClick, onContextMenu }: TreeViewParams): VNode => {
  // const { store, runtime } = useContext(AppContext)
  const emptyCb = (): null => null
  return <ListViewWrapper>{nodes.map(node => <TreeNodeMarkup node={node} key={node.id} onClick={onClick ?? emptyCb} onContextMenu={onContextMenu ?? emptyCb} focusedNodeId={focusedNodeId} />)}</ListViewWrapper>
})
