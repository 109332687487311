/**
 * Building blocks that are shared between many workbenches.
 * Generate workbench layouts and sidebars.
 */

import { exClass, SimpleWrapper } from "../jsx_helpers"

/**
 * The outer most wrapper of what is rendered inside an active workbench.
 * Inside the WorkbenchWrapper, SidebarPanels can be used.
 */
export const WorkbenchViewWrapper: SimpleWrapper<{ onMouseMove?: (ev: MouseEvent) => void }> = ({ children, onMouseMove }) => {
  return (
    <div className='flex-fill d-flex flex-row' onMouseMove={onMouseMove}>
      {children}
    </div>
  )
}

export const SidebarContainer: SimpleWrapper = ({ children }) => (<div className='sidebar-container'>{children}</div>)

/**
 * BenchDesk is a wrapper for the (usually) middle part of a workbench, the actual work area that expands as needed.
 * Left and right are usually flanked by detachable sidebars
 */
// TODO: make sure overflowing in X direction is handled correctly too
export const BenchDesk: SimpleWrapper<{ className?: string }> = ({ children, className }) => (
  <div className={exClass('min-h-100 overflow-auto flex-fill', className)}>{children}</div>
)

// TODO: move here the following components:
//
// * Panel: A wrapper (margin and bg color) which contains all actual UI components like buttons, lists, etc.
// panel can also have a title. Should be renamed! actual low level UI is contained inside sections in the panel.

// Different types of sections:
// (should be one overarching section which is then controlled by options (collapsible yes/no header yes/no etc.)
// * Section: container holding low level ui components like buttons and input fields. different bg color.
// * FormSection: has a margin to better hold input boxes etc.
// * CollapsibleSection: has a header and can collapse its content.
