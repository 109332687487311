import { useContext } from 'preact/hooks'
import { observer } from 'mobx-react'

import { AppContext } from '../app_context'
import { Agjs2, ST } from '../agjs/types'
import { Ide } from '../types'
import { CollapsibleSection, Panel, IconButton, DataTypeTag } from '../pcomponents'

import { NameEditor } from '../pcomponents/prop_editor'
import { ArgumentsEditor } from './arguments_editor'
import { F } from '../agjs'
import { toJS } from 'mobx'
import { deepClone } from '../agjs/utils'
import { VNode } from 'preact'

const propDefsWithValue = (element: ST.Exp.Render.PageElement, store: Ide.Store): Agjs2.PropDefValue[] => {
  return Object.keys(element.propValues).map(key => ({
    prop: store.project.getPropDef(element, key),
    propValue: element.propValues[key]
  }))
}

const InspectorRow = ({ children }): VNode => (
  <div className='p-2 border-ridge border-b'>{children}</div>
)

const CustomState = observer(({ state }: { state: ST.Exp.Variable }) => {
  const { store } = useContext(AppContext)

  return (
    <InspectorRow>
      <div class='flex items-center'>
        {state.name}<DataTypeTag dataType={state.dataType} />
        <IconButton icon='edit_note' title='Edit default values' onClick={() => store.ui.showCustomStateEditor(state)} />
        <IconButton icon='delete' title='Remove custom state' onClick={() => store.project.op.generic.deleteGenericNode(state)} />
      </div>
    </InspectorRow>
  )
}
)

const PageElementInspector = observer(({ element }: { element: ST.Exp.Render.PageElement | null }): VNode => {
  const { store } = useContext(AppContext)

  if (element != null) {
    const changeName = (newName: string): void => {
      store.project.op.generic.renameElement(element, newName)
    }

    const cd = store.project.getElementClassDef(element)

    const argsOnly: ST.Exp.ArgsOnly = {
      args: [],
      kwArgs: deepClone(element.propValues)
    }

    const paramsOnly: ST.ParamsOnly = {
      parameters: [],
      kwParameters: cd.props,
      returnType: F.makeNoneType(),
      typeVars: cd.typeVars,
      meta: cd.meta
    }

    const scope = store.project.nodeIndex.sc(element)

    const updateArg = (paramId: string, newArg: ST.Exp.Expression): void => {
      // TODO: will break with non kw-args (?) (index based args)... important to have this unified with method signatures etc.
      // needs a new genericOp for this.
      store.project.op.generic.setProp(element, paramId, toJS(newArg))
    }

    console.log('PageElementInspector argsOnly:', element.id, element.name, Object.keys(argsOnly.kwArgs))

    return (
      <div style='width: 380px'>
        <NameEditor element={element} onChange={changeName} />
        <ArgumentsEditor
          argsOnly={argsOnly}
          paramsOnly={paramsOnly}
          onChange={() => null}
          onChangeArg={updateArg}
          scope={scope}
        />
        <CollapsibleSection title='Custom states'>
          {element.states.map(p => <CustomState state={p} key={p.id} />)}
          <InspectorRow>
            <IconButton icon='add' label='Add custom state' onClick={() => store.ui.openCustomStateEditor(element)} />
          </InspectorRow>
        </CollapsibleSection>
      </div>
    )

    // return (
    //   <Panel>
    //     <div class='p-2'>
    //       <NameEditor element={element} onChange={changeName} />
    //     </div>
    //     <CollapsibleSection title='Properties'>
    //       {propSectionPropDefs.map(p => <PropEditor element={element} propDefValue={p} />)}
    //     </CollapsibleSection>
    //     <CollapsibleSection title='Custom states'>
    //       {element.states.map(p => <CustomState state={p} />)}
    //       <InspectorRow>
    //         <IconButton icon='add' label='Add custom state' onClick={() => store.ui.openCustomStateEditor(element)} />
    //       </InspectorRow>
    //     </CollapsibleSection>
    //     {stylesSectionPropDefs.length > 0 ?
    //     <CollapsibleSection title='Styling'>
    //       {stylesSectionPropDefs.map(p => <PropEditor element={element} propDefValue={p} />)}
    //     </CollapsibleSection> : null}
    //   </Panel>
    // )
  } else {
    return (
      <Panel>
        <div class='m-2 text-center'>
          (No element selected)
        </div>
      </Panel>
    )
  }
})

export { PageElementInspector }
