import { VNode } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { DropdownButton } from '.'
import { PresetList } from '../agjs/theme_service'
import { command, divider, header, MenuItem } from './menu'

const LENGTH_UNITS = ['px', 'cm', 'mm', 'in', 'pt', 'pc', 'em', 'rem', '%', 'vw', 'vh', 'vmin', 'vmax']

const NONE: PresetList = [
  {
    name: '-',
    label: '-',
    rawValue: '',
    propValue: ''
  }
]

type CSSPropUnitType = 'length' | 'arbitrary'

interface PropValuePickerProps {
  propName: string
  style: CSSStyleDeclaration
  options: PresetList
  onChange: (style: CSSStyleDeclaration) => void
  onFocus?: () => void
  onBlur?: () => void
  unitType?: CSSPropUnitType
  unit?: string
  onChangeMode?: (newMode: PropValuePickerMode, unit: string | null) => void
}

const ModeSwitcher = (props: PropValuePickerProps & { mode: PropValuePickerMode }): VNode => {
  const { mode, onChangeMode } = props

  const unitSelectionMenu = (): MenuItem[] => {
    if (props.unitType === 'length') {
      const head: MenuItem[] = [
        divider(),
        header('Unit')
      ]
      return head.concat(
        LENGTH_UNITS.map(uname => command(uname, () => onChangeMode?.('unit', uname), undefined, { checked: mode === 'unit' && props.unit === uname, radio: true }))
      )
    } else {
      return []
    }
  }

  const menuGen = (): MenuItem[] => {
    const menuHead: MenuItem[] = [
      header('Edit value as'),
      command('Theme preset', () => onChangeMode?.('options', ''), undefined, { checked: mode === 'options', radio: true })
    ]

    return menuHead.concat(
      unitSelectionMenu()
    ).concat([
      divider(),
      command('Custom value', () => onChangeMode?.('custom', ''), undefined, { checked: mode === 'custom', radio: true })
    ])
  }

  return <DropdownButton icon='more_vert' generator={menuGen} autosize />
}

export const PropValueFromSelect = (props: PropValuePickerProps): VNode => {
  const { propName, style, options, onChange, onFocus, onBlur } = props
  return (
    <div class='input-group'>
      <select
        className='form-select form-select-sm'
        onInput={ev => { style[propName] = (ev.target as HTMLSelectElement).value; onChange(style) }}
        onFocus={() => onFocus?.()}
        onBlur={() => onBlur?.()}
      >
        {options.map(opt =>
          <option
            key={opt.label}
            value={opt.propValue}
            selected={style[propName] === opt.propValue}
          >{opt.label}
          </option>)}
      </select>
      <span>
        <ModeSwitcher {...props} mode='options' />
      </span>
    </div>
  )
}

export const PropValueFromUnit = (props: PropValuePickerProps): VNode => {
  const { propName, style, onChange, onFocus, onBlur } = props

  const pv = ((style[propName] ?? '') as string).toString()
  const res = valueAndUnitName(pv)

  const handleInput = (value: string): void => {
    // TODO: validation (needs to be numeric), add unit (if unit was stripped before)
    style[propName] = value
    onChange(style)
  }

  return (
    <div class='input-group'>
      <input
        type='text'
        class='form-control'
        placeholder={props.unitType}
        value={res.value}
        spellcheck={false}
        onInput={ev => handleInput((ev.target as HTMLInputElement).value)}
        onFocus={() => onFocus?.()}
        onBlur={() => onBlur?.()}
      />
      <span class='input-group-text'>{res.unitName}</span>
      <ModeSwitcher {...props} unit={res.unitName} mode='unit' />
    </div>
  )
}

export const PropValueFromText = (props: PropValuePickerProps): VNode => {
  const { propName, style, onChange, onFocus, onBlur } = props

  const pv = ((style[propName] ?? '') as string).toString()

  const handleInput = (value: string): void => {
    style[propName] = value
    onChange(style)
  }

  return (
    <div class='input-group'>
      <input
        type='text'
        class='form-control'
        placeholder={props.unitType}
        value={pv}
        onInput={ev => handleInput((ev.target as HTMLInputElement).value)}
        onFocus={() => onFocus?.()}
        onBlur={() => onBlur?.()}
      />
      <ModeSwitcher {...props} mode='custom' />
    </div>
  )
}

function valueAndUnitName (propValue: string): { value: string, unitName: string } {
  const unitName = LENGTH_UNITS.find(uname => propValue.endsWith(uname))
  if (unitName == null) return { value: '0', unitName: LENGTH_UNITS[0] }

  const num = propValue.slice(0, unitName.length)
  return { value: num, unitName }
}

function getPickerMode (propValue: string, options: PresetList, unit: CSSPropUnitType | null): PropValuePickerMode {
  if (unit === 'length') {
    const regex = /^(-?\d*\.?\d+)(px|cm|mm|in|pt|pc|em|rem|%|vw|vh|vmin|vmax)$/i
    if (regex.test(propValue)) return 'unit'
  }
  if (propValue == null || propValue === '') return 'options'
  if (options.map(pv => pv.propValue).includes(propValue)) return 'options'
  return 'custom'
}

type PropValuePickerMode = 'options' | 'unit' | 'custom'

export const CSSPropValuePicker = (props: Omit<PropValuePickerProps, 'onChangeMode'>): VNode => {
  const { propName, style, onChange } = props

  const options = NONE.concat(props.options)

  const propsWithOptions = { ...props, options }

  const pv = style[propName] as string
  const [mode, setMode] = useState(() => getPickerMode(pv, options, props.unitType ?? null))

  useEffect(() => {
    setMode(getPickerMode(pv, options, props.unitType ?? null))
  }, [propName])

  const handleChangeMode = (newMode: PropValuePickerMode, unit: string | null): void => {
    setMode(newMode)

    if (newMode === 'options' && mode === 'custom') {
      const preset = options.find(pvitem => pvitem.rawValue === pv)
      if (preset != null) {
        style[propName] = preset.propValue
      } else {
        style[propName] = options[0].propValue
      }
      onChange(style)
    }

    if (newMode === 'custom' && mode === 'options') {
      const preset = options.find(pvitem => pvitem.propValue === pv)
      if (preset != null) {
        style[propName] = preset.rawValue
        onChange(style)
      }
    }
  }

  switch (mode) {
    case 'options':
      return <PropValueFromSelect {...propsWithOptions} onChangeMode={handleChangeMode} />
    case 'custom':
      return <PropValueFromText {...propsWithOptions} onChangeMode={handleChangeMode} />
    case 'unit':
      return <PropValueFromUnit {...propsWithOptions} onChangeMode={handleChangeMode} />
  }
}
