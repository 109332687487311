import { Runtime } from './agjs'
import { UiState } from './stores/ui_state'
import { ProjectState } from './stores/project_state'
import { Ide } from './types'
import { Agjs2 } from './agjs/types'
import { createContext } from 'preact'
import { observable } from 'mobx'

type GenIdeAttrFunc = (node: Agjs2.Node) => Ide.CustomAttrs

const generateIdeAttrs: GenIdeAttrFunc = (_: Agjs2.Node) => observable({
  hover: false,
  focus: false,
  dragTarget: false,
  dragTargetPropId: null,
  dragMarker: null,
  dz: {},
  height: 0,
  width: 0,
  ide: true
})

class App {
  runtime: Runtime
  store: Ide.Store
  currentUser: Ide.IUser
  studioSessionId: string

  constructor () {
    const customAttrs = generateIdeAttrs
    this.runtime = new Runtime({ customAttrs })
    this.studioSessionId = `sess${Math.floor(Math.random() * 100000000)}`

    this.store = {
      ui: new UiState(this),
      project: new ProjectState(this)
    }

    this.currentUser = {
      id: '1',
      name: 'Malte'
    }
  }
}

const globalApp: App = new App()
const AppContext = createContext(globalApp)

export { App, AppContext, globalApp }
