import { Agjs2, ST } from './../types'
import * as Factory from './../factory'

const { F } = Factory

const category = 'Storage'
const imports = [{ name: 'cookies', source: 'next/headers' }]

const CookieDT = F.makeRecordDefType([
  F.makeRecordField('name', F.makePrimitiveDT('String')),
  F.makeRecordField('value', F.makePrimitiveDT('String')),
  F.makeRecordField('expires', F.makePrimitiveDT('String'))
])

const set: ST.Exp.Method = {
  t: 'Method',
  id: 'Cookie.set',
  name: 'set',
  meta: {
    description: 'Store a cookie on the user\'s browser.',
    category,
    order: 1
  },
  js: () => {
    return 'console.log(args[0]);'
  },
  parameters: [F.makeParam('cookieSpecs', CookieDT)],
  kwParameters: {},
  returnType: F.makeNoneType(),
  workflowStmt: true
}

const getAll: ST.Exp.Method = {
  t: 'Method',
  id: 'Cookie.getAll',
  name: 'getAll',
  meta: {
    description: 'Returns a list with all cookies stored on the user\'s browser.',
    category,
    order: 1
  },
  js: () => {
    return 'return cookieStore.getAll().map((cookie) => ({ Name: cookie.name, Value: cookie.value, Expires: cookie.expires }));'
  },
  parameters: [],
  kwParameters: {},
  returnType: F.makeListType(CookieDT)
}

export const CookieClass: ST.Exp.StaticClass = {
  t: 'StaticClass',
  name: 'Cookie',
  cid: 'CookieClass',
  id: 'CookieClass',
  meta: {
    summary: 'Methods for dealing with browser cookies.',
    description: 'Cookie provides methods for setting, reading or deleting cookies from the user\'s browser.',
    searchHint: 'cookie,session,browser',
    category: 'Stdlib'
  },
  props: {},
  jsImports: imports,
  methods: [
    set,
    getAll
  ]
}
