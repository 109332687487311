import { Scope } from '../agjs/scope'
import { Agjs2, ST } from '../agjs/types'
import { Parser } from '../agjs/parser'
import { Runtime } from '../agjs/runtime'

import { Tree as LeezerTree } from '@lezer/common'
import { CompletionContext, Completion, CompletionResult, CompletionSection } from '@codemirror/autocomplete'
import { Diagnostic } from '@codemirror/lint'
import { Cursor, Ancestry } from '../agjs/cursor'
import { renderFunuruDoc } from './funuru_doc'
import { AutocompleteAssistant } from './autocomplete_assistants'

export function makeAutocompleteCallback (parser: Parser, scope: Scope, runtime: Runtime) {
  const assistant = new AutocompleteAssistant()

  return (tree: LeezerTree, context: CompletionContext): CompletionResult => {
    const doc = context.state.doc.sliceString(0)
    const newExp = parser.astToExp(tree.topNode, doc, scope)
    const cursor = new Cursor(newExp)

    const closestNode: Array<Agjs2.Node & Agjs2.ParserMetaInfo> = []
    const pos: number = context.pos

    cursor.iterate2((node: Agjs2.Node & Agjs2.ParserMetaInfo, _ancestors: Ancestry) => {
      if (pos >= node.parser.from && pos <= node.parser.to) closestNode.push(node)
      return null
    })

    const sortedByCloseness = closestNode.sort((a, b) => (a.parser.to - a.parser.from) - (b.parser.to - b.parser.from))
    const closest = sortedByCloseness[0]

    // Assign one of these section objects to the returned Completion item and they will be grouped by sections.
    const sections: Record<string, CompletionSection> = {
      thisPage: { name: 'On this page', rank: 0 },
      tables: { name: 'Database and static data', rank: 1 },
      misc: { name: 'Other', rank: 2 },
      assistants: { name: 'Common topics', rank: 2 }
    }

    let hits: Completion[] = []

    const mkAssistant = (id: string, description: string, callback: () => void): Completion => ({
      label: `${id}-funuru$`,
      displayLabel: '\u200B',
      detail: description,
      apply: callback,
      type: 'identifier',
      section: sections.assistants
    })

    hits.push(
      mkAssistant('database', 'Find/search/read from database table...', () => assistant.openDatabaseAssistant()),
      mkAssistant('inputfield', 'Get input from a form element...', () => assistant.openDatabaseAssistant()),
      mkAssistant('custom state', 'Get value from a custom state...', () => assistant.openDatabaseAssistant()),
      mkAssistant('help', 'Can\'t find what you are looking for?', () => assistant.openDatabaseAssistant())
    )

    const topLevelNames = (name: string): Completion[] => {
      const scopeMembers = scope.findByName(name)
      return scopeMembers.map(sm => {
        let section: CompletionSection | undefined // = sections.other

        // if (sm.node.t === 'DbTableDef') section = sections.tables

        return {
          label: sm.name,
          type: 'identifier',
          info: () => renderFunuruDoc(runtime.nodeIndex, sm.node),
          section
        }
      })
    }

    const dotOpRightSideNames = (leftOperand: ST.Exp.Expression): Completion[] => {
      const nodes = runtime.nodeIndex.suggestDotOpRightSide(leftOperand)
      return nodes.map(item => {
        const textToInsert = (item as ST.Exp.ObjMethod).name ?? (item as ST.Exp.GetAttr)._name
        let apply: string | undefined
        let section: CompletionSection | undefined // = sections.other
        if (item.t === 'ObjMethod' || item.t === 'Method') {
          apply = textToInsert + '()'
        }
        // See more details:
        // https://codemirror.net/examples/autocompletion/
        // full docs:
        // https://codemirror.net/docs/ref/#autocomplete
        return {
          label: textToInsert,
          apply,
          // class, constant, enum, function, interface, keyword, method, namespace, property, text, type, variable.
          // custom types can be added and somehow map to icons in css or so (check reference docs)
          type: 'identifier',
          info: () => renderFunuruDoc(runtime.nodeIndex, item),
          section
        }
      })
    }

    const parent = closest.parser.parent

    if (parent == null && ['$incomplete', '$unresolved'].includes((closest as ST.Exp.Expression).t)) {
      hits = hits.concat(topLevelNames(closest.parser.raw))
    }

    if (parent != null && (parent as ST.Exp.DotOp).t === 'DotOp') {
      const dotOp = parent as ST.Exp.DotOp
      if (closest.id === dotOp.right.id) {
        hits = hits.concat(dotOpRightSideNames(dotOp.left))
      } else {
        hits = hits.concat(topLevelNames(closest.parser.raw))
      }
    }

    return {
      from: closest.parser.from,
      options: hits
    }
  }
}

export function makeLintingCallback (parser: Parser, scope: Scope, runtime: Runtime, requiredType: ST.DT.TypeDef, fieldName: string) {
  return (tree: LeezerTree, source: string, diagnostics: Diagnostic[]): void => {
    if (source.length === 0) return
    const newExp = parser.astToExp(tree.topNode, source, scope)
    const cursor = new Cursor(newExp)

    cursor.iterate2((node: Agjs2.Node, _ancestors: Ancestry) => {
      if ((node as ST.Exp.Expression).t === '$incomplete') {
        const parser = (node as ST.Exp.Expression & Agjs2.ParserMetaInfo).parser
        diagnostics.push({
          from: parser.from,
          to: parser.to,
          severity: 'error',
          message: 'Unknown expression'
          /* actions: [{
            name: 'Remove',
            apply (view, from, to) { view.dispatch({ changes: { from, to } }) }
          }] */
        })
      }
      return null
    })

    if (requiredType != null) {
      const inferred = runtime.tc.inferType(newExp)
      const tcRes = runtime.tc.isCompatible([requiredType], inferred)
      if (!tcRes.ok) {
        const message = tcRes.messages.join('\n')
          .replaceAll('%R', fieldName)
          .replaceAll('%I', 'this expression')
        diagnostics.push({
          from: 0,
          to: source.length,
          severity: 'error',
          message
        })
      }
    }
  }
}
