import { defaultTheme, ToupleList } from './default_theme'
import { presetToCssProperty, StylePresetType } from './style_compiler'

interface PresetValue {
  // the name/id this preset is stored in the array based theme config (for example the color name)
  name: string

  // What to display in the option (probably the same as 'name')
  label: string

  // the value stored in the theme (for example a hex color code)
  rawValue: string

  // the actual 'var(--xx-name)' string
  propValue: string
}

export type PresetList = PresetValue[]

export class ThemeService {
  collapsedColors: PresetList = []

  colors: typeof defaultTheme.colors

  fontFamily: PresetList
  fontSize: PresetList
  fontWeight: PresetList

  width: PresetList
  height: PresetList

  minHeight: PresetList
  minWidth: PresetList

  maxHeight: PresetList
  maxWidth: PresetList

  spacing: PresetList

  blur: ToupleList
  borderRadius: PresetList
  borderWidth: PresetList
  borderStyle: PresetList
  overflow: PresetList
  boxShadow: ToupleList
  dropShadow: ToupleList

  generatePresetValues (spt: StylePresetType | null, input: ToupleList): PresetList {
    if (spt === null) {
      return input.map(([key, value]) => ({
        name: key,
        label: key,
        rawValue: value,
        propValue: value
      }))
    } else {
      return input.map(([key, value]) => ({
        name: key,
        label: key,
        rawValue: value,
        propValue: presetToCssProperty(key, spt)
      }))
    }
  }

  constructor (theme: typeof defaultTheme) {
    this.colors = theme.colors

    this.fontFamily = this.generatePresetValues('font-family', theme.fonts.fontFamily)
    this.fontSize = this.generatePresetValues('font-size', theme.fonts.fontSize)
    this.fontWeight = this.generatePresetValues('font-weight', theme.fonts.fontWeight)

    this.spacing = this.generatePresetValues('spacing', theme.spacing)

    this.width = this.generatePresetValues('width', theme.widthHeight)
    this.height = this.generatePresetValues('height', theme.widthHeight)

    this.minWidth = this.generatePresetValues('min-width', theme.minWidth)
    this.maxWidth = this.generatePresetValues('max-width', theme.maxWidth)

    this.minHeight = this.generatePresetValues('min-height', theme.minMaxHeight)
    this.maxHeight = this.generatePresetValues('max-height', theme.minMaxHeight)

    this.blur = theme.misc.blur

    this.borderRadius = this.generatePresetValues('border-radius', theme.misc.borderRadius)
    this.borderWidth = this.generatePresetValues('border-width', theme.misc.borderWidth)
    this.borderStyle = this.generatePresetValues(null, theme.misc.borderStyle)

    this.overflow = this.generatePresetValues(null, theme.misc.overflow)

    this.boxShadow = theme.misc.boxShadow
    this.dropShadow = theme.misc.dropShadow

    theme.colors.forEach(([colorKey, colorDef]) => {
      if (typeof colorKey === 'string' && typeof colorDef === 'string') {
        // this.collapsedColors.push([colorKey, colorDef])
        this.collapsedColors.push({
          name: colorKey,
          label: colorKey,
          rawValue: colorDef,
          propValue: presetToCssProperty(colorDef, 'color')
        })
      } else {
        for (const [colorWeight, colorCode] of colorDef) {
          // this.collapsedColors.push([`${colorKey.toString()}-${colorWeight.toString()}`, colorCode])
          const compositeKey = `${colorKey.toString()}-${colorWeight.toString()}`
          this.collapsedColors.push({
            name: colorKey,
            label: compositeKey,
            rawValue: colorCode,
            propValue: presetToCssProperty(compositeKey, 'color')
          })
        }
      }
    })
  }
}

export const defaultThemeService = new ThemeService(defaultTheme)
