import { useContext } from 'preact/hooks'

import { TopMenu } from './top_menu'
import { PageWorkbench, WorkflowsWorkbench, DataWorkbench, ElementsWorkbench } from './../workbenches'

import { observer } from 'mobx-react'
import { AppContext } from '../app_context'
import { Toaster } from './toaster'
import { Overlays } from '../overlay_manager'
import { VNode } from 'preact'
import {Icon, IconButton} from '.'
import {Ide} from '../types'

// WARNING: the id of this div is used for calculating the viewport size and positioning of the context menu.
// so make sure to update id as well in ContextMenu.
const IdeScreen = ({ children }): VNode => <div id='studio-wrapper'>{children}</div>

const DragImagePlaceholder = (): VNode => (
  <div id='drag-image' className='position-absolute' style='z-index: -1'>
    <div className='d-inline-block p-2 border border-2 border-primary'>
      drag image placeholder
    </div>
  </div>
)

const WorkbenchContent = observer(({ id, children }) => {
  const { store } = useContext(AppContext)
  return <div className={'flex-fill ' + (store.ui.activeWorkbench === id ? 'd-flex flex-row' : 'd-none')}>{children}</div>
})

const WorkbenchTabButton = ({ title, help, active, callback }): VNode => {
  const click = (event): void => {
    event.preventDefault()
    callback()
  }

  return (
    <div className='nav-item'>
      <a href='#' className={'nav-link' + (active === true ? ' active' : '')} style='transform: rotate(180deg); writing-mode: vertical-lr;' title={title} onClick={click}>{title}</a>
    </div>
  )
}

interface WbListItem {
  id: Ide.WorkspaceIds
  title: string
  help: string
}

const WorkbenchSelector = observer(() => {
  const { store } = useContext(AppContext)

  const list: WbListItem[] = [
    { id: 'page', title: 'Pages', help: 'Edit Pages' },
    { id: 'workflows', title: 'Workflows', help: 'Edit business logic' },
    { id: 'data', title: 'Data', help: 'Edit database schema' },
    { id: 'elements', title: 'Elements', help: 'Create or edit page elements (components)' }
  ]
  // writing-mode: vertical-lr;
  return (
    <div id='workbench-selector' className='sidebar-container'>
      <div className='nav nav-pills flex-column' style='--bs-nav-link-padding-x: 0.2rem; --bs-nav-link-padding-y: 0.5rem;'>
        <WorkbenchTabButton title='+' help='Create a new thing' active={false} callback={() => store.ui.runCommand('NEW_WB_ITEM')} />
        {list.map(wb => <WorkbenchTabButton key={wb.id} title={wb.title} help={wb.help} active={store.ui.activeWorkbench === wb.id} callback={() => store.ui.openWorkbench(wb.id)} />)}
      </div>
      <div className='d-flex flex-column h-100'>
        <div className='flex-fill'></div>
        <div>
          <div className='cursor-pointer select-none' title='Studio settings' onClick={() => console.log('TODO: implement studio settings dialog')}>
            <Icon icon='settings'/>
          </div>
        </div>
      </div>
    </div>
  )
})

const MainLayout = observer(() => {
  return (
    <IdeScreen>
      <DragImagePlaceholder />
      <TopMenu />
      <div id='studio-content-area-wrapper' className='bg-body'>
        <WorkbenchSelector />
        <WorkbenchContent id='page'>
          <PageWorkbench />
        </WorkbenchContent>
        <WorkbenchContent id='workflows'>
          <WorkflowsWorkbench />
        </WorkbenchContent>
        <WorkbenchContent id='data'>
          <DataWorkbench />
        </WorkbenchContent>
        <WorkbenchContent id='elements'>
          <ElementsWorkbench />
        </WorkbenchContent>
      </div>
      <Toaster />
      <Overlays />
    </IdeScreen>
  )
})

export { MainLayout }
