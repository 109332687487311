import { Runtime } from './runtime'
import { Agjs2, ST } from './types'
import { F } from './factory'
import { Identation, Group as IdentationGroup } from './identation'

export type Source = ST.Exp.Expression | ST.Statement | ST.DT.TypeDef |
Agjs2.Workflow | ST.Param | ST.Exp.Render.PageElement | ST.Exp.WorkflowRef | ST.Exp.WorkflowList
// line 6 above includes types that need to be removed- page element should be replaced with renderPageElement expression,
// Parameter is maybe unused and workflow should be an expression too? (or just needs dedicated syntax support)

type PrepRes = IdentationGroup

export class Formatter {
  runtime: Runtime
  identation: Identation

  constructor (runtime: Runtime) {
    this.runtime = runtime
    this.identation = new Identation()
  }

  toString (source: Source): string {
    const i = this.identation
    i.reset()

    const result = this.prepare(source, i)

    i.root.items = [i.line(result)]
    // console.log(JSON.stringify(i.root.items))
    return i.toString()
  }

  prepare (source: Source, i?: Identation): PrepRes {
    if (i == null) {
      i = this.identation
      i.reset()
    }
    if ((source as ST.DT.TypeDef).t === 'DataType') {
      return this.dataTypeToString(source as ST.DT.TypeDef, i)
    }
    if ((source as ST.Exp.Expression).t != null) {
      return this.expToString(source as ST.Exp.Expression, i)
    } else {
      return i.hgroup(i.token(`don't know how to format ${JSON.stringify(source)}`))
    }
  }

  // TODO: rename to stmtToString or so... is taking care of both
  expToString (exp: ST.Exp.Expression | ST.Statement, i: Identation): PrepRes {
    /** Formats a record as a keyword/value list.
     * @param enclosure is a string either "{}" (for record types) or "()" (for function calls)
     * @param padded formats the key word list with spaces between the enclosure ({ ... } instead of {...})
     */
    const formatKV = (fields: Record<string, ST.Exp.Expression>, i: Identation, enclosure: string = '', padded: boolean = false): IdentationGroup => {
      const [lp, rp] = enclosure
      if (Object.keys(fields).length === 0) {
        return i.hgroup(i.token(enclosure))
      }

      const inner = i.join(
        Object.keys(fields).map(key =>
          i.hgroup(i.token(key), i.token(': '), this.expToString(fields[key], i))
        ), ', ')

      if (enclosure === '') {
        return inner
      } else {
        if (padded) inner.items = inner.items.concat(i.token(' '))
        return i.parens(lp + (padded ? ' ' : ''), inner, rp)
      }
    }

    switch (exp.t) {
      case 'Null':
      case 'String':
      case 'Boolean':
      case 'Number':
        return i.hgroup(i.token(JSON.stringify(exp.value)))
      case 'Text':
        return i.hgroup(i.token(JSON.stringify(exp.value)))
      case 'Timestamp': {
        const date = new Date(exp.value.toUTCString())
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        const seconds = String(date.getSeconds()).padStart(2, '0')

        // TODO: this is not correct as this is the offset to the COMPUTER, not the current timezone associated with
        // the timestamp. It should probably work like this: when parsing, the timestamp is converted to UTC (if no tz is given,
        // project TZ is assumed).
        // NOTE: it is probably the easiest to just store a string how the timestamp was specified literally and use this here to format (if available).
        //
        // const tzPre = '+'
        // const tzMinutes = 0
        const tzMinutes = Math.abs(date.getTimezoneOffset())
        const tzPre = date.getTimezoneOffset() >= 0 ? '-' : '+'
        const tzHours = Math.floor(tzMinutes / 60)
        const remainingMinutes = tzMinutes % 60
        const formattedHours = String(tzHours).padStart(2, '0')
        const formattedMinutes = String(remainingMinutes).padStart(2, '0')
        const tzString = ` ${tzPre}${formattedHours}:${formattedMinutes}`

        const s = `${year}-${month}-${day} ${hours}:${minutes}${date.getSeconds() > 0 ? `:${seconds}` : ''}${tzString}`

        return i.hgroup(i.token(`~t(${s})`))
      }
      case 'BinaryOp': {
        const left = this.expToString(exp.left, i)
        const op = exp.op // wie raw handlen?
        const right = this.expToString(exp.right, i)

        // return `${left} ${op} ${right}`
        return i.hgroup(left, i.token(' '), i.token(op), i.token(' '), right)
      }
      case 'Group': {
        const inner = this.expToString(exp.child, i)
        // return `(${inner})`
        return i.nesting(
          {
            open: [i.token('(')],
            inner: [inner],
            close: [i.token(')')]
          })
      }
      case 'GetNode': {
        if (['thisElement', 'thisPage'].includes(exp._name)) return i.hgroup(i.token(exp._name))

        if (exp.refId === '$thisElement') {
          console.log('!!!THIS TRIGGERS, I wanted to know if $thisElement is ever used as refId')
          return i.hgroup(i.token('thisElement'))
        }
        // const item = this.runtime.nodeIndex.getRef(exp) as Agjs2.NamedNode;
        const item = this.runtime.nodeIndex.lookup(exp.refId) as Agjs2.NamedNode
        // TODO: hier könnte man erreichen, dass das highlight vom refType abhängt (item)
        // return `${item.name}`
        return i.hgroup(i.token(item.name))
      }
      case 'GetAttr':
        // return exp._name
        return i.hgroup(i.token(exp._name))
      case 'DotOp': {
        // hier ist auch das problem, das un umgekehrter reihenfolge gehighlighted werden muss,
        // wie es jetzt ist überschreiben die äußeren nodes die inneren?
        const leftStr = this.prepare(exp.left, i)
        const rightStr = this.prepare(exp.right, i)
        // return leftStr + '.' + rightStr
        return i.hgroup(leftStr, i.token('.'), rightStr)
      }
      case 'CallMethod':
      case 'CallObjMethod': {
        const args: PrepRes[] = exp.args.map(item => this.expToString(item, i))
        const kwArgs: IdentationGroup = formatKV(exp.kwArgs, i)

        if (Object.keys(exp.kwArgs).length > 0) {
          args.push(kwArgs)
        }

        const inner = i.join(args, ', ')
        return i.hgroup(i.token(exp._name), i.parens('(', inner, ')'))
      }
      case 'List': {
        const inner = i.join(exp.items.map(item => this.expToString(item, i)), ', ')
        return i.parens('[', inner, ']')
      }
      case 'DefsList': {
        const inner = i.join(exp.items.map(item => this.expToString(item, i)), ', ')
        return i.parens('$[', inner, ']')
      }
      case 'Record': {
        return formatKV(exp.fields, i, '{}', true)
      }
      case 'ElementStyling': {
        return i.hgroup(i.token('EMPTY'))
      }
      case 'Param': {
        // TODO (almost) same as variableStmt (value vs defaultValue)
        if (exp.dataTypes.length > 1) throw new Error('Multiple dataTypes for parameter not supported')
        let args = [
          i.token(exp.name),
          this.prepare(exp.dataTypes[0])
        ]

        if (exp.defaultValue != null) args.push(this.prepare(exp.defaultValue))

        const meta = this.metaInfoAsString(exp.meta, i)
        if (meta != null) args = args.concat(meta)

        return i.line(
          i.token('$param'),
          i.parens('(',
            i.join(args, ', ')
            , ')')
        )
      }
      case 'VariableStmt':
        return i.line(
          i.token('$var'),
          i.parens('(',
            i.join([
              i.token(exp.name),
              this.prepare(exp.dataType),
              this.prepare(exp.value)
            ], ', ')
            , ')')
        )
      case 'PageElementClass': {
        // TODO: maybe have a helper for this inline hash style parameters (to generate identation objects)
        const attrs: ST.Exp.KwArgs = {
          name: F.makeString(exp.name),
          cid: F.makeString(exp.cid ?? 'give-me-a-cid')
        }

        Object.keys(exp.props).forEach(key => {
          attrs.props ??= F.makeList([])
          ;(attrs.props as ST.Exp.ListGen<ST.Exp.PropValue>).items.push(exp.props[key])
        })

        attrs.render = exp.render

        // TODO: with some tweaking possibility to use this.metaInfoAsString helper here.
        attrs.metaCategory = F.makeString(exp.meta.category)
        if (exp.meta.description != null) attrs.metaDescription = F.makeString(exp.meta.description)
        if (exp.meta.order != null) attrs.metaOrder = F.makeNumber(exp.meta.order)

        // TODO: sort by name
        const hashParams = Object.entries(attrs).map(([key, value]) => {
          return i.hgroup(i.token(key), i.token(': '), this.prepare(value))
        })

        return i.line(
          i.token('$pageElement'),
          i.parens('(', i.join(hashParams, ', '), ')')
        )
      }
      case 'RenderContext': {
        // return `$render(\n${this.identedListOfExpToString(exp.items, 1)}\n)`
        const inner = i.parens('(', exp.items.map(item => i.line(this.prepare(item))), ')')
        return i.line(i.token('$render'), inner)
      }
      case 'RenderNothing':
        return i.line(i.token('RenderNothing'))
      case 'RenderEval':
        // return `{${this.expToString(exp.value, i)}}`
        return i.parens('{', this.prepare(exp.value), '}')
      case 'RenderPageElement': {
        const element = exp
        const attributes: Record<string, ST.Exp.PropValue> = {
          name: F.makeString(element.name)
        }

        const specialAttrs = ['children', 'workflows']
        if (element.propValues == null) throw new Error(JSON.stringify(exp))
        Object.keys(element.propValues).filter(key => !specialAttrs.includes(key)).forEach(key => {
          attributes[key] = element.propValues[key]
        })

        // FIXME: Hier auch neuen typ WorkflowList verwenden?
        // if ((element.propValues.workflows as Agjs2.Workflow[]).length > 0) {
        //  attributes.workflows = element.propValues.workflows
        // }

        // console.log(toJS(element))
        const peChildren: ST.Exp.Render.PageElement[] = element.propValues?.children != null ? (element.propValues.children as ST.Exp.PageElementList).items : []

        return this.tagAsString(
          element.cid,
          attributes,
          peChildren,
          i
        )
      }
      case 'RenderHTMLTag': {
        // throw new Error(JSON.stringify(exp))
        return this.tagAsString(exp.tag, exp.attributes, exp.children, i)
      }
      /* TODO: convert to new identation service
      case 'RenderCond':
        if (exp.else == null) {
          return (
            `RenderIf (${this.expToString(exp.condition, i)}) then\n` +
            `  ${this.expToString(exp.then, i)}\n` +
            'end'
          )
        } else {
          return (
            `RenderIf (${this.expToString(exp.condition, i)}) then\n` +
            `  ${this.expToString(exp.then, i)}\n` +
            'else\n' +
            `  ${this.expToString(exp.else, i)}\n` +
            'end'
          )
        }
      case 'RenderList':
        return `RenderForEach(${this.expToString(exp.list, i)}) do\n` +
               `  ${this.expToString(exp.yield, i)}\n` +
               'end'
 */
      case '$incomplete':
      case '$unresolved':
        return i.hgroup(i.token('' + exp.value))
      default:
        throw new Error(`Don't know how to handle: ${JSON.stringify(exp)}\n`)
        // return i.line(i.token(`expToString: Don't know how to handle ${exp.t}.`))
    }
  }

  metaInfoAsString (meta: Agjs2.UIMetaInfo | undefined, i: Identation): PrepRes | null {
    if (meta == null) return null
    const attrs: ST.Exp.KwArgs = {}

    if (meta.category !== 'none') attrs.metaCategory = F.makeString(meta.category)
    if (meta.description != null) attrs.metaDescription = F.makeString(meta.description)
    if (meta.order != null) attrs.metaOrder = F.makeNumber(meta.order)

    // TODO: sort by name
    const hashParams = Object.entries(attrs).map(([key, value]) => {
      return i.hgroup(i.token(key), i.token(': '), this.prepare(value))
    })

    return hashParams.length > 0 ? i.join(hashParams, ', ') : null
  }

  tagAsString (tag: string, attributes: Record<string, ST.Exp.PropValue>, children: ST.Exp.Render.All[], i: Identation): PrepRes {
    const hasAttrs = Object.keys(attributes).length > 0
    const attrGroup = i.join(
      Object
        .keys(attributes)
        .map(key =>
          i.hgroup(
            i.token(key),
            i.token('='),
            i.parens(
              '{',
              this.prepare(attributes[key]),
              '}'
            )
          )
        ), ' ')

    if (hasAttrs) attrGroup.items = [i.token(' ')].concat(attrGroup.items)

    const innerGroup = children.map(childExp => i.line(this.prepare(childExp)))

    if (children.length === 0) {
      return i.parens('<', i.hgroup(i.token(tag), attrGroup, i.token(' ')), '/>')
    } else {
      const openTag = i.parens('<', i.hgroup(i.token(tag), attrGroup), '>')
      return i.nesting({
        open: [openTag],
        inner: innerGroup,
        close: [i.token(`</${tag}>`)]
      })
    }
  }

  /*
  identedListOfExpToString (exps: ST.Exp.Expression[], identLevel: number = 0): string {
    return this.ident(exps.map(exp => `${this.expToString(exp, i)}`), identLevel)
  }

  ident (source: string | string[], identLevel: number = 0): string {
    const padding = ' '.repeat(identLevel * 2)
    const lines: string[] = Array.isArray(source) ? source : source.split('\n')
    return lines.map(line => `${padding}${line}`).join('\n')
  }
 */

  dataTypeToString (dataType: ST.DT.TypeDef, i: Identation): PrepRes {
    switch (dataType.type) {
      case 'List':
        return i.hgroup(
          this.dataTypeToString(dataType.itemType, i),
          i.token('[]')
        )
      case 'Record': {
        const inner = i.join(
          dataType.fields.map(field =>
            i.hgroup(i.token(field.name), i.token(': '), this.dataTypeToString(field.dataType, i))
          ), ', ')
        inner.items = inner.items.concat(i.token(' '))
        return i.parens('{ ', inner, '}')
      }
      default:
        return i.hgroup(i.token(dataType.type))
    }
  }
}
