import { observer } from 'mobx-react'
import { VNode } from 'preact'
import { useContext } from 'preact/hooks'
import { Icon } from '.'
import { AppContext } from '../app_context'
import { Toast } from '../stores/toast_manager'

interface ISliceWrapper {
  // children: VNode[]
  onClose: () => void
  icon: VNode
  title?: string
  message: string | VNode
  progress: number | null
}

/***
 * @param position is a value from 0-1 (0-100%)
 */
const ProgressBar = ({ position }: { position: number }): VNode => {
  const p = position // Math.min(Math.round(position * 100), 100)
  return (
    <div class='progress mt-2' role='progressbar'>
      <div class='progress-bar' style={`width: ${p}%`} />
    </div>
  )
  // return (
  //   <div class='w-full bg-neutral-200 rounded'>
  //     <div class='bg-blue-600 text-xs h-2 font-medium text-blue-100 text-center p-0.5 leading-none rounded' style={`width: ${p}%`} />
  //   </div>
  // )
}

// const SliceWrapper = ({ icon, message, title, progress, onClose }: ISliceWrapper): VNode => (
//   <div class='animated-toast-slice animate-ease-in' role='alert'>
//     <div class='flex items-top w-full max-w-xs p-4 mb-4 rounded-sm border border-neutral-400 text-neutral-500 bg-white shadow-xl' role='alert'>
//       {icon}
//       <div class='ml-3 w-full text-sm font-normal'>
//         {title != null ? <div class='font-bold text-sm mb-1'>{title}</div> : null}
//         <div class='text-sm mb-1'>
//           {message}
//         </div>
//         {progress != null ? <ProgressBar position={progress} /> : null}
//       </div>
//       <button type='button' class='ml-auto -mx-1.5 -my-1.5 bg-white text-neutral-400 hover:text-neutral-900 rounded-lg focus:ring-2 focus:ring-neutral-300 p-1.5 hover:bg-neutral-100 inline-flex h-8 w-8' onClick={() => onClose()} aria-label='Close'>
//         <span class='sr-only'>Close</span>
//         <svg aria-hidden='true' class='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
//           <path fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' />
//         </svg>
//       </button>
//     </div>
//   </div>
// )
const SliceWrapper = ({ icon, message, title, progress, onClose }: ISliceWrapper): VNode => (
  <div class='toast' style='display: block;' role='alert' aria-live='assertive' aria-atomic='true'>
    <div class='toast-header'>
      {icon}
      <strong class='me-auto'>{title}</strong>
      {/* <small>11 mins ago</small> */}
      <button type='button' class='btn-close' aria-label='Close' onClick={() => onClose()} />
    </div>
    <div class='toast-body'>
      {message}
      {progress != null ? <ProgressBar position={progress} /> : null}
    </div>
  </div>
  // <div class='animated-toast-slice animate-ease-in' role='alert'>
  //  <div class='flex items-top w-full max-w-xs p-4 mb-4 rounded-sm border border-neutral-400 text-neutral-500 bg-white shadow-xl' role='alert'>
  //    {icon}
  //    <div class='ml-3 w-full text-sm font-normal'>
  //      {title != null ? <div class='font-bold text-sm mb-1'>{title}</div> : null}
  //      <div class='text-sm mb-1'>
  //        {message}
  //      </div>
  //      {progress != null ? <ProgressBar position={progress} /> : null}
  //    </div>
  //    <button type='button' class='ml-auto -mx-1.5 -my-1.5 bg-white text-neutral-400 hover:text-neutral-900 rounded-lg focus:ring-2 focus:ring-neutral-300 p-1.5 hover:bg-neutral-100 inline-flex h-8 w-8' onClick={() => onClose()} aria-label='Close'>
  //      <span class='sr-only'>Close</span>
  //      <svg aria-hidden='true' class='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
  //        <path fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' />
  //      </svg>
  //    </button>
  //  </div>
  // </div>
)

const ToastSlice = observer(({ toast }: { toast: Toast }) => {
  const animClass = (toast.content.progress != null && toast.content.progress < 100) ? ' animate-pulse' : ''
  // const icon = (
  //   <span class={`material-icons select-none text-xl text-neutral-600 ${animClass}`}>{toast.content.icon}
  //   </span>
  // )
  const icon = (
    <div className={`me-2 ${animClass}`}><Icon icon={toast.content.icon} /></div>
  )

  return (
    <SliceWrapper
      icon={icon}
      title={toast.content.title}
      message={toast.content.body}
      progress={toast.content.progress ?? null}
      onClose={() => toast.remove()}
    />
  )
})

export const Toaster = observer(() => {
  const { store } = useContext(AppContext)

  if (store.ui.toastManager.empty) {
    return null
  } else {
    return (
      <div style='position: fixed; right: 0; bottom: 0'>
        <div class='toast-container position-static p-3 bottom-0 end-0'>
          {store.ui.toastManager.list.map(toast => <ToastSlice toast={toast} key={toast.id} />)}
        </div>
      </div>
    )
    // return (
    //   <div class='fixed right-4 bottom-0 w-72'>
    //     {store.ui.toastManager.list.map(toast => <ToastSlice toast={toast} key={toast.id} />)}
    //   </div>
    // )
  }
})
