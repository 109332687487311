import { useRef, useState } from 'preact/hooks'
import { VNode } from 'preact'
import { CloseFn, openMenu } from '../overlay_manager'
import { Icon } from '.'

interface DividerItem { t: 'divider' }
interface HeaderItem { t: 'header', label: string }
interface CommandItem { t: 'command', label: string, action: () => void, key?: string, disabled: boolean, checked?: boolean, radio?: boolean }
interface SubmenuItem { t: 'sub', label: string, submenu: MenuItem[] }

export type MenuItem = HeaderItem | DividerItem | CommandItem | SubmenuItem

export type MenuGenerator = () => MenuItem[]

export const command = (label: string, action: () => void, key?: string, opts: { checked?: boolean, disabled?: boolean, radio?: boolean } = {}): CommandItem => ({
  t: 'command',
  label,
  action,
  key,
  disabled: opts?.disabled === true,
  checked: opts?.checked === true,
  radio: opts?.radio === true
})

export const subMenu = (label: string, submenu: MenuItem[]): SubmenuItem => ({
  t: 'sub',
  label,
  submenu
})

export const header = (label: string): HeaderItem => ({ t: 'header', label })
export const divider = (): DividerItem => ({ t: 'divider' })

const CommandLi = ({ item, closeFn, onMouseEnter }: { item: CommandItem, closeFn: CloseFn, onMouseEnter: (ev: MouseEvent) => void }): VNode => {
  const checkmark = (item.radio === true ? (item.checked === true ? 'radio_button_checked' : 'radio_button_unchecked') : (item.checked === true ? 'check' : null))
  const prefix = checkmark != null ? <Icon icon={checkmark} autosize /> : null

  return (
    <li tabIndex={0}>
      <a href='#' className={`dropdown-item d-flex${item.disabled ? ' disabled' : ''}`} onClick={() => { closeFn(); item.action() }} onMouseEnter={onMouseEnter}>
        <span className='flex-fill'>
          <span className='pe-2'>{prefix}</span>{item.label}
        </span>
        {item.key != null ? <span className='text-muted'>{item.key}</span> : null}
      </a>
    </li>
  )
}

class ChainLink {
  closeOverlayFn: CloseFn
  next: ChainLink | null = null
  parent: ChainLink | null = null

  constructor (parentRef: ChainLink | null, closeOverlayFn: CloseFn) {
    this.parent = parentRef
    this.closeOverlayFn = () => { closeOverlayFn() }
  }

  addLink (closeOverlayFn: CloseFn): ChainLink {
    // if (this.next != null) throw new Error(`cannot add a link to ${this.id} "next" is already pointing to ${this.next.id}`)
    if (this.next != null) {
      // console.log('addLink: next not empty, closing old menu first.')
      this.next.closeLinks()
    }
    const newLink = new ChainLink(this, () => {
      closeOverlayFn()
    })

    this.next = newLink
    return newLink
  }

  closeLinks (): void {
    if (this.next != null) {
      this.next.closeLinks()
      this.next.parent = null
      this.next = null
    }
    this.closeOverlayFn()
  }

  closeLinksFromRoot (): void {
    if (this.parent == null) {
      this.closeLinks()
    } else {
      this.parent.closeLinksFromRoot()
    }
  }
}

const SubmenuLi = ({ item, chain }: { item: SubmenuItem, chain: ChainLink }): VNode => {
  const [isOpen, setIsOpen] = useState(false)
  const parentMenu = useRef<HTMLAnchorElement>(null)
  const closeSubmenu = useRef<CloseFn>()

  const closeSub = (): void => {
    closeSubmenu.current?.()
    setIsOpen(false)
  }

  const openSub = (): void => {
    if (isOpen) return
    if (parentMenu.current == null) return
    const rect = parentMenu.current.getBoundingClientRect()
    setIsOpen(true)

    const nextLink = chain.addLink(() => { closeSub() })
    closeSubmenu.current = openMenu(
      () => {
        return (
          <Menu
            generator={() => item.submenu}
            chain={nextLink}
            closeFn={() => { nextLink.closeLinksFromRoot() }}
          />
        )
      },
      { x: rect.x + rect.width, y: rect.y },
      {
        noBackdrop: false,
        placement: 'right-start'
      }
    )
  }

  // In the future, the sub should open onMouseEnter or so, but it is difficult to also close on mouseLeave with the current implementation.
  return (
    <li tabIndex={0} onMouseEnter={() => openSub()}>
      <a href='#' className='dropdown-item d-flex' ref={parentMenu}>
        <span className='flex-fill'>{item.label}</span>
        <span className='material-symbols-outlined text-muted'>chevron_right</span>
      </a>
    </li>
  )
}

interface MenuProps {
  generator: MenuGenerator
  closeFn: CloseFn
  onOpenSub?: (closeFn: CloseFn) => void
  onMouseEnter?: (ev: MouseEvent) => void
  chain: ChainLink
}

export const Menu = ({ generator, closeFn, onOpenSub, chain, ...props }: MenuProps): VNode => {
  const closeOpenSubmenus = (): void => {
    chain.next?.closeLinks()
  }

  const menuItem = (item: MenuItem, key: string): VNode => {
    switch (item.t) {
      case 'header':
        return <li key={key}><h6 class='dropdown-header'>{item.label}</h6></li>
      case 'divider':
        return <li key={key}><hr className='dropdown-divider' /></li>
      case 'command':
        return <CommandLi key={key} item={item} closeFn={closeFn} onMouseEnter={closeOpenSubmenus} />
      case 'sub':
        return <SubmenuLi key={key} item={item} chain={chain} />
    }
  }

  const keyFor = (item: MenuItem, index: number): string => (item.t === 'divider') ? index.toString() : item.label

  return (
    <ul className='dropdown-menu d-block position-static' {...props}>
      {generator().map((item, index) => menuItem(item, keyFor(item, index)))}
    </ul>
  )
}

export const openContextMenu = (generator: MenuGenerator, x: number, y: number): CloseFn => {
  let closeMenuFn: CloseFn | null = null
  const root = new ChainLink(null, () => { closeMenuFn?.() })
  // We need to hook into onBeforeClose so we can close all submenus when the main menu is closed due to clicking on the background.
  closeMenuFn = openMenu(() => {
    return (<Menu generator={generator} closeFn={() => root.closeLinks()} chain={root} />)
  }, { x, y }, { onBeforeClose: () => root.next?.closeLinks() })
  return closeMenuFn
}

export const openDropdownMenu = (generator: MenuGenerator, ref: HTMLElement): CloseFn => {
  let closeMenuFn: CloseFn | null = null
  const root = new ChainLink(null, () => { closeMenuFn?.() })
  closeMenuFn = openMenu(() => {
    return (<Menu generator={generator} closeFn={() => root.closeLinks()} chain={root} />)
  }, ref, { onBeforeClose: () => root.next?.closeLinks() })
  return closeMenuFn
}
