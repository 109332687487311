import { AppContext } from './../app_context'
import { useContext } from 'preact/hooks'
import { observer } from 'mobx-react'

import { PageElement } from './PageElement'

const PageIframeContent = observer(({ globalMouseMove }) => {
  const { store } = useContext(AppContext)
  if (store.project.currentPage != null) {
    return (
      <div onMouseMove={ev => globalMouseMove(ev)}>
        <PageElement
          runtime={store.project.app.runtime}
          elementNode={store.project.currentPage}
        />
      </div>
    )
  } else {
    return null
  }
})

export { PageIframeContent }
