/* eslint-disable quote-props */

// These are taken from the tailwind default theme
// https://github.com/tailwindlabs/tailwindcss/blob/master/stubs/config.full.js

/* The following code (by ChatGPT) can be used to convert the nested object structures
 * (from the tailwind sources) into nested arrays, which are more easily traversable (keys retain order)
 */
/*
function objectToArray(obj) {
    const result: any[] = [];
    const keys = Object.keys(obj);
    for (const key of keys) {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            result.push([key, objectToArray(obj[key])]);
        } else {
            result.push([key, obj[key]]);
        }
    }

    return result;
}

const nestedArray = objectToArray(TW_THEME_COLORS);
console.log(JSON.stringify(nestedArray, null, 2))
*/

/*
const SEMANTIC_COLORS = {
  primary: '#0d6efd',
  secondary: '#6c757d',
  success: '#198754',
  info: '#0dcaf0',
  warning: '#ffc107',
  danger: '#dc3545',
  light: '#f8f9fa',
  dark: '#212529'
}
*/

export type ToupleList = Array<[string, string]>

const SPACING: ToupleList = [
  ['px', '1px'],
  ['0', '0px'],
  ['0.5', '0.125rem'],
  ['1', '0.25rem'],
  ['1.5', '0.375rem'],
  ['2', '0.5rem'],
  ['2.5', '0.625rem'],
  ['3', '0.75rem'],
  ['3.5', '0.875rem'],
  ['4', '1rem'],
  ['5', '1.25rem'],
  ['6', '1.5rem'],
  ['7', '1.75rem'],
  ['8', '2rem'],
  ['9', '2.25rem'],
  ['10', '2.5rem'],
  ['11', '2.75rem'],
  ['12', '3rem'],
  ['14', '3.5rem'],
  ['16', '4rem'],
  ['20', '5rem'],
  ['24', '6rem'],
  ['28', '7rem'],
  ['32', '8rem'],
  ['36', '9rem'],
  ['40', '10rem'],
  ['44', '11rem'],
  ['48', '12rem'],
  ['52', '13rem'],
  ['56', '14rem'],
  ['60', '15rem'],
  ['64', '16rem'],
  ['72', '18rem'],
  ['80', '20rem'],
  ['96', '24rem']
]

const WIDTH_HEIGHT: ToupleList = ([
  ['auto', 'auto'],
  ['1/2', '50%'],
  ['1/3', '33.333333%'],
  ['2/3', '66.666667%'],
  ['1/4', '25%'],
  ['2/4', '50%'],
  ['3/4', '75%'],
  ['1/5', '20%'],
  ['2/5', '40%'],
  ['3/5', '60%'],
  ['4/5', '80%'],
  ['1/6', '16.666667%'],
  ['2/6', '33.333333%'],
  ['3/6', '50%'],
  ['4/6', '66.666667%'],
  ['5/6', '83.333333%'],
  ['full', '100%'],
  ['screen', '100vh'],
  ['svh', '100svh'],
  ['lvh', '100lvh'],
  ['dvh', '100dvh'],
  ['min', 'min-content'],
  ['max', 'max-content'],
  ['fit', 'fit-content']
] as ToupleList).concat(SPACING)

const MIN_MAX_HEIGHT: ToupleList = ([
  ['none', 'none'],
  ['full', '100%'],
  ['screen', '100vh'],
  ['svh', '100svh'],
  ['lvh', '100lvh'],
  ['dvh', '100dvh'],
  ['min', 'min-content'],
  ['max', 'max-content'],
  ['fit', 'fit-content']
] as ToupleList).concat(SPACING)

const MIN_WIDTH: ToupleList = ([
  ['none', 'none'],
  ['full', '100%'],
  ['min', 'min-content'],
  ['max', 'max-content'],
  ['fit', 'fit-content']
] as ToupleList).concat(SPACING)

const MAX_WIDTH: ToupleList = ([
  ['none', 'none'],
  ['xs', '20rem'],
  ['sm', '24rem'],
  ['md', '28rem'],
  ['lg', '32rem'],
  ['xl', '36rem'],
  ['2xl', '42rem'],
  ['3xl', '48rem'],
  ['4xl', '56rem'],
  ['5xl', '64rem'],
  ['6xl', '72rem'],
  ['7xl', '80rem'],
  ['full', '100%'],
  ['min', 'min-content'],
  ['max', 'max-content'],
  ['fit', 'fit-content'],
  ['prose', '65ch']
] as ToupleList).concat(SPACING)

interface IMisc {
  blur: ToupleList
  borderRadius: ToupleList
  borderWidth: ToupleList
  borderStyle: ToupleList
  overflow: ToupleList
  boxShadow: ToupleList
  dropShadow: ToupleList
}

const MISC: IMisc = {
  // backgroundPosition: {
  //   bottom: 'bottom',
  //   center: 'center',
  //   left: 'left',
  //   'left-bottom': 'left bottom',
  //   'left-top': 'left top',
  //   right: 'right',
  //   'right-bottom': 'right bottom',
  //   'right-top': 'right top',
  //   top: 'top'
  // },
  // backgroundSize: {
  //   auto: 'auto',
  //   cover: 'cover',
  //   contain: 'contain'
  // },
  blur: [
    ['0', '0'],
    ['none', ''],
    ['sm', '4px'],
    ['DEFAULT', '8px'],
    ['md', '12px'],
    ['lg', '16px'],
    ['xl', '24px'],
    ['2xl', '40px'],
    ['3xl', '64px']
  ],
  borderRadius: [
    ['none', '0px'],
    ['sm', '0.125rem'],
    ['DEFAULT', '0.25rem'],
    ['md', '0.375rem'],
    ['lg', '0.5rem'],
    ['xl', '0.75rem'],
    ['2xl', '1rem'],
    ['3xl', '1.5rem'],
    ['full', '9999px']
  ],
  borderWidth: [
    ['DEFAULT', '1px'],
    ['0', '0px'],
    ['2', '2px'],
    ['4', '4px'],
    ['8', '8px']
  ],
  boxShadow: [
    ['sm', '0 1px 2px 0 rgb(0 0 0 / 0.05)'],
    ['DEFAULT', '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)'],
    ['md', '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'],
    ['lg', '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)'],
    ['xl', '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)'],
    ['2xl', '0 25px 50px -12px rgb(0 0 0 / 0.25)'],
    ['inner', 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)'],
    ['none', 'none']
  ],
  dropShadow: [
    ['sm', '0 1px 1px rgb(0 0 0 / 0.05)'],
    // TODO: wofür sind die werte mit zwei array elementen?
    // ['DEFAULT', ['0 1px 2px rgb(0 0 0 / 0.1)', '0 1px 1px rgb(0 0 0 / 0.06)']],
    // ['md', ['0 4px 3px rgb(0 0 0 / 0.07)', '0 2px 2px rgb(0 0 0 / 0.06)']],
    // ['lg', ['0 10px 8px rgb(0 0 0 / 0.04)', '0 4px 3px rgb(0 0 0 / 0.1)']],
    // ['xl', ['0 20px 13px rgb(0 0 0 / 0.03)', '0 8px 5px rgb(0 0 0 / 0.08)']],
    ['2xl', '0 25px 25px rgb(0 0 0 / 0.15)'],
    ['none', '0 0 #0000']
  ],
  borderStyle: asToupleList([
    'none',
    'solid',
    'dotted',
    'dashed',
    'hidden',
    'double',
    'groove',
    'ridge',
    'inset',
    'outset'
  ]),
  overflow: asToupleList([
    'visible',
    'hidden',
    'clip',
    'scroll',
    'auto',
    'hidden visible'
  ])
}

function asToupleList (keywords: string[]): ToupleList {
  return keywords.map(kw => [kw, kw])
}

interface IFonts {
  fontFamily: ToupleList
  fontSize: ToupleList
  fontWeight: ToupleList
}

const FONTS: IFonts = {
  fontFamily: [
    ['sans', 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'],
    ['serif', 'ui-serif, Georgia, Cambria, "Times New Roman", Times, serif'],
    ['mono', 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace']
  ],
  fontSize: [
    ['xs', '0.75rem'],
    ['sm', '0.875rem'],
    ['base', '1rem'],
    ['lg', '1.125rem'],
    ['xl', '1.25rem'],
    ['2xl', '1.5rem'],
    ['3xl', '1.875rem'],
    ['4xl', '2.25rem'],
    ['5xl', '3rem'],
    ['6xl', '3.75rem'],
    ['7xl', '4.5rem'],
    ['8xl', '6rem'],
    ['9xl', '8rem']
    // xs: ['0.75rem', { lineHeight: '1rem' }],
    // sm: ['0.875rem', { lineHeight: '1.25rem' }],
    // base: ['1rem', { lineHeight: '1.5rem' }],
    // lg: ['1.125rem', { lineHeight: '1.75rem' }],
    // xl: ['1.25rem', { lineHeight: '1.75rem' }],
    // '2xl': ['1.5rem', { lineHeight: '2rem' }],
    // '3xl': ['1.875rem', { lineHeight: '2.25rem' }],
    // '4xl': ['2.25rem', { lineHeight: '2.5rem' }],
    // '5xl': ['3rem', { lineHeight: '1' }],
    // '6xl': ['3.75rem', { lineHeight: '1' }],
    // '7xl': ['4.5rem', { lineHeight: '1' }],
    // '8xl': ['6rem', { lineHeight: '1' }],
    // '9xl': ['8rem', { lineHeight: '1' }]
  ],
  fontWeight: [
    ['thin', '100'],
    ['extralight', '200'],
    ['light', '300'],
    ['normal', '400'],
    ['medium', '500'],
    ['semibold', '600'],
    ['bold', '700'],
    ['extrabold', '800'],
    ['black', '900']
  ]
}

type TColors = Array<[string, string | ToupleList]>

const COLORS: TColors = [
  [
    'black', '#000000'
  ],
  [
    'white', '#ffffff'
  ],
  [
    'slate',
    [
      ['50', '#f8fafc'],
      ['100', '#f1f5f9'],
      ['200', '#e2e8f0'],
      ['300', '#cbd5e1'],
      ['400', '#94a3b8'],
      ['500', '#64748b'],
      ['600', '#475569'],
      ['700', '#334155'],
      ['800', '#1e293b'],
      ['900', '#0f172a'],
      ['950', '#020617']
    ]
  ],
  [
    'gray',
    [
      ['50', '#f9fafb'],
      ['100', '#f3f4f6'],
      ['200', '#e5e7eb'],
      ['300', '#d1d5db'],
      ['400', '#9ca3af'],
      ['500', '#6b7280'],
      ['600', '#4b5563'],
      ['700', '#374151'],
      ['800', '#1f2937'],
      ['900', '#111827'],
      ['950', '#030712']
    ]
  ],
  [
    'zinc',
    [
      ['50', '#fafafa'],
      ['100', '#f4f4f5'],
      ['200', '#e4e4e7'],
      ['300', '#d4d4d8'],
      ['400', '#a1a1aa'],
      ['500', '#71717a'],
      ['600', '#52525b'],
      ['700', '#3f3f46'],
      ['800', '#27272a'],
      ['900', '#18181b'],
      ['950', '#09090b']
    ]
  ],
  [
    'neutral',
    [
      ['50', '#fafafa'],
      ['100', '#f5f5f5'],
      ['200', '#e5e5e5'],
      ['300', '#d4d4d4'],
      ['400', '#a3a3a3'],
      ['500', '#737373'],
      ['600', '#525252'],
      ['700', '#404040'],
      ['800', '#262626'],
      ['900', '#171717'],
      ['950', '#0a0a0a']
    ]
  ],
  [
    'stone',
    [
      ['50', '#fafaf9'],
      ['100', '#f5f5f4'],
      ['200', '#e7e5e4'],
      ['300', '#d6d3d1'],
      ['400', '#a8a29e'],
      ['500', '#78716c'],
      ['600', '#57534e'],
      ['700', '#44403c'],
      ['800', '#292524'],
      ['900', '#1c1917'],
      ['950', '#0c0a09']
    ]
  ],
  [
    'red',
    [
      ['50', '#fef2f2'],
      ['100', '#fee2e2'],
      ['200', '#fecaca'],
      ['300', '#fca5a5'],
      ['400', '#f87171'],
      ['500', '#ef4444'],
      ['600', '#dc2626'],
      ['700', '#b91c1c'],
      ['800', '#991b1b'],
      ['900', '#7f1d1d'],
      ['950', '#450a0a']
    ]
  ],
  [
    'orange',
    [
      ['50', '#fff7ed'],
      ['100', '#ffedd5'],
      ['200', '#fed7aa'],
      ['300', '#fdba74'],
      ['400', '#fb923c'],
      ['500', '#f97316'],
      ['600', '#ea580c'],
      ['700', '#c2410c'],
      ['800', '#9a3412'],
      ['900', '#7c2d12'],
      ['950', '#431407']
    ]
  ],
  [
    'amber',
    [
      ['50', '#fffbeb'],
      ['100', '#fef3c7'],
      ['200', '#fde68a'],
      ['300', '#fcd34d'],
      ['400', '#fbbf24'],
      ['500', '#f59e0b'],
      ['600', '#d97706'],
      ['700', '#b45309'],
      ['800', '#92400e'],
      ['900', '#78350f'],
      ['950', '#451a03']
    ]
  ],
  [
    'yellow',
    [
      ['50', '#fefce8'],
      ['100', '#fef9c3'],
      ['200', '#fef08a'],
      ['300', '#fde047'],
      ['400', '#facc15'],
      ['500', '#eab308'],
      ['600', '#ca8a04'],
      ['700', '#a16207'],
      ['800', '#854d0e'],
      ['900', '#713f12'],
      ['950', '#422006']
    ]
  ],
  [
    'lime',
    [
      ['50', '#f7fee7'],
      ['100', '#ecfccb'],
      ['200', '#d9f99d'],
      ['300', '#bef264'],
      ['400', '#a3e635'],
      ['500', '#84cc16'],
      ['600', '#65a30d'],
      ['700', '#4d7c0f'],
      ['800', '#3f6212'],
      ['900', '#365314'],
      ['950', '#1a2e05']
    ]
  ],
  [
    'green',
    [
      ['50', '#f0fdf4'],
      ['100', '#dcfce7'],
      ['200', '#bbf7d0'],
      ['300', '#86efac'],
      ['400', '#4ade80'],
      ['500', '#22c55e'],
      ['600', '#16a34a'],
      ['700', '#15803d'],
      ['800', '#166534'],
      ['900', '#14532d'],
      ['950', '#052e16']
    ]
  ],
  [
    'emerald',
    [
      ['50', '#ecfdf5'],
      ['100', '#d1fae5'],
      ['200', '#a7f3d0'],
      ['300', '#6ee7b7'],
      ['400', '#34d399'],
      ['500', '#10b981'],
      ['600', '#059669'],
      ['700', '#047857'],
      ['800', '#065f46'],
      ['900', '#064e3b'],
      ['950', '#022c22']
    ]
  ],
  [
    'teal',
    [
      ['50', '#f0fdfa'],
      ['100', '#ccfbf1'],
      ['200', '#99f6e4'],
      ['300', '#5eead4'],
      ['400', '#2dd4bf'],
      ['500', '#14b8a6'],
      ['600', '#0d9488'],
      ['700', '#0f766e'],
      ['800', '#115e59'],
      ['900', '#134e4a'],
      ['950', '#042f2e']
    ]
  ],
  [
    'cyan',
    [
      ['50', '#ecfeff'],
      ['100', '#cffafe'],
      ['200', '#a5f3fc'],
      ['300', '#67e8f9'],
      ['400', '#22d3ee'],
      ['500', '#06b6d4'],
      ['600', '#0891b2'],
      ['700', '#0e7490'],
      ['800', '#155e75'],
      ['900', '#164e63'],
      ['950', '#083344']
    ]
  ],
  [
    'sky',
    [
      ['50', '#f0f9ff'],
      ['100', '#e0f2fe'],
      ['200', '#bae6fd'],
      ['300', '#7dd3fc'],
      ['400', '#38bdf8'],
      ['500', '#0ea5e9'],
      ['600', '#0284c7'],
      ['700', '#0369a1'],
      ['800', '#075985'],
      ['900', '#0c4a6e'],
      ['950', '#082f49']
    ]
  ],
  [
    'blue',
    [
      ['50', '#eff6ff'],
      ['100', '#dbeafe'],
      ['200', '#bfdbfe'],
      ['300', '#93c5fd'],
      ['400', '#60a5fa'],
      ['500', '#3b82f6'],
      ['600', '#2563eb'],
      ['700', '#1d4ed8'],
      ['800', '#1e40af'],
      ['900', '#1e3a8a'],
      ['950', '#172554']
    ]
  ],
  [
    'indigo',
    [
      ['50', '#eef2ff'],
      ['100', '#e0e7ff'],
      ['200', '#c7d2fe'],
      ['300', '#a5b4fc'],
      ['400', '#818cf8'],
      ['500', '#6366f1'],
      ['600', '#4f46e5'],
      ['700', '#4338ca'],
      ['800', '#3730a3'],
      ['900', '#312e81'],
      ['950', '#1e1b4b']
    ]
  ],
  [
    'violet',
    [
      ['50', '#f5f3ff'],
      ['100', '#ede9fe'],
      ['200', '#ddd6fe'],
      ['300', '#c4b5fd'],
      ['400', '#a78bfa'],
      ['500', '#8b5cf6'],
      ['600', '#7c3aed'],
      ['700', '#6d28d9'],
      ['800', '#5b21b6'],
      ['900', '#4c1d95'],
      ['950', '#2e1065']
    ]
  ],
  [
    'purple',
    [
      ['50', '#faf5ff'],
      ['100', '#f3e8ff'],
      ['200', '#e9d5ff'],
      ['300', '#d8b4fe'],
      ['400', '#c084fc'],
      ['500', '#a855f7'],
      ['600', '#9333ea'],
      ['700', '#7e22ce'],
      ['800', '#6b21a8'],
      ['900', '#581c87'],
      ['950', '#3b0764']
    ]
  ],
  [
    'fuchsia',
    [
      ['50', '#fdf4ff'],
      ['100', '#fae8ff'],
      ['200', '#f5d0fe'],
      ['300', '#f0abfc'],
      ['400', '#e879f9'],
      ['500', '#d946ef'],
      ['600', '#c026d3'],
      ['700', '#a21caf'],
      ['800', '#86198f'],
      ['900', '#701a75'],
      ['950', '#4a044e']
    ]
  ],
  [
    'pink',
    [
      ['50', '#fdf2f8'],
      ['100', '#fce7f3'],
      ['200', '#fbcfe8'],
      ['300', '#f9a8d4'],
      ['400', '#f472b6'],
      ['500', '#ec4899'],
      ['600', '#db2777'],
      ['700', '#be185d'],
      ['800', '#9d174d'],
      ['900', '#831843'],
      ['950', '#500724']
    ]
  ],
  [
    'rose',
    [
      ['50', '#fff1f2'],
      ['100', '#ffe4e6'],
      ['200', '#fecdd3'],
      ['300', '#fda4af'],
      ['400', '#fb7185'],
      ['500', '#f43f5e'],
      ['600', '#e11d48'],
      ['700', '#be123c'],
      ['800', '#9f1239'],
      ['900', '#881337'],
      ['950', '#4c0519']
    ]
  ]
]

export const defaultTheme = {
  colors: COLORS,
  spacing: SPACING,
  widthHeight: WIDTH_HEIGHT,
  minMaxHeight: MIN_MAX_HEIGHT,
  minWidth: MIN_WIDTH,
  maxWidth: MAX_WIDTH,
  fonts: FONTS,
  misc: MISC
}
