import { Agjs2, ST } from './types'
import { F, randomId } from './factory'
import { NodeIndex } from './node_index'
import { Formatter } from './formatter'
import { TypeCheck } from './TypeCheck'
import { Libs } from './libs'
import { isElementDataType, deepClone } from './utils'

interface RuntimeParams {
  customAttrs?: NodeIndex['customAttrs']
}

class Runtime {
  nodeIndex: NodeIndex
  formatter: Formatter
  tc: TypeCheck
  libs: Libs

  constructor (opts: RuntimeParams) {
    this.nodeIndex = new NodeIndex({ customAttrs: opts.customAttrs, runtime: this })
    this.libs = new Libs(this)
    this.formatter = new Formatter(this)
    this.tc = new TypeCheck(this)
  }

  isRootElement (element: ST.Exp.Render.PageElement): boolean {
    return element.cid === 'PageRootClass'
  }

  getElementClassDef (element: Agjs2.InstanceNode): Agjs2.ClassDef {
    return this.libs.getClassDef(element.cid)
  }

  getPropDef (element: Agjs2.InstanceNode, propKey: Agjs2.nodeId): ST.Param {
    const cd = this.libs.getClassDef(element.cid)
    const result = cd.props[propKey]
    if (result == null) {
      throw new Error(`${element.cid} (${element.name}/${element.id}) has defined a prop value for ${propKey}, but class doesn't have a prop with this key.`)
    }
    return result
  }

  getPropDefValues (element: Agjs2.InstanceNode): Agjs2.PropDefValue[] {
    const classDef = this.libs.getClassDef(element.cid)

    return Object.keys(classDef.props).map(key => ({
      prop: classDef.props[key],
      propValue: element.propValues[key]
    }))
  }

  setDefaultProps<T extends Agjs2.InstanceNode>(node: T): T {
    const classDef = this.libs.getClassDef(node.cid)

    Object.keys(classDef.props).forEach(propKey => {
      const defaultPropValue = (node.propValues?.[propKey] ?? deepClone(classDef.props[propKey].defaultValue)) as unknown
      // for this to be 100% correct, classDef/props/defaultValue needs to be digged through recursively and work on all string defs (interpolate them)
      // instead, we just address the case of having a string prop right away:
      const stringDefault = defaultPropValue as ST.Exp.Lit.String
      if (stringDefault.t === 'String' || stringDefault.t === 'Text') {
        // TODO: extract to interpolation scheme:
        stringDefault.value = stringDefault.value.replaceAll('${name}', node.name)
      }

      if (isElementDataType(classDef.props[propKey])) {
        (defaultPropValue as ST.Exp.PageElementList).items.forEach(childElement => this.setDefaultProps(childElement))
      }
      node.propValues[propKey] = defaultPropValue as ST.Exp.PropValue
    }
    )
    return node
  }

  // TODO: move to VM
  runObjMethod (id: Agjs2.nodeId, args: ST.Exp.LiteralExpression[]): ST.Exp.Expression {
    const method = this.libs.getObjMethodDef(id)

    // if (method.js != null) {
    //   console.log('WARNING: method calling not yet 100% complete (only uses first argument)')
    //   return method.js(args[0])
    // } else {
    console.log(`error: no implementation for ${method.name}`)
    throw new Error(`error: no implementation for ${method.name}, VM style emulation is deprecated, compiled source only.`)
    // }
  }

  defaultValueForDataType (dataType: ST.DT.TypeDef, opts: { allowNull?: boolean } = {}): ST.Exp.LiteralExpression {
    const id = randomId('ex')
    switch (dataType.type) {
      case 'String':
        return F.makeString('')
      case 'Text':
        return F.makeLit('Text', '')
      case 'Number':
        return F.makeLit('Number', 0)
      case 'Boolean':
        return F.makeLit('Boolean', false)
      case 'Timestamp':
        return F.makeLit('Timestamp', new Date(2000, 1, 1))
      case 'List': {
        const listExp: ST.Exp.Lit.ListOfLit = {
          id,
          t: 'List',
          items: []
        }
        return listExp
      }
      case 'Record': {
        const expFields = {}

        dataType.fields.forEach(field => {
          if (field.optional !== true) expFields[field.name] = this.defaultValueForDataType(field.dataType)
        })

        const recordExp: ST.Exp.Lit.RecordOfLit = { id, t: 'Record', fields: expFields }
        return recordExp
      }
      default:
        if (opts.allowNull != null) {
          return F.makeNull()
        }
        throw new Error(`Unsupported data type "${dataType.type}"`)
    }
  }
}

export { Runtime }
