import { Agjs2, ST } from './types'
import { Runtime } from './runtime'

export interface ScopeMember {
  name: string
  context: Scope
  node: ScopeInput
}

type ScopeInput = ST.Exp.Render.PageElement | Agjs2.DbTableDef | Agjs2.Workflow | ST.Exp.StatementInExp | Agjs2.ClassDef | ST.Param | ST.Exp.Variable | ST.Exp.Method

export class Scope {
  parent: Scope | null
  name: string
  runtime: Runtime
  children: Scope[] = []
  symbols = new Map<Agjs2.nodeId, ScopeMember>()

  constructor (parentScope: Scope | null, name: string, runtime: Runtime) {
    this.parent = parentScope
    this.name = name
    this.runtime = runtime
  }

  /**
   * Removes any references to this Scope by removing it from the child list of
   * parent as well as calling detachFromTree on all children
   * (Does not touch the symbols stored in this or any other scope)
   *
   * @see reset
   *
   */
  detachFromTree (): void {
    // if (this.symbols.size > 0) throw new Error(
    //   `Cannot detach Scope, there are still symbols contained: ${Array.from(this.symbols.values()).map(v => v.name).join(', ')}`
    // )
    this.children.forEach(child => child.detachFromTree())
    // if (this.children.length > 0) throw new Error('Cannot detach Scope, there are still undetached child scopes.')
    if (this.parent != null) {
      this.parent.children.splice(this.children.indexOf(this), 1)
    }
  }

  /**
   * Clears out the scopes content (remove all symbols as well as the content of all sub scopes
   * (and detaches them and itself).
   * This should be called to reset a global scope entirely without creating a new Scope object.
   */
  reset (): void {
    this.symbols.clear()
    this.children.forEach(child => child.reset())
    this.detachFromTree()
  }

  /**
   * Returns all visible symbols in this scope.
   * Symbols from parent scopes will also be visible, unless they are overwritten
   * by a symbol with the same name in a more local (closer to this scope) scope.
   */
  allSymbols (): ScopeMember[] {
    let s = Array.from(this.symbols.values())

    const localNames = s.map(ls => ls.name)

    if (this.parent != null) {
      const parentSymbols = this.parent
        .allSymbols()
        .filter(ps => !localNames.includes(ps.name))
      s = s.concat(parentSymbols)
    }
    return s
  }

  /**
   * Returns nodes that are visible in this scope, supporting partial case insensitive matches.
   * Visible in this scope means the symbols can also be defined in parent scopes.
   */
  findByName (name: string): ScopeMember[] {
    return Array.from(this.allSymbols().values()).filter(sm => sm.name.toLowerCase().startsWith(name.toLowerCase()))
  }

  /**
   * Finds an immediate child by that name. If no such child scope exists, null is returned */
  findChildScope (name: string): Scope | null {
    return this.children.find(sc => sc.name === name) ?? null
  }

  sub (name: string): Scope {
    const child = new Scope(this, name, this.runtime)
    this.children.push(child)
    return child
  }

  add (input: ScopeInput, altName?: string, altId?: string): void {
    const name = altName ?? input.name
    const id = altId ?? input.id

    this.symbols.set(id, {
      name,
      context: this,
      node: input
    })
  }

  addOrRemove (mode: 'add' | 'remove', input: ScopeInput, altName?: string, altId?: string): void {
    if (mode === 'add') {
      this.add(input, altName, altId)
    } else {
      this.remove(input)
    }
  }

  /**
   * Removes all symbols for this node, if they are part of this scope (does not traverse parent scopes)
   *
   * @remarks
   * In theory there can be multiple symbols for the same node, since the key in the symbol map
   * (which is usually the `node`'s id) can also be custom, for example `$thisElement`
   */
  remove (node: ScopeInput): void {
    const deletableKeys: string[] = []

    this.symbols.forEach((sm, key) => {
      if (sm.node.id === node.id) deletableKeys.push(key)
    })

    deletableKeys.forEach(key => this.symbols.delete(key))
  }

  lookup (id: Agjs2.nodeId): ScopeMember | null {
    if (this.symbols.has(id)) {
      return this.symbols.get(id) as ScopeMember
    } else {
      return (this.parent != null) ? this.parent.lookup(id) as ScopeMember : null
    }
  }
}
