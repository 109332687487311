import Preact, { Fragment } from 'preact';
import { useContext, useState } from 'preact/hooks';
import { observer } from "mobx-react"

import { AppContext } from "../app_context"
import { Agjs2, ST } from '../agjs/types'
import { Ide } from '../types'
import { CollapsibleSection, ToolbarGripper, ListViewWrapper, Panel, IconButton, EntityTags } from '../pcomponents'

import { generateStaticDataElementContextMenuFunc } from '../helpers'
import { NameEditor } from '../pcomponents/prop_editor';
import { DataBrowser } from '../pcomponents/data_browser';
import { ConstEditor } from '../pcomponents/const_editor';

/*
const DataItems = observer(({ states }) => {
  const { store } = useContext(AppContext) as Ide.App

  const [renamingId, setRenamingId] = useState(null)

  const triggerRename = (item: ST.Exp.Variable) => {
    store.project.openDataItem(item.id)
    setRenamingId(item.id)
  }

  interface ItemAsVariable { item: ST.Exp.Variable }

  const ConstItem = observer((p : ItemAsVariable) => {
    const { item } = p
    let cl = ''
    if (item.id === store.project.currentDataItemId) {
      cl = ' focused'
    }

    let tags: Preact.VNode | null = null

    const contextMenuHandler = (ev) => {
      store.project.openDataItem(item.id)
      store.ui.contextMenu(
        ev,
        generateStaticDataElementContextMenuFunc(item, store)
      )
    }

    if (item.dataType.type === 'List') {
      const count = (item.value as ST.Exp.Lit.ListOfLit).items.length
      tags = <Fragment>
        <EntityTags dataType={'List'} /><span class="p-0_5"></span>
        <EntityTags dataType={`${count} items`} />
        </Fragment>
    }
    if (item.dataType.type === 'Record') {
      tags = <EntityTags dataType={'Record'} />
    }


    return (
      <div class={'node-title p-1 select-none ' +cl} onContextMenu={contextMenuHandler} onDblClick={() => triggerRename(item)} onClick={() => store.project.openDataItem(item.id)}>
        {renamingId === item.id ? (<NameEditor element={item} onChange={newName => { store.project.op.generic.renameElement(item, newName); triggerRename(null) }} light={true} />) :
          (<Fragment>{item.name}&nbsp;{tags}</Fragment>)}
      </div>
    )
  })

  const items = states.length == 0 ? '(no items)' : states.map((c : ST.Exp.Variable) => <ConstItem item={c} />)

  return (<Panel>
          <ListViewWrapper>
            {items}
          </ListViewWrapper>
          <div class="mt-1">
            <IconButton icon={'add'} title={'Add new data structure'} onClick={() => store.ui.runCommand('NEW_WB_ITEM')} />
          </div>
        </Panel>)
})

const DataWorkbenchContent = observer(() => {
  const { store } = useContext(AppContext) as Ide.App

  const dataSource = store.project.currentDataItem

  if (!dataSource) {
    return <div class="border border-1 rounded border-neutral-200 m-1 p-2 text-neutral-700 text-xs">(no item selected)</div>
  }

  const dt = dataSource.dataType
  const exp = dataSource.value

  const update = (newExp: ST.Exp.LiteralExpression) => {
    store.project.setAttr(dataSource, 'value', newExp)
  }

  return (
    <DataBrowser key={dataSource.id} name={dataSource.name} dataType={dt} exp={exp} onChange={update} />
  )
})
*/

// const CustomStateEditorXXX = observer(({ states }: { states : ST.Exp.Variable[] }) => {
//   const { store } = useContext(AppContext) as Ide.App
// 
//   const dm = useMemo(() => new DockManager(store), [store])
// 
//   const mkDw = (title: string, name:string, parentContainer:string, content:Preact.Element) => {
//     const ds = store.ui.getDockableState(name, parentContainer)
// 
//     return [ds,
//       <DockableWindow title={title} dockableState={ds} manager={dm}>
//         {content}
//       </DockableWindow>]
//   }
// 
//   const dockableViews = [
//     mkDw('Schema', 'data-defs-window',
//          'left-container',
//          <DataItems states={states}/>
//     ) /*,
//     mkDw('Inspector', 'data-inspector-window',
//          'right-container', (<DataInspector />)
//         )
//        */
//   ]
// 
//   const outerMouseMove = (ev: PointerEvent) => {
//     // TODO: optimize
//     const movingDockable = store.ui.getMovingDockable()
//     if (movingDockable) {
//       dm.move(ev, movingDockable)
//     }
//   }
// 
//   return <div class='flex flex-grow' onMouseMove={outerMouseMove}>
//     <FloatingWindows views={dockableViews} manager={dm}/>
//     <ToolbarGripper />
//     <VerticalDock name={'left-container'} attachFrom={'right'} views={dockableViews} manager={dm} />
//     <div className='h-0 min-h-full overflow-auto flex-grow'>
//       <DataWorkbenchContent />
//     </div>
//     <VerticalDock name={'right-container'} attachFrom={'left'} views={dockableViews} manager={dm} />
//   </div>
// })


// Relevant stuff here, everything above is just copy from dataworkbench

const CustomStateEditor = observer((
  { state, extExp, onSubmit } :
  { state: ST.Exp.Variable, extExp: ST.Exp.LiteralExpression, onSubmit: (exp: ST.Exp.Expression) => void }) => {
  const dt = state.dataType
  const exp = extExp || state.value

  const update = (newExp: ST.Exp.LiteralExpression) => {
    onSubmit(newExp)
  }

  return (
    <div>
      <DataBrowser key={state.id} name={state.name} dataType={dt} exp={exp} onChange={update} />
    </div>
  )
})

export { CustomStateEditor }
