import { VNode, ComponentChildren, FunctionComponent, RefObject, Ref } from 'preact'
import { Agjs2, ST } from '../agjs/types'
import { AppContext } from '../app_context'
import { useContext, useState, useRef, useEffect } from 'preact/hooks'

import { MainLayout } from './layout'
import { CloseFn, openPopover, OverlayContentFn, PlacementType } from '../overlay_manager'
import { openDropdownMenu, MenuGenerator } from './menu'
import {JSXInternal} from 'preact/src/jsx'

const gapX = 'gap-x-1'

const UI = {
  HGroup: ({ children }) => (<div className={`d-flex flex-row ${gapX}`}>{children}</div>),
  Grow: ({ children }) => (<div className={`d-flex flex-row flex-fill ${gapX}`}>{children}</div>)
}

const DismissableBackdrop = (props: { shade?: boolean, bootstrapModal?: boolean, children?: ComponentChildren, onDismiss: () => void }): VNode => {
  const backdrop = useRef<HTMLDivElement>(null)

  const keyListener = (ev: KeyboardEvent): void => {
    // FIXME: does not trigger on key press
    if (ev.key === 'Escape') {
      ev.stopPropagation()
      props.onDismiss()
    }
  }

  const onClickDismiss = (ev: MouseEvent): void => {
    if (ev.target === backdrop.current) {
      ev.preventDefault()
      props.onDismiss()
    }
  }

  // const zindex = 'z-index: auto; overflow: hidden;'

  // z-index used to be 'auto'. However, bootstrap buttons have z-index set to 2 and 3, to 'avoid overlapping issues' when
  // these are used in button groups etc (with a border I think).
  // To migitate this, we set the z-index for the backdrop to 10, so it is above all buttons.
  // Issue: what happens with nested overlays (sub menus)?
  const zindex = 'z-index: 10; overflow: hidden;'

  const shade = props.shade === true ? 'backdrop-filter: brightness(.5); ' : ''
  return (
    <div
      className={`${props.bootstrapModal ? 'modal d-block' : ''} position-fixed top-0 start-0 end-0 bottom-0 ag-fadein`}
      style={zindex + shade}
      ref={backdrop}
      onMouseDown={onClickDismiss}
      onContextMenu={onClickDismiss}
      onKeyDown={keyListener}
    >
      {props.children}
    </div>
  )
}

interface IDropdownMenu {
  icon?: string
  disabled?: boolean
  name?: ComponentChildren
  title?: string
  generator: MenuGenerator
  closeRef?: RefObject<(closeFn: CloseFn) => void>
}

/**
 * Only used for the top bar (uses ItemButton which is only used in top bar
 * @see DropdownButton for regular menu that uses a bootstrap button
 */
export const DropdownMenu = ({ name, closeRef, generator, ...props }: IDropdownMenu): VNode => {
  const parent = useRef<HTMLElement>(null)

  const open = () => {
    if (parent.current != null) {
      const domItem = parent.current['base'] as HTMLElement

      const closeFn = openDropdownMenu(generator, domItem)
      if (closeRef != null) closeRef.current = closeFn
    }
  }

  return (
    <ItemButton ref={parent} {...props} onClick={() => open()}>
      {name}
    </ItemButton>
  )
}

interface IDropdownButton {
  children?: ComponentChildren
  generator: MenuGenerator
  icon?: string
  title?: string
  btnClass?: string
  disabled?: boolean
  autosize?: boolean
  closeRef?: RefObject<(closeFn: CloseFn) => void>
}

export const DropdownButton = ({ children, closeRef, generator, btnClass, icon, ...props }: IDropdownButton): VNode => {
  const parent = useRef<HTMLButtonElement>(null)

  const open = () => {
    if (parent.current != null) {
      const domItem = parent.current

      const closeFn = openDropdownMenu(generator, domItem)
      if (closeRef != null) closeRef.current = closeFn
    }
  }

  const iconEl = icon != null ? <Icon icon={icon} autosize={props.autosize} /> : null

  return (
    <button
      ref={parent}
      className={`btn ${btnClass ?? ' btn-secondary'}`}
      type='button'
      onClick={() => open()} {...props}>
        {iconEl}{children}
    </button>
  )
}

interface IPopoverButton {
  icon?: string
  disabled?: boolean
  name?: ComponentChildren
  title?: string
  children: ComponentChildren
  closeRef?: RefObject<(closeFn: CloseFn) => void>
  placement: PlacementType
  popoverTitle?: string
}

interface IPopoverWrapper {
  content: OverlayContentFn
  placement: PlacementType
  popoverTitle?: string
  children: ComponentChildren
}

export const PopoverWrapper = ({ content, children, placement, popoverTitle }: IPopoverWrapper): VNode => {
  const parent = useRef<HTMLSpanElement>(null)

  const open = () => {
    console.log('opening')
    if (parent.current != null) {
      // const domItem = parent.current['base'] as HTMLElement
      const domItem = parent.current

      openPopover((closeFn: CloseFn) => {
        return (
          <div className='popover bs-popover-auto fade show'>
            <div className='popover-arrow position-absolute'></div>
            {popoverTitle && <h3 className='popover-header'>{popoverTitle}</h3>}
            <div className='popover-body'>
              {content(closeFn)}
            </div>
          </div>
        )
      }, domItem, placement)
    }
  }

  return (
    <span ref={parent} onClick={() => open()}>
      {children}
    </span>
  )
}

export const PopoverButton = ({ name, children, closeRef, placement, popoverTitle, ...props }: IPopoverButton): VNode => {
  const parent = useRef<HTMLElement>(null)

  const open = () => {
    if (parent.current != null) {
      const domItem = parent.current['base'] as HTMLElement

      openPopover((closeFn: CloseFn) => {
        if (closeRef) closeRef.current = closeFn
        return (
          <div className='popover bs-popover-auto fade show'>
            <div className='popover-arrow position-absolute'></div>
            {popoverTitle && <h3 className='popover-header'>{popoverTitle}</h3>}
            <div className='popover-body'>
              {children}
            </div>
          </div>
        )
      }, domItem, placement)
    }
  }

  return (
    <ItemButton ref={parent} {...props} onClick={() => open()}>
      {name}
    </ItemButton>
  )
}

/* ItemButton used in TopBar */
const ItemButton: FunctionComponent<
{ icon?: string
  children?: ComponentChildren
  title?: string
  disabled?: boolean
  onClick: (ev: MouseEvent) => void }
> = ({ icon, children, title, disabled, onClick }) => {
  const click = (ev: MouseEvent): void => {
    ev.preventDefault()
    disabled !== true && onClick != null && onClick(ev)
  }

  // const cl: string[] = []
  // if (disabled === true) {
  //   cl.push('text-neutral-500 cursor-default')
  // } else {
  //   cl.push('hover:bg-container-bg hover:text-neutral-100')
  // }

  // let iconMarkup: null | VNode = null

  // if (icon === 'animated-spinner') {
  //   iconMarkup = (
  //     <svg aria-hidden='true' role='status' className='inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600' viewBox='0 0 100 101' fill='none' xmlns='http://www.w3.org/2000/svg'>
  //       <path d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z' fill='currentColor' />
  //       <path d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z' fill='#1C64F2' />
  //     </svg>
  //   )
  // } else if (icon != null) {
  //   iconMarkup = (<span className='material-icons'>{icon}</span>)
  // }

  // return (
  //   <a
  //     className={`p-1 px-2 border-ridge border-r flex items-center select-none ${cl.join(' ')}`}
  //     title={title}
  //     href='#'
  //     onClick={click}
  //   >
  //     {iconMarkup}
  //     {children != null ? (<div className={icon != null ? 'ml-0_5' : ''}>{children}</div>) : null}
  //   </a>
  // )
  let iconMarkup: VNode | null = null
  if (icon === 'animated-spinner') {
    iconMarkup = (
      <svg aria-hidden='true' role='status' className='d-inline-block top-menu-spinner animate-spin' viewBox='0 0 100 101' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z' fill='currentColor' />
        <path d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z' fill='#1C64F2' />
      </svg>
    )
  } else if (icon != null) {
    iconMarkup = <span className="material-symbols-outlined">{icon}</span>
  }

  const cl = disabled === true ? ' disabled' : ''

  // return (
  //   <a className={`d-flex align-items-center border-end px-1${cl}`} title={title} href='#' onClick={click} >
  //     {iconMarkup}
  //     {children != null ? (<span className={icon != null ? 'ms-1' : ''}>{children}</span>) : null}
  //   </a>
  // )
  return (
    <div className={"icon-link border-end p-1 select-none cursor-pointer"} title={title} href='#' onClick={click} >
      {iconMarkup}{children}
    </div>
  )
}

const ToolbarGripper = (): VNode => <div className='bg-container-bg w-2 border-ridge border-r' />

/**
 * Panel renders a container that can be placed inside a sidebar. Each Panel has its own title (if enabled) and should
 * be able to detach to a floating window if supported.
 * TODO: options to specify a grab handle and set floating style
 */
interface PanelProps {
  title?: string | ComponentChildren
  divRef?: Ref<HTMLDivElement>
  children?: ComponentChildren
  width?: 'sm' | 'md' | 'lg'
}
const Panel = ({ title, children, divRef, width = 'md' }: PanelProps): VNode => (
  <div className={`bg-base-2 panel-${width}`} ref={divRef}>
    {title != null ? (typeof title === 'string' ? <div className='bg-base-3 p-2'><strong>{title}</strong></div> : title) : null}
    {children}
  </div>
)


export const Section = ({ children }: { children: ComponentChildren }): VNode => (<div className='bg-base-2'>{children}</div>)
export const FormSection = ({ children }: { children: ComponentChildren }): VNode => (<div className='bg-base-2 p-2'>{children}</div>)
/*
 * when first shown, section is expanded (height NOT set!)
 * when it should collapse, store current heigth and then trigger collapse
 * when it should expand, use last stored height (for transition)
*/
const CollapsibleSection = ({ title, children }: { title?: string | ComponentChildren, children?: ComponentChildren }): VNode => {
  const [expandedHeight, setExpandedHeight] = useState<null | number>(null)
  const [visibleHeight, setVisibleHeight] = useState<null | number>(null)
  const [mode, setMode] = useState('expanded')
  const content = useRef<HTMLDivElement>(null)

  useEffect(() => showOrHide(), [mode])

  const storeLastExpandedHeight = (): void => {
    const currentHeight: null | number = content?.current?.offsetHeight ?? null
    setExpandedHeight(currentHeight)
  }

  const toggle = (): void => {
    if (mode === 'expanded') {
      storeLastExpandedHeight()
      setMode('beforeCollapse')
    } else if (mode === 'collapsed') {
      setMode('expanded')
    }
  }

  const showOrHide = (): void => {
    if (mode === 'beforeCollapse') {
      setVisibleHeight(expandedHeight)
      setMode('collapsed')
    } else if (mode === 'collapsed') {
      setVisibleHeight(0)
    } else if (mode === 'expanded') {
      setVisibleHeight(expandedHeight)
    }
  }

  const transitionEnd = (): void => {
    if (visibleHeight != null && visibleHeight > 0) {
      setVisibleHeight(null)
    }
  }

  const contentStyle = {
    transitionDuration: ((expandedHeight ?? 0) / 500.0).toString() + 's',
    height: visibleHeight
  }

  return (
    <span className={'overflow-hidden ' + (mode === 'collapsed' ? ' collapsed' : '')}>
      <div className="p-2 bg-base-3" onClick={toggle}>
        <span className="section-title cursor-pointer select-none">{title}</span>
      </div>
      <div className="bg-base-2 section-content overflow-hidden" onTransitionEnd={transitionEnd} style={contentStyle} ref={content}>
        {children}
      </div>
    </span>
  )
}

interface IIconButton {
  icon: string
  onClick: (event: MouseEvent) => void
  title?: string
  label?: string
  disabled?: boolean
  pressed?: boolean
  light?: boolean
}
const IconButton = ({ icon = '', onClick, title, label, disabled = false, light = false, pressed = false }: IIconButton): VNode => {
  // let colors = light
  //   ? ' border-neutral-200 ' + (
  //     pressed
  //       ? 'border-t-neutral-400 border-l-neutral-400 border-b-neutral-100 border-r-neutral-100 bg-neutral-300 text-neutral-700 '
  //       : 'bg-neutral-100 text-neutral-800 '
  //   )
  //   : ' border-ridge ' + (
  //     pressed
  //       ? 'border-t-neutral-900 border-l-neutral-900 border-b-neutral-600 border-r-neutral-600 bg-neutral-800 text-neutral-400 '
  //       : 'bg-popped text-guitext  '
  //   )
  // if (!disabled) { colors += light ? ' hover:bg-neutral-300 ' : ' hover:bg-pressed ' }

  // const iconCl = 'material-symbols-outlined w-[24px] h-[24px] text-sm'

  // const cl = 'cursor-pointer select-none h-[24px] text-xs text-center inline-block border rounded-sm ' +
  //   colors +
  //   (disabled ? ' opacity-40 ' : '')


  let colors = light
    ? ' btn-light ' + (pressed ? 'btn-dark ' : '')
    : ' btn-secondary ' + (pressed ? ' btn-dark ' : '')
  //if (!disabled) { colors += light ? ' hover:bg-neutral-300 ' : ' hover:bg-pressed ' }

  const iconCl = 'material-symbols-outlined'

  const cl = 'icon-link btn btn-sm ' +
    colors +
    (disabled ? ' opacity-40 ' : '')

  return (
    <button
      className={cl}
      title={title}
      onMouseDown={ev => ev.stopPropagation()}
      onClick={(ev) => { ev.stopPropagation(); ev.preventDefault(); !disabled && onClick != null && onClick(ev) }}
      disabled={disabled}
    >
      {icon != null && <div className={iconCl}>{icon}</div>}
      {label}
    </button>
  )
}

/**
 * Generates a bare minium span with a material symbol. will only add a css border if asked to do some
 */
const Icon = ({ icon, title, border, autosize }: { icon: string, title?: string, border?: boolean, autosize?: boolean }): VNode => {
  const cl = 'material-symbols-outlined'
  const borderCl = ' border bg-dark'

  return (<span className={cl + (border != null ? borderCl : '')} style={autosize === true ? 'font-size: 100%; vertical-align: middle;' : ''} title={title}>{icon}</span>)
}

interface IUITextInputEvent extends InputEvent {
  target: EventTarget & HTMLInputElement | null
}

interface IUITextInput {
  value: string
  placeholder?: string
  // onInput: (ev: TargetedInputEvent<HTMLInputElement>) => void
  // onInput: (ev: IUITextInputEvent) => void
  onInput: (ev: JSXInternal.TargetedInputEvent<HTMLInputElement>) => void
  onEsc?: () => void
  onEnter?: () => void
  autofocus?: boolean
  ref?: Ref<HTMLInputElement>
}

const UITextInput = ({ ref, value, placeholder, onInput, onEsc, onEnter, autofocus }: IUITextInput): VNode => {
  const inputProps = { value, placeholder, onInput, autofocus, ref }
  const keyhandler = (ev: KeyboardEvent): void => {
    if (ev.key === 'Escape' && (onEsc != null)) { ev.stopPropagation(); ev.preventDefault(); onEsc() }
    if (ev.key === 'Enter' && (onEnter != null)) { ev.stopPropagation(); ev.preventDefault(); onEnter() }
  }

  return (
    <input
      {...inputProps}
      onKeyDown={keyhandler}
      className="form-control"
    />
  )
}

export interface UISelectOption {
  id: string
  label: any
  value: any
  disabled?: boolean
}

interface IUISelect {
  options: UISelectOption[]
  selected?: any
  onSelect: (value: any) => void
}

const UISelect = ({ options, selected, onSelect }: IUISelect): VNode => {
  const select = (ev): void => {
    const newId = ev.target.value
    const selectedItem = options.find(item => item.id === newId)
    onSelect(selectedItem?.value)
  }

  return (
    <select className="form-select" onInput={select}>
      {options.map(item => <option disabled={item.disabled ?? false} key={item.id} value={item.id} selected={item.value === selected}>{item.label}</option>)}
    </select>
  )
}

export const ShortUUID = ({ uuid }: { uuid: string }): VNode => {
  return <span title={uuid}>{uuid.substring(0, 5)}<span className='text-muted'>…</span></span>
}

interface TElementTagParams {
  light?: boolean
  name: string
  title?: string | null
  cc?: string
}

const ElementTag = ({ name, title, cc, light }: TElementTagParams): VNode => {
  const props = title != null ? { title } : {}

  return (
    <div className={`badge ${cc ?? 'text-bg-secondary'}`} {...props}>{name}</div>
  )
}

const DataTypeTag = ({ dataType, light }: { dataType: ST.DT.TypeDef, light?: boolean }): VNode => {
  const { runtime } = useContext(AppContext)
  const bg = 'bg-neutral-700'
  let color = bg + ' ' + 'text-neutral-200'

  if (bg === 'bg-neutral-700') color += ' outline outline-1 outline-neutral-200'

  let name = dataType.type
  let title: string | null = null

  if (dataType.type === 'List' || dataType.type === 'Record') {
    title = runtime.formatter.toString(dataType)
    name += ' ...'
  }

  return <ElementTag title={title} name={name} cc={color} light={light} />
}

const EntityTags = ({ classDef, dataType }: { classDef?: Agjs2.ClassDef, dataType?: ST.DT.TypeDef }): VNode | null => {
  const cl = 'text-bg-secondary'
  let label: string | null = null
  let title: string | null = null

  if (classDef != null) {
    label = classDef.name
    title = 'PageElement'
  }

  if (dataType != null) {
    label = dataType.type
  }

  if (label === null) return null

  return <ElementTag name={label} title={title} cc={cl} />
}

const ListViewWrapper = ({ children }): VNode => (
  <div className='m-2 bg-base-2'>
    <div className='p-0_5 overflow-auto max-h-48'>
      {children}
    </div>
  </div>
)
export { UI, ListViewWrapper, ItemButton, Panel, CollapsibleSection, ElementTag, ToolbarGripper, MainLayout, Icon, DataTypeTag, IconButton, UITextInput, UISelect, EntityTags, DismissableBackdrop }
