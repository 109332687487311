/* Shared code that is specific to the IDE in relation to interacting with AGJS
 * (additions to the Agjs library that are not required in exported / compiled apps */

import * as Agjs from './agjs'
// https://stackoverflow.com/a/27078401/440811
// Returns a function, that, when invoked, will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you'd like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.
//
// Update: Types by Malte

type TPFunc = (...args: any) => any
type ThrottledFunc<Payload extends TPFunc> = (...args: Parameters<Payload>) => void

interface ThrottleOpts {
  leading?: boolean
  trailing?: boolean
}

function throttle<Payload extends TPFunc> (func: Payload, wait: number, options: ThrottleOpts = {}): ThrottledFunc<Payload> {
  let context: typeof this
  let args: IArguments | null
  let result: ReturnType<Payload>
  let timeout: null | NodeJS.Timeout = null
  let previous = 0

  const later = function () {
    previous = options.leading === false ? 0 : Date.now()
    timeout = null
    result = func.apply(context, args)
    if (!timeout) context = args = null
  }
  return function () {
    const now = Date.now()
    if (!previous && options.leading === false) previous = now
    const remaining = wait - (now - previous)
    context = this
    args = arguments
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      previous = now
      result = func.apply(context, args)
      if (!timeout) context = args = null
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining)
    }
    return result
  }
};

const randomId = Agjs.randomId

export { throttle, randomId }
