import { useState, useContext } from 'preact/hooks'

import { IconButton } from './index'
import { Scope } from './../agjs/scope'
// import { CmInput } from './CmInput'
import { F } from './../agjs/factory'

import { AppContext } from '../app_context'
import { ExpressionEditor } from './ExpressionEditor'
import { VNode } from 'preact'

let uiStyleRef: Element | null = null
let iframeStyleRefs: Element[] = []

function toggleVisualDOMRemounts (): void {
  if (uiStyleRef == null) {
    const sheet = `@keyframes flash-new-elements {
      0% { outline: 2px solid blue; }
      50% { outline: 2px solid red; }
      100% { outline: 2px solid blue; }
    }

    * {
      animation: flash-new-elements 0.5s linear;
    }`
    iframeStyleRefs.forEach(iframeStyleTag => iframeStyleTag.remove())
    iframeStyleRefs = []
    for (const fr of document.getElementsByTagName('iframe')) {
      const iframeContent = fr.contentDocument as Document
      const styleTag = iframeContent.createElement('style')
      styleTag.textContent = sheet
      iframeContent.head.appendChild(styleTag)
      iframeStyleRefs.push(styleTag)
    }
    uiStyleRef = document.createElement('style')
    uiStyleRef.textContent = sheet
    document.head.appendChild(uiStyleRef)
  } else {
    iframeStyleRefs.forEach(iframeStyleTag => iframeStyleTag.remove())
    iframeStyleRefs = []
    document.head.removeChild(uiStyleRef)
  }
}

const serializeExScope = (scope: Scope) => {
  const res = {
    symbols: {},
    children: {}
  }

  scope.symbols.forEach((member, id) => {
    // was member.type??
    res.symbols[member.name] = member.name
  })

  scope.children.forEach(child => {
    res.children[child.name] = serializeExScope(child)
  })

  return res
}

const Playground = (): VNode => {
  const { runtime } = useContext(AppContext)
  // return (<CmInput runtime={runtime} scope={runtime.nodeIndex.globalScope} source='' onAutocomplete={() => null} onInput={() => null} onLint={() => null}/>)
  // const ExpressionEditor = observer(({ exp, fieldName, requiredType, onSubmit, scope, light }: IExpressionEditor) => {
  const testExp = F.makeString('')
  return (<ExpressionEditor exp={testExp} scope={runtime.nodeIndex.globalScope} fieldName='ConsoleSession' onSubmit={exp => console.log('Finished expression:', exp)} light={false} />)
}

export const DevConsole = (): VNode => {
  const { store } = useContext(AppContext)
  const [buffer, setBuffer] = useState('')
  const [activeTab, setActiveTab] = useState('playground')

  const setContent = (t: string) => {
    switch (t) {
      case 'project':
        setActiveTab(t)
        setBuffer(JSON.stringify(store.project._full, null, 2))
        break
      case 'scope':
        setActiveTab(t)
        setBuffer(JSON.stringify(serializeExScope(store.project.nodeIndex.globalScope), null, 2))
        break
      case 'playground':
        setActiveTab(t)
        break
    }
  }

  return (
    <div class='d-flex flex-column h-100'>
      <div class='d-flex flex-row'>
        <IconButton icon='terminal' label='AGL Console' onClick={() => setContent('playground')} />
        <IconButton icon='code_blocks' label='Project' onClick={() => setContent('project')} />
        <IconButton icon='account_tree' label='Scope' onClick={() => setContent('scope')} />
      </div>
      {activeTab === 'playground' ? <Playground /> : <textarea class='font-mono'>{buffer}</textarea>}
      <div>
        <IconButton icon='file_save' label='Export to browser console' light onClick={() => console.log(JSON.parse(buffer))} />
        <IconButton icon='animation' label={(uiStyleRef != null ? 'Disable' : 'Enable') + ' visual DOM remounts'} light onClick={() => toggleVisualDOMRemounts()} />
      </div>
    </div>
  )
}
