import { closeAllMenus, nonMenuOverlaysVisible } from './overlay_manager'
import { Ide } from './types'

type HotKeyHandlerFunc = (evt: KeyboardEvent) => void

const noModKeys = (event: KeyboardEvent): boolean => !(event.ctrlKey || event.metaKey || event.shiftKey)

/** Returns true if the given element needs keyboard events itself (for example input elements).
 * This is to make sure that pressing the delete key while in an input field doesn't triger the global delete function
 * etc. */
const elementNeedsKeyboard = (el: HTMLElement): boolean => {
  return (
    ['INPUT', 'LABEL', 'SELECT', 'TEXTAREA',
      'BUTTON', 'FIELDSET', 'LEGEND', 'DATALIST',
      'OUTPUT', 'OPTION', 'OPTGROUP'].includes(el.tagName) || el.closest('.needs-kbd') != null)
}

// TODO: use a library for this. Mousetrap seems good but abandoned, maybe https://github.com/jaywcjlove/hotkeys-js?
const makeHotKeyHandler = (store: Ide.App['store']): HotKeyHandlerFunc => {
  return (evt: KeyboardEvent) => {
    if (nonMenuOverlaysVisible() ||
       elementNeedsKeyboard(evt.target as HTMLElement)) return

    if ((noModKeys(evt)) && (evt.key === 'Delete' || evt.key === 'Backspace')) {
      evt.preventDefault()
      closeAllMenus()
      store.ui.runCommand('DELETE')
      return
    }

    const ctrlOrCmdZ = (evt.key === 'z' && (evt.ctrlKey || evt.metaKey))
    const cmdShiftZ = (evt.key === 'Z' && (evt.ctrlKey || evt.metaKey) && evt.shiftKey)
    const ctrlY = (evt.key === 'y' && evt.ctrlKey)

    if (ctrlOrCmdZ) {
      evt.preventDefault()
      closeAllMenus()
      store.project.undo()
      return
    }
    if (navigator.userAgent.includes('OS X')) {
      if (cmdShiftZ) {
        evt.preventDefault()
        closeAllMenus()
        store.project.undo()
        return
      }
    } else {
      if (ctrlY) {
        evt.preventDefault()
        closeAllMenus()
        store.project.redo()
        return
      }
    }

    switch (evt.key) {
      case 'w':
        evt.preventDefault()
        closeAllMenus()
        store.ui.openWorkbench('workflows')
        break
      case 'p':
        evt.preventDefault()
        closeAllMenus()
        store.ui.openWorkbench('page')
        break
      case 'd':
        evt.preventDefault()
        closeAllMenus()
        store.ui.openWorkbench('data')
        break
      case '/':
        evt.preventDefault()
        closeAllMenus()
        store.ui.openDevConsole()
        break
    }
  }
}

const makeElementKeyHandler = (store, elementNode) => {
  return (evt: KeyboardEvent) => {
    if ((noModKeys(evt)) && (evt.key === 'Delete' || evt.key === 'Backspace')) {
      evt.stopImmediatePropagation()
      store.project.op.page.deleteElement(elementNode)
    }
  }
}

export { makeHotKeyHandler, makeElementKeyHandler }
