import { UITextInput, UISelect, IconButton } from './index';
import { ST } from './../agjs/types';
import { F, randomId } from './../agjs/factory';
import { AppContext } from '../app_context'
import {useContext} from 'preact/hooks';

const isPrimitive = (dataType: ST.DT.TypeDef) => ['none', 'String', 'Number', 'Text', 'Date', 'Boolean'].includes(dataType.type)

const mkDt = (name: string, plural: boolean) => ({ id: name, label: `${name}${plural ? 's' : ''}`, value: name })

const mkDataTypeOptions = (plural: boolean, dbCompatible?: boolean) => {
  if (dbCompatible) {
    return [
    { id: 'none', label: '(Select a data type)', value: 'none' },
    { id: 'DbForeignKey', label: 'Reference (Record in another table)', value: 'DbForeignKey' },
    { id: '_break', label: '---', value: '-', disabled: true },
    mkDt('String', plural),
    mkDt('Number', plural),
    mkDt('Text', plural),
    mkDt('Date', plural),
    mkDt('Boolean', plural),
    mkDt('Timestamp', plural)
  ]
  } else {
    return [
    { id: 'none', label: '(Select a data type)', value: 'none' },
    { id: 'List', label: plural ? 'Lists...' : 'List of...', value: 'List' },
    { id: 'Record', label: plural ? 'Records...' : 'Record of...', value: 'Record' },
    { id: '_break', label: '---', value: '-', disabled: true },
    mkDt('String', plural),
    mkDt('Number', plural),
    mkDt('Text', plural),
    mkDt('Date', plural),
    mkDt('Boolean', plural)
  ]
  }
}

const TypeContainer = ({ children }) => <div class="border border-ridge rounded-sm p-1 mb-1">{children}</div>

interface IRecordTypeEditor {
  fields: ST.DT.RecordFieldDef[]
  onPickFields: (fields: ST.DT.RecordFieldDef[]) => void
}
const RecordTypeEditor = ({ fields, onPickFields } : IRecordTypeEditor) => {
  const pickField = (index, value) => {
    fields[index].dataType = value
    onPickFields(fields)
  }

  const setFieldName = (index, newName) => {
    fields[index].name = newName
    onPickFields(fields)
  }

  const removeField = (index) => {
    fields.splice(index, 1)
    onPickFields(fields)
  }

  const renderFieldRow = ({ field, index }) => {
    if (isPrimitive(field.dataType)) {
      return (
        <TypeContainer>
          <div class='flex items-center'>
            <UITextInput value={field.name} placeholder={'Field name'} onInput={(ev: InputEvent) => setFieldName(index, (ev.target as HTMLInputElement).value)} />
            <div class="ml-2">
              <DataTypeEditor
                dt={field.dataType}
                onPick={value => pickField(index, value)}
              />
            </div>
          <IconButton
            icon={'delete'}
            title={'Remove field'}
            onClick={() => removeField(index)} />
          </div>
        </TypeContainer>)
    } else {
      return (
        <TypeContainer>
          <UITextInput value={field.name} placeholder={'Field name'} onInput={ev => setFieldName(index, (ev.target as HTMLInputElement).value)} />
          <div class="mt-2">
          <DataTypeEditor
            dt={field.dataType}
            onPick={value => pickField(index, value)}
          />
          </div>
          <div class="mt-2 text-right">
            <IconButton
              icon={'delete'}
              title={'Remove field'}
              onClick={() => removeField(index)} />
          </div>
        </TypeContainer>
      )
    }
  }

  const addField = () => {
    fields.push({ name: 'NewField', dataType: F.makeNoneType(), t: 'DataType', type: 'RecordFieldDef', id: randomId() })
    onPickFields(fields)
  }

  return (
    <div>
      {fields.map((field, index) => renderFieldRow({ field, index }))}
      <TypeContainer>
        <IconButton
          icon={'add'}
          title={'Add field'}
          onClick={addField} />
      </TypeContainer>
    </div>
  )
}

interface DataTypeEditorProps {
  plural?: boolean
  dt: ST.DT.TypeDef
  dbCompatible?: boolean
  onPick: (dt: ST.DT.TypeDef) => void
}

const DataTypeEditor = ({ dt, onPick, dbCompatible, plural }: DataTypeEditorProps) => {
  const { runtime } = useContext(AppContext)

  const dataTypes = mkDataTypeOptions(plural != null, dbCompatible)

  const processPick = (fullDt: ST.DT.TypeDef) => {
    onPick(fullDt)
    // Wozu ist diese func da? wahrscheinlich um id zu strippen?
    // das war der alte code (ist aufgefallen weil 't' fehlte- kann ja eigentlich gar
    // nicht funktionieren da z.b. bei record types das 'fields' fehlen würde:
    // fullDt.itemType !== null ? onPick(fullDt) : onPick({ type: fullDt.type })
  }

  const select = (value: string): void => {
    switch (value) {
      case 'List':
        processPick(F.makeListType((dt as ST.DT.ListTypeDef).itemType ?? F.makeNoneType()))
        break
      case 'Record':
        processPick(F.makeRecordDefType((dt as ST.DT.RecordTypeDef).fields ?? []))
        break
      case 'DbForeignKey':
        processPick(F.makeDbForeignKeyDT((dt as ST.DT.DbForeignKey).refId ?? 'none'))
        break
      default:
        processPick(F.makePrimitiveDT(value as ST.DT.PrimitiveTypeDef['type']))
    }
  }

  const pickSubType = (itemType: ST.DT.TypeDef): void => {
    // processPick({ type: dt.type, itemType: itemType })
    processPick(F.makeListType(itemType))
  }

  const pickRecordFields = (fields: ST.DT.RecordFieldDef[]): void => {
    processPick(F.makeRecordDefType(fields))
  }

  const pickForeignKeyTable = (tableId: string): void => {
    processPick(F.makeDbForeignKeyDT(tableId))
  }

  if (dt.type === 'Record') {
    return (
      <div>
        <UISelect options={dataTypes} selected={dt.type} onSelect={select} />
        <div class="ml-2 mt-2">
          <RecordTypeEditor fields={dt.fields} onPickFields={pickRecordFields} />
        </div>
      </div>
    )
  }

  if (dt.type === 'DbForeignKey') {
    const foreignTables = [{ id: 'none', label: '(Select a data type)', value: 'none' }].concat(
      runtime.nodeIndex.project.db.tables.map(td => ({ id: td.id, label: td.name, value: td.id }))
    )

    return (
      <div>
        <UISelect options={dataTypes} selected={dt.type} onSelect={select} />
        <div class="ml-2 mt-2">
          <UISelect options={foreignTables} selected={dt.refId} onSelect={pickForeignKeyTable} />
        </div>
      </div>
    )
  }

  if (dt.type === 'List') {
    if (isPrimitive(dt.itemType)) {
      return (
        <div class="flex items-center">
          <UISelect options={dataTypes} selected={dt.type} onSelect={select} />
          <div class="ml-2">
            <DataTypeEditor dt={dt.itemType} onPick={pickSubType} plural={true} />
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <UISelect options={dataTypes} selected={dt.type} onSelect={select} />
          <div class="ml-2 mt-2">
            <div>LookMeUp this code should never render.</div>
            <DataTypeEditor dt={dt.itemType} onPick={pickSubType} plural={true} />
          </div>
        </div>
      )
    }
  }

  return <UISelect options={dataTypes} selected={dt.type} onSelect={select} />
}

const DataTypePreview = ({ dt }: { dt: ST.DT.TypeDef }) => {
  // return <div>{JSON.stringify(dt)}</div>
  const cl = 'border border-ridge p-1 m-1'
  if (!dt || dt.type === 'none') {
    return <div>{'(incomplete)'}</div>
  }
  if (dt.type === 'List') {
    return (
      <div class={cl}>
        {' ['} <DataTypePreview dt={dt.itemType} />{' ,'} <DataTypePreview dt={dt.itemType} />{' ,'} {'...'} {' ]'}
      </div>
    )
  } else if (dt.type === 'Record') {
    const FieldPreview = ({ field }) => <div>{field.name} = <DataTypePreview dt={field.dt} /></div>
    if (dt.fields.length === 0) return <div>(incomplete record definition)</div>
    return (
      <div class={cl}>
        {' {'} {dt.fields.map(f => <FieldPreview field={f} />)} {' }'}
      </div>
    )
  } else {
    return <strong>{dt.type}</strong>
  }
}

export { DataTypeEditor, DataTypePreview }
