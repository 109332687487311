import { Agjs2, ST } from './types'

type GenNodeCallback<NodeType> = <T extends NodeType>(node: T, ancestors: Ancestry) => T | null
type NodeCallbackPlain = GenNodeCallback<Agjs2.Node>
type NodeCallbackWithParserMetaInfo = GenNodeCallback<Agjs2.Node & Agjs2.ParserMetaInfo>

type NodeCallback = NodeCallbackPlain | NodeCallbackWithParserMetaInfo

export interface Ancestry {
  parentNode: Agjs2.Node | null
  ancestors: Ancestry | null
  attrName: string | null
  attrIndex: number | null
}

function fromAttr (ancestors: Ancestry, attrName: string, attrIndex: number | null = null): Ancestry {
  return { ...ancestors, attrName, attrIndex }
}

export function StripParserMetaInfo (tree: Agjs2.Node, stripRaw: boolean = false): Agjs2.Node {
  const cursor = new Cursor(tree)
  return cursor.iterate2(
    <T extends Agjs2.Node>(node: T, _ancestors: Ancestry): T => {
      if (stripRaw) {
        const { parser, _raw, ...rest } = node as Agjs2.Node & Agjs2.ParserMetaInfo & { _raw: string | null }
        return rest as T
      } else {
        const { parser, ...rest } = node as Agjs2.Node & Agjs2.ParserMetaInfo
        return rest as T
      }
    }
  )
}

export function WipeIds (tree: Agjs2.Node): Agjs2.Node {
  const cursor = new Cursor(tree)
  return cursor.iterate2(
    <T extends Agjs2.Node>(node: T, _ancestors: Ancestry): T => {
      node.id = ''
      return node
    }
  )
}

export class Cursor {
  tree: Agjs2.Node

  constructor (tree: Agjs2.Node) {
    this.tree = tree
  }

  iterate2 (fn: NodeCallback): Agjs2.Node {
    return this.iterate(fn, this.tree, {
      parentNode: null,
      attrName: null,
      attrIndex: null,
      ancestors: null
    })
  }

  iterate <T extends Agjs2.NodeTree>(fn: NodeCallback, tree: T, ancestors: Ancestry): T {
    if (Array.isArray(tree)) {
      return tree.map((sibling, index) => {
        const result = this.iterate(fn, sibling, { ...ancestors, attrIndex: index })
        return result ?? sibling
      }) as T
    }

    // Do not attempt to parse this if errors are present
    const ae = tree as ST.Exp.AbstractExpression
    if (ae.errors != null && ae.errors.length > 0) {
      return tree
    }

    if ((tree as ST.Exp.Render.PageElement).t === 'RenderPageElement') return this.iteratePageElement(fn, tree as ST.Exp.Render.PageElement, ancestors) as T
    if ((tree as ST.DT.TypeDef).t === 'DataType') return this.iterateDataType(fn, tree as ST.DT.TypeDef, ancestors) as T

    if (typeof (tree as ST.Exp.Expression).t === 'string') return this.iterateOverExp(fn, tree as ST.Exp.Expression, ancestors) as T
    throw new Error(`unknown node type for iterate: ${JSON.stringify(tree)}`)
  }

  iteratePageElement (fn: NodeCallback, el: ST.Exp.Render.PageElement, prevAnc: Ancestry): ST.Exp.Render.PageElement {
    const ancestors = { ancestors: prevAnc, parentNode: el, attrName: null, attrIndex: null }

    const attr = (attr: string, index: number | null = null): Ancestry => fromAttr(ancestors, attr, index)

    const u = <T extends Agjs2.Node>(node: T, ancestors: string | Ancestry): T => {
      const subAnc = typeof ancestors === 'string'
        ? attr(ancestors)
        : ancestors
      const result = (fn as NodeCallbackPlain)(node, subAnc)
      return result === null ? node : result
    }

    if (el.cid === 'PageRootClass') {
      return {
        ...u(el, prevAnc),
        states: this.iterate(fn, el.states, attr('states')),
        propValues: this.iterateKeyValue(fn, el.propValues, attr('propValues'))
      }
    } else {
      return {
        ...u(el, prevAnc),
        propValues: this.iterateKeyValue(fn, el.propValues, attr('propValues'))
      }
    }
  }

  iterateDataType (fn: NodeCallback, dt: ST.DT.TypeDef, prevAnc: Ancestry): ST.DT.TypeDef {
    const ancestors = { ancestors: prevAnc, parentNode: dt, attrName: null, attrIndex: null }

    const attr = (attr: string, index: number | null = null): Ancestry => fromAttr(ancestors, attr, index)

    const u = <T extends Agjs2.Node>(node: T, ancestors: string | Ancestry): T => {
      const subAnc = typeof ancestors === 'string'
        ? attr(ancestors)
        : ancestors
      const result = (fn as NodeCallbackPlain)(node, subAnc)
      return result === null ? node : result
    }

    switch (dt.type) {
      case 'List':
        return {
          ...u(dt, prevAnc),
          itemType: this.iterate(fn, dt.itemType, attr('itemType'))
        }
      case 'Record':
        return {
          ...u(dt, prevAnc),
          fields: dt.fields.map(
            (field, index) =>
              this.iterateRecordFieldDef(fn, field, { ...attr('fields'), attrIndex: index }) ?? field
          )
        }
      default:
        // Primitive data type
        return u(dt, prevAnc)
    }
  }

  /** Iterates over the RecordFieldDef, which is not part of the DT.TypeDef type union.
  * So this function mostly exists for typing reasons, could otherwise be handled inside iterateDataType */
  iterateRecordFieldDef (fn: NodeCallback, field: ST.DT.RecordFieldDef, ancestors: Ancestry): ST.DT.RecordFieldDef {
    const attr = (attr: string, index: number | null = null): Ancestry => fromAttr(ancestors, attr, index)

    const u = <T extends Agjs2.Node>(node: T, ancestors: string | Ancestry): T => {
      const subAnc = typeof ancestors === 'string'
        ? attr(ancestors)
        : ancestors
      const result = (fn as NodeCallbackPlain)(node, subAnc)
      return result === null ? node : result
    }

    return {
      ...u(field, ancestors),
      dataType: this.iterateDataType(fn, field.dataType, attr('dataType'))
    }
  }

  /** Iterates over a Record<string, Expression> or object */
  iterateKeyValue <VT extends ST.Param | ST.Exp.Expression>(fn: NodeCallback, kv: Record<string, VT>, ancestors: Ancestry): Record<string, VT> {
    const newObj = {}
    for (const key in kv) {
      newObj[key] = this.iterate(fn, kv[key], {
        // TODO: possibly wrong (ancestors)
        ancestors,
        parentNode: ancestors.parentNode,
        attrName: `${ancestors.attrName ?? ''}.${key}`,
        attrIndex: null
      })
    }
    return newObj
  }

  iterateOverExp (fn: NodeCallback, node: ST.Lang, prevAnc: Ancestry): ST.Lang {
    const ancestors = { ancestors: prevAnc, parentNode: node, attrName: null, attrIndex: null }

    const attr = (attr: string, index: number | null = null): Ancestry => fromAttr(ancestors, attr, index)

    const u = <T extends Agjs2.Node>(node: T, ancestors: string | Ancestry): T => {
      const subAnc = typeof ancestors === 'string'
        ? attr(ancestors)
        : ancestors
      const result = (fn as NodeCallbackPlain)(node, subAnc)
      return result === null ? node : result
    }

    switch (node.t) {
      case 'ExecutionContext':
      case 'LastResult':
      case '$incomplete':
      case '$unresolved':
      case 'String':
      case 'Timestamp':
      case 'Number':
      case 'Boolean':
      case 'Text':
      case 'Null':
      case 'GetNode':
      case 'GetAttr':
      case 'RenderNothing':
      case 'ElementStyling':
        return u(node, prevAnc)

      case 'WorkflowRef':
        return {
          ...u(node, prevAnc),
          kwArgs: this.iterateKeyValue(fn, node.kwArgs, attr('kwArgs'))
        }
      case 'VariableStmt':
        return {
          ...u(node, prevAnc),
          dataType: this.iterate(fn, node.dataType, attr('dataType')),
          value: this.iterate(fn, node.value, attr('value'))
        }
      case 'List':
        return {
          ...u(node, prevAnc),
          items: this.iterate(fn, node.items, attr('items'))
        }
      case 'DefsList':
        return {
          ...u(node, prevAnc),
          items: this.iterate(fn, node.items, attr('items'))
        }
      case 'Group':
        return {
          ...u(node, prevAnc),
          child: this.iterate(fn, node.child, attr('child'))
        }
      case 'Record':
        return {
          ...u(node, prevAnc),
          fields: this.iterateKeyValue(fn, node.fields, attr('fields'))
        }
      case 'BinaryOp':
        return {
          ...u(node, prevAnc),
          left: this.iterate(fn, node.left, attr('left')),
          right: this.iterate(fn, node.right, attr('right'))
        }
      case 'DotOp': {
        const pNode = u(node, prevAnc)

        return {
          ...pNode,
          left: this.iterate(fn, pNode.left, attr('left')),
          right: this.iterate(fn, pNode.right, attr('right'))
        }
      }
      case 'CallMethod':
      case 'CallObjMethod':
        return {
          ...u(node, prevAnc),
          args: this.iterate(fn, node.args, attr('args')),
          kwArgs: this.iterateKeyValue(fn, node.kwArgs, attr('kwArgs'))
        }
      case 'RenderContext':
        return {
          ...u(node, prevAnc),
          items: this.iterate(fn, node.items, attr('items'))
        }
      case 'RenderHTMLTag':
        return {
          ...u(node, prevAnc),
          attributes: this.iterateKeyValue(fn, node.attributes, attr('attributes')),
          children: this.iterate(fn, node.children, attr('children'))
        }
      case 'RenderList':
        return {
          ...u(node, prevAnc),
          list: this.iterate(fn, node.list, attr('list')),
          yield: this.iterate(fn, node.yield, attr('yield'))
        }
      case 'RenderPageElement':
        return this.iteratePageElement(fn, node, ancestors)
        // return {
        //   ...u(node, prevAnc),
        //   element: this.iterate(fn, node.element, attr('element'))
        // }
      case 'RenderCond':
        return {
          ...u(node, prevAnc),
          condition: this.iterate(fn, node.condition, attr('condition')),
          then: this.iterate(fn, node.then, attr('then')),
          else: node.else === undefined ? undefined : this.iterate(fn, node.else, attr('else'))
        }
      case 'RenderEval':
        return {
          ...u(node, prevAnc),
          value: this.iterate(fn, node.value, attr('value'))
        }
      case 'PageElementClass':
        return {
          ...u(node, prevAnc),
          props: this.iterateKeyValue(fn, node.props, attr('props')),
          render: this.iterate(fn, node.render, attr('render'))
        }
      case 'Method':
        return {
          ...u(node, prevAnc),
          parameters: this.iterate(fn, node.parameters, attr('parameters')),
          kwParameters: this.iterateKeyValue(fn, node.kwParameters, attr('kwParameters'))
        }
      case 'Param': {
        const p: ST.Param = {
          ...u(node, prevAnc),
          dataTypes: this.iterate(fn, node.dataTypes, attr('dataTypes'))
        }
        const dv: ST.Lang | undefined = node.defaultValue
        if (dv != null) p.defaultValue = this.iterate(fn, dv, attr('defaultValue'))
        return p
      }
      case 'StaticClass':
        // TODO: this is probably wrong, do I need a list node instead of an array?
        return {
          ...u(node, prevAnc),
          props: this.iterateKeyValue(fn, node.props, attr('props')),
          methods: this.iterate(fn, node.methods, attr('methods'))
        }
      // case 'PropType':
      //   return {
      //     ...u(node, prevAnc),
      //     dataType: this.iterate(fn, node.dataType, attr('dataType')),
      //     defaultValue: this.iterate(fn, node.defaultValue, attr('defaultValue'))
      //   }
      case 'Workflow':
        console.log('WARNING: Workflow nodes are not handled by Cursor.iterate. Check cursor.ts to implement this.')
        return u(node, prevAnc)
      default:
        throw new Error(`iterateExp not implemented for ${JSON.stringify(node)}`)
    }
  }
}
