import { makeAutoObservable } from 'mobx'
import { VNode, ComponentChildren } from 'preact'
import { observer } from 'mobx-react'

export const TabButton = ({ title, help, active, callback }: { title: string, help: string, active: boolean, callback: () => void }): VNode<any> => {
  let tabAClasses = ''
  let tabBtnClasses = ''

  if (active) {
    tabAClasses = 'bg-container-bg'
    tabBtnClasses = 'bt-container-bg border-r-bg-container'
  } else {
    tabAClasses = 'opacity-60 hover:opacity-100'
    tabBtnClasses = 'bg-content-bg border-r'
  }

  const click = (event): void => {
    event.preventDefault()
    callback()
  }

  return (
    <div className={'border-ridge border-b ' + tabBtnClasses}>
      <a href='#' className={'p-1 py-2 text-xs font-bold block ' + tabAClasses} title={title} onClick={click}>
        <div className='rotate-180' style='writing-mode: vertical-lr'>
          {title}
        </div>
      </a>
    </div>
  )
}

export const TabContent = observer(<TabIds extends string>({ id, children, manager }: { id: string, children: ComponentChildren, manager: TabManager<TabIds> }) => {
  return <div className={'flex-grow flex' + (manager.currentTab === id ? '' : ' hidden')}>{children}</div>
})

export class TabManager<TabIds extends string> {
  tabId: TabIds

  constructor (tabId: TabIds) {
    this.tabId = tabId
    makeAutoObservable(this)
  }

  get currentTab (): TabIds {
    return this.tabId
  }

  set currentTab (tabId: TabIds) {
    this.tabId = tabId
  }
}
