import * as Preact from 'preact'
import { DismissableBackdrop } from './pcomponents'

import { runInAction, makeAutoObservable } from 'mobx'
import { randomId } from './agjs'
import { observer } from 'mobx-react'
import { useEffect, useLayoutEffect, useRef, useState } from 'preact/hooks'

export type CloseFn = () => void
export type OverlayContentFn = (closeFn: CloseFn) => Preact.ComponentChild

type OverlayType = 'modal' | 'menu' | 'popover'

interface Point {
  x: number
  y: number
}
export type PlacementType = 'left-start' | 'left-center' | 'left-end' | 'top-start' | 'top-center' | 'top-end' | 'right-start' | 'right-center' | 'right-end' | 'bottom-start' | 'bottom-center' | 'bottom-end' | 'default'

interface OverlayItem {
  id: string
  contentFn: OverlayContentFn
  onBeforeClose?: () => void
  type: OverlayType
  placement: PlacementType
  ref?: HTMLElement
  position?: Point
  noBackdrop?: boolean
  anmiatedOutline?: boolean
  outlineOrigin?: HTMLElement
}

interface OverlayOpts {
  noBackdrop?: boolean
  animatedOutline?: boolean
  outlineOrigin?: HTMLElement
  placement?: PlacementType
  onBeforeClose?: () => void
}

class OverlayManager {
  overlays: OverlayItem[] = []

  // Declare these as arrow function to keep the proper 'this' context when exporting
  // the instance functions.
  openModal = (contentFn: OverlayContentFn, opts: OverlayOpts = {}): CloseFn => {
    return this.openOverlay(contentFn, 'modal', 'default', opts.outlineOrigin ?? null, opts)
  }

  openMenu = (contentFn: OverlayContentFn, ref: HTMLElement | Point, opts: OverlayOpts = {}): CloseFn => {
    return this.openOverlay(contentFn, 'menu', 'bottom-start', ref, opts)
  }

  openPopover = (contentFn: OverlayContentFn, ref: HTMLElement, placement: PlacementType, opts: OverlayOpts = {}): CloseFn => {
    return this.openOverlay(contentFn, 'popover', placement, ref, opts)
  }

  constructor () {
    makeAutoObservable(this)
  }

  closeOverlay (id: string): void {
    const o = this.overlays.find(item => item.id === id)

    o?.onBeforeClose?.()

    runInAction(() => {
      this.overlays = this.overlays.filter(item => item.id !== id)
    })
  }

  openOverlay (contentFn: OverlayContentFn, type: OverlayType, placement: PlacementType, ref: HTMLElement | Point | null, opts: OverlayOpts): CloseFn {
    const id = randomId()
    runInAction(() => {
      const item: OverlayItem = { id, contentFn, type, placement }
      if (ref != null) {
        const pos = ref as Point
        if (pos.x != null && pos.y != null) {
          item.position = pos
        } else {
          item.ref = ref as HTMLElement
        }
      }

      if (opts.animatedOutline != null) item.anmiatedOutline = true
      if (opts.outlineOrigin != null) item.outlineOrigin = opts.outlineOrigin
      if (opts.noBackdrop != null) item.noBackdrop = true
      if (opts.placement != null) item.placement = opts.placement
      if (opts.onBeforeClose != null) item.onBeforeClose = opts.onBeforeClose

      this.overlays.push(item)
    })
    return () => this.closeOverlay(id)
  }
}

const om = new OverlayManager()

/**
 * Returns true if a modal or popover is visible (open).
 * Ignores menu overlays, since this is used to determine if hotkeys should be disabled
 */
export function nonMenuOverlaysVisible (): boolean { return om.overlays.find(overlay => overlay.type === 'modal' || overlay.type === 'popover') != null }
export function closeAllMenus (): void {
  om.overlays.forEach(overlay => {
    if (overlay.type === 'menu') om.closeOverlay(overlay.id)
  })
}

export const openModal = om.openModal
export const openMenu = om.openMenu
export const openPopover = om.openPopover

/**
 * The rect refers to the reference element (where the overlay is attahed to).
 * The resulting coordinates signify where the 'pointer' of the overlay should point to.
 */
function anchorPoint (placement: PlacementType, rect: DOMRect): { x: number, y: number } {
  let x = 0
  let y = 0

  switch (placement) {
    case 'bottom-start':
      x = rect.left
      y = rect.bottom
      break
    case 'bottom-center':
      x = rect.left + (rect.width / 2)
      y = rect.bottom
      break
    case 'bottom-end':
      x = rect.right
      y = rect.bottom
      break
    case 'top-start':
      x = rect.left
      y = rect.top
      break
    case 'top-center':
      x = rect.left + (rect.width / 2)
      y = rect.top
      break
    case 'top-end':
      x = rect.right
      y = rect.top
      break
    case 'left-start':
      x = rect.left
      y = rect.top
      break
    case 'left-center':
      x = rect.left
      y = rect.top + (rect.height / 2)
      break
    case 'left-end':
      x = rect.left
      y = rect.bottom
      break
    case 'right-start':
      x = rect.right
      y = rect.top
      break
    case 'right-center':
      x = rect.right
      y = rect.top + (rect.height / 2)
      break
    case 'right-end':
      x = rect.right
      y = rect.bottom
      break
    case 'default':
      x = rect.left
      y = rect.bottom
      break
  }
  return { x, y }
}

/**
 * Calculates the position (top/left) that the overlay has to be moved to so that its correct corner/side
 * faces the anchorPoint of the reference element.
 */
function overlayPositionPoint (placement: PlacementType, anchor: { x: number, y: number }, overlay: DOMRect): { x: number, y: number } {
  let x = 0
  let y = 0

  switch (placement) {
    case 'bottom-start':
      x = anchor.x
      y = anchor.y
      break
    case 'bottom-center':
      x = anchor.x - (overlay.width / 2)
      y = anchor.y
      break
    case 'bottom-end':
      x = anchor.x - overlay.width
      y = anchor.y
      break
    case 'top-start':
      x = anchor.x
      y = anchor.y - overlay.height
      break
    case 'top-center':
      x = anchor.x - (overlay.width / 2)
      y = anchor.y - overlay.height
      break
    case 'top-end':
      x = anchor.x - overlay.width
      y = anchor.y - overlay.height
      break
    case 'left-start':
      x = anchor.x - overlay.width
      y = anchor.y
      break
    case 'left-center':
      x = anchor.x - overlay.width
      y = anchor.y - (overlay.height / 2)
      break
    case 'left-end':
      x = anchor.x - overlay.width
      y = anchor.y - overlay.height
      break
    case 'right-start':
      x = anchor.x
      y = anchor.y
      break
    case 'right-center':
      x = anchor.x
      y = anchor.y - (overlay.height / 2)
      break
    case 'right-end':
      x = anchor.x
      y = anchor.y - overlay.height
      break
    case 'default':
      x = 0
      y = 0
      break
  }
  return { x, y }
}

const Positioning = ({ children, item }: { children: Preact.ComponentChildren, item: OverlayItem }): Preact.VNode => {
  // FIXME: It would be nice to support animatedOutline for modals as well (also on closing!)
  // this needs more work though, if children are rendered after all this it kind of works,
  // but layout from the modal is off.
  // For this to properly work, the first wrapper from the modal dialog markup needs to be rendered
  // here too. Right now this is happening (expected to be) from OverlayContentFn
  if (item.type === 'modal') return <>{children}</>

  const [renderCount, setRenderCount] = useState(0)
  const [layoutPhase, setLayoutPhase] = useState<'positioning' | 'outline' | 'completed'>('positioning')

  const [placement, setPlacement] = useState(item.placement)
  const [ofsX, setOfsX] = useState(0)
  const [ofsY, setOfsY] = useState(0)

  const overlayRef = useRef<HTMLDivElement>(null)

  let anchorX = 0
  let anchorY = 0

  const finishPositioning = (): void => {
    if (layoutPhase === 'positioning') {
      setLayoutPhase(item.anmiatedOutline === true ? 'outline' : 'completed')
    }
  }

  const refOrPositionRect = (): DOMRect | null => {
    if (item.ref != null) return item.ref.getBoundingClientRect()
    if (item.position != null) {
      return new DOMRect(item.position.x, item.position.y, 0, 0)
    }
    return null
  }

  const setAnchor = (): boolean => {
    let updated = false
    const rect = refOrPositionRect()
    if (rect != null) {
      const { x, y } = anchorPoint(placement, rect)

      if (anchorX !== x || anchorY !== y) updated = true
      anchorX = x
      anchorY = y
    }
    return updated
  }

  setAnchor()

  const adjustPosition = (): void => {
    const attachmentRect = refOrPositionRect()

    if (attachmentRect == null || overlayRef.current == null) return

    if (setAnchor()) {
      setRenderCount(renderCount + 1)
    }

    const rect = overlayRef.current.getBoundingClientRect()

    const wrapper = document.getElementById('studio-wrapper')
    if (wrapper == null) throw new Error('no #studio-wrapper element')
    const body = wrapper.getBoundingClientRect()

    const screenWidth = body.width
    const screenHeight = body.height

    const { x, y } = overlayPositionPoint(placement, { x: anchorX, y: anchorY }, rect)

    const adjustX = x - anchorX
    const adjustY = y - anchorY

    // Attach opposite point of overlay to opposite point of reference box
    const [plSide, plAlign] = placement.split('-')

    let newSide = plSide
    let newAlign = plAlign

    const fitsTop = anchorY + adjustY >= 0
    const fitsBottom = anchorY + adjustY + rect.height <= screenHeight

    const fitsLeft = anchorX + adjustX >= 0
    const fitsRight = anchorX + adjustX + rect.width <= screenWidth

    if (plSide === 'bottom' || plSide === 'top') {
      if (plAlign === 'end' && !fitsLeft) newAlign = 'start'
      if (plAlign === 'start' && !fitsRight) newAlign = 'end'
      if (plSide === 'bottom' && !fitsBottom) newSide = 'top'
      if (plSide === 'top' && !fitsTop) newSide = 'bottom'
    }

    if (newSide !== plSide || newAlign !== plAlign) {
      // TODO: maybe this can be optimized that initial position calc is done entirely in this callback and
      // preventing an unnecessary slow re-render, preventing flickering?
      // I managed to get this under control with setting visibility: hidden on the first render, but that still
      // shows a scrollbar for a brief moment (could be averted by setting overflow: none to some of the outer divs).
      setPlacement(`${newSide}-${newAlign}` as PlacementType)
      finishPositioning()
      return
    }

    if (adjustX !== 0 || adjustY !== 0) {
      setOfsX(adjustX)
      setOfsY(adjustY)
    }
    finishPositioning()
  }

  // Here are some potential performance optimizations- adjustPosition could be piped through useCallback, otherwise
  // the observer will be destroyed and recreated in every re-render.
  // This is necessary because the adjustPosition callback is different every time.
  useEffect(() => {
    const resizeObserver = new ResizeObserver(adjustPosition)
    resizeObserver.observe(document.getElementsByTagName('body')[0], { box: 'border-box' })
    if (item.ref != null) resizeObserver.observe(item.ref, { box: 'border-box' })
    if (overlayRef.current != null) resizeObserver.observe(overlayRef.current, { box: 'border-box' })
    return () => resizeObserver.disconnect()
  }, [item.ref, overlayRef.current, adjustPosition])

  useLayoutEffect(() => adjustPosition(), [])

  const hiddenVisibility = layoutPhase === 'positioning' ? 'opacity: 0;' : 'opacity: 1;'

  const outlineMarkup = (): Preact.VNode | null => {
    if (layoutPhase !== 'outline' || overlayRef.current == null) return null

    // TODO: if outlineOrigin was specified, we should take the whole shape and not only the center
    // (calculated below)
    const ref = item.outlineOrigin?.getBoundingClientRect() ?? refOrPositionRect()
    if (ref == null) return null

    const overlayRect = overlayRef.current.getBoundingClientRect()

    // if we would just use the overlayRect here, we would still get the original position
    // (if it was repositioned during layout phase), because the div is rendered after the outline element.
    // so manually calculate the starting pos. We assume width and height stay the same since there is no support
    // to adjust these yet.
    const target = new DOMRect(anchorX + ofsX, anchorY + ofsY, overlayRect.width, overlayRect.height)
    const refCenter = new DOMRect(ref.x + (ref.width / 2), ref.y + (ref.height / 2), 0, 0)
    return (<AnimatedOutline origin={refCenter} target={target} onFinish={() => setLayoutPhase('completed')} />)
  }

  return (
    <>
      <div
        className={`position-absolute ${item.anmiatedOutline === true ? 'animated-overlay' : ''}`}
        ref={overlayRef}
        style={`transform: translate3d(${anchorX + ofsX}px, ${anchorY + ofsY}px, 0);${hiddenVisibility}`}
      >
        {children}
      </div>
      {outlineMarkup()}
    </>
  )
}

const AnimatedOutline = ({ origin, target, onFinish }: { origin: DOMRect | null, target: DOMRect | null, onFinish?: () => void }): Preact.VNode | null => {
  if (origin == null || target == null) return null
  return (
    <div
      className='animated-outline'
      onAnimationEnd={onFinish}
      style={
    ` --start-left: ${origin.left}px;
    --start-top: ${origin.top}px;
    --start-width: ${origin.width}px;
    --start-height: ${origin.height}px;
    --end-left: ${target.left}px;
    --end-top: ${target.top}px;
    --end-width: ${target.width}px;
    --end-height: ${target.height}px;`
}
    />
  )
}

export const PopoverContent = ({ title, children }): Preact.VNode => (
  <div className='popover bs-popover-auto fade show'>
    <div className='popover-arrow position-absolute' />
    {title != null && <h3 className='popover-header'>{title}</h3>}
    <div className='popover-body'>
      {children}
    </div>
  </div>
)

// Attention. Originally this function was contained in the Overlays component. This somehow triggered a complete remounting
// in the DOM every time preact looped through the OverlayElements.
const OverlayElement = ({ item, shadedBackdrop }: { item: OverlayItem, shadedBackdrop: boolean }): Preact.VNode => {
  const inner = <Positioning item={item}>{item.contentFn(() => om.closeOverlay(item.id))}</Positioning>

  if (item.noBackdrop === true) return inner

  return (
    <DismissableBackdrop
      shade={shadedBackdrop}
      onDismiss={() => om.closeOverlay(item.id)}
      bootstrapModal
    >
      {inner}
    </DismissableBackdrop>
  )
}

export const Overlays = observer(() => {
  const items = om.overlays.slice()

  if (items.length === 0) return null

  const rev = items.slice().reverse()
  let shadedIndex = rev.findIndex(item => item.type === 'modal')
  if (shadedIndex !== -1) shadedIndex = (items.length - 1) - shadedIndex

  return (
    <>
      {items.map((item, index) => <OverlayElement item={item} shadedBackdrop={index === shadedIndex} key={item.id} />)}
    </>
  )
})
